export const ACTIVITIES: Record<string, Record<string, string>> = {
   toDoDateTime: {
      status: 'To Do',
      statusExtended: 'Task got Available for Solution',
      message: 'The answer has not been submitted yet',
      class: 'in-progress',
      side: 'mentor'
   },
   inProgressDateTime: {
      status: 'In Progress',
      statusExtended: 'Task got in Progress',
      message: 'The answer has not been submitted yet',
      class: 'in-progress',
      side: 'student'
   },
   answerSubmittedDateTime: {
      status: 'Submitted',
      statusExtended: 'Answer got Submitted',
      message: 'The answer has been successfully submitted',
      class: 'submitted',
      side: 'student'
   },
   answerInReviewDateTime: {
      status: 'In Review',
      statusExtended: 'Answer Opened for Review',
      message: 'The answer has been successfully submitted',
      class: 'submitted',
      side: 'mentor'
   },
   answerRejectedDateTime: {
      status: 'Rejected',
      statusExtended: 'Answer Failed Review',
      message: 'Your answer has failed review. Try again',
      class: 'rejected',
      side: 'mentor'
   },
   answerAcceptedDateTime: {
      status: 'Accepted',
      statusExtended: 'Answer got Accepted',
      message: 'The task has been completed successfully',
      class: 'accepted',
      side: 'mentor'
   },
};

export const studentFeedbackType = ['course', 'topic', 'task'];

export const uIElements = {
   EXACT_TEXT: {
      subTitle: 'Exact Text Question',
   },
   SINGLE_CHOICE: {
      subTitle: 'Single Choice Question',
   },
   MULTI_CHOICE: {
      subTitle: 'Multiple Choice Question',
   },
   MATCHING: {
      subTitle: 'Matching Question',
   },
   TEXT_FOR_MENTOR: {
      subTitle: 'Text for Mentor',
   },
};

//router
import { useParams } from 'react-router-dom';
import { ENDPOINTS } from '../../../../http/studentAPI';
//project imports
import useProtectedContent from '../../hooks/useProtectedContent';
import { ICourse } from '../../interfaces/interfaces';
//components
import {
   BannerCourse,
   CourseInfoBar,
   TasksAndResultSection,
   AboutThisCourse,
   AboutTheAuthor,
   Feedback,
   StudentFeedback,
   CourseSlider,
} from '../../components/studentCourse';
import { Loader } from '../../components/Loader';
import {useEffect, useState} from "react";
import {ICourseFeedback, studentCourseFeedback} from "../../../../http/studentsAPI";
import {commentsLengthChecker} from "../../../../utils/utils";

export const StudentCourse = () => {
   const [feedback, setFeedback] = useState<ICourseFeedback | null>(null)
   const { title } = useParams();
   const courseContent = useProtectedContent(
      ENDPOINTS.course(title!),
   ) as ICourse;

  useEffect(() => {
    if (courseContent?.id) {
      studentCourseFeedback(courseContent.id)
        .then(feedback => setFeedback({
          ...feedback,
          comments: commentsLengthChecker(feedback.comments)
        }))
    }
  }, [courseContent])

   if (!courseContent) return <Loader />;

   const { courseTitle, courseDescription, topicToWorkCourse, id } =
      courseContent;

   return (
      <>
         <BannerCourse title={courseTitle} description={courseDescription} />
         <CourseInfoBar />
         <TasksAndResultSection courseItems={topicToWorkCourse} id={id} />
         <AboutThisCourse />
         <AboutTheAuthor />
          {!feedback ? <Loader /> : <Feedback feedback={feedback} />}
         <StudentFeedback />
         <CourseSlider />
      </>
   );
};

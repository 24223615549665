import { BorderedBlockContainer } from '../../../UI';
import { students } from '../../mockData';
import styles from './Students.module.scss';

export const Students = () => {
   return (
      <BorderedBlockContainer marginBottom='20px'>
         {students.map(
            ({ studentFirstName, studentLastName, courseTitle, id }) => (
               <div key={id} className={styles.row}>
                  <div className={styles.picture} />

                  <div>
                     <strong>
                        {studentFirstName} {studentLastName}
                     </strong>
                     <p>{courseTitle}</p>
                  </div>
               </div>
            ),
         )}
      </BorderedBlockContainer>
   );
};

import React, { CSSProperties } from 'react';

// styles
import styles from './ErrorMessage.module.scss';

interface Props {
   message?: string;
   style?: CSSProperties;
   className?: string;
}

export function ErrorMessage({ message, style, className }: Props) {
   const text = message ? message : 'Error while loading data...';

   return (
      <p className={[styles.text, className].join(' ')} style={style}>
         {text}
      </p>
   );
}
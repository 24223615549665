import { FC } from 'react';
import style from './CardCommentOfTeacher.module.scss'

interface CommentOfTeachersProps {
  imgUser: string;
  userName: string;
  comment: string;
  course: string;
}

const CardCommentOfTeacher: FC<CommentOfTeachersProps> = ({imgUser, userName, comment, course}) => {
  return (
    <div className={style.card_comment_teacher}>
      <img src={imgUser} alt="user" />
      <div className={style.wrapper_data}>
        <p>{comment}</p>
        <h2>{userName}</h2>
        <p>{course}</p>
      </div>
    </div>
  )
}

export default CardCommentOfTeacher
import { observer } from 'mobx-react-lite';
//components
import { MentorHomePageTabs } from '../../components/MentorHomePageTabs';
//router
import { Outlet } from 'react-router-dom';
//styles
import styles from './MentorHomePage.module.scss';

export const MentorHomePage = observer(() => {
   return (
      <>
         <div className={styles.hero}>
            <div className={styles.hero_container}>
               <h2>Mentor</h2>
               <MentorHomePageTabs />
            </div>
         </div>
         <div className={styles.content_container}>
            <Outlet />
         </div>
      </>
   );
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuizCourseInfo_courseInfo__GWTni {\n  display: flex;\n  width: 100%;\n}\n\n.QuizCourseInfo_image__X7GRl {\n  width: 60px;\n}\n.QuizCourseInfo_image__X7GRl img {\n  width: 100%;\n}\n\n.QuizCourseInfo_info__a-Xro {\n  margin-left: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.QuizCourseInfo_info__a-Xro h3 {\n  font-size: 20px;\n  font-weight: 600;\n}\n.QuizCourseInfo_info__a-Xro p {\n  margin-top: 5px;\n  font-size: 16px;\n  font-weight: 400;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/QuizCourseInfo/QuizCourseInfo.module.scss"],"names":[],"mappings":"AAEA;EACG,aAAA;EACA,WAAA;AADH;;AAIA;EACG,WAAA;AADH;AAGG;EACG,WAAA;AADN;;AAKA;EACG,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAFH;AAIG;EACG,eAAA;EACA,gBAAA;AAFN;AAKG;EACG,eAAA;EACA,eAAA;EACA,gBAAA;AAHN","sourcesContent":["@use '../../../../../theme/config' as v;\n\n.courseInfo {\n   display: flex;\n   width: 100%;\n}\n\n.image {\n   width: 60px;\n\n   & img {\n      width: 100%;\n   }\n}\n\n.info {\n   margin-left: 10px;\n   display: flex;\n   flex-direction: column;\n   justify-content: center;\n\n   & h3 {\n      font-size: 20px;\n      font-weight: 600;\n   }\n\n   & p {\n      margin-top: 5px;\n      font-size: 16px;\n      font-weight: 400;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseInfo": "QuizCourseInfo_courseInfo__GWTni",
	"image": "QuizCourseInfo_image__X7GRl",
	"info": "QuizCourseInfo_info__a-Xro"
};
export default ___CSS_LOADER_EXPORT___;

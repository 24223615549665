import styles from './QuizMatchingItem.module.scss';

interface IProps {
   matchingAnswers: string[];
   matchingQuestions: string[];
   handleChange: (e: React.ChangeEvent<HTMLSelectElement>, aswer: string) => void;
}

export const QuizMatchingItem: React.FC<IProps> = ({
   matchingAnswers,
   matchingQuestions,
   handleChange,
}) => {
   return (
      <>
         {matchingAnswers.map((answer, i) => (
            <label key={i} className={styles.label_item}>
               <p className={styles.item_answer}>{answer}</p>
               <select
                  className={styles.item_select}
                  defaultValue='DEFAULT'
                  onChange={(e) => {
                     handleChange(e, answer);
                  }}
               >
                  <option value='DEFAULT' disabled>
                     Chose your answer
                  </option>
                  {matchingQuestions.map((quest, i) => (
                     <option value={`${quest}`} key={i}>
                        {quest}
                     </option>
                  ))}
               </select>
            </label>
         ))}
      </>
   );
};
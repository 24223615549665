import { FC } from 'react';
import { PageContainer } from '../../UI';
import { AllStudents } from '../components/AllStudents';
import styles from './ViewAllStudents.module.scss';

const ViewAllStudents: FC = () => {
    return (
        <>
            <PageContainer>
                <section className={styles.container}>
                    <AllStudents />
                </section>
            </PageContainer>
        </>
    )
}

export default ViewAllStudents;
import { v4 } from 'uuid';
//components
import { PageContainer } from '../../../../../UI';
//project imports
import { imgTopCategories } from '../mockData';
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
//styles
import styles from './TopCategories.module.scss';

export const TopCategories = () => {
   const { windowWidth } = useWindowDimensions();
   return (
      <section className={styles.container}>
         <PageContainer>
            <h2>Top categories</h2>
            {windowWidth > 600 ? (
               <div className={styles.grid_container}>
                  {imgTopCategories.map((cat) => (
                     <a href='#' key={v4()} className={styles.grid_link}>
                        <div className={styles.grid_item}>
                           <img src={cat.img} alt={cat.category} />
                        </div>
                        <span className={styles.title}>{cat.category}</span>
                     </a>
                  ))}
               </div>
            ) : (
               <ul className={styles.mobile_container}>
                  {imgTopCategories.map((cat) => (
                     <li key={v4()}>
                        <a href='#'>{cat.category}</a>
                     </li>
                  ))}
               </ul>
            )}
         </PageContainer>
      </section>
   );
};

import img1 from '../../../../../assets/images/guestHome/udemy-slider-1.jpg';
import img2 from '../../../../../assets/images/guestHome/udemy-slider-2.jpg';
import img3 from '../../../../../assets/images/guestHome/udemy-slider-3.jpg';
import img4 from '../../../../../assets/images/guestHome/udemy-slider-4.jpg';
import img1mobile from '../../../../../assets/images/guestHome/udemy-slider-1-mobile.jpg';
import img2mobile from '../../../../../assets/images/guestHome/udemy-slider-2-mobile.jpg';
import img3mobile from '../../../../../assets/images/guestHome/udemy-slider-3-mobile.jpg';
import img4mobile from '../../../../../assets/images/guestHome/udemy-slider-4-mobile.jpg';

export const dataHero = [
   {
      img: img1,
      imgMobile: img1mobile,
      title: 'Unlock the power of your people',
      description:
         'Udemy Business is trusted by 12.5K+ companies around the world. Find out what we can do for yours. ',
   },
   {
      img: img2,
      imgMobile: img2mobile,
      title: 'Learning that gets you',
      description:
         'Skills for your present (and your future). Get started with us.',
   },
   {
      img: img3,
      imgMobile: img3mobile,
      title: 'Sale ends today',
      description:
         'Stay on top of the skills you need. Log in now to see deals on courses.',
   },
   {
      img: img4,
      imgMobile: img4mobile,
      title: 'The latest in learning',
      description:
         'Stay on top of the skills you need. Log in for deals on courses. Sale ends April 6.',
   },
];

export const titleCourses = [
   {
      id: 1,
      title: 'Front-End',
      description:
         'Frontend - This discovers any software system or application what the user sees and interacts with. The development interface is one of the most interesting and creative developments.',
      courses: [
         {
            id: 1,
            logo: 'https://img-b.udemycdn.com/course/240x135/3624936_53aa_2.jpg',
            subcategory: 'Front-End',
            title:
               'Complete Vue Developer 2023: Zero to Mastery (Pinia, Vitest)',
            price: 150,
            rating: 4.7,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Chris Walker',
            countVoters: 1350,
            slug: '/preview/vue-js',
            additionalData: {
               title: 'Complete Vue Developer 2023: Zero to Mastery (Pinia, Vitest)',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 2,
            logo: 'https://img-b.udemycdn.com/course/240x135/756150_c033_2.jpg',
            subcategory: 'Front-End',
            title: 'Angular',
            price: 150,
            rating: 4.1,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Leon Fuller',
            countVoters: 577,
            additionalData: {
               title: 'Angular',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 3,
            logo: 'https://img-b.udemycdn.com/course/240x135/364426_2991_6.jpg',
            subcategory: 'Front-End',
            title: 'JavaScript Start',
            price: 80,
            rating: 4.8,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Jack Simmons',
            countVoters: 223,
            additionalData: {
               title: 'JavaScript Start',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 4,
            logo: 'https://img-b.udemycdn.com/course/240x135/705264_caa9_13.jpg',
            subcategory: 'Front-End',
            title: 'React Plus',
            price: 300,
            rating: 4.0,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Robert Gomez',
            countVoters: 132,
            additionalData: {
               title: 'React Plus',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
      ],
   },
   {
      id: 2,
      title: 'Back-End',
      description:
         'Backend - Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
      courses: [
         {
            id: 5,
            logo: 'https://img-b.udemycdn.com/course/240x135/4149968_c1c2.jpg',
            subcategory: 'Back-End',
            title: 'Java Spring',
            price: 150,
            rating: 4.7,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Bruce Spencer',
            countVoters: 5889,
            additionalData: {
               title: 'Java Spring',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 6,
            logo: 'https://img-c.udemycdn.com/course/240x135/1581488_e3e1_2.jpg',
            subcategory: 'Back-End',
            title: 'C#/.NET',
            price: 150,
            rating: 4.1,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Peter Wright',
            countVoters: 1777,
            additionalData: {
               title: 'C#/.NET',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 7,
            logo: 'https://img-b.udemycdn.com/course/240x135/2485240_d405_7.jpg',
            subcategory: 'Back-End',
            title: 'Python',
            price: 150,
            rating: 4.5,
            createdBy: 'Manager1 Manager1',
            mentorName: 'James Jensen',
            countVoters: 845,
            additionalData: {
               title: 'Python',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 8,
            logo: 'https://img-b.udemycdn.com/course/240x135/405282_27d2_3.jpg',
            subcategory: 'Back-End',
            title: 'PHP',
            price: 150,
            rating: 5,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Richard Martinez',
            countVoters: 22,
            additionalData: {
               title: 'PHP',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 9,
            logo: 'https://img-b.udemycdn.com/course/240x135/1879018_95b6_3.jpg',
            subcategory: 'Back-End',
            title: 'Node JS',
            price: 150,
            rating: 4.8,
            createdBy: 'Manager1 Manager1',
            mentorName: 'James Wells',
            countVoters: 7892,
            additionalData: {
               title: 'Node JS',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 10,
            logo: 'https://img-c.udemycdn.com/course/240x135/2669808_fcbe_3.jpg',
            subcategory: 'Back-End',
            title: 'Java "Start"',
            price: 80,
            rating: 4.8,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Anthony Williams',
            countVoters: 321,
            additionalData: {
               title: 'Java "Start"',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 11,
            logo: 'https://img-c.udemycdn.com/course/240x135/827692_91ad_2.jpg',
            subcategory: 'Back-End',
            title: 'Java "Tools"',
            price: 105,
            rating: 4.5,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Anthony Ellis',
            countVoters: 221,
            additionalData: {
               title: 'Java "Tools"',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 12,
            logo: 'https://img-b.udemycdn.com/course/240x135/2707184_4c5e_10.jpg',
            subcategory: 'Back-End',
            title: 'Java Spring Plus',
            price: 300,
            rating: 4.2,
            createdBy: 'Manager1 Manager1',
            mentorName: 'George Walker',
            countVoters: 3579,
            additionalData: {
               title: 'Java Spring Plus',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
      ],
   },
   {
      id: 3,
      title: 'Mobile Development',
      description:
         'Mobile Development - operating system for touch screen mobile devices. The number of mobile Internet users in 2017 exceeded the number of users of desktop applications for the web. Android development starts with learning Java and XML, recently Kotlin has been added to this list.',
      courses: [
         {
            id: 13,
            logo: 'https://img-c.udemycdn.com/course/240x135/1992186_0de2_13.jpg',
            subcategory: 'Mobile Development',
            title: 'Android',
            price: 150,
            rating: 4.7,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Richard Aguilar',
            countVoters: 338,
            additionalData: {
               title: 'Android',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 14,
            logo: 'https://img-b.udemycdn.com/course/240x135/2460442_4106_7.jpg',
            subcategory: 'Mobile Development',
            title: 'iOS',
            price: 150,
            rating: 4.1,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Derek Wilson',
            countVoters: 4578,
            additionalData: {
               title: 'iOS',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 15,
            logo: 'https://img-c.udemycdn.com/course/240x135/4390930_5cdc_8.jpg',
            subcategory: 'Mobile Development',
            title: 'Android Plus',
            price: 300,
            rating: 4.8,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Randy Williams',
            countVoters: 985,
            additionalData: {
               title: 'Android Plus',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 16,
            logo: 'https://img-b.udemycdn.com/course/240x135/2896370_ca99_8.jpg',
            subcategory: 'Mobile Development',
            title: 'iOS Plus',
            price: 300,
            rating: 4.0,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Dean Rogers',
            countVoters: 211,
            additionalData: {
               title: 'iOS Plus',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
      ],
   },
   {
      id: 4,
      title: 'Game Development',
      description:
         'Game Development - one of the oldest and most popular game engines, owned by the well-known company Epic Games. On this engine, you can create first-person shooters, but its functionality is not limited to this. Whether you want to create world-class PC games, on your own or as part of a large studio, you need to be proficient with Unreal Engine. And thanks to FoxmindEd mentored training, you learn what real problems an Unreal Engine developer solves, and how exactly.',
      courses: [
         {
            id: 17,
            logo: 'https://img-c.udemycdn.com/course/240x135/4915192_86f5_8.jpg',
            subcategory: 'Game Development',
            title: 'Unreal Engine',
            price: 150,
            rating: 4.7,
            createdBy: 'Manager1 Manager1',
            mentorName: 'George Walker',
            countVoters: 781,
            additionalData: {
               title: 'Unreal Engine',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 18,
            logo: 'https://img-c.udemycdn.com/course/240x135/1328572_b05d_5.jpg',
            subcategory: 'Game Development',
            title: 'Unity',
            price: 150,
            rating: 4.1,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Richard Aguilar',
            countVoters: 9852,
            additionalData: {
               title: 'Unity',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 19,
            logo: 'https://img-c.udemycdn.com/course/240x135/4850786_3890.jpg',
            subcategory: 'Game Development',
            title: 'Unreal Engine Plus',
            price: 300,
            rating: 4.0,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Charles Garcia',
            countVoters: 12574,
            additionalData: {
               title: 'Unreal Engine Plus',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
         {
            id: 20,
            logo: 'https://img-c.udemycdn.com/course/240x135/4308321_1608_3.jpg',
            subcategory: 'Game Development',
            title: 'Unity Plus',
            price: 300,
            rating: 4.0,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Dwayne Turner',
            countVoters: 875,
            additionalData: {
               title: 'Unity Plus',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
      ],
   },
   {
      id: 5,
      title: 'UI/UX Design',
      description:
         'UI / UX product design is a way of developing a product that will solve certain business problems, and at the same time be user-friendly. The demand for UI/UX designers is huge, because in the software market it is difficult for a poorly designed product to compete with other solutions.',
      courses: [
         {
            id: 21,
            logo: 'https://img-c.udemycdn.com/course/240x135/4060612_7bfe_3.jpg',
            subcategory: 'UI/UX Design',
            title: 'UI/UX Design',
            price: 150,
            rating: 4.7,
            createdBy: 'Manager1 Manager1',
            mentorName: 'Kenneth Wilson',
            countVoters: 697,
            additionalData: {
               title: 'UI/UX Design',
               updated: 'March 2021',
               subtitle: '22 total hours All Levels Subtitles',
               description:
                  'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
               checkedPoints: [
                  'You will learn how to leverage the power of Python to solve tasks.',
                  'You will build games and programs that use Python libraries.',
                  'You will be able to use Python for your own work problems or personal projects.',
               ],
            },
         },
      ],
   },
];

export const comments = [
   {
      userName: 'Will A',
      comment:
         'I am proud to say that after a few months of taking this course...I passed my exam and am now an AWS Certified Cloud Practitioner! This content was exactly what the CCP exam covered.',
      course: 'Python',
   },
   {
      userName: 'Ron F',
      comment:
         'This course helped me freshen up on my product manager skills and land a job at Facebook! Thanks guys :)',
      course: 'React Plus',
   },
   {
      userName: 'Phillip W',
      comment:
         'One of the best courses on management and leadership I have come across so far. The advice is practical, and examples highly relatable. Would help anyone become a better manager.',
      course: 'Java Spring',
   },
   {
      userName: 'Surya M',
      comment:
         'I highly recommend this course for all budding data scientists. Even people with no prior knowledge of any visualization tools can become a master after completing this course.',
      course: 'Angular',
   },
];

export const questions = [
   {
      id: 1,
      topic: 'Lessons',
      category: 'Angular',  
      userName: 'Will A',
      userID: 1,
      title:
         'Using Angular HttpClientModule instead of HttpModule',
      details: 'More text 1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores beatae quisquam iste maiores libero, corrupti totam saepe itaque quidem perspiciatis',
      course: 'Angular Fundamentals',
      answers: [
         {
            answerID: 1,
            answerText: 'Yes, this is very good idea!!!',
            author: 'Ron F',
         },
      ],
   },
   {
      id: 2,
      topic: 'General',
      category: '',  
      userName: 'Ron F',
      userID: 2,
      title:
         'Why am I getting an error when trying to install angular/http@2.4.2',
      details: 'More text 2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores beatae quisquam iste maiores libero, corrupti totam saepe itaque quidem perspiciatis',
      course: '',
      answers: [
         {
            answerID: 1,
            answerText: 'The error you are encountering when trying to install angular/http@2.4.2 could be due to compatibility issues or changes in the Angular ecosystem. The @angular/http module was deprecated in Angular 4 and replaced with the HttpClient module.',
            author: 'Will A',
         },
      ],
   },
   {
      id: 3,
      topic: 'Lessons',
      category: 'Java Spring',  
      userName: 'Phillip W',
      userID: 3,
      title:
         'Using Angular HttpClientModule instead of HttpModule',
      details: 'More text 3. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores beatae quisquam iste maiores libero, corrupti totam saepe itaque quidem perspiciatis',
      course: 'Java Spring',
      answers: [
         {
            answerID: 1,
            answerText: 'Yes, this is very good idea!!!',
            author: 'Ron F',
         },
         {
            answerID: 2,
            answerText: 'The error you are encountering when trying to install angular/http@2.4.2 could be due to compatibility issues or changes in the Angular ecosystem. The @angular/http module was deprecated in Angular 4 and replaced with the HttpClient module.',
            author: 'Fillip M',
         },
         {
            answerID: 3,
            answerText: 'I do not now man)',
            author: 'Ronny',
         },
      ],
   },
   {
      id: 4,
      topic: 'Customer Support',
      category: '',  
      userName: 'Surya M',
      userID: 4,
      title:
         'Why am I getting an error when trying to install angular/http@2.4.2',
      details: 'More text 4. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores beatae quisquam iste maiores libero, corrupti totam saepe itaque quidem perspiciatis',
      course: '',
      answers: [
         {
            answerID: 1,
            answerText: 'Yes, this is very good idea!!!',
            author: 'Will A',
         },
         {
            answerID: 2,
            answerText: 'I do not now man)',
            author: 'Ronny',
         },
      ],
   },
   {
      id: 5,
      topic: 'Lessons',
      category: 'Angular',  
      userName: 'Will A',
      userID: 5,
      title:
         'Using Angular HttpClientModule instead of HttpModule',
      details: 'More text 5. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores beatae quisquam iste maiores libero, corrupti totam saepe itaque quidem perspiciatis',
      course: 'Angular Fundamentals',
      answers: [
         {
            answerID: 1,
            answerText: 'Yes, this is very good idea!!!',
            author: 'Ron F',
         },
      ],
   },
   {
      id: 6,
      topic: 'Lessons',
      category: 'Angular',  
      userName: 'Ron F',
      userID: 6,
      title:
         'Why am I getting an error when trying to install angular/http@2.4.2',
      details: 'More text 6. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores beatae quisquam iste maiores libero, corrupti totam saepe itaque quidem perspiciatis',
      course: '',
      answers: [
         {
            answerID: 1,
            answerText: 'The error you are encountering when trying to install angular/http@2.4.2 could be due to compatibility issues or changes in the Angular ecosystem. The @angular/http module was deprecated in Angular 4 and replaced with the HttpClient module.',
            author: 'Will A',
         },
      ],
   },
];

export const studentsViewingList = [
   {
      id: 1,
      logo: 'https://img-b.udemycdn.com/course/240x135/364426_2991_6.jpg',
      subcategory: 'Front End',
      title: 'JavaScript Start',
      price: 80,
      rating: 4.8,
      createdBy: 'Manager1 Manager1',
      mentorName: 'Jack Simmons',
      countVoters: 1235,
      additionalData: {
         title: 'JavaScript Start',
         updated: 'March 2021',
         subtitle: '22 total hours All Levels Subtitles',
         description:
            'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
         checkedPoints: [
            'You will learn how to leverage the power of Python to solve tasks.',
            'You will build games and programs that use Python libraries.',
            'You will be able to use Python for your own work problems or personal projects.',
         ],
      },
   },
   {
      id: 2,
      logo: 'https://img-b.udemycdn.com/course/240x135/705264_caa9_13.jpg',
      subcategory: 'Front End',
      title: 'React Plus',
      price: 300,
      rating: 4.0,
      createdBy: 'Manager1 Manager1',
      mentorName: 'Robert Gomez',
      countVoters: 2445,
      additionalData: {
         title: 'React Plus',
         updated: 'March 2021',
         subtitle: '22 total hours All Levels Subtitles',
         description:
            'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
         checkedPoints: [
            'You will learn how to leverage the power of Python to solve tasks.',
            'You will build games and programs that use Python libraries.',
            'You will be able to use Python for your own work problems or personal projects.',
         ],
      },
   },
   {
      id: 3,
      logo: 'https://img-b.udemycdn.com/course/240x135/2460442_4106_7.jpg',
      subcategory: 'Mobile Development',
      title: 'iOS',
      price: 150,
      rating: 4.1,
      createdBy: 'Manager1 Manager1',
      mentorName: 'Derek Wilson',
      countVoters: 412,
      additionalData: {
         title: 'iOS',
         updated: 'March 2021',
         subtitle: '22 total hours All Levels Subtitles',
         description:
            'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
         checkedPoints: [
            'You will learn how to leverage the power of Python to solve tasks.',
            'You will build games and programs that use Python libraries.',
            'You will be able to use Python for your own work problems or personal projects.',
         ],
      },
   },
   {
      id: 4,
      logo: 'https://img-c.udemycdn.com/course/240x135/4390930_5cdc_8.jpg',
      subcategory: 'Mobile Development',
      title: 'Android Plus',
      price: 300,
      rating: 4.8,
      createdBy: 'Manager1 Manager1',
      mentorName: 'Randy Williams',
      countVoters: 9854,
      additionalData: {
         title: 'Android Plus',
         updated: 'March 2021',
         subtitle: '22 total hours All Levels Subtitles',
         description:
            'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
         checkedPoints: [
            'You will learn how to leverage the power of Python to solve tasks.',
            'You will build games and programs that use Python libraries.',
            'You will be able to use Python for your own work problems or personal projects.',
         ],
      },
   },
   {
      id: 5,
      logo: 'https://img-c.udemycdn.com/course/240x135/1581488_e3e1_2.jpg',
      subcategory: 'Back End',
      title: 'C#/.NET',
      price: 150,
      rating: 4.1,
      createdBy: 'Manager1 Manager1',
      mentorName: 'Peter Wright',
      countVoters: 777,
      additionalData: {
         title: 'C#/.NET',
         updated: 'March 2021',
         subtitle: '22 total hours All Levels Subtitles',
         description:
            'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
         checkedPoints: [
            'You will learn how to leverage the power of Python to solve tasks.',
            'You will build games and programs that use Python libraries.',
            'You will be able to use Python for your own work problems or personal projects.',
         ],
      },
   },
   {
      id: 6,
      logo: 'https://img-b.udemycdn.com/course/240x135/2485240_d405_7.jpg',
      subcategory: 'Back End',
      title: 'Python',
      price: 150,
      rating: 4.5,
      createdBy: 'Manager1 Manager1',
      mentorName: 'James Jensen',
      countVoters: 999,
      additionalData: {
         title: 'Python',
         updated: 'March 2021',
         subtitle: '22 total hours All Levels Subtitles',
         description:
            'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
         checkedPoints: [
            'You will learn how to leverage the power of Python to solve tasks.',
            'You will build games and programs that use Python libraries.',
            'You will be able to use Python for your own work problems or personal projects.',
         ],
      },
   },
   {
      id: 7,
      logo: 'https://img-c.udemycdn.com/course/240x135/4308321_1608_3.jpg',
      subcategory: 'Game Development',
      title: 'Unity Plus',
      price: 300,
      rating: 4.0,
      createdBy: 'Manager1 Manager1',
      mentorName: 'Dwayne Turner',
      countVoters: 1888,
      additionalData: {
         title: 'Unity Plus',
         updated: 'March 2021',
         subtitle: '22 total hours All Levels Subtitles',
         description:
            'Learn Python like a Professional Start from the basics and go all the way to creating your own applications and games',
         checkedPoints: [
            'You will learn how to leverage the power of Python to solve tasks.',
            'You will build games and programs that use Python libraries.',
            'You will be able to use Python for your own work problems or personal projects.',
         ],
      },
   },
];

export const imgTrustTeamSection = [
   'https://s.udemycdn.com/partner-logos/v4/nasdaq-dark.svg',
   'https://s.udemycdn.com/partner-logos/v4/volkswagen-dark.svg',
   'https://s.udemycdn.com/partner-logos/v4/box-dark.svg',
   'https://s.udemycdn.com/partner-logos/v4/netapp-dark.svg',
   'https://s.udemycdn.com/partner-logos/v4/eventbrite-dark.svg',
   'https://s.udemycdn.com/partner-logos/v4/tcs-dark.svg',
];

export const imgTopCategories = [
   {
      category: 'Design',
      img: 'https://s.udemycdn.com/home/top-categories/lohp-category-design-v2.jpg',
   },
   {
      category: 'Development',
      img: 'https://s.udemycdn.com/home/top-categories/lohp-category-development-v2.jpg',
   },
   {
      category: 'Marketing',
      img: 'https://s.udemycdn.com/home/top-categories/lohp-category-marketing-v2.jpg',
   },
   {
      category: 'IT and Software',
      img: 'https://s.udemycdn.com/home/top-categories/lohp-category-it-and-software-v2.jpg',
   },
   {
      category: 'Personal Development',
      img: 'https://s.udemycdn.com/home/top-categories/lohp-category-personal-development-v2.jpg',
   },
   {
      category: 'Business',
      img: 'https://s.udemycdn.com/home/top-categories/lohp-category-business-v2.jpg',
   },
   {
      category: 'Photography',
      img: 'https://s.udemycdn.com/home/top-categories/lohp-category-photography-v2.jpg',
   },
   {
      category: 'Music',
      img: 'https://s.udemycdn.com/home/top-categories/lohp-category-music-v2.jpg',
   },
];

import { MentorLayout } from '../UI/Layout/MentorLayout';
import { MentorHomePage, MentorTask } from '../mentor';
import { StudentProfile } from '../studentProfile/studentProfile';
import  ViewAllStudents  from '../viewAllStudents/pages/ViewAllStudents';
import { AllCourses }  from '../allCourses';
import { MyStudents } from '../mentor/pages/MyStudents';

import {
   TaskReview,
   QuizReview,
   AllAnswers,
   Courses,
} from '../mentor/components';

enum MentorPaths {
   home = '/',
   task = 'task/:id',
   student_profile = '/student-profile/:id',
   all_tasks = '/all-tasks',
   all_quiz = '/all-quiz',
   all_answers = '/all-answers',
   all_students = '/all-students',
   all_courses = '/all-courses',
   my_students = '/my-students',
}

export const mentorNavigator = {
   task: (id: number) => `task/${id}`,
   student_profile: (id: number) => `student-profile/${id}`,
   all_students: () => `all-students`,
   all_courses: () => `all-courses`,
   my_students: () => `my-students`,
};

export const MentorRoutes = {
   path: MentorPaths.home,
   element: <MentorLayout />,
   children: [
      {
         path: MentorPaths.home,
         element: <MentorHomePage />,
         children: [
            {
               path: '',
               element: <TaskReview />,
            },
            {
               path: MentorPaths.all_tasks,
               element: <TaskReview />,
            },
            {
               path: MentorPaths.all_quiz,
               element: <QuizReview />,
            },
            {
               path: MentorPaths.all_answers,
               element: <AllAnswers />,
            },
            {
               path: MentorPaths.my_students,
               element: <MyStudents />,
            },
            {
               path: MentorPaths.all_courses,
               element: <Courses />,
            },
         ],
      },
      {
         path: MentorPaths.task,
         element: <MentorTask />,
      },
      {
         path: MentorPaths.student_profile,
         element: <StudentProfile />,
      },
      {
         path: MentorPaths.all_students,
         element: <ViewAllStudents />,
      },
      {
         path: MentorPaths.all_courses,
         element: <AllCourses />,
      },
      { 
         path: MentorPaths.my_students,
         element: <MyStudents />,
      },
      // {
      //    path: '*',
      //    element: <MentorHomePage />,
      // },
   ],
};

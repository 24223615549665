import { v4 } from 'uuid';

// project components
import { HelpCenterHeader } from '../../components/HelpCenterHeader';
import { HelpCenterCard } from '../../components/HelpCenterCard';
import { HelpCenterSearch } from '../../components/HelpCenterSearch';
import { Divider } from '../../../UI/Divider';

// assets
import { helpCenterData } from '../../library/helpCenterData';

//styles
import styles from './HelpCenter.module.scss';

export const HelpCenter: React.FC = () => {

  return (
    <>
      <HelpCenterHeader />

      <div className={styles.container}>
        <HelpCenterSearch />

        <Divider>
          General
        </Divider>

        <div className={styles.chapter}>
          {helpCenterData
            .filter(item => item.category === 'General')
            .map(item => (
              <HelpCenterCard cardInfo={item} key={v4()}/>
            ))}
        </div>

        <Divider>
          Documentation
        </Divider>

        <div className={styles.chapter}>
        {helpCenterData
            .filter(item => item.category === 'Documentation')
            .map(item => (
              <HelpCenterCard cardInfo={item} key={v4()}/>
            ))}
        </div>
      </div>
    </>
  )
}
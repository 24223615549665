// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutLearning_header__GVuze {\n  font-weight: 700;\n  font-size: 20px;\n}\n\n.AboutLearning_container__zJeYV {\n  text-align: center;\n}\n@media screen and (max-width: 900px) {\n  .AboutLearning_container__zJeYV img {\n    width: 300px;\n  }\n}\n.AboutLearning_container__zJeYV p {\n  width: 600px;\n  margin: 0 auto;\n}\n.AboutLearning_container__zJeYV h2 {\n  width: 700px;\n  font-weight: 700;\n  font-size: 20px;\n  margin: 20px auto;\n}\n.AboutLearning_container__zJeYV p, .AboutLearning_container__zJeYV h2 {\n  text-align: start;\n}\n@media screen and (max-width: 600px) {\n  .AboutLearning_container__zJeYV p, .AboutLearning_container__zJeYV h2 {\n    width: calc(100% - 20px);\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/guest/pages/TeachOnTraining/components/LearningSection/AboutLearning/AboutLearning.module.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,eAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;AAIQ;EADJ;IAEQ,YAAA;EADV;AACF;AAGI;EACG,YAAA;EACA,cAAA;AADP;AAGI;EACI,YAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AADR;AAGI;EACG,iBAAA;AADP;AAEO;EAFH;IAGI,wBAAA;EACN;AACF","sourcesContent":["@use '../../../../../../../theme/config' as v;\n\n.header {\n    font-weight: 700;\n    font-size: 20px;\n}\n\n.container {\n    text-align: center;\n   \n    img {\n        @media screen and (max-width: v.$md) {\n            width: 300px;\n           }\n    }\n    p {\n       width: 600px;\n       margin: 0 auto;\n    }\n    h2 {\n        width: 700px;\n        font-weight: 700;\n        font-size: 20px;\n        margin: 20px auto;\n    }\n    p, h2 {\n       text-align: start; \n       @media screen and (max-width: v.$sm) {\n        width: calc(100% - 20px);\n       }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "AboutLearning_header__GVuze",
	"container": "AboutLearning_container__zJeYV"
};
export default ___CSS_LOADER_EXPORT___;

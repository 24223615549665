import { FC } from 'react';
//component
import { AppBtn, AppBtnTypes } from '../../../../UI';
//styles
import styles from './BannerCourse.module.scss';

interface IProps {
   title: string;
   description: string;
}

export const BannerCourse: FC<IProps> = ({ title, description }) => {
   return (
      <section className={styles.container}>
         <div className={styles.content}>
            <h2 className={styles.title}>{title}</h2>
            <h4 className={styles.description}>
               {description} Mock description: Lorem ipsum dolor sit amet,
               consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
               labore et dolore magna aliqua. Ut enim ad minim veniam, quis
               nostrud exercitation ullamco laboris nisi ut aliquip ex ea
               commodo consequat.
            </h4>
            <AppBtn buttonType={AppBtnTypes.inverted} className={styles.btn}>Resume Course</AppBtn>
         </div>
      </section>
   );
};

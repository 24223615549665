//components
import { SlickSlider } from '../../../../UI';
import { commentsSection } from '../../../../UI/SlickSlider/Settings';
import { CommentsItemCard } from '../../../../guest/pages/GuestHome/components/CommentsSection/CommentItemCard/CommentItemCard';
import { v4 } from 'uuid';
//styles
import styles from './Feedback.module.scss';
import {ICourseFeedback} from "../../../../../http/studentsAPI";

interface IFeedback {
  feedback: ICourseFeedback
}

export const Feedback = ({ feedback }: IFeedback) => {
  const { comments } = feedback
   return (
      <div className={styles.container}>
         <div className={styles.content}>
            <h2>Feedback</h2>
            <h4>
               What other students turned professionals have to say about us
               after learning with us and reaching their goals.
            </h4>

            <SlickSlider settings={commentsSection}>
               {comments.map((comment) => (
                  <CommentsItemCard
                     userName={comment.nameStudent}
                     comment={comment.comment}
                     course={comment.courseName}
                     key={v4()}
                  />
               ))}
            </SlickSlider>
         </div>
      </div>
   );
};

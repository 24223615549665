import { useState, useEffect, useCallback } from 'react';
import { v4 } from 'uuid';
// animation library
import { motion, AnimatePresence } from 'framer-motion';
// material ui
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
//project
import { dataHero } from '../mockData';
//styles
import styles from './Hero.module.scss';

const variants = {
   initial: (direction: number) => {
      return {
         x: direction > 0 ? 1000 : -1000,
         opacity: 0,
      };
   },
   animate: {
      x: 0,
      opacity: 1,
      transition: {
         x: { type: 'spring', stiffness: 300, damping: 30 },
         opacity: { duration: 0.2 },
      },
   },
   exit: (direction: number) => {
      return {
         x: direction > 0 ? -1000 : 1000,
         opacity: 0,
         transition: {
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
         },
      };
   },
};

export const Hero = () => {
   const [index, setIndex] = useState(0);
   const [direction, setDirection] = useState(0);

   const nextStep = useCallback(() => {
      setDirection(1);
      if (index === dataHero.length - 1) {
         setIndex(0);
         return;
      }
      setIndex(index + 1);
   }, [index]);

   const prevStep = useCallback(() => {
      setDirection(-1);
      if (index === 0) {
         setIndex(dataHero.length - 1);
         return;
      }
      setIndex(index - 1);
   }, [index]);

   const swipeSpeed = 10000;
   const swipePower = (offset: number, velocity: number) => {
      return Math.abs(offset) * velocity;
   };

   const windowWidth = window.innerWidth < 768 ? true : false;
   const backImgSize =
      window.innerWidth < 500 ? dataHero[index].imgMobile : dataHero[index].img;

   useEffect(() => {
      const interval = setInterval(() => {
         nextStep();
      }, 5000);

      return () => clearInterval(interval);
   }, [index, nextStep, prevStep]);

   return (
      <motion.div
         className={styles.container}
         initial={{ opacity: 0, y: -100 }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeOut' }}
      >
         <div className={styles.slideshow}>
            <AnimatePresence initial={false} custom={direction}>
               <motion.div
                  variants={variants}
                  animate='animate'
                  initial='initial'
                  exit='exit'
                  className={styles.slides}
                  key={v4()}
                  custom={direction}
                  drag='x'
                  dragConstraints={{ left: 0, right: 0 }}
                  onDragEnd={(_, { offset, velocity }) => {
                     const swipe = swipePower(offset.x, velocity.x);
                     if (swipe < swipeSpeed) {
                        nextStep();
                     } else if (swipe > swipeSpeed) {
                        prevStep();
                     }
                  }}
               >
                  <motion.div
                     className={styles.slides_content}
                     initial={{ opacity: 0, scale: 0.4 }}
                     animate={{ opacity: 1, scale: 1 }}
                     transition={{ duration: 0.8 }}
                  >
                     <h3>{dataHero[index].title}</h3>
                     <p>{dataHero[index].description}</p>
                  </motion.div>
                  <img src={`${backImgSize}`} alt='test' />
               </motion.div>
            </AnimatePresence>

            {!windowWidth && dataHero.length > 1 ? (
               <>
                  <motion.span
                     className={styles.prevButton}
                     initial={{ x: -200, opacity: 0 }}
                     animate={{ x: 0, opacity: 1 }}
                     transition={{ duration: 0.5, delay: 0.8 }}
                  >
                     <ArrowBackIosNewIcon onClick={prevStep} />
                  </motion.span>

                  <motion.span
                     className={styles.nextButton}
                     initial={{ x: 200, opacity: 0 }}
                     animate={{ x: 0, opacity: 1 }}
                     transition={{ duration: 0.5, delay: 0.8 }}
                  >
                     <ArrowForwardIosIcon onClick={nextStep} />
                  </motion.span>
               </>
            ) : null}
         </div>
      </motion.div>
   );
};

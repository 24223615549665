import { useState } from 'react';
// mui
import { Rating } from '@mui/material';
import {
   StarBorder,
   ThumbUpOutlined,
   ThumbDownOutlined,
   ThumbUp,
   ThumbDown,
} from '@mui/icons-material';
// components
import { AppAvatar, AppDotsMenu } from '../../../UI';
// styles
import styles from './CommentArticle.module.scss';

interface Props {
   comment: {
      id: number;
      name: string;
      rating: number;
      date: string;
      text: string;
   };
}

export const CommentArticle: React.FC<Props> = ({ comment }) => {
   const { name, rating, date, text } = comment;
   const [isLiked, setIsLiked] = useState(false);
   const [isDisliked, setIsDisliked] = useState(false);

   const handleLike = () => {
      setIsLiked(!isLiked);
      setIsDisliked(false);
   };

   const handleDislike = () => {
      setIsDisliked(!isDisliked);
      setIsLiked(false);
   };

   return (
      <div className={styles.wrapper}>
         <div className={styles.info}>
            <AppAvatar name={name} className={styles.avatar} />
            <div className={styles.indicators}>
               <h5 className={styles.name}>{name}</h5>
               <div className={styles.meta}>
                  <span className={styles.rating_container}>
                     <Rating
                        className={styles.rating_stars}
                        emptyIcon={<StarBorder className={styles.star_icon} />}
                        name='read-only'
                        value={rating}
                        readOnly
                        max={5}
                        precision={0.5}
                     />
                  </span>
                  <span className={styles.date}>{date}</span>
               </div>
            </div>
            <AppDotsMenu className={styles.dots} />
         </div>
         <div 
            className={styles.text}
            dangerouslySetInnerHTML={{__html:text}}
         ></div>
         <div className={styles.helpful}>
            <span>Helpful?</span>
            {isLiked ? (
               <ThumbUp className={styles.thumb} onClick={handleLike} />
            ) : (
               <ThumbUpOutlined className={styles.thumb} onClick={handleLike} />
            )}
            {isDisliked ? (
               <ThumbDown className={styles.thumb} onClick={handleDislike} />
            ) : (
               <ThumbDownOutlined
                  className={styles.thumb}
                  onClick={handleDislike}
               />
            )}
         </div>
      </div>
   );
};

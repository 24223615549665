// material-ui
import { Box, Typography } from '@mui/material';

// project components
import { NavGroup } from './NavGroup';

// project imports
import { menuItems } from '../../../menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

interface INavItem {
   id: string;
   title: string;
   type: string;
   url?: string;
   icon: React.ComponentType<{ style?: React.CSSProperties }>;
   target?: boolean;
   breadcrumbs?: boolean;
   external?: boolean;
   children?: INavItem[];
}

export const Navigation: React.FC = () => {
   const navGroups = menuItems.items.map((item) => {
      switch (item.type) {
         case 'group':
            return (
               <NavGroup key={item.id} item={item as unknown as INavItem} />
            );
         default:
            return (
               <Typography
                  key={item.id}
                  variant='h6'
                  color='error'
                  align='center'
               >
                  Fix - Navigation Group
               </Typography>
            );
      }
   });

   return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};
import { $host } from './index';

export interface ICommentFeedbackAll {
   courseId: number;
   comment: string;
   nameStudent: string;
   grade: number;
   courseName: string;
}

export interface IAllFeedback {
   totalElements: number;
   comments: ICommentFeedbackAll[];
   currentPage: number;
   totalPages: number;
}

export interface ICommentFeedbackAll {
   courseId: number;
   comment: string;
   nameStudent: string;
   grade: number;
   courseName: string;
}

export interface IAllFeedback {
   totalElements: number;
   comments: ICommentFeedbackAll[];
   currentPage: number;
   totalPages: number;
}

export interface ICoursesPreview {
   totalElements: number;
   courses: Course[];
   currentPage: number;
   totalPages: number;
}

export interface Course {
   id: number;
   logo: string;
   subcategory: string;
   title: string;
   price: number;
   rating: number;
   createdBy: string;
   countVoters: number;
   slug: string;
}

export interface ICoursePopupInfo {
   title: string;
   updateDate: number[];
   description: string;
   knowledge: string[];
   subtitle: string;
   courseInWishList: boolean;
}

export const getAllFeedback = async (page?: number, size?: number) => {
   const { pageNumber, pageSize } = {
      pageNumber: page ? `pageNumber=${page}` : '',
      pageSize: size ? `&pageSize=${size}` : '',
   };
   const { data } = await $host.get(
      `/api/courses/comments/all?${pageNumber}${pageSize}`,
   );
   return data as IAllFeedback;
};

export const getCoursesPreview = async () => {
   const { data } = await $host.get(`api/courses/preview`);
   return data as ICoursesPreview;
};

export const getCoursesPopupInfo = async (courseId: number) => {
   const { data } = await $host.get(`api/courses/${courseId}/popup-info`);
   return data as ICoursePopupInfo;
};

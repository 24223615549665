export const baseURL = process.env.REACT_APP_BASE_URL;
export const publicURL = process.env.REACT_APP_PUBLIC_URL;
export const keycloakURL = process.env.REACT_APP_KEYCLOAK_URL;
export enum RoutesEnum {
   MAIN = '/main',
   LOGIN = '/login',
   TEST_LIST = '/test-list',
   TEST = '/test',
   STUDENT_COURSES = '/student/courses',
   TASKS = '/tasks',
   STUDENT_FEEDBACK = '/student/feedback',
   QUIZ = '/quiz',
   PROFILE = '/profile',
   EXTERNAL_USER_RESULT = '/external-user-result',
   MENTOR = '/mentor',
   STUDENT_PROFILE = '/student-profile',
   MANAGER = '/manager',
   SPECTATOR = '/spectator',
   ALL_STUDENTS = '/all-students',
}

export enum UserRoles {
   mentor = 'MENTOR',
   student = 'STUDENT',
   spectator = 'SPECTATOR',
   manager = 'MANAGER',
   user = 'USER',
}

export type RoleType = 'MANAGER' | 'SPECTATOR' | 'MENTOR' | 'STUDENT' | 'USER';
export type QuizQuestionsType =
   | 'SINGLE_CHOICE'
   | 'MULTI_CHOICE'
   | 'EXACT_TEXT'
   | 'MATCHING'
   | 'TEXT_FOR_MENTOR';
export type QuestionsType = 'MULTI' | 'SINGLE' | 'LISTING';

export const maxCommentLength = 200;
export const maxCourseTitleLength: Record<string, number> = {
   '400': 25,
   '460': 30,
   '600': 60,
   '630': 15,
   '730': 20,
   '800': 25,
   '900': 30,
   '1000': 35,
   '1100': 40,
   '1200': 25,
   '1350': 30,
   '1536': 50,
};

//components
import { PageContainer } from '../../../../../UI';
import { AppBtn, AppBtnTypes } from '../../../../../UI/AppBtn/AppBtn';
//styles
import styles from './FeaturedTopics.module.scss';

export const FeaturedTopics = () => {
   return (
      <section className={styles.container}>
         <PageContainer>
            <h2>Featured topics by category</h2>
            <div className={styles.grid_container}>
               <div className={styles.column}>
                  <h3>Development</h3>
                  <ul>
                     <li>
                        <a href='#'>Python</a>
                        <span>36,354,994 students</span>
                     </li>
                     <li>
                        <a href='#'>Web Development</a>
                        <span>11,415,615 students</span>
                     </li>
                     <li>
                        <a href='#'>Machine Learning</a>
                        <span>7,070,015 students</span>
                     </li>
                  </ul>
               </div>
               <div className={styles.column}>
                  <h3>Business</h3>
                  <ul>
                     <li>
                        <a href='#'>Financial Analysis</a>
                        <span>1,195,282 students</span>
                     </li>
                     <li>
                        <a href='#'>SQL</a>
                        <span>5,977,561 students</span>
                     </li>
                     <li>
                        <a href='#'>PMP</a>
                        <span>1,733,398 students</span>
                     </li>
                  </ul>
               </div>
               <div className={styles.column}>
                  <h3>IT and Software</h3>
                  <ul>
                     <li>
                        <a href='#'> AWS Certification</a>
                        <span>6,078,244 students</span>
                     </li>
                     <li>
                        <a href='#'> Ethical Hacking</a>
                        <span>10,931,066 students</span>
                     </li>
                     <li>
                        <a href='#'>Cyber Security</a>
                        <span>3,998,037 students</span>
                     </li>
                  </ul>
               </div>
               <div className={styles.column}>
                  <h3>Design</h3>
                  <ul>
                     <li>
                        <a href='#'>Photoshop</a>
                        <span>10,909,736 students</span>
                     </li>
                     <li>
                        <a href='#'>Graphic Design</a>
                        <span>3,381,052 students</span>
                     </li>
                     <li>
                        <a href='#'>Drawing</a>
                        <span>2,410,849 students</span>
                     </li>
                  </ul>
               </div>
            </div>
            <div className={styles.button_container}>
               <AppBtn
                  buttonType={AppBtnTypes.base}
                  className={styles.secondary}
               >
                  Explore more topics
               </AppBtn>
            </div>
         </PageContainer>
      </section>
   );
};

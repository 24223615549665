import { FC, useState, useEffect } from 'react';
//mui
import Rating from '@mui/material/Rating/Rating';
import { StarBorder } from '@mui/icons-material';
//styles
import styles from './CommentsItem.module.scss';

interface IProps {
   userName: string;
   comment: string;
}

export const CommentsItem: FC<IProps> = ({ userName, comment }) => {
   const [letters, setLetters] = useState<string>();

   useEffect(() => {
      const nameArr = userName.split(' ');
      setLetters(nameArr[0][0] + nameArr[1]);
   }, []);

   return (
      <div className={styles.container}>
         <div className={styles.name_block}>
            <div className={styles.avatar}>{letters}</div>
            <div>
               <h5 className={styles.public_date}>2 days ago</h5>
               <h4 className={styles.name}>{userName}</h4>
            </div>
         </div>
         <div className={styles.comment_block}>
            <span className={styles.rating}>
               <Rating
                  emptyIcon={<StarBorder className={styles.star} />}
                  name='read-only'
                  value={4.5}
                  readOnly
                  size='small'
                  max={5}
                  precision={0.5}
               />
            </span>
            <div className={styles.comment}>{comment}</div>
         </div>
      </div>
   );
};

//mui
import Rating from '@mui/material/Rating/Rating';
import { StarBorder } from '@mui/icons-material';
//styles
import styles from './RatingSection.module.scss';

export const RatingSection = () => {
   return (
      <section className={styles.rating}>
         <Rating
            emptyIcon={<StarBorder className={styles.star} />}
            name='read-only'
            value={4.2}
            readOnly
            size='medium'
            max={5}
            precision={0.5}
         />
         <span className={styles.count_voters}>20 ratings</span>
      </section>
   );
};

import { useNavigate } from 'react-router-dom';

// material-ui
import { Box, Theme, useMediaQuery, Button } from '@mui/material';

// project components
import {
   Search,
   SignInButton,
   CategoriesNavMenu,
   HoverMenu,
} from '../../../common';
import { MobileSection } from './MobileSection';

// project imports
import { publicURL } from '../../../../../../utils/consts';

// styles
import styles from './index.module.scss';

const teachPoperDescription =
   'Turn what you know into an opportunity and reach millions around the world.';
const classesPoperDescription =
   'Have a team of 5 and more? Get an exclusive offer anytime and anywhere.';

export const HeaderContent: React.FC = () => {
   const navigate = useNavigate();

   const handleClick = () => {
      navigate('/');
   };

   const handleClickTeach = () => {
      navigate('/teach_on_raining');
   };

   const matchesMd = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('md'),
   );
   const matchesSm = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('sm'),
   );
   const matchesXs = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('xs'),
   );

   return (
      <>
         {matchesMd && (
            <img
               className={styles.logo_md}
               src={`${publicURL}/images/logo.jpg`}
               alt='Foxminded Logo'
               onClick={handleClick}
            />
         )}

         {matchesSm && <CategoriesNavMenu />}

         {matchesXs && !matchesMd && (
            <img
               className={styles.logo_xs}
               src={`${publicURL}/images/logo.jpg`}
               alt='Foxminded Logo'
               onClick={handleClick}
            />
         )}

         {matchesMd && <Search />}

         {matchesXs && !matchesMd && <Box sx={{ width: '100%', ml: 1 }} />}
         {matchesMd && (
            <>
               <HoverMenu
                  title='Classes on Training'
                  desc={classesPoperDescription}
               />
               <HoverMenu
                  title='Teach on Training'
                  desc={teachPoperDescription}
                  functionNavigate={handleClickTeach}
               />
            </>
         )}
         {matchesSm && !matchesMd && (
            <Button className={styles.teach_button}>Teach</Button>
         )}
         {matchesMd && <SignInButton />}
         {matchesXs && !matchesMd && <MobileSection />}
      </>
   );
};

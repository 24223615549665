import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

// project imports
import { useRootStore } from '../../../../../hooks';

// types
import { IQuizQuestion } from '../../../interfaces/interfaces';

// styles
import styles from './QuizTextForMentor.module.scss';

interface IProps {
   question: IQuizQuestion;
   title: string;
}
export const QuizTextForMentor: React.FC<IProps> = observer(({ question }) => {
   const [textAreaValue, setTextAreaValue] = useState<string>('');
   const { studentStore } = useRootStore();
   const { questionId } = question;

   useEffect(() => {
      textAreaValue &&
         studentStore.setActualValue({
            questionId: questionId,
            textForMentorAnswer: textAreaValue,
         });
   }, [textAreaValue]);

   return (
      <>
         <textarea
            className={styles.textarea}
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            placeholder='Write your answer....'
            rows={7}
         ></textarea>
         
         <p className={styles.description}>
            Note: The result of this question will be evaluated by your mentor.
         </p>
      </>
   );
});

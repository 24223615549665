
export const dataText = [
    {
        header: 'Plan your curriculum',
        img: 'https://s.udemycdn.com/teaching/plan-your-curriculum-v3.jpg',
        your_task: `You start with your passion and knowledge. Then choose a promising topic with the help of our Marketplace Insights tool.
        The way that you teach — what you bring to it — is up to you.`,
        our_task:  `We offer plenty of resources on how to create your first course. And, our instructor dashboard and curriculum pages help keep you organized.`,
    },
    {
        header: 'Record your video',
        img: 'https://s.udemycdn.com/teaching/record-your-video-v3.jpg',
        your_task: `Use basic tools like a smartphone or a DSLR camera. Add a good microphone and you’re ready to start.
        If you don’t like being on camera, just capture your screen. Either way, we recommend two hours or more of video for a paid course.`,
        our_task:  `Our support team is available to help you throughout the process and provide feedback on test videos.`,
    },
    {
        header: 'Launch tour curses',
        img: 'https://s.udemycdn.com/teaching/launch-your-course-v3.jpg',
        your_task:  `Gather your first ratings and reviews by promoting your course through social media and your professional networks.
        Your course will be discoverable in our marketplace where you earn revenue from each paid enrollment.`,
        our_task: `Our custom coupon tool lets you offer enrollment incentives while our global promotions drive traffic to courses. There’s even more opportunity for courses chosen for Udemy Business.`,
    },
]
import { useState, ChangeEvent } from 'react';

// ui
import { AppModal, MODAL_TYPES, AppBtn, AppBtnTypes } from '../../../UI';
import useFormHandler from '../../hooks/useFormHandler';

// styles
import styles from './TaskForm.module.scss';

interface IProps {
   id: string;
   answer: string | null;
   state: string | null;
}

export const TaskForm: React.FC<IProps> = ({ id, answer, state }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isSubmitted, setIsSubmitted] = useState(false);
   const [recentSubmission, setRecentSubmission] = useState<string>('');
   const isDisabled = state !== 'In progress' && state !== 'Answer rejected';

   const { userInput, handleChange, submitAnswer, error } = useFormHandler(id);

   const openModal = (e: ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsModalOpen(true);
   };

   const closeModal = () => {
      setIsModalOpen(false);
   };

   const updateFormMessage = () => {
      setRecentSubmission(userInput);
   };

   const submitForm = () => {
      setIsSubmitted(true);
      closeModal();
      updateFormMessage();
      submitAnswer();
   };

   return (
      <>
         <AppModal
            type={MODAL_TYPES.optional}
            open={isModalOpen}
            handleClose={closeModal}
            handleConfirm={submitForm}
            confirmBtnContent='Yes'
            dismissBtnContent='No'
         >
            Are you sure you want to submit the answer for review ?
         </AppModal>

         <form onSubmit={openModal}>
            <label htmlFor='studentTaskTextarea' className={styles.label}>
               {error ? (
                  <span className={styles.error}>
                     ERROR: submission has failed. Check your connection
                  </span>
               ) : recentSubmission ? (
                  <>
                     <strong>Your current answer location: </strong>
                     {recentSubmission}
                  </>
               ) : answer ? (
                  <>
                     <strong>Your current answer location: </strong>
                     {answer}
                  </>
               ) : (
                  <span className={styles.h3}>Your Answer Goes Here:</span>
               )}
            </label>
            <textarea
               id='studentTaskTextarea'
               className={styles.textarea}
               value={userInput}
               onChange={handleChange}
               placeholder='paste your repo link here...'
               rows={3}
               required
               disabled={isDisabled || isSubmitted}
            ></textarea>
            <AppBtn
               buttonType={AppBtnTypes.success_inverted}
               disabled={isDisabled || isSubmitted}
               className={styles.answer_btn}
               type='submit'
            >
               Submit Answer
            </AppBtn>
         </form>
      </>
   );
};

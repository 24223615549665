import { ReactNode } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// styles
import './SlickSlider.scss';

type SlickSliderProps = {
   settings: Settings;
   children: ReactNode;
};

export const SlickSlider = ({ children, settings }: SlickSliderProps) => {
   return <Slider {...settings}>{children}</Slider>;
};

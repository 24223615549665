import { FC, useState } from 'react';
// Components
import {
   Row,
   CellGroup,
   CellName,
   CellTask,
   CellSubmission,
   CellStatus,
} from '../index';
// Project
import { IReviewTask } from '../../../../../http/mentorAPI';
import { courseItemStateClassNames } from '../../../consts';
import { useIsoDateTimeFormat } from '../../../../../hooks';

interface Props {
   task: IReviewTask;
}

export const AllAnswersTableRow: FC<Props> = ({ task }) => {
   const {
      answer,
      studentLastName,
      studentFirstName,
      studentId,
      courseTitle,
      courseItemState,
      courseItemTitle,
      id,
      answerSubmittedDateTime,
      answerRejectedDateTime,
      answerInReviewDateTime,
      answerAcceptedDateTime,
   } = task;

   const [itemState, setItemState] = useState(courseItemState);

   const { stateClassName } = courseItemStateClassNames[itemState];

   const dateTime =
      itemState === 'Answer in review'
         ? answerInReviewDateTime
         : itemState === 'Answer accepted'
         ? answerAcceptedDateTime
         : itemState === 'Answer rejected'
         ? answerRejectedDateTime
         : answerSubmittedDateTime;

   const stateDateTime = useIsoDateTimeFormat(dateTime);
   const submittedDateTime = useIsoDateTimeFormat(answerSubmittedDateTime);
   const shortCourseItemState = itemState.slice(7);

   return (
      <Row>
         <CellGroup group={courseTitle} />

         <CellName
            studentFirstName={studentFirstName}
            studentLastName={studentLastName}
            studentId={studentId}
         />

         <CellTask task={courseItemTitle} id={id} />

         <CellSubmission
            courseItemState={itemState}
            setItemState={setItemState}
            id={id}
            answer={answer}
            submittedDateTime={submittedDateTime}
         />

         <CellStatus
            stateClassName={stateClassName}
            stateDateTime={stateDateTime}
            shortCourseItemState={shortCourseItemState}
         />
      </Row>
   );
};

import { Modal, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { AppBtn, AppBtnTypes } from '../AppBtn';

type Props = {
   children: React.ReactNode;
   open: boolean;
   handleClose: () => void;

   className?: string;
   handleConfirm?: () => void;
   confirmBtnContent?: string;
   dismissBtnContent?: string;
};

// MODAL_TYPES.basic
export const BasicModal: React.FC<Props> = ({
   open,
   handleClose,
   className,
   children,
}) => {
   return (
      <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby='modal-modal-title'
         aria-describedby='modal-modal-description'
         style={{ overflowY: 'auto' }}
      >
         <Box sx={styles.box} className={className}>
            {children}
         </Box>
      </Modal>
   );
};

// MODAL_TYPES.optional
export const OptionalModal: React.FC<Props> = ({
   open,
   handleClose,
   className,
   children,
   handleConfirm,
   confirmBtnContent,
   dismissBtnContent,
}) => {
   return (
      <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby='modal-modal-title'
         aria-describedby='modal-modal-description'
         style={{ overflowY: 'auto' }}
      >
         <Box
            sx={styles.box}
            style={{ maxWidth: '500px' }}
            className={className}
         >
            <div style={styles.header}>
               <button type='button' onClick={handleClose} style={styles.close}>
                  <FontAwesomeIcon icon={faXmark} size='xl' color='#8f96a3' />
               </button>

               <p style={styles.title}>{children}</p>
            </div>
            <div style={styles.buttonContainer}>
               <AppBtn
                  buttonType={AppBtnTypes.success_inverted}
                  style={styles.button}
                  onClick={handleConfirm}
               >
                  {confirmBtnContent}
               </AppBtn>
               <AppBtn
                  buttonType={AppBtnTypes.warning_inverted}
                  style={styles.button}
                  onClick={handleClose}
               >
                  {dismissBtnContent}
               </AppBtn>
            </div>
         </Box>
      </Modal>
   );
};

// styles object
const styles = {
   box: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: 350,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
   },

   header: {
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'column' as 'column',
   },

   close: {
      display: 'inline-block',
      marginLeft: 'auto',
      padding: '4px 8px',
      borderRadius: '5px',
      backgroundColor: 'transparent',
      '&:hover': {
         backgroundColor: '#f2f2f2', // does not work with the style attribute
      },
   },

   title: {
      marginTop: '10px',
      padding: '0 10px',
      fontSize: '22px',
      lineHeight: '160%',
      textAlign: 'center' as 'center',
   },

   buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '30px',
      marginBottom: '10px',
   },

   button: {
      width: '110px',
      fontSize: '20px',
   },
};

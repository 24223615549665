// material ui
import Typography from '@mui/material/Typography/Typography';
import { useMediaQuery, Theme } from '@mui/material';
//components
import { DesktopTabs } from './DesktopTabs/DesktopTabs';
import { PageContainer } from '../../../../../UI';
import { Accordions } from './Accordions/Accordions';
//animation
import { motion } from 'framer-motion';
//styles
import styles from './TabsSection.module.scss';

export const TabsSection = () => {
   const matchesSm = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('sm'),
   );

   return (
      <PageContainer>
         <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
         >
            <div className={styles.tabs_section_container}>
               <Typography variant='h2' component='h2' className={styles.title}>
                  A broad selection of courses
               </Typography>
               <Typography variant='h4' component='p'>
                  Choose from 213,000 online video courses with new additions
                  published every month
               </Typography>
               {!matchesSm ? <Accordions /> : <DesktopTabs />}
            </div>
         </motion.div>
      </PageContainer>
   );
};

import { v4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import { TaskStatusCard } from '../TaskStatusCard/TaskStatusCard';
import { useRootStore } from '../../../../hooks';
import { ITask } from '../../interfaces/interfaces';
import { ACTIVITIES } from '../../consts/consts';
import useChatlikeDataModel from '../../hooks/useChatlikeDataModel';
import styles from './TaskHistory.module.scss';

interface IProps {
   taskContent: ITask;
}

export const TaskHistory: React.FC<IProps> = observer(({ taskContent }) => {
   const {
      id,
      toDoDateTime,
      inProgressDateTime,
      answerSubmittedDateTime,
      answerInReviewDateTime,
      answerRejectedDateTime,
      answerAcceptedDateTime,
   } = taskContent;
   const data = {
      toDoDateTime,
      inProgressDateTime,
      answerSubmittedDateTime,
      answerInReviewDateTime,
      answerRejectedDateTime,
      answerAcceptedDateTime,
   };
   const { studentStore } = useRootStore();
   const taskId = studentStore.getTask()?.id;
   const submittedDateTime = studentStore.getTask()?.answerSubmittedDateTime;

   const { statuses, sortedStatuses } = useChatlikeDataModel(
      data,
      id,
      taskId,
      submittedDateTime,
   );

   const lastActivity = statuses[statuses.length - 1];
   const messageClass = ACTIVITIES[lastActivity.name].class;
   const messageText = ACTIVITIES[lastActivity.name].message;

   return (
      <div className={styles.chat}>
         <div className={styles.header}>
            <h2 className={styles.title}>Task Status Tracker</h2>
         </div>
         <div className={styles.body}>
            {sortedStatuses.map((statusGroup) => (
               <div key={statusGroup.date}>
                  <p className={styles.date}>{statusGroup.date}</p>
                  {statusGroup.data.map((status) => (
                     <TaskStatusCard key={v4()} status={status} />
                  ))}
               </div>
            ))}
         </div>
         <div className={styles.footer}>
            <p className={styles[messageClass]}>{messageText}</p>
         </div>
      </div>
   );
});

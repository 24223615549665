// assets
import { ListAltOutlined, NavigateNextOutlined } from '@mui/icons-material';

// icons
const icons = {
  ListAltOutlined,
  NavigateNextOutlined,
};

export const myCourses = {
  id: 'my-courses',
  title: 'My Courses',
  type: 'group',
  children: [
    {
      id: 'java spring',
      title: 'Java Spring',
      type: 'nested',
      icon: icons.NavigateNextOutlined,
      target: true,
      // url: '/',
      children: [
        {
          id: 'open-course',
          title: 'Open Course',
          type: 'sub',
          url: '/',
          target: true,
          // icon: icons.NavigateNextOutlined,
        },
        {
          id: 'mentors',
          title: 'Mentors',
          type: 'sub',
          url: '/mentors/vue-js',
          target: true,
          // icon: icons.NavigateNextOutlined,
        },
        {
          id: 'resume',
          title: 'Resume',
          type: 'sub',
          url: '/',
          target: true,
          // icon: icons.NavigateNextOutlined,
        },
      ],
    },
  ],
};

import React, { useState, useEffect, ChangeEvent, useCallback} from 'react';
import { 
    setNote,
} from '../../../http/noteAPI';
import { publicURL } from '../../../utils/consts';

import { TextareaType } from './NoteType';
import styles from './Note.module.scss';

export const BasicNote: React.FC<TextareaType> = ({
    className,
    placeholder,
    id,
    note
 }) => {
    const [valueText, setValueText] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSavedNote, setIsSavedNote] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);

    const defValue = (note === 'TEST_NOTE' || note === null || note === '' || note === undefined);

    const handlerTextBlur = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setIsActive(false)
        submitNote()
    }

    const handlerTextareaOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setValueText(e.target.value)
    
    }

    const handlerOnFocus = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setIsActive(true)
    }

    const submitNote = useCallback(() => {
        setIsLoading(true)
        setIsSavedNote(false)
        setNote(+id, valueText)
        .then(() => {
            loadingAnswers()
            savedAnswer()
        })
        .catch(() => {
            setIsError(true)
            setIsLoading(false)
        })  
    },[id, valueText]);

    const savedNote = () => {
        return (
            <img src={`${publicURL}/images/data_is_saved_on_cloud.png`} 
                alt="Saved icon" 
                className={styles.saved}/>
        )   
    }

    const loadingNote = () => {
        return (
            <img src={`${publicURL}/images/transmiting_data_to_cloud.png`} 
                alt="Loading" 
                className={styles.loading}/>
        )   
    }

    const loadingAnswers = () => {
        const loadingIcon = setTimeout(() => {
            setIsLoading(false)
        }, 2000)
        return () => clearTimeout(loadingIcon)
    }

    const savedAnswer = () => {
        const savedIcon = setTimeout(() => {
            setIsSavedNote(true) 
        }, 2000);
        return () => clearTimeout(savedIcon)
    }

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
        if (isActive) {
          intervalId = setInterval(() => {
            submitNote()
          }, 10000);
        }
        return () => {
            if (intervalId) {
              clearInterval(intervalId);
            }
        };
    }, [isActive, submitNote]);
 
    useEffect(() => {
        if (valueText.length % 20 === 0 && valueText.length > 0) {
            submitNote()
        }
    }, [valueText, submitNote]);

    return (
        <form className={styles.note}>
                {isLoading ? loadingNote() : null}
                {isSavedNote ? savedNote() : null}
            <textarea 
                maxLength={255}
                rows={1}
                className={isError ? `${className} ${styles.error}` : `${className}` }
                id={id}
                placeholder={placeholder}
                defaultValue={defValue ? '' : note} 
                onFocus={handlerOnFocus}
                onBlur={handlerTextBlur}
                onChange={handlerTextareaOnChange}
                >
            </textarea>
         </form>
    );
 };

export default BasicNote;


import React, { FC } from 'react';
import { v4 } from 'uuid';
//mui
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
//styles
import styles from './ProfileInfo.module.scss';

import { AppAvatar } from '../../../../../UI';

interface IStudents {
    id: number;
    avatarLogoUrl: string | null;
    firstName: string;
    lastName: string; 
    email: string; 
    phone: string;
    skype: string; 
    telegram: string; 
}

interface IProps { 
    student: IStudents | undefined;
}

export const ProfileInfo: FC<IProps> = ({ student }) => {

    return (
        <>
        <div className={styles.container}>
            <div key={v4()}>
                <div className={styles.avatar}>
                    <AppAvatar name={`${student?.firstName} ${student?.lastName}`} className={styles.avatar_content} />
                </div>

                <div className={styles.main}>
                    <div className={styles.name}>
                        <div>{student?.firstName}</div>
                        <div>{student?.lastName}</div> 
                    </div>
    
                    <div className={styles.main_list}>
                        <div key={v4()} className={styles.contacts}>
                                <EmailOutlinedIcon />
                                <span>Email: </span> {student?.email}
                        </div>
                        <div key={v4()}  className={styles.contacts}>
                                <SendOutlinedIcon />
                                <span>Telegram: </span> {student?.telegram}
                        </div>
                        <div key={v4()}  className={styles.contacts}>
                                <LocalPhoneOutlinedIcon />
                            <span>Skype: </span> {student?.skype}
                        </div>
                        <div key={v4()}  className={styles.contacts}>
                                <LocalPhoneOutlinedIcon />
                            <span>Phone: </span> {student?.phone}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
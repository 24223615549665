// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmptyTabContent_container__eZDL\\+ {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 1.1rem;\n  padding: 20% 0;\n  text-align: center;\n  font-size: 1.5rem;\n  line-height: 1.33;\n}\n.EmptyTabContent_container__eZDL\\+ h3:first-child {\n  font-weight: 700;\n}\n.EmptyTabContent_container__eZDL\\+ h3 a {\n  font-weight: 700;\n  text-decoration: underline;\n  text-underline-offset: 5px;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/EmptyTabContent/EmptyTabContent.module.scss"],"names":[],"mappings":"AAEA;EACG,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;AADH;AAIM;EACG,gBAAA;AAFT;AAKM;EACG,gBAAA;EACA,0BAAA;EACA,0BAAA;AAHT","sourcesContent":["@use '../../../../theme/config' as v;\n\n.container {\n   display: flex;\n   flex-direction: column;\n   justify-content: center;\n   align-items: center;\n   gap: 1.1rem;\n   padding: 20% 0;\n   text-align: center;\n   font-size: 1.5rem;\n   line-height: 1.33;\n\n   h3 {\n      &:first-child {\n         font-weight: 700;\n      }\n\n      a {\n         font-weight: 700;\n         text-decoration: underline;\n         text-underline-offset: 5px; \n      }\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EmptyTabContent_container__eZDL+"
};
export default ___CSS_LOADER_EXPORT___;

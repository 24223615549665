import { FC, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../UI';
import { SingleChoiceQuestion } from '../SingleChoiceQuestion';
import { TextQuestion } from '../TextQuestion';
import { IFeedbackQuestion } from '../../../mockData';
import styles from './FeedbackForm.module.scss';

interface Props {
   questions: IFeedbackQuestion[];
   onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

const FeedbackForm: FC<Props> = ({ onSubmit, questions }) => {
   const navigate = useNavigate();
   const navigateBack = () => {
      navigate(-1);
   };

   return (
      <form onSubmit={onSubmit}>
         {questions.map(({ id, questionType, description, answers }) => (
            <div key={id}>
               {questionType === 'SINGLE_CHOICE' && (
                  <SingleChoiceQuestion
                     questionId={id}
                     question={description}
                     answers={answers}
                  />
               )}

               {questionType === 'TEXT' && (
                  <TextQuestion questionId={id} question={description} />
               )}
            </div>
         ))}

         <div className={styles.buttonWrapper}>
            <Button
               buttonClass='primary'
               type='submit'
               buttonText='Submit your answers'
               additionalClass={styles.submitBtn}
            />

            <Button
               buttonClass='secondary'
               type='button'
               buttonText='Back to course'
               additionalClass={styles.dismissBtn}
               buttonClick={navigateBack}
            />
         </div>
      </form>
   );
};

export default FeedbackForm;

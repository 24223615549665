// components
import { CourseAccordion } from '../index';
// project
import { courseContent, useCourseContent } from '../../library';
import { AppBtn, AppBtnTypes } from '../../../UI';
// styles
import styles from './CourseContent.module.scss';

export const CourseContent: React.FC = () => {
   const { totalSections, totalTheory, totalPractice } = courseContent;
   const {
      isShownMore,
      sectionsRendered,
      handleClick,
      allExpanded,
      toggleExpandAll,
   } = useCourseContent();

   return (
      <div className={styles.wrapper}>
         <h3 className={styles.title}>Course content</h3>
         <div className={styles.meta}>
            <span>Sections: {totalSections}</span>{' '}
            <span className={styles.dot}>●</span>
            <span>Theory: {totalTheory}</span>{' '}
            <span className={styles.dot}>●</span>
            <span>Practice: {totalPractice}</span>{' '}
            <div className={styles.gap}></div>
            <span>
               <AppBtn
                  buttonType={AppBtnTypes.link}
                  className={styles.expand_btn}
                  onClick={toggleExpandAll}
               >
                  {allExpanded ? 'Collapse' : 'Expand All'}
               </AppBtn>
            </span>
         </div>
         <div className={styles.accordion_container}>
            {sectionsRendered.map((section, i) => {
               return (
                  <CourseAccordion
                     key={Math.random() * 100000}
                     section={section}
                     index={i}
                     allExpanded={allExpanded}
                  />
               );
            })}
         </div>
         <AppBtn className={styles.button} onClick={handleClick}>
            {isShownMore ? 'Show less' : 'Show more'}
         </AppBtn>
      </div>
   );
};

import { ACTIVITIES } from '../../consts/consts';
import styles from './TaskStatusCard.module.scss';

interface IProps {
   status: {
      name: string;
      time: string;
   };
}

export const TaskStatusCard: React.FC<IProps> = ({ status }) => {
   const { name, time } = status;
   const timeEdited = time.split(':').slice(0, 2).join(':');
   const side = ACTIVITIES[name].side

   return (
      <div className={styles.message}>
         <div className={[styles.meta, styles[side]].join(' ')}>
            <span className={styles.name}>
               {ACTIVITIES[name].statusExtended}
            </span>
            <span className={styles.time}>{timeEdited}</span>
         </div>
      </div>
   );
};

import React from 'react';

// types
import { ButtonPropsType } from './ButtonType';

// styles
import './Button.scss';

export const Button: React.FC<ButtonPropsType> = ({
   type,
   buttonClass,
   buttonText,
   buttonClick,
   additionalClass,
   children,
   width,
   style,
   disabled,
}) => {
   const buttonDisabled = !!disabled;

   return (
      <button
         type={type}
         disabled={buttonDisabled}
         className={`button button--${buttonClass} ${
            buttonDisabled && 'button--disabled'
         } ${additionalClass}`}
         onClick={buttonClick}
         style={{ width, ...style }}
      >
         <span>{buttonText}</span>
         <div className='button__icon'>{children}</div>
      </button>
   );
};

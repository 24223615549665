// project imports
import { pages } from './pages';
import { dashboard } from './dashboard';
import { utilities } from './utilities';
import { support } from './support';
import { categories } from './categories';

// ==============================|| MENU ITEMS ||============================== //

export const menuItems = {
    items: [dashboard, utilities, support]
};
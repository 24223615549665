import { Outlet, Link } from 'react-router-dom';

// project imports
import { publicURL } from '../../../../utils/consts';

// assets
import MinimalBackground from '../../../../assets/images/auth/MinimalBackground';

export const MinimalLayout: React.FC = () => {
   return (
      <>
         <div>
            <Link to='/'>
               <img
                  style={{
                     margin: '10px 25px',
                     maxHeight: '40px',
                     cursor: 'pointer',
                  }}
                  src={`${publicURL}/images/logo.jpg`}
                  alt='Foxminded Logo'
               />
            </Link>
         </div>
         <MinimalBackground />
         <Outlet />
      </>
   );
};


import style from './Advantages.module.scss'

export const Advantages = () => {

const support_1 = 'https://s.udemycdn.com/teaching/support-1-v3.jpg'
const support_2 = 'https://s.udemycdn.com/teaching/support-2-v3.jpg'

  return (
    <div className={style.advantages}>
        <img src={support_1} alt="support_1" />
        <div className={style.wrapper}>
            <h2 className={style.title}>You won’t have to do it alone</h2>
            <p>Our <b>Instructor Support Team</b> is here to answer your questions and review your test video, while our <b>Teaching Center</b> gives you plenty of resources to help you through the process. Plus, get the support of experienced instructors in our <b>online community</b>.</p>
            <a href="">Need more details before you start? Learn more.</a>
        </div>
        <img className={style.none} src={support_2} alt="support_2" />
    </div>
  )
}


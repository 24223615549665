// project components
import { CoursePreview } from '../coursePreview';
import { HelpCenter, AskQuestion } from '../helpCenter';

//project layouts
import { StudentLayout } from '../UI/Layout/StudentLayout';
import { GuestLayout } from '../UI/Layout/GuestLayout';
import { SpectatorLayout } from '../UI/Layout/SpectatorLayout';
import { MentorLayout } from '../UI/Layout/MentorLayout';
import { ManagerLayout } from '../UI/Layout/ManagerLayout';
import { CourseMentors } from "../courseMentors";
import TeachOnTraining from '../guest/pages/TeachOnTraining/TeachOnTraining';


enum CommonPaths {
   coursePreview = 'preview/:title',
   helpCenter = 'help-center',
   askQuestion = 'ask-question',
   courseMentors = 'mentors/:title',
   teach = '/teach_on_raining',
}

class CommonRoutes {
   path: string;
   element: React.ReactElement;
   children: { path: string; element: React.ReactElement }[];

   constructor(element: React.ReactElement) {
      this.path = '/';
      this.element = element;
      this.children = [
         {
            path: CommonPaths.coursePreview,
            element: <CoursePreview />,
         },
         {
            path: CommonPaths.helpCenter,
            element: <HelpCenter />,
         },
         {
            path: CommonPaths.askQuestion,
            element: <AskQuestion />,
         },

        {
          path: CommonPaths.courseMentors,
          element: <CourseMentors />,
        },
        {
         path: CommonPaths.teach,
         element: <TeachOnTraining />,
       },
      ];
   }
}

export const StudentCommonRoutes = new CommonRoutes(<StudentLayout />);
export const GuestCommonRoutes = new CommonRoutes(<GuestLayout />);
export const SpectatorCommonRoutes = new CommonRoutes(<SpectatorLayout />);
export const MentorCommonRoutes = new CommonRoutes(<MentorLayout />);
export const ManagerCommonRoutes = new CommonRoutes(<ManagerLayout />);

import { FC, useEffect, useState } from 'react';
import { v4 } from 'uuid';
//material ui
import Popper from '@mui/material/Popper';
import { Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { FavoriteBorderOutlined, FavoriteOutlined } from '@mui/icons-material';
//components
import { AppBtn, AppBtnTypes } from '../../AppBtn';
//types
//styles
import styles from './PopperCourseCard.module.scss';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from '../../../../hooks';
import { getCoursesPopupInfo } from '../../../../http/guestAPI';
import { Spinner } from '../../Spinner';
import { CourseData } from '../CourseCardWidgetTypes';

interface IProp {
   isOpen: boolean;
   anchorEl: HTMLElement | null;
   courseData: CourseData;
}

export const PopperCourseCard: FC<IProp> = ({
   isOpen,
   anchorEl,
   courseData,
}) => {
   const courseId = courseData.id;
   const [isOnWishlist, setIsOnWishlist] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const { guestStore } = useRootStore();
   const navigate = useNavigate();
   const toggleWishlist = () => setIsOnWishlist(!isOnWishlist);
   const coursePopupInfoFromStore = guestStore.getCoursePopupInfoById(courseId);
   const [coursePopupInfo, setCoursePopupInfo] = useState(
      coursePopupInfoFromStore,
   );
   const updateDate = coursePopupInfo?.updateDate;

   useEffect(() => {
      if (isOpen && anchorEl && !coursePopupInfo) {
         // Making sure we only fetch (getCoursesPopupInfo) once for each courseId (and cache it in the store)
         if (coursePopupInfoFromStore) {
            setCoursePopupInfo(coursePopupInfoFromStore);
         } else {
            setIsLoading(true);
            getCoursesPopupInfo(courseId).then((data) => {
               guestStore.setCoursePopupInfo(courseId, data);
               setCoursePopupInfo(data);
               setIsLoading(false);
            });
         }
      }
   }, [
      isOpen,
      anchorEl,
      guestStore,
      coursePopupInfoFromStore,
      coursePopupInfo,
      courseId,
   ]);

   const handleTitleClick = () => {
      if (courseData.slug) {
         navigate(courseData.slug);
      }
   };
   const handleMentorsClick = () => {
      if (courseData.slug) {
         navigate(courseData.slug.replace('/preview/', '/mentors/'));
      }
   };

   return (
      <Popper
         open={isOpen}
         anchorEl={anchorEl}
         placement='right'
         disablePortal={false}
      >
         <Paper className={styles.paper}>
            {isLoading || !coursePopupInfo ? (
               <Spinner />
            ) : (
               <>
                  <h3 className={styles.title} onClick={handleTitleClick}>
                     {coursePopupInfo.title}
                  </h3>
                  <p className={styles.updated}>
                     Updated{' '}
                     <span>{`${updateDate[0]}/${updateDate[1]}/${updateDate[2]}`}</span>
                  </p>
                  <p className={styles.subtitle}>{coursePopupInfo.subtitle}</p>
                  <p className={styles.description}>
                     {coursePopupInfo.description}
                  </p>
                  <ul className={styles.checked_points}>
                     {coursePopupInfo.knowledge.map((item) => (
                        <li key={v4()}>
                           <CheckIcon />
                           <span>{item}</span>
                        </li>
                     ))}
                  </ul>
                  <div className={styles.btn_container}>
                     <AppBtn
                        buttonType={AppBtnTypes.secondary_dark}
                        className={styles.cart_btn}
                     >
                        Add to cart
                     </AppBtn>
                     <AppBtn
                        buttonType={AppBtnTypes.secondary_dark}
                        className={styles.cart_btn}
                        onClick={handleMentorsClick}
                     >
                        Mentors
                     </AppBtn>
                     <AppBtn
                        onClick={toggleWishlist}
                        className={styles.wishlist_btn}
                     >
                        {coursePopupInfo.courseInWishList ? (
                           <FavoriteOutlined />
                        ) : (
                           <FavoriteBorderOutlined />
                        )}
                     </AppBtn>
                  </div>
               </>
            )}
         </Paper>
      </Popper>
   );
};

import { useState, useEffect } from 'react';
import {
   getReviewTaskList,
   IReviewQuiz,
   IReviewTask,
   getReviewQuiz,
   getAllTasksList,
} from '../../../http/mentorAPI';

import { useRootStore } from '../../../hooks';

export const useReviewList = () => {
   const [tasks, setTasks] = useState<IReviewTask[]>([]);
   const [quizzes, setQuizzes] = useState<IReviewQuiz[]>([]);
   const [answers, setAnswers] = useState<IReviewTask[]>([]);
   const [error, setError] = useState<boolean>(false);

   const { mentorStore } = useRootStore();
   const tasksRefresh = mentorStore.getTaskRefresh();
   const quizRefresh = mentorStore.getQuizRefresh();

   const tabHookParams = {
      tasksNum: tasks.length,
      quizzesNum: quizzes.length,
   };

   useEffect(() => {
      getReviewTaskList()
         .then((data) => {
            setTasks(data);
         })
         .catch(() => setError(true));

      getAllTasksList()
         .then((data) => {
            setAnswers(data);
         })
         .catch(() => setError(true));
   }, [tasksRefresh]);

   useEffect(() => {
      getReviewQuiz()
         .then((data) => {
            setQuizzes(data);
         })
         .catch(() => setError(true));
   }, [quizRefresh]);

   return {
      tasks,
      quizzes,
      answers,
      error,
      tabHookParams,
   };
};

// project
import { icons } from '../../library';
// styles
import styles from './CourseAssignment.module.scss';

interface Props {
   props: {
      title: string;
      type: string;
      link: string;
   };
}

export const CourseAssignment: React.FC<Props> = ({ props }) => {
   const { title, type, link } = props;
   const Icon = type === 'theory' ? icons.video : icons.exercise;

   return (
      <div className={styles.wrapper}>
         <Icon />
         <h5 className={styles.title}>{title}</h5>
         {
            link && <a href={link} className={styles.preview}>Preview</a>
         }
      </div>
   );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HelpCenterSearch_search_container__kYSBt {\n  padding: 24px 0 0;\n}\n@media screen and (max-width: 600px) {\n  .HelpCenterSearch_search_container__kYSBt {\n    padding: 16px 0;\n  }\n}\n\n.HelpCenterSearch_search_btn__4uTvc {\n  min-width: 16px;\n  padding: 0px;\n  font-size: 1rem;\n  color: #858f97;\n  background-color: white;\n  box-shadow: none;\n}\n.HelpCenterSearch_search_btn__4uTvc:hover {\n  color: #858f97;\n  background-color: white;\n  box-shadow: none;\n}\n\n.HelpCenterSearch_description__0M-mH {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  margin-top: 16px;\n}\n.HelpCenterSearch_description__0M-mH .HelpCenterSearch_articles_info__-EDvQ {\n  color: #858f97;\n  font-size: 16px;\n  font-weight: 600;\n}", "",{"version":3,"sources":["webpack://./src/modules/helpCenter/components/HelpCenterSearch/HelpCenterSearch.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;AAGE;EAHF;IAII,eAAA;EAAF;AACF;;AAGA;EACE,eAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,uBAAA;EACA,gBAAA;AAAF;AAEE;EACE,cAAA;EACA,uBAAA;EACA,gBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;EACA,gBAAA;AADF;AAGE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AADJ","sourcesContent":["@use '../../../../theme/config' as v;\n\n.search_container {\n  padding: v.$px-large 0 0;\n\n  @media screen and (max-width: v.$sm) {\n    padding: v.$px-small 0;\n  }\n}\n\n.search_btn {\n  min-width: 16px;\n  padding: 0px;\n  font-size: 1rem;\n  color: #858f97;\n  background-color: white;\n  box-shadow: none;\n  \n  &:hover {\n    color: #858f97;\n    background-color: white;\n    box-shadow: none;\n  }\n}\n\n.description {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  margin-top: 16px;\n\n  .articles_info {\n    color: #858f97;\n    font-size: 16px;\n    font-weight: 600;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search_container": "HelpCenterSearch_search_container__kYSBt",
	"search_btn": "HelpCenterSearch_search_btn__4uTvc",
	"description": "HelpCenterSearch_description__0M-mH",
	"articles_info": "HelpCenterSearch_articles_info__-EDvQ"
};
export default ___CSS_LOADER_EXPORT___;

// mui
import { Rating } from '@mui/material';
import { StarBorder } from '@mui/icons-material';
// styles
import styles from './CommentsTitle.module.scss';

type Props = {
   rating: number;
   rates: number;
};

export const CommentsTitle: React.FC<Props> = ({ rating, rates }) => {
   return (
      <div className={styles.wrapper}>
         <span className={styles.rating_container}>
            <Rating
               className={styles.rating}
               emptyIcon={<StarBorder className={styles.star} />}
               name='read-only'
               value={rating / 5}
               readOnly
               max={1}
               precision={0.5}
            />{' '}
            {rating} course rating{' '}
         </span>
         <span>● {Math.floor(rates / 1000)}K ratings</span>
      </div>
   );
};

//project imports
import { IActiveCompletedCourseList} from '../../../../interfaces/interfaces';
import { ENDPOINTS } from '../../../../../../http/studentAPI';
//components
import { Loader } from '../../../../components/Loader';
import { StudentCourseCard } from '../../../../components/StudentCourseCard/StudentCourseCard';
//styles
import styles from './ActiveCoursesPage.module.scss';
//hooks
import useGetSessionStorageItem from '../../../../../../hooks/useSessionStorageData';
import useProtectedContent from '../../../../hooks/useProtectedContent';
import { EmptyTabContent } from '../../../../components/EmptyTabContent/EmptyTabContent';
//interfases
import { IUser } from '../../../../../../store/UserStore';




export const ActiveCoursesPage = () => {
   
   const {id:userId} : IUser = useGetSessionStorageItem('user') 
   
   const isHomepage = true;
   
   const activeCourses  = useProtectedContent(
      ENDPOINTS.courseСompletionStatus(userId, false),
      isHomepage
   ) as IActiveCompletedCourseList ;
   
   const courses  =  activeCourses?.workCourses
   if (!courses) return <Loader />;
   
   

   

   return (
    <div>
      {!courses.length && <EmptyTabContent/>}
      <div className={styles.container}>
         {courses.map((course) => (
            <StudentCourseCard
               key={course.id}
               completionPercentage={course.completionPercentage}
               imageURL={course.courseLogoUrl}
               courseName={course.courseTitle}
               courseDescription={course.courseDescription}
               mentorName={`${course.mentorFirstName} ${course.mentorLastName}`}
               slug={course.slug}
               courseId={course.id}
            />
         ))}
      </div>
      </div>  
   );
};

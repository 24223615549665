import { FC } from 'react';
import PaginationLink from '../PaginationLink/PaginationLink';
import styles from './PaginationComponent.module.scss';

export interface IPagination  {
  currentPage: number;
  lastPage: number;
  maxLength: number;
  href?: string;
  setCurrentPage: (page: number) => void;
};

const Pagination: FC<IPagination> = ({
  currentPage,
  lastPage,
  maxLength,
  setCurrentPage,
}) =>  {
    function  getPaginationItems (
      currentPage: number,
      lastPage: number,
      maxLength: number
    ) {
      const res: Array<number> = [];
    
      if (lastPage <= maxLength) {
        for (let i = 1; i <= lastPage; i++) {
          res.push(i);
        }
      }
      return res;
  }

  const pageNums = getPaginationItems(currentPage, lastPage, maxLength)

  return (
    <nav className={styles.pagination} aria-label="Pagination">
      <PaginationLink
        disabled={currentPage === 0}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
          &#60;
        
      </PaginationLink>

      {pageNums.map((pageNum, idx) => (
        <PaginationLink
          key={idx-1}
          active={currentPage === pageNum - 1}
          disabled={isNaN(pageNum)}
          onClick={() => setCurrentPage(pageNum - 1)}
        >
          {!isNaN(pageNum) ? pageNum : '...'}
        </PaginationLink>
      ))}

      <PaginationLink
        disabled={currentPage === lastPage - 1}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        &#62;
      </PaginationLink>
    </nav>
  );
}

export default Pagination;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabButtons_tabButton__yozNS {\n  padding: 16px 16px 20px;\n  font-size: 20px;\n  font-weight: 500;\n  background-color: #f3f3f3;\n  border: 1px solid #f3f3f3;\n  border-bottom: none;\n  position: relative;\n}\n.TabButtons_tabButton__yozNS:first-child {\n  border-top-left-radius: 8px;\n}\n.TabButtons_tabButton__yozNS:last-child {\n  border-top-right-radius: 8px;\n}\n.TabButtons_tabButton__yozNS.TabButtons_active__5binH {\n  background-color: #fff;\n  border-color: #d9d9d9;\n}\n.TabButtons_tabButton__yozNS.TabButtons_active__5binH:before {\n  content: \"\";\n  position: absolute;\n  left: 0;\n  right: 0;\n  bottom: -2px;\n  height: 4px;\n  background-color: #fff;\n  z-index: 1;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/AnswerList/TabButtons/TabButtons.module.scss"],"names":[],"mappings":"AAAA;EACG,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;AACH;AACG;EACG,2BAAA;AACN;AACG;EACG,4BAAA;AACN;AAEG;EACG,sBAAA;EACA,qBAAA;AAAN;AAEM;EACG,WAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,UAAA;AAAT","sourcesContent":[".tabButton {\n   padding: 16px 16px 20px;\n   font-size: 20px;\n   font-weight: 500;\n   background-color: #f3f3f3;\n   border: 1px solid #f3f3f3;\n   border-bottom: none;\n   position: relative;\n\n   &:first-child {\n      border-top-left-radius: 8px;\n   }\n   &:last-child {\n      border-top-right-radius: 8px;\n   }\n\n   &.active {\n      background-color: #fff;\n      border-color: #d9d9d9;\n\n      &:before {\n         content: '';\n         position: absolute;\n         left: 0;\n         right: 0;\n         bottom: -2px;\n         height: 4px;\n         background-color: #fff;\n         z-index: 1;\n      }\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabButton": "TabButtons_tabButton__yozNS",
	"active": "TabButtons_active__5binH"
};
export default ___CSS_LOADER_EXPORT___;

import { allCoursesDesktop } from '../../../../../../UI/SlickSlider/Settings';
import { Spinner } from '../../../../../../UI/Spinner';
import styles from './DesktopTabs.module.scss';
// import { useCoursesPreviewData } from '../../../../../hooks/useCoursesPreviewData';
import { useRootStore, useWindowDimensions } from '../../../../../../../hooks';
import { AppBtn, CourseCardWidget, SlickSlider } from '../../../../../../UI';
import { checkEnableSlider } from '../../../../../../../utils/sliderUtils';
import { Subcategories } from '../../../../../../UI/Layout/hooks/useCategoriesCascader';

interface DesktopTabProps {
   category: Subcategories;
   categoryFilter: string;
   showDescriptionAndExploreBtn: boolean;
}

const DesktopTab = (props: DesktopTabProps) => {
   const { category, categoryFilter, showDescriptionAndExploreBtn } = props;
   const { windowWidth } = useWindowDimensions();
   const { guestStore } = useRootStore();
   const isLoadingCoursesPreview = guestStore.getIsLoadingCoursesPreview();
   const filteredCourses = guestStore.getCoursesByCategory(categoryFilter);
   const enableSlider = checkEnableSlider(filteredCourses, windowWidth);

   return (
      <div className={styles.category}>
         <h3>{category.name}</h3>
         {isLoadingCoursesPreview ? (
            <Spinner />
         ) : (
            <div className={styles.container}>
               {showDescriptionAndExploreBtn ? (
                  <>
                     <h5>{category.description}</h5>
                     <div className={styles.btn_container}>
                        <AppBtn>Explore {category.name}</AppBtn>
                     </div>
                  </>
               ) : (
                  <></>
               )}

               {enableSlider ? (
                  <SlickSlider settings={allCoursesDesktop}>
                     {filteredCourses?.map((course) => (
                        <CourseCardWidget courseData={course} key={course.id} />
                     ))}
                  </SlickSlider>
               ) : (
                  <div className={styles.conteiner_small_amount}>
                     {filteredCourses?.map((course) => (
                        <CourseCardWidget courseData={course} key={course.id} />
                     ))}
                  </div>
               )}
            </div>
         )}
      </div>
   );
};

export default DesktopTab;

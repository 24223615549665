import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
//material ui
import Rating from '@mui/material/Rating/Rating';
import { StarBorder } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
//types
import { IProp, CourseData } from '../CourseCardWidgetTypes';
//styles
import styles from './CourseCardWidgetMobile.module.scss';
import useCourseImage from '../../../student/hooks/useCourseImage';

export const CourseCardWidgetMobile: FC<IProp> = ({ courseData }) => {
   const { id, title, price, rating, createdBy, slug } = courseData as CourseData;
   const navigate = useNavigate();
   const courseImage = useCourseImage(`api/courses/logo/${id}`, false);

   const handleClickNavigator = () => {
      if (slug) {
         navigate(`${slug}`);
      }
   };

   return (
      <div className={styles.container} onClick={handleClickNavigator}>
         <div className={styles.img_container}>
            {courseImage ? (
               <img src={courseImage} alt='Course_logo' />
            ) : (
               <div></div>
            )}
         </div>
         <div className={styles.content_container}>
            <h4 className={styles.title}>{title}</h4>
            <h4 className={styles.mentor_name}>{createdBy}</h4>

            <span className={styles.rating}>
               {rating}
               <Rating
                  emptyIcon={<StarBorder className={styles.star} />}
                  name='read-only'
                  value={rating}
                  readOnly
                  size='small'
                  max={5}
                  precision={0.5}
               />
            </span>
            <div className={styles.price}>
               <AttachMoneyIcon />
               <span>{price}</span>
            </div>
         </div>
      </div>
   );
};

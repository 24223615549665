import { FC} from 'react';
import { v4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import { 
    ICourse,
} from '../../../../../http/studentsAPI';
import styles from './StudentCourses.module.scss';

interface IProps {
    courses: ICourse[] | undefined;
}

const StudentCourses: FC<IProps> =({ courses }) => {

    return (
        <>
            <div style={{ 
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                    }}
                    >
                <div style={{width: '100%'}}>
                    {courses?.map(item => (
                        <div
                            key={v4()}
                            className={styles.item}
                        >       

                            <div style={{marginRight: '20px', marginLeft: '8px', width: '250px'}}>
                                <FontAwesomeIcon icon={faGraduationCap} className={styles.icons}/>
                                {item.title}
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{marginLeft: '8px', width: '90px',}}><strong>last action:</strong></div>
                                <div style={{width: '150px',}}>{item.lastAction}</div>
                            </div>
                            <div className={styles.status}>
                                {
                                    item.status === "ACTIVE" ? (
                                        <span className={styles.active}>{item.status}</span>  
                                    ) : item.status === "COMPLETED" ? (  
                                        <span className={styles.completed}>{item.status}</span>
                                    ) : item.status === "STOPPED"  ? (
                                        <span className={styles.stopped}>{item.status}</span>
                                    ) : null
                                }
                            </div>  
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default StudentCourses;                    
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderContent_logo__Xm6rg {\n  cursor: pointer;\n  max-height: 40px;\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.HeaderContent_teach_button__yU2Oq {\n  letter-spacing: 1px;\n  color: #000000;\n  font-weight: 500;\n  margin-right: 10px;\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/Layout/StudentLayout/Header/HeaderContent/index.module.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,2BAAA;AACH;;AAEA;EACG,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AACH","sourcesContent":[".logo {\n   cursor: pointer;\n   max-height: 40px;\n   position: absolute;\n   left: 50%;\n   transform: translateX(-50%);\n}\n\n.teach_button {\n   letter-spacing: 1px;\n   color: #000000;\n   font-weight: 500;\n   margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "HeaderContent_logo__Xm6rg",
	"teach_button": "HeaderContent_teach_button__yU2Oq"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

// project components
import { QuizSingleChoice } from '../QuizSingleChoice';
import { QuizMultiChoice } from '../QuizMultiChoice';
import { QuizExactText } from '../QuizExactText';
import { QuizMatching } from '../QuizMatching';
import { QuizTextForMentor } from '../QuizTextForMentor';
import { Divider } from '../../../../UI/Divider';

// types
import { IQuizQuestion } from '../../../interfaces/interfaces';

// project imports
import { uIElements } from '../../../consts/consts';

// styles
import styles from './QuizQuestion.module.scss';

interface IProps {
   question: IQuizQuestion;
   topicTitle: string;
}

export const QuizQuestion: React.FC<IProps> = ({
   question,
   topicTitle,
}) => {
   const { questionType } = question;

   return (
      <>
         <div className={styles.titleWrapper}>
            <p className={styles.title}>
               {uIElements[questionType].subTitle}
            </p>
         </div>
         
         <div key={question.questionId}>
            <p className={styles.questionTitle}>
               {questionType === 'EXACT_TEXT'
                  ? 'What is the result of code execution?'
                  : question.description}
            </p>

            {questionType !== 'EXACT_TEXT' &&
               <Divider>
                  Your answer
               </Divider>
            }

            {questionType === 'SINGLE_CHOICE' && (
               <QuizSingleChoice
                  answers={question.choiceAnswers!}
                  question={question}
                  title={topicTitle}
               />
            )}

            {questionType === 'MULTI_CHOICE' && (
               <QuizMultiChoice
                  answers={question.choiceAnswers!}
                  question={question}
                  title={topicTitle}
               />
            )}

            {questionType === 'EXACT_TEXT' && (
               <QuizExactText
                  answers={question.choiceAnswers!}
                  question={question}
                  title={topicTitle}
               />
            )}

            {questionType === 'MATCHING' && (
               <QuizMatching question={question} title={topicTitle} />
            )}

            {questionType === 'TEXT_FOR_MENTOR' && (
               <QuizTextForMentor question={question} title={topicTitle} />
            )}
         </div>
      </>
   );
};

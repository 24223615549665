import { IMentorCourseData } from '../../../../http/spectatorAPI';
import styles from './AllMentorsBox.module.scss';
import MentorCourse from '../MentorCourse/MentorCourse';

export interface IAllMentorsDataProps {
   firstName: string;
   courses: IMentorCourseData[];
}
const AllMentorsBox: React.FC<IAllMentorsDataProps> = ({
   firstName,
   courses,
}) => {
   return (
      <div>
         <div className={styles.wrapper}>
            <strong>{firstName}</strong>
            <div className={styles.courses}>
               {courses.length
                  ? courses.map(({ id, logoUrl, title }) => {
                       return (
                          <div key={id} className={styles.course}>
                             <MentorCourse logoUrl={logoUrl} title={title} />
                          </div>
                       );
                    })
                  : []}
            </div>
         </div>
      </div>
   );
};

export default AllMentorsBox;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AllStudents_container__igVF2 {\n  min-height: 60vh;\n}\n\n.AllStudents_page__title__RYiYT {\n  margin-top: 20px;\n  margin-bottom: 28px;\n  font-size: calc(1.3rem + 0.6vw);\n  font-weight: 500;\n  line-height: 1.2;\n}\n\n.AllStudents_empty__block__nyX6w {\n  height: 65vh;\n}", "",{"version":3,"sources":["webpack://./src/modules/viewAllStudents/components/AllStudents/AllStudents/AllStudents.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;EACA,+BAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".container {\n    min-height: 60vh;\n}\n\n.page__title {\n    margin-top: 20px;\n    margin-bottom: 28px;\n    font-size: calc(1.3rem + 0.6vw);\n    font-weight: 500;\n    line-height: 1.2;\n}\n\n.empty__block {\n    height: 65vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AllStudents_container__igVF2",
	"page__title": "AllStudents_page__title__RYiYT",
	"empty__block": "AllStudents_empty__block__nyX6w"
};
export default ___CSS_LOADER_EXPORT___;

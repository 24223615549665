import styles from './EmptyTabContent.module.scss';

export const EmptyTabContent = () => {
   return (
      <div className={styles.container}>
         <h3>Start learning from over 213.000 courses today.</h3>
         <h3>
            When you enroll in a course, it will appear here.{' '}
            <a href='#'>Browse now.</a>
         </h3>
      </div>
   );
};

import React, { FC }   from 'react';
import { v4 } from 'uuid';
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
//styles
import styles from './ProfileCourses.module.scss';

interface ICourse {
    id: number;
    title: string;
    status?: string;
    lastAction?: string;
}

interface ICourses {
    courses: ICourse[] | undefined;
}

export const ProfileCourses: FC<ICourses>= ({ courses }) => {

    return (
        <div  className={styles.container}>
            <div className={styles.heading}>Courses</div>
            {courses?.map(course => (
                <div key={v4()}>
                    <div className={styles.title}>
                        <FontAwesomeIcon
                            icon={faGraduationCap}
                            className={styles.icons}
                        />
                        <strong>{course.title}</strong>
                    </div>
                    <div style={{display: 'flex'}}>
                        {course.lastAction ? (
                            <div>
                                <div className={styles.last_action}><strong>last action:</strong></div>
                                <div>{course.lastAction}</div>
                            </div>
                        ) : null}
    
                    </div>
                    <div className={styles.status}>
                        {
                            course.status === "ACTIVE" ? (
                                <span className={styles.active}>{course.status}</span>  
                            ) : course.status === "COMPLETED" ? (  
                                <span className={styles.completed}>{course.status}</span>
                            ) : course.status === "STOPPED"  ? (
                                <span className={styles.stopped}>{course.status}</span>
                            ) : null
                        }
                    </div>  
                </div>
            ))}   

        </div>
    )
}
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Redirect.module.scss'

export const Redirect: React.FC = () => {
   const navigate = useNavigate()
   const location = useLocation()
   const [counter, setCounter] = useState(15)
   const isHomepage = location.state.isHomepage

   const handleRedirection = () => {
      navigate('/', { state: { from: location }, replace: true })
   }

   useEffect(() => {
      setTimeout(() => {
         setCounter(counter - 1)
      }, 1000)

      if (counter < 1) {
         handleRedirection()
      }

   }, [counter])

   return (
      <>
         {
            isHomepage
               ? <div className={styles.redirect}>
                  Another attempt in: {counter}
               </div >

               : <div className={styles.redirect}>
                  You will be redirected to <span
                     className={styles.link}
                     onClick={handleRedirection}
                  >
                     the Homepage
                  </span> in: {counter}
               </div>
         }
      </>
   );
};

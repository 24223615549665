// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CollToJoin_coll_join__dNB7I {\n  background: rgb(226, 235, 236);\n  padding: 100px 0;\n  width: 100%;\n  text-align: center;\n}\n.CollToJoin_coll_join__dNB7I .CollToJoin_wrapper__5Zjlj {\n  max-width: 1400px;\n  margin: auto;\n  padding: 0 24px;\n}\n@media screen and (max-width: 900px) {\n  .CollToJoin_coll_join__dNB7I .CollToJoin_wrapper__5Zjlj {\n    padding: 0 16px;\n  }\n}\n.CollToJoin_coll_join__dNB7I .CollToJoin_wrapper__5Zjlj h2 {\n  font-weight: 700;\n  font-size: 36px;\n}\n.CollToJoin_coll_join__dNB7I .CollToJoin_wrapper__5Zjlj p {\n  font-size: 24px;\n}\n.CollToJoin_coll_join__dNB7I .CollToJoin_wrapper__5Zjlj p, .CollToJoin_coll_join__dNB7I .CollToJoin_wrapper__5Zjlj h2 {\n  margin: 20px;\n}\n.CollToJoin_coll_join__dNB7I .CollToJoin_wrapper__5Zjlj .CollToJoin_btn__b6VdS {\n  margin: 0 auto;\n  width: 300px;\n}", "",{"version":3,"sources":["webpack://./src/modules/guest/pages/TeachOnTraining/components/CollToJoin/CollToJoin.module.scss"],"names":[],"mappings":"AAEA;EACI,8BAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AADJ;AAEI;EACI,iBAAA;EACA,YAAA;EACA,eAAA;AAAR;AACI;EAJA;IAKE,eAAA;EAEJ;AACF;AADQ;EACI,gBAAA;EACA,eAAA;AAGZ;AADQ;EACI,eAAA;AAGZ;AADQ;EACI,YAAA;AAGZ;AADQ;EACI,cAAA;EACA,YAAA;AAGZ","sourcesContent":["@use '../../../../../../theme/config' as v;\n\n.coll_join {\n    background: rgb(226, 235, 236);\n    padding: 100px 0;\n    width: 100%;\n    text-align: center;\n    .wrapper {\n        max-width: 1400px; \n        margin: auto;\n        padding: 0 v.$px-large;\n    @media screen and (max-width: v.$md) {\n      padding: 0 v.$px-small;\n   }\n        h2 {\n            font-weight: 700;\n            font-size: 36px;  \n        }\n        p {\n            font-size: 24px;\n        }\n        p, h2 {\n            margin: 20px;\n        }\n        .btn {\n            margin: 0 auto;\n            width: 300px;\n        }\n    }\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coll_join": "CollToJoin_coll_join__dNB7I",
	"wrapper": "CollToJoin_wrapper__5Zjlj",
	"btn": "CollToJoin_btn__b6VdS"
};
export default ___CSS_LOADER_EXPORT___;

import { v4 } from 'uuid';

// material-ui
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

// project components
import { HelpCenterHeader } from '../../components/HelpCenterHeader';
import { AskQuestionTitle } from '../../components/AskQuestionTitle';
import { AskQuestionDetails } from '../../components/AskQuestionDetails';

// styles
import  styles from './AskQuestion.module.scss';

export const AskQuestion: React.FC = () => {
  return (
    <>
      <HelpCenterHeader />
      <div className={styles.container}>
        <div className={styles.question}>
          <AskQuestionTitle />
          <AskQuestionDetails />
        </div>
        <div className={styles.ask_tip}>
          <h3 className={styles.tip_title}>
            Before you post
            <TipsAndUpdatesOutlinedIcon />
          </h3>
          <p className={styles.tip_text}>There may be other students who have asked the same question before</p>
          <p className={styles.tip_text}>You should do a quick search first to make sure your question is unique.</p>
        </div>
      </div>
    </>
  )
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Divider_title__lPcDS {\n  display: flex;\n  align-items: center;\n  color: #858f97;\n  font-size: 22px;\n  font-weight: 700;\n  letter-spacing: 2px;\n  margin: 20px 0;\n}\n.Divider_title__lPcDS h3 {\n  white-space: nowrap;\n}\n\n.Divider_divider__nkXIX {\n  width: 100%;\n  margin-left: 10px !important;\n  border-top: 1px solid rgba(128, 128, 128, 0.3);\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/Divider/Divider.module.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AACH;AACG;EACG,mBAAA;AACN;;AAGA;EACG,WAAA;EACA,4BAAA;EACA,8CAAA;AAAH","sourcesContent":[".title {\n   display: flex;\n   align-items: center;\n   color: #858f97;\n   font-size: 22px;\n   font-weight: 700;\n   letter-spacing: 2px;\n   margin: 20px 0;\n\n   h3 {\n      white-space: nowrap;\n   }\n}\n\n.divider {\n   width: 100%;\n   margin-left: 10px !important;\n   border-top: 1px solid rgba(128, 128, 128, 0.3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Divider_title__lPcDS",
	"divider": "Divider_divider__nkXIX"
};
export default ___CSS_LOADER_EXPORT___;

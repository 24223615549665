// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScoreDetails_score__RBje\\+ {\n  margin: 20px 0;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n.ScoreDetails_score__RBje\\+ p {\n  display: inline;\n}\n\n.ScoreDetails_heading__krJNA {\n  font-size: 18px;\n  font-weight: 600;\n}\n\n@media screen and (min-width: 700px) {\n  .ScoreDetails_grid_info__WEK\\+0 {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    column-gap: 5px;\n  }\n}\n\n.ScoreDetails_answers__rW8tY {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-top: 10px;\n}\n.ScoreDetails_answers__rW8tY li {\n  margin-left: 20px;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/score/ScoreDetails/ScoreDetails.module.scss"],"names":[],"mappings":"AAAA;EACG,cAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACH;AACG;EACG,eAAA;AACN;;AAGA;EACG,eAAA;EACA,gBAAA;AAAH;;AAIG;EADH;IAEM,aAAA;IACA,8BAAA;IACA,eAAA;EAAJ;AACF;;AAGA;EACG,aAAA;EACA,sBAAA;EACA,QAAA;EACA,gBAAA;AAAH;AAEG;EACG,iBAAA;AAAN","sourcesContent":[".score {\n   margin: 20px 0;\n   display: flex;\n   flex-direction: column;\n   gap: 10px;\n\n   p {\n      display: inline;\n   }\n}\n\n.heading {\n   font-size: 18px;\n   font-weight: 600;\n}\n\n.grid_info {\n   @media screen and (min-width: 700px) {\n      display: grid;\n      grid-template-columns: 1fr 1fr;\n      column-gap: 5px;\n   }\n}\n\n.answers {\n   display: flex;\n   flex-direction: column;\n   gap: 8px;\n   margin-top: 10px;\n\n   li {\n      margin-left: 20px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"score": "ScoreDetails_score__RBje+",
	"heading": "ScoreDetails_heading__krJNA",
	"grid_info": "ScoreDetails_grid_info__WEK+0",
	"answers": "ScoreDetails_answers__rW8tY"
};
export default ___CSS_LOADER_EXPORT___;

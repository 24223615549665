//components
import { HomePageTabs } from '../../components';
//router
import { Outlet } from 'react-router-dom';
//styles
import styles from './Home.module.scss';

export const Home = () => {
   return (
      <>
         <div className={styles.hero}>
            <div className={styles.hero_container}>
               <h2>My learning</h2>
               <HomePageTabs />
            </div>
         </div>
         <div className={styles.content_container}>
            <Outlet />
         </div>
      </>
   );
};

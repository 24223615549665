import { observer } from 'mobx-react-lite';
// styles
import styles from '../Categories.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import {
   Categories,
   Subcategories,
} from '../../../hooks/useCategoriesCascader';
import { useRootStore } from '../../../../../../hooks';

interface CategoryTypes {
   options: Categories[] | Subcategories[] | [];
   setOpen: React.Dispatch<
      React.SetStateAction<{ id: number; isOpen: boolean }>
   >;
   closeCourses: React.Dispatch<
      React.SetStateAction<{
         id: number;
         isOpen: boolean;
      }>
   >;
   setID: number;
}

export const CategoriesRow: React.FC<CategoryTypes> = observer(
   ({ options, setOpen, closeCourses, setID }) => {
      const { layoutStore } = useRootStore();
      const coursesRefresh = layoutStore.getCoursesRefresh();

      const onMouseEnterLi = (e: React.MouseEvent<HTMLLIElement>) => {
         const idLI = Number(e.currentTarget.id);

         if (setID === 1) {
            closeCourses({ id: 0, isOpen: false });
         } else {
            layoutStore.setIDCourses(idLI);
            layoutStore.setCoursesRefresh(!coursesRefresh);
         }

         setOpen({ id: idLI - setID, isOpen: true });
      };

      return (
         <ul className={styles.menu}>
            {options.map((option: Categories | Subcategories) => {
               return (
                  <li
                     key={option.id.toString()}
                     className={styles.item}
                     id={option.id.toString()}
                     onMouseEnter={onMouseEnterLi}
                  >
                     {option.name}
                     <FontAwesomeIcon icon={faAngleRight} />
                  </li>
               );
            })}
         </ul>
      );
   },
);

//mui
import CheckIcon from '@mui/icons-material/Check';
//styles
import styles from './AboutThisCourse.module.scss';

export const AboutThisCourse = () => {
   return (
      <div className={styles.container}>
         <div className={styles.content}>
            <div className={styles.about_course}>
               <div className={styles.title}>
                  <h3>About this course</h3>
                  <p className={styles.divider}></p>
               </div>
               <div className={styles.description}>
                  <p>
                     This course will teach you the fundamentals o*f working
                     with Angular 2. You *will learn everything you need to know
                     to create complete applications including: components,
                     services, directives, pipes, routing, HTTP, and even
                     testing.
                  </p>
                  <p>
                     This course will teach you the fundamentals o*f working
                     with Angular 2. You *will learn everything you need to know
                     to create complete applications including: components,
                     services, directives, pipes, routing, HTTP, and even
                     testing.
                  </p>
               </div>
            </div>
            <div className={styles.learn}>
               <div className={styles.title}>
                  <h3>What you`ll learn</h3>
                  <p className={styles.divider}></p>
               </div>
               <ul className={styles.points}>
                  <li>
                     <CheckIcon />
                     Fundamentals of working with Angular
                  </li>
                  <li>
                     <CheckIcon />
                     Create complete Angular applications
                  </li>
                  <li>
                     <CheckIcon />
                     Working with the Angular CLI
                  </li>
                  <li>
                     <CheckIcon />
                     Understanding Dependency Injection
                  </li>
                  <li>
                     <CheckIcon />
                     Testing with Angular
                  </li>
               </ul>
            </div>
         </div>
      </div>
   );
};

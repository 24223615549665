import { v4 } from 'uuid';
//component
import { PageContainer } from '../../../../../UI';
//project import
import { imgTrustTeamSection } from '../mockData';
//styles
import styles from './TrustTeamSection.module.scss';

export const TrustTeamSection = () => {
   return (
      <section className={styles.container}>
         <PageContainer>
            <h2>Trusted by over 13,400 great teams</h2>
            <h3>
               Leading companies use the same courses to help employees keep
               their skills fresh.
            </h3>
            <ul className={styles.img_container}>
               {imgTrustTeamSection.map((img) => (
                  <li key={v4()}>
                     <img loading='lazy' src={img} alt='team_logo' />
                  </li>
               ))}
            </ul>
         </PageContainer>
      </section>
   );
};

import UserService from "../utils/userService";

export const useSignOut = () => {
   const signOut = () => {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('isAuth');
      UserService.doLogout();
   };

   return signOut;
};

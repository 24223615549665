function useIsoDateTimeFormat(dateTime: string | number | Date | undefined) {
   if (dateTime) {
      const date: Date = new Date(dateTime);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

      if (formattedDate === 'Invalid Date') return;

      return formattedDate;
   }
}

export default useIsoDateTimeFormat;

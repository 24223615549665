// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpectatorHome_container__AE2aV {\n  margin: 1rem 15px;\n  display: flex;\n  flex-direction: column;\n  row-gap: 1rem;\n  padding-top: 60px !important;\n}\n@media screen and (min-width: 600px) {\n  .SpectatorHome_container__AE2aV {\n    margin: 1rem 20vw;\n    padding-top: 60px !important;\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/spectator/pages/SpectatorHome/SpectatorHome.module.scss"],"names":[],"mappings":"AAAA;EACG,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,4BAAA;AACH;AACG;EAPH;IAQM,iBAAA;IACA,4BAAA;EAEJ;AACF","sourcesContent":[".container {\n   margin: 1rem 15px;\n   display: flex;\n   flex-direction: column;\n   row-gap: 1rem;\n   padding-top: 60px !important;\n\n   @media screen and (min-width: 600px) {\n      margin: 1rem 20vw;\n      padding-top: 60px !important;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SpectatorHome_container__AE2aV"
};
export default ___CSS_LOADER_EXPORT___;

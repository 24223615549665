// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Redirect_redirect__HfW4C {\n  font-size: x-large;\n  margin-top: 20px;\n}\n\n.Redirect_link__zUQsh {\n  color: #1976d2;\n  text-decoration: underline;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/modules/error/components/Redirect/Redirect.module.scss"],"names":[],"mappings":"AAAA;EACG,kBAAA;EACA,gBAAA;AACH;;AAEA;EACG,cAAA;EACA,0BAAA;EACA,eAAA;AACH","sourcesContent":[".redirect {\n   font-size: x-large;\n   margin-top: 20px;\n}\n\n.link {\n   color: #1976d2;\n   text-decoration: underline;\n   cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redirect": "Redirect_redirect__HfW4C",
	"link": "Redirect_link__zUQsh"
};
export default ___CSS_LOADER_EXPORT___;

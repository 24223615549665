import { useMediaQuery, Theme } from '@mui/material';
import { motion } from 'framer-motion';
//components
import { PageContainer } from '../../UI';
import { Accordions } from '../../guest/pages/GuestHome/components/TabsSection/Accordions/Accordions';
import { AllCoursesSliders } from '../components/AllCoursesSliders/AllCoursesSliders';
import { Training5OrMore } from '../../student/components';
//styles
import styles from './AllCourses.module.scss';

export const AllCourses = () => {
   const matchesSm = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('sm'),
   );

   return (
      <PageContainer>
         <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
         >
            <div className={styles.section_container}>
               <Training5OrMore />
               {!matchesSm ? <Accordions /> : <AllCoursesSliders />}
            </div>
         </motion.div>
      </PageContainer>
   );
};

import { useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

// project import
import { useRootStore } from '../../hooks';
import { UserRoles } from '../../utils/consts';
import { GuestRoutes } from './GuestRoutes';
import { StudentRoutes, StudentRoutesMinimal } from './StudentRoutes';
import { MentorRoutes } from './MentorRoutes';
import { SpectatorRoutes } from './SpectatorRoutes';
import { ManagerRoutes } from './ManagerRoutes';
import { ErrorRoutes } from './ErrorRoutes';
import {
   StudentCommonRoutes,
   GuestCommonRoutes,
   SpectatorCommonRoutes,
   MentorCommonRoutes,
   ManagerCommonRoutes,
} from './CommonRoutes';
import { UserRoutes } from './UserRoutes';

const Routes: React.FC = observer(() => {
   const { userStore } = useRootStore();
   const userRole = userStore.getUserRole();

   let routes;

   switch (userRole) {
      case UserRoles.student:
         routes = [
            StudentRoutes,
            StudentCommonRoutes,
            StudentRoutesMinimal,
            ErrorRoutes,
         ];
         break;
      case UserRoles.mentor:
         routes = [
            MentorRoutes,
            MentorCommonRoutes,
         ];
         break;
      case UserRoles.spectator:
         routes = [
            SpectatorRoutes,
            SpectatorCommonRoutes,
         ];
         break;
      case UserRoles.manager:
         routes = [
            ManagerRoutes,
            ManagerCommonRoutes,
         ];
         break;
      case UserRoles.user:
         routes = [
            UserRoutes
         ]
         break;
      // case 'admin':
      //    routes = [MainRoutes, LoginRoutes, StudentRoutes, MentorRoutes];
      //    break;
      default:
         routes = [
            GuestRoutes,
            GuestCommonRoutes,
         ];
         break;
   }

   return useRoutes(routes);
});

export default Routes;

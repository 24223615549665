import { useState, useEffect } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
// components
import { ShowMoreBox } from '../../../UI';
// project
// import file from './description.md';
import file from '../../../../assets/markdown/coursePreview/description.md';
// styles
import styles from './Requirements.module.scss';

const requirements = [
   'Basic HTML, CSS and JavaScript knowledge',
   'You do not need any experience with Vue or any other JS framework!',
];

export const Requirements: React.FC = () => {
   const [description, setDescription] = useState('');

   useEffect(() => {
      fetch(file)
         .then((res) => {
            return res.text();
         })
         .then((text) => {
            setDescription(text);
         });
   }, []);

   const requirementsMap = requirements.map((item) => (
      <li key={Math.random() * 100000} className={styles.requirements_li}>
         <div className={styles.bullet}>●</div>
         {item}
      </li>
   ));

   return (
      <div className={styles.wrapper}>
         <h3 className={styles.title}>Requirements</h3>
         <ul className={styles.requirements_ul}>{requirementsMap}</ul>
         <h3 className={styles.title}>Description</h3>
         <ShowMoreBox height={400}>
            <div className={styles.md_container}>
               <MarkdownPreview
                  source={description}
                  className={styles.markdown}
                  disableCopy
                  warpperElement={{
                     'data-color-mode': 'light',
                  }}
               />
            </div>
         </ShowMoreBox>
      </div>
   );
};

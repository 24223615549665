import axios from 'axios';
import { baseURL } from '../utils/consts';
import UserService from '../utils/userService';

const $host = axios.create({
   baseURL,
});

const $authHost = axios.create({
   baseURL,
});

$authHost.interceptors.request.use((config) => {
   if (UserService.isLoggedIn())
      config.headers.Authorization = `Bearer ${UserService.getToken()}`;
   return config;
});

$authHost.interceptors.response.use(
   (res) => {
      return res;
   },
   (err) => {
      if (err.response.data.status === 401) {
         UserService.doLogin();
      }
      return Promise.reject(err);
   },
);

export { $host, $authHost };

import { QuestionsType } from '../utils/consts';
import { makeAutoObservable } from 'mobx';
import { postQuizAnswersAndGetResult } from '../http/quizAPI';

export interface IThemaAnswer {
   id: number;
   description?: string;
}

export interface IThemaQuestion {
   id: number;
   questionType: QuestionsType;
   quizAnswers: IThemaAnswer[];
}

export interface IThemaQuestions {
   id: number;
   title: string;
   questions: IThemaQuestion[];
}

export default class QuizStore {
   private quizId: number;
   private quizName: string;
   public numberOfQuestions: number;
   private themas: IThemaQuestions[];
   private counterFunction: Function;
   private isQuizTimeOver: boolean;
   private guestUserName: string | null;

   constructor() {
      this.quizId = 0;
      this.quizName = '';
      this.numberOfQuestions = 0;
      this.themas = [];
      this.counterFunction = () => {};
      this.isQuizTimeOver = false;
      this.guestUserName = sessionStorage.getItem('guestUserName')
         ? JSON.parse(sessionStorage.getItem('guestUserName') || '')
         : null;
      makeAutoObservable(this);
   }

   setCounterFunction(func: () => void) {
      this.counterFunction = func;
   }

   setIsQuizTimeOver(isTimeOver: boolean) {
      this.isQuizTimeOver = isTimeOver;
   }

   setGuestUserName(userName: string) {
      this.guestUserName = userName;
   }

   addPresetQuestionAnswerByTitle(
      title: string,
      answerId: number,
      questionId: number,
   ) {
      this.themas = this.themas.map((thema) =>
         thema.title === title
            ? {
                 ...thema,
                 questions: [
                    ...thema.questions.map((question) =>
                       question.id === questionId
                          ? {
                               ...question,
                               quizAnswers: [
                                  ...question.quizAnswers,
                                  { id: answerId },
                               ],
                            }
                          : question,
                    ),
                 ],
              }
            : thema,
      );
   }

   addListingQuestionAnswerByTitle(
      title: string,
      answerId: number,
      questionId: number,
      answerText: string,
   ) {
      this.themas = this.themas.map((thema) =>
         thema.title === title
            ? {
                 ...thema,
                 questions: [
                    ...thema.questions.map((question) =>
                       question.id === questionId
                          ? {
                               ...question,
                               quizAnswers: [
                                  ...question.quizAnswers,
                                  { id: answerId, description: answerText },
                               ],
                            }
                          : question,
                    ),
                 ],
              }
            : thema,
      );
   }

   setQuiz(
      quizId: number,
      quizName: string,
      numberOfQuestions: number,
      themas: IThemaQuestions[],
   ) {
      this.quizId = quizId;
      this.quizName = quizName;
      this.numberOfQuestions = numberOfQuestions;
      this.themas = [...themas];
   }

   getCounterFunction() {
      return this.counterFunction;
   }

   getIsQuizTimeOver() {
      return this.isQuizTimeOver;
   }

   getGuestUserName() {
      return this.guestUserName;
   }

   getThemas() {
      return this.themas;
   }

   getQuizState() {
      const { quizName, numberOfQuestions } = this;
      return {
         quizName,
         numberOfQuestions,
      };
   }

   async getQuizResult() {
      const data = await postQuizAnswersAndGetResult({
         id: this.quizId,
         name: this.quizName,
         guestQuizUserName: this.guestUserName,
         themas: this.themas,
      });
      return data;
   }
}

import { FC, RefObject } from 'react';
//project imports
import useProtectedContent from '../../../../../hooks/useProtectedContent';
import { IScoreData } from '../../../../../interfaces/interfaces';
import { ENDPOINTS } from '../../../../../../../http/studentAPI';
//components
import { ResultItem } from './ResultItem/ResultItem';
import { Loader } from '../../../../Loader';
import { TaskAndResultAccordion } from '../Accordion/TaskAndResultAccordion';
//styles
import styles from './ResultsSection.module.scss';

interface IProps {
   id: number;
   refForResults: RefObject<HTMLDivElement>;
}

export const ResultsSection: FC<IProps> = ({ id, refForResults }) => {
   const result = useProtectedContent(ENDPOINTS.resultsTab(id)) as IScoreData;
   const topics = result && result.topicToWorkQuizScore;

   if (!result) return <Loader />;

   return (
      <div className={styles.container} id='Results' ref={refForResults}>
         <div className={styles.number}>2</div>
         <h2 className={styles.title}>Results Section</h2>
         <p className={styles.description}>
            Data sharing is an essential concept to understand before diving
            into your first Angular project. In this section, you will learn
            four different methods for sharing data between Angular components.
         </p>
         <div className={styles.accordion_container}>
            {Object.keys(topics).map((topic) => (
               <TaskAndResultAccordion
                  key={Math.random() * 1000}
                  defaultExpanded={false}
                  topic={`${topic} Quiz Result`}
               >
                  {topics[topic as keyof typeof topics].map((result) => (
                     <ResultItem key={Math.random() * 1000} result={result} />
                  ))}
               </TaskAndResultAccordion>
            ))}
         </div>
      </div>
   );
};

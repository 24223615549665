// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AnswerSubmitted_result__nZk4v {\n  display: flex;\n  width: 100%;\n  white-space: nowrap;\n}\n\n.AnswerSubmitted_result_info__nEi12 {\n  font-weight: 600;\n  padding-left: 5px;\n  display: inline-block;\n  width: 100%;\n  text-align: end;\n}\n\n.AnswerSubmitted_info_color__xiM-K {\n  color: grey;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/QuizResults/QuizResultsItems/AnswerSubmitted/AnswerSubmitted.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,WAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".result {\n  display: flex;\n  width: 100%;\n  white-space: nowrap;\n}\n\n.result_info {\n  font-weight: 600;\n  padding-left: 5px;\n  display: inline-block;\n  width: 100%;\n  text-align: end;\n}\n\n.info_color {\n  color: grey;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"result": "AnswerSubmitted_result__nZk4v",
	"result_info": "AnswerSubmitted_result_info__nEi12",
	"info_color": "AnswerSubmitted_info_color__xiM-K"
};
export default ___CSS_LOADER_EXPORT___;

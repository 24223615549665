import { FC } from 'react';
import {
    ProfileInfo,
    ProfileCourses,
    ProfileNote
} from '../index';

interface ICourses {
    id: number;
    title: string;
    status?: string;
    lastAction?: string;
}

interface IStudents {
    id: number;
    avatarLogoUrl: string | null;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    skype: string;
    telegram: string;
    courses?: ICourses[] | undefined;
    note: string ;
}

interface IProps {
    students: IStudents[];
    activeItem: number;
}

export const Profile: FC<IProps> = ({students, activeItem}) => {

    const student = students.find(obj => {
        return obj.id === activeItem;
    });

    const studentInfo = activeItem === 0 ? students[0] : student;

    return (
        <>
            <div>
                <ProfileInfo student={studentInfo}/>
                <ProfileCourses courses={studentInfo?.courses} />
                <ProfileNote id={studentInfo?.id} note={studentInfo?.note}/>
            </div>

        </>
    )
}

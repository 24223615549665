import { Link, useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

// project imports
import { publicURL } from '../../../../../utils/consts';

// assets
import { LanguageOutlined } from '@mui/icons-material';
// styles
import styles from './Footer.module.scss';

const links = [
   {
      title: 'Training Business',
      url: '',
      path: '/',
      blank: true,
      active: true,
   },
   {
      title: 'Teach on Training',
      url: '',
      path: '/teach_on_raining',
      blank: true,
      active: true,
   },
   {
      title: 'Get the app',
      url: '',
      path: '/',
      blank: true,
      active: false,
   },
   {
      title: 'About us',
      url: '',
      path: '/',
      blank: true,
      active: true,
   },
   {
      title: 'Contact us',
      url: '',
      path: '/',
      blank: true,
      active: true,
   },
   {
      title: 'Careers',
      url: '',
      path: '/',
      blank: true,
      active: true,
   },
   {
      title: 'Blog',
      url: '',
      path: '/',
      blank: true,
      active: false,
   },
   {
      title: 'Help Center',
      url: '/help-center',
      path: '/help-center',
      blank: false,
      active: true,
   },
   {
      title: 'API',
      url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      path: '/',
      blank: true,
      active: true,
   },
   {
      title: 'Investors',
      url: '',
      path: '/',
      blank: true,
      active: false,
   },
   
   {
      title: 'Terms',
      url: '',
      path: '/',
      blank: true,
      active: true,
   },
   {
      title: 'Privacy policy',
      url: '',
      path: '/',
      blank: true,
      active: true,
   },
   {
      title: 'Cookie settings',
      url: '',
      path: '/',
      blank: true,
      active: false,
   },
   
   {
      title: 'User Manual',
      url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      path: '',
      blank: true,
      active: true,
   },
   {
      title: 'Accessibility statement',
      url: '',
      path: '/',
      blank: true,
      active: false,
   },
];




export const Footer: React.FC = () => {

   const navigate = useNavigate();

   const handleClickTeach = () => {
      navigate('/teach_on_raining');
   };

   return (
      <div className={styles.wrapper}>
         <header className={styles.header}>
            <div className={styles.content}>
               <h3>Teach Your Students Online</h3>
               <p>
                  Create an online video course, reach students across the
                  globe, and earn money
               </p>
            </div>
            <button onClick={handleClickTeach} className={[styles.button, styles.teach_btn].join(' ')}>
               Teach on Training
            </button>
         </header>
         <main className={styles.main}>
            <button className={styles.button}>
               <LanguageOutlined sx={{ fontSize: 18, verticalAlign: 'text-bottom' }} />
               <span>English</span>
            </button>
            <div className={styles.grid}>
               {links.map((link) => {
                  const { title, url, path, blank, active } = link;
                  return url ? (
                     <a
                        key={v4()}
                        href={url}
                        target={blank ? '_blank' : '_self'}
                        rel='noreferrer'
                        className={[
                           styles.link,
                           !active ? styles.inactive : '',
                        ].join(' ')}
                     >
                        {title}
                     </a>
                  ) : (
                     <Link
                        key={v4()}
                        to={path}
                        target={blank ? '_blank' : '_self'}
                        title={title}
                        className={[
                           styles.link,
                           !active ? styles.inactive : '',
                        ].join(' ')}
                     >
                        {title}
                     </Link>
                  );
               })}
            </div>
         </main>
         <footer className={styles.footer}>
            <img
               className={styles.logo}
               src={`${publicURL}/images/logo-tall.svg`}
               alt='Foxminded Logo'
            />{' '}
            <p>© 2023 Training Service, Inc.</p>
         </footer>
      </div>
   );
};
import { UserLayout } from "../UI/Layout/UserLayout";
import { UserHomePage } from "../user";

enum User {
  home = '/'
}

export const UserRoutes = {
  path: User.home,
  element: <UserLayout />,
  children: [{
    path: '',
    element: <UserHomePage />
  }]
}
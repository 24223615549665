import { FC } from 'react';
//project imports
import { v4 } from 'uuid';
import { useWindowDimensions } from '../../../../hooks';
import { titleCourses } from '../../../guest/pages/GuestHome/components/mockData';
//mui
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box';
//components
import { ExpandedBlock } from '../ExpandedBlock/ExpandedBlock';
import { SlickSlider } from '../../../UI';
import { allCoursesDesktop } from '../../../UI/SlickSlider/Settings';
import { CourseCardWidget } from '../../../UI/CourseCardWidget/CourseCardWidget';
//styles
import styles from './AllCoursesSliders.module.scss';

export const AllCoursesSliders: FC = () => {
   const { windowWidth } = useWindowDimensions();

   const checkEnableSlider = (arr: any) => {
      if (
         (windowWidth >= 1200 && arr.length >= 5) ||
         (windowWidth < 1200 && windowWidth >= 900 && arr.length >= 4) ||
         (windowWidth < 900 && windowWidth >= 600 && arr.length >= 3)
      ) {
         return true;
      } else {
         return false;
      }
   };

   return (
      <div className={styles.all_courses}>
         <Box>
            <Typography variant='h3' component='h3'>
               All our courses
            </Typography>

            <SlickSlider settings={allCoursesDesktop}>
               {titleCourses.map((category) =>
                  category.courses.map((course) => (
                     <CourseCardWidget courseData={course} key={v4()} />
                  )),
               )}
            </SlickSlider>
         </Box>

         {titleCourses.map((category) => (
            <div className={styles.category} key={v4()}>
               <h3>{category.title}</h3>
               <div className={styles.container}>
                  <ExpandedBlock 
                     description={`${category.description}`}
                     title={`${category.title}`}
                  />

                  {checkEnableSlider(category.courses) ? (
                     <SlickSlider settings={allCoursesDesktop}>
                        {category.courses.map((course) => (
                           <CourseCardWidget courseData={course} key={v4()} />
                        ))}
                     </SlickSlider>
                  ) : (
                     <div className={styles.conteiner_small_amount}>
                        {category.courses.map((course) => (
                           <CourseCardWidget courseData={course} key={v4()} />
                        ))}
                     </div>
                  )}
               </div>
            </div>
         ))}
      </div>
   );
};
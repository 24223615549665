import { v4 } from 'uuid';

// material-ui
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

// styles
import styles from './HelpCenterCard.module.scss';

interface Props {
  cardInfo: {
    category: string;
    title: string;
    subtitle: string;
    items: {
        itemTitle: string;
        url: string;
      }[];
  };
}

export const HelpCenterCard: React.FC<Props> = ({ cardInfo }) => {
  return (
    <div className={styles.topics_card}>
      <div className={styles.card}>
        <div className={styles.card_title}>
          <h3 className={styles.title}>{cardInfo.title}</h3>
          <p className={styles.subtitle}>{cardInfo.subtitle}</p>
        </div>
        <div className={styles.info}>
          <InfoOutlinedIcon 
            className={styles.info_icon}
          />
        </div>
      </div>

      {cardInfo.items.map((item) => (
        <div className={styles.list_item} key={v4()}>
          <a 
            href={`${item.url}`}
            className={styles.item_link}
          >
            <p className={styles.item_title}>
              {item.itemTitle}
            </p>
          </a>
          <ArrowRightAltIcon 
            className={styles.arrow_icon}
          />
        </div>
      ))}
    </div>
  )
}
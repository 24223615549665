export const helpCenterData = [
  {
    category: 'General',
    title: 'Introdaction',
    subtitle: 'Getting Started',
    items: [
      {
        itemTitle: 'User Manual',
        url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      },
      {
        itemTitle: 'Basic Setup',
        url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      },
      {
        itemTitle: 'Working with the Dev',
        url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      },
    ],
  },
  {
    category: 'General',
    title: 'Customization',
    subtitle: 'Documentation',
    items: [
      {
        itemTitle: 'Styling with SASS',
        url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      },
      {
        itemTitle: 'Editing the Pagesp',
        url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      },
      {
        itemTitle: 'Saving for later',
        url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      },
    ],
  },
  {
    category: 'Documentation',
    title: 'API',
    subtitle: 'Documentation',
    items: [
      {
        itemTitle: 'Course API Docs',
        url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      },
      {
        itemTitle: 'Student API Docs',
        url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      },
      {
        itemTitle: 'Instructor API Docs',
        url: 'https://drive.google.com/drive/folders/1-O_i7B2PWmk9natNlTCmW5pGmLHo2EDL',
      },
    ],
  },
];
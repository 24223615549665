import React from 'react';

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import { RadioButtonType } from './RadioButtonType';
import './RadioButton.scss';

export const RadioButton: React.FC<RadioButtonType> = ({
   activeValue,
   value,
   name,
   handleChange,
   answerId
}) => {
   return (
      <FormControlLabel 
         className='radio-button'   
         value={value} 
         control={<Radio 
            size="small"
            color="secondary"
            sx={{
               color: "#e0e0e0",
               borderRadius: '50%',
               backgroundColor: '#e0e0e0',
               width: '8px',
               marginRight: '16px',
               height: '8px',
            }}
         />} 
         label={value}
         checked={answerId === activeValue}
         name={name}
         onChange={() => handleChange!(answerId!)} 
      />
   );
};
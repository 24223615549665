import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
   ITaskData,
   getTaskData,
   getTaskMarkdown,
} from '../../../http/mentorAPI';

export const useMentorTask = (id: string) => {
   const [taskData, setTaskData] = useState<ITaskData>();
   const [taskDescription, setTaskDescription] = useState<string>();
   const [error, setError] = useState<boolean>(false);

   const navigate = useNavigate();
   const location = useLocation();
   const isHomepage = false;

   useEffect(() => {
      getTaskData(id!)
         .then((data) => {
            setTaskData(data);
         })
         .catch(() => setError(true));
   }, []);

   useEffect(() => {
      getTaskMarkdown(id!)
         .then((data) => {
            setTaskDescription(data);
         })
         .catch(() => setError(true));
   }, []);

   useEffect(() => {
      if (error) {
         return navigate('/not-found', {
            state: { from: location, isHomepage },
            replace: true,
         });
      }
   }, [error]);

   return { taskData, taskDescription, error };
};

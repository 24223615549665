import { useState } from 'react';
//material ui
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from './TabPanel';
//project imports
import { useRootStore } from '../../../../../../../hooks';
//components
import DesktopTab from './DesktopTab';
import { Subcategories } from '../../../../../../UI/Layout/hooks/useCategoriesCascader';

export const DesktopTabs = () => {
   const { layoutStore } = useRootStore();
   const subcategories = layoutStore.getSubcategories();
   const [activeTabValue, setActiveTabValue] = useState(0);
   const allSubcategories = [
      { id: -1, name: 'All Courses', description: '' },
      ...subcategories,
   ] as Subcategories[];

   const handleActiveTabChange = (
      event: React.SyntheticEvent,
      newValue: number,
   ) => {
      setActiveTabValue(newValue);
   };

   return (
      <Box sx={{ minWidth: '100%' }}>
         <Box>
            <Tabs
               value={activeTabValue}
               onChange={handleActiveTabChange}
               variant='scrollable'
               scrollButtons='auto'
               aria-label='secondary tabs example'
               TabIndicatorProps={{ sx: { backgroundColor: 'orange' } }}
               sx={{
                  '& button': {
                     color: 'grey',
                     fontSize: '14px',
                     fontWeight: 700,
                     letterSpacing: '0.5px',
                  },
                  '& button:hover': { color: 'black' },
                  '& button.Mui-selected': { color: 'black' },
               }}
            >
               {allSubcategories.map((subcategory) => (
                  <Tab label={subcategory.name} key={subcategory.name} />
               ))}
            </Tabs>
         </Box>

         {allSubcategories.map((category, index) => {
            const categoryFilter = index === 0 ? '' : category.name;
            const showDescription = index !== 0;

            return (
               <TabPanel
                  value={activeTabValue}
                  index={index}
                  key={category.name}
               >
                  {activeTabValue === index ? (
                     <DesktopTab
                        category={category}
                        categoryFilter={categoryFilter}
                        showDescriptionAndExploreBtn={showDescription}
                     />
                  ) : (
                     <></>
                  )}
               </TabPanel>
            );
         })}
      </Box>
   );
};

import { FC, useState, useEffect} from 'react';
import { BorderedBlockContainer } from '../../../../UI/BorderedBlockContainer';
import { v4 } from 'uuid';
import { 
    getViewAllStudents, 
    IViewAllStudents,
    getPagination,
    IPagination,
} from '../../../../../http/studentsAPI';
import Pagination from '../../../../pagination/components/PaginationComponent/PaginationComponent';
import StudentItem from '../StudentItem/StudentItem';
import styles from './AllStudents.module.scss';

const AllStudents: FC = () => {
    const [studentsList, setStudentsList] = useState<IViewAllStudents[] | null>(null);
    const [pagination, setPagination] = useState<IPagination | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [lastPage, setLastPage] = useState<number>(1);
    const pageSize = 25;

    const getResultPagination = (pageSize: number, pageNumber: number) => {
        getPagination(pageSize, pageNumber).then((data) => setPagination(data));
    };

    const getLastPage = () => {
        getPagination(pageSize, pageNumber).then((data) => setLastPage(data.totalPages));
    };

    const getStudentsList = () => {
        getViewAllStudents(pageSize, pageNumber)
            .then((data) => setStudentsList(data));
    }

    useEffect(() => {
        getStudentsList()
        getLastPage()
        getResultPagination(pageSize, pageNumber)
    }, [pageNumber, pageSize])

    const renderResultPaginationList = () => {
        return (
            <>
                <Pagination
                    currentPage={pageNumber}
                    lastPage={2}
                    maxLength={7}
                    setCurrentPage={setPageNumber}
                /> 
            </>
        )
    } 

    return (
        <>
            <h3 className={styles.page__title}>All students</h3>
                {studentsList?.length ? (
                    <>
                        {studentsList?.map(
                            ({ firstName, lastName, courses, id, avatarLogoUrl, email, phone, telegram, skype, note, defaultValue }) => (
                                <StudentItem 
                                    firstName={firstName} 
                                    lastName={lastName}
                                    courses={courses}
                                    key={v4()}
                                    id={id} 
                                    avatarLogoUrl={avatarLogoUrl} 
                                    email={email}
                                    phone={phone}
                                    telegram={telegram}
                                    skype={skype}
                                    note={note}
                                />
                            ),
                        )} 
                    </>
            ) : <BorderedBlockContainer className={styles.empty__block}>
                    <p>The list of students is empty...</p> 
                </BorderedBlockContainer>
            }

            {lastPage !== 1 ? renderResultPaginationList() : null }
        </>
    );
};

export default AllStudents;
// import
import { BasicModal, OptionalModal } from './modalTypes';

type Props = {
   children: React.ReactNode;
   open: boolean;
   handleClose: () => void;

   className?: string;
   type?: MODAL_TYPES;
   handleConfirm?: () => void;
   confirmBtnContent?: string;
   dismissBtnContent?: string;
};

export enum MODAL_TYPES {
   basic = 'basic',
   optional = 'optional',
}

const getModal = (type = MODAL_TYPES.basic): typeof BasicModal =>
   ({
      [MODAL_TYPES.basic]: BasicModal,
      [MODAL_TYPES.optional]: OptionalModal,
   }[type]);

export const AppModal: React.FC<Props> = ({
   children,
   open,
   handleClose,
   className = '',
   type,
   handleConfirm,
   confirmBtnContent,
   dismissBtnContent,
}) => {
   const CustomModal = getModal(type);
   return (
      <CustomModal
         open={open}
         handleClose={handleClose}
         className={className}
         handleConfirm={handleConfirm}
         confirmBtnContent={confirmBtnContent}
         dismissBtnContent={dismissBtnContent}
      >
         {children}
      </CustomModal>
   );
};

import { v4 } from 'uuid';
//components
import {
   SlickSlider,
   CourseCardWidget,
   CourseCardWidgetMobile,
} from '../../../../../UI';
import {
   allCoursesDesktopForStudent,
   allCoursesMobileForStudent,
   studentsAreViewingForStudent,
} from '../../../../../UI/SlickSlider/Settings';
import {
   titleCourses,
   studentsViewingList,
} from '../../../../../guest/pages/GuestHome/components/mockData';
import { Training5OrMore } from '../../../../components';
//project imports
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
//styles
import styles from './AllCoursesPage.module.scss';

export const AllCoursePage = () => {
   const { windowWidth } = useWindowDimensions();

   return (
      <div className={styles.container}>
         <Training5OrMore />
         <h1 className={styles.title}>What to learn next</h1>

         <div className={styles.slider_container}>
            <h2 className={styles.slider_title}>Students are viewing</h2>
            <SlickSlider settings={studentsAreViewingForStudent}>
               {windowWidth > 600
                  ? studentsViewingList.map((course) => (
                       <CourseCardWidget courseData={course} key={v4()} />
                    ))
                  : studentsViewingList.map((course) => (
                       <CourseCardWidgetMobile courseData={course} key={v4()} />
                    ))}
            </SlickSlider>
         </div>

         {titleCourses.map((category) => {
            if (
               category.title === 'Front-End' ||
               category.title === 'Back-End'
            ) {
               return (
                  <div key={v4()} className={styles.slider_container}>
                     <h2 className={styles.slider_title}>{category.title}</h2>

                     <SlickSlider
                        settings={
                           windowWidth > 600
                              ? allCoursesDesktopForStudent
                              : allCoursesMobileForStudent
                        }
                     >
                        {category.courses.map((course) => (
                           <CourseCardWidget courseData={course} key={v4()} />
                        ))}
                     </SlickSlider>
                  </div>
               );
            } else {
               return null;
            }
         })}
      </div>
   );
};

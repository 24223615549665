import styles from './UserHomePage.module.scss';
export const UserHomePage = () => {
  return (
    <>
      <div className={styles.hero}>
        <div className={styles.hero_container}>
          <h2>Newcomer</h2>
          <p>Welcome to our website! Thank you for visiting. We're delighted to have you here!</p>
        </div>
      </div>
    </>
  )
}

import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
// styles
import styles from '../Categories.module.scss';

import { Courses } from '../../../hooks/useCategoriesCascader';

interface CoursesTypes {
   options: Courses[] | [];
}

export const CoursesRow: React.FC<CoursesTypes> = observer(({ options }) => {
   return (
      <ul className={styles.menu}>
         {options.map((option: Courses, index) => {
            return (
               <li key={index} className={styles.item}>
                  <NavLink to={option.slug} className={styles.category__link}>
                     {option.title}
                  </NavLink>
               </li>
            );
         })}
      </ul>
   );
});

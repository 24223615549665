//components
import {
   Hero,
   TabsSection,
   CommentsSection,
   StudentsAreViewing,
   TopCategories,
   FeaturedTopics,
   TrustTeamSection,
   BusinessSection,
   InstructorSection,
} from './components';
//styles
import styles from './GuestHome.module.scss';
import { useEffect, useState } from 'react';
import {
   getAllFeedback,
   getCoursesPreview,
   IAllFeedback,
} from '../../../../http/guestAPI';
import { commentsLengthChecker } from '../../../../utils/utils';
import { useRootStore } from '../../../../hooks';
import { Spinner } from '../../../UI/Spinner';

export const GuestHome: React.FC = () => {
   const [feedback, setFeedback] = useState<IAllFeedback>();
   const { guestStore } = useRootStore();

   useEffect(() => {
      if (!guestStore.getCoursesPreview()) {
         guestStore.setIsLoadingCoursesPreview(true);

         getCoursesPreview().then((data) => {
            guestStore.setCoursesPreview(data);
            guestStore.setIsLoadingCoursesPreview(false);
         });
      }

      getAllFeedback(5, 5).then((feedback) =>
         setFeedback({
            ...feedback,
            comments: commentsLengthChecker(feedback.comments),
         }),
      );
   }, []);

   return (
      <div className={styles.container}>
         <Hero />
         <TabsSection />
         {!feedback ? <Spinner /> : <CommentsSection feedback={feedback} />}
         <StudentsAreViewing />
         <TopCategories />
         <FeaturedTopics />
         <TrustTeamSection />
         <BusinessSection />
         <InstructorSection />
      </div>
   );
};

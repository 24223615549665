import { GuestLayout } from "../UI/Layout/GuestLayout";
import { GuestHome } from "../guest/pages/GuestHome/GuestHome";

export enum GuestPaths {
   home = '/',
   auth = '/auth',
   signUp = '/auth/sign-up'
}

export const GuestRoutes = {
   path: GuestPaths.home,
   element: <GuestLayout />,
   children: [
      {
         path: GuestPaths.home,
         element: <GuestHome />,
      },
   ],
};
import { FC, useState, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
// components
import { AppModal, Button, MODAL_TYPES } from '../../../../UI';
import { CellName, Row, CellGroup, CellTask, CellQuizAnswer } from '../index';
// project
import { IReviewQuiz, rateQuizAnswer } from '../../../../../http/mentorAPI';
import { useRootStore, useIsoDateTimeFormat } from '../../../../../hooks';
// styles
import styles from './QuizTableRow.module.scss';

interface Props {
   quiz: IReviewQuiz;
}

export const QuizTableRow: FC<Props> = observer(({ quiz }) => {
   const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
   const [isAnswerOpen, setIsAnswerOpen] = useState<boolean>(false);
   const [score, setScore] = useState<string>('');

   const { mentorStore } = useRootStore();
   const refresh = mentorStore.getQuizRefresh();

   const {
      answer,
      studentLastName,
      studentFirstName,
      studentId,
      answerId,
      courseTitle,
      description,
      answerSubmittedDateTime,
   } = quiz;

   const submittedDateTime = useIsoDateTimeFormat(answerSubmittedDateTime);

   const openAcceptModal = () => {
      setAcceptModalOpen(true);
   };

   const handleModalClose = () => {
      setAcceptModalOpen(false);
   };

   const openAnswer = () => {
      setIsAnswerOpen(true);
   };

   const handleRateChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      setScore(
         +target.value > 100 ? '100' : +target.value < 0 ? '0' : target.value,
      );
   };

   const onConfirm = () => () => {
      rateQuizAnswer(answerId, +score).then(() =>
         mentorStore.setQuizRefresh(!refresh),
      );
      setAcceptModalOpen(false);
   };

   return (
      <Row>
         <AppModal
            type={MODAL_TYPES.optional}
            open={acceptModalOpen}
            handleClose={handleModalClose}
            handleConfirm={onConfirm()}
            confirmBtnContent='Confirm'
            dismissBtnContent='Dismiss'
         >
            {`Your rate to ${studentFirstName} ${studentLastName} answer will be ${
               score ? score : 0
            }`}
         </AppModal>

         <CellGroup group={courseTitle} />

         <CellName
            studentFirstName={studentFirstName}
            studentLastName={studentLastName}
            studentId={studentId}
         />

         <td className={styles.submission}>
            <span className={styles.date}>{submittedDateTime}</span>
         </td>

         <CellTask task={description} />

         <CellQuizAnswer
            isAnswerOpen={isAnswerOpen}
            openAnswer={openAnswer}
            answer={answer}
         />

         <td className={styles.score}>
            {isAnswerOpen && (
               <input
                  value={score}
                  onChange={handleRateChange}
                  min={0}
                  max={'100'}
                  placeholder='0-100'
                  type='number'
               />
            )}
            <Button
               type='button'
               buttonClass='primary'
               buttonText='Rate'
               style={{ margin: 0, width: 'auto' }}
               buttonClick={openAcceptModal}
               disabled={!isAnswerOpen}
            />
         </td>
      </Row>
   );
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HelpCenterArticles_container__4hICJ {\n  width: 100%;\n}\n.HelpCenterArticles_container__4hICJ .HelpCenterArticles_list_item__0Mf6t::before {\n  content: \"\";\n  margin: 0;\n  font-size: 0;\n}\n.HelpCenterArticles_container__4hICJ .HelpCenterArticles_show_btn__AMXmv {\n  font-size: 14px;\n  font-weight: 700;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/modules/helpCenter/components/HelpCenterArticles/HelpCenterArticles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,WAAA;EACA,SAAA;EACA,YAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;AAAJ","sourcesContent":[".container {\n  width: 100%;\n\n  .list_item::before {\n    content: '';\n    margin: 0;\n    font-size: 0;\n  }\n\n  .show_btn {\n    font-size: 14px;\n    font-weight: 700;\n    width: 100%;\n } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HelpCenterArticles_container__4hICJ",
	"list_item": "HelpCenterArticles_list_item__0Mf6t",
	"show_btn": "HelpCenterArticles_show_btn__AMXmv"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { iCourseCardType } from './CourseCardType';
import { studentNavigator } from '../../../router/StudentRoutes';
import useCourseImage from '../../hooks/useCourseImage';
import styles from './CourseCard.module.scss';

export const CourseCard: React.FC<iCourseCardType> = ({
   imageURL,
   courseName,
   courseDescription,
   mentorName,
   slug,
}) => {
   const navigate = useNavigate();
   const image = useCourseImage(imageURL);

   function handleClick() {
      navigate(studentNavigator.course(slug));
   }

   return (
      <div className={styles.itemContainer} onClick={handleClick}>
         <div className={styles.item}>
            <div className={styles.imageContainer}>
               {image && (
                  <img src={image} alt={courseName} className={styles.image} />
               )}
            </div>
            <div className={styles.body}>
               <div className={styles.infoName}>
                  <span className={styles.label}>Course title:</span>
                  <span className={styles.itemName}>{courseName}</span>
               </div>
               <div className={styles.infoContainer}>
                  <span className={styles.label}>Description:</span>
                  <div className={styles.info}>{courseDescription}</div>
               </div>
               <div className={`${styles.infoContainer} ${styles.mt05}`}>
                  <span className={styles.label}>Mentor:</span>
                  <span className={styles.info}>{mentorName}</span>
               </div>
            </div>
         </div>
      </div>
   );
};


import styles from './ReasonsToStart.module.scss'

export const ReasonsToStart = () => {

const task = 'https://s.udemycdn.com/teaching/value-prop-teach-v3.jpg'
const inspire = 'https://s.udemycdn.com/teaching/value-prop-inspire-v3.jpg'
const rewarded = 'https://s.udemycdn.com/teaching/value-prop-get-rewarded-v3.jpg'

  return (
    <div className={styles.wrapper_container_start}>
        <h2>So many reasons to start</h2>
        <div className={styles.container_start}>
            <div className={styles.item_container_start}>
                <img src={task} alt="task" />
                <h2>Teach your way</h2>
                <div>
                Publish the course you want, in the way you want, and always have control of your own content. 
                </div>
            </div>
            <div className={styles.item_container_start}>
                <img src={inspire} alt="inspire" />
                <h2>Inspire learners</h2>
                <div>
                Teach what you know and help learners explore their interests, gain new skills, and advance their careers. 
                </div>
            </div>
            <div className={styles.item_container_start}>
                <img src={rewarded} alt="rewarded" />
                <h2>Get rewarded</h2>
                <div>
                Expand your professional network, build your expertise, and earn money on each paid enrollment. 
                </div>
            </div>
        </div>
    </div>
  )
}


import {
   getAllTasksList,
   getReviewQuiz,
   getReviewTaskList,
} from '../../../http/mentorAPI';

interface Params {
   tasksNum: number;
   quizzesNum: number;
}

export const useTabData = ({ tasksNum, quizzesNum }: Params) => {
   const tabData = {
      'Task Review': {
         id: 1,
         getDataFunc: getReviewTaskList,
         number: tasksNum,
      },
      'Quiz Review': {
         id: 2,
         getDataFunc: getReviewQuiz,
         number: quizzesNum,
      },
      'All Task Answers': {
         id: 3,
         getDataFunc: getAllTasksList,
      },
   };

   return { tabData };
};

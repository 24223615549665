import { useState, useRef, useEffect } from 'react';
// Material imports
import { Rating } from '@mui/material';
import {
   StarBorder,
   FavoriteBorderOutlined,
   FavoriteOutlined,
   LanguageOutlined,
   ErrorOutlineOutlined,
} from '@mui/icons-material';
// Project imports
import { useLocalData } from '../../library';
import { useWindowDimensions } from '../../../../hooks';
import { publicURL } from '../../../../utils/consts';
import { useRootStore } from '../../../../hooks';
// Project Components
import { HeroBreadcrumbs } from '../HeroBreadcrumbs';
import { AppBtn, AppBtnTypes } from '../../../UI';
// Styles
import styles from './Hero.module.scss';

export const Hero: React.FC = () => {
   const { windowWidth } = useWindowDimensions();

   const { currentCourse } = useLocalData();
   const {
      desc,
      title,
      rating,
      ratesNumber,
      students,
      createdBy,
      lastUpdate,
      language,
      price,
   } = currentCourse;
   const ref = useRef<HTMLDivElement>(null);
   const { pagesStore } = useRootStore();

   const [isOnWishlist, setIsOnWishlist] = useState(false);
   const toggleWishlist = () => setIsOnWishlist(!isOnWishlist);

   useEffect(() => {
      pagesStore.setCoursePreviewHeroHeight(ref.current?.offsetHeight!);
   }, [windowWidth]);

   const authors = <span>{createdBy.join(', ')}</span>;

   return (
      <div ref={ref} className={styles.wrapper}>
         <div className={styles.container}>
            <HeroBreadcrumbs />

            {windowWidth < 900 && (
               <div className={styles.img_wrapper}>
                  <img
                     src={`${publicURL}/images/temp/preview/vue-js.jpg`}
                     alt='Course Preview'
                  />
               </div>
            )}

            <section className={styles.content_section}>
               <h2 className={styles.title}>{title}</h2>
               <h4 className={styles.subtitle}>{desc}</h4>

               <div className={styles.meta}>
                  <span className={styles.rating}>
                     {rating}
                     <Rating
                        emptyIcon={<StarBorder className={styles.star} />}
                        name='read-only'
                        value={rating}
                        readOnly
                        sx={{ fontSize: '14px' }}
                        max={5}
                        precision={0.1}
                     />
                  </span>
                  <span className={styles.ratesNumber}>
                     ({ratesNumber.toLocaleString('en-US')} ratings)
                  </span>
                  <span className={styles.students}>
                     {students.toLocaleString('en-US')} students
                  </span>
               </div>

               <p>Created by: {authors}</p>
               <p>
                  {' '}
                  <ErrorOutlineOutlined
                     sx={{ fontSize: 20 }}
                     className={styles.icon}
                  />{' '}
                  Last updated {lastUpdate}
               </p>
               <p className=''>
                  <LanguageOutlined
                     sx={{ fontSize: 20 }}
                     className={styles.icon}
                  />{' '}
                  {language}
               </p>
            </section>

            {windowWidth < 900 && (
               <section className={styles.checkout_section}>
                  <h2 className={styles.price}>${price}</h2>
                  <div className={styles.btn_container}>
                     <AppBtn
                        buttonType={AppBtnTypes.secondary_dark}
                        className={styles.cart_btn}
                     >
                        Add to cart
                     </AppBtn>
                     <AppBtn
                        onClick={toggleWishlist}
                        buttonType={AppBtnTypes.inverted}
                        className={styles.wishlist_btn}
                     >
                        {isOnWishlist ? (
                           <FavoriteOutlined />
                        ) : (
                           <FavoriteBorderOutlined />
                        )}
                     </AppBtn>
                  </div>
                  <div className={styles.links_container}>
                     <button className={styles.share_btn}>Share</button>
                     <button className={styles.coupon_btn}>Apply coupon</button>
                  </div>
               </section>
            )}
         </div>
      </div>
   );
};

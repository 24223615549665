// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuizExactText_wrapper__M2nVv {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: auto;\n}\n.QuizExactText_wrapper__M2nVv .QuizExactText_markdown__JmCTc {\n  color: #000000;\n  font-weight: 600;\n  padding: 15px;\n  margin-bottom: 20px;\n  border-radius: 6px;\n  background-color: rgb(246, 248, 250);\n  -webkit-user-select: none;\n          user-select: none;\n}\n@media screen and (max-width: 900px) {\n  .QuizExactText_wrapper__M2nVv .QuizExactText_markdown__JmCTc {\n    order: -2;\n  }\n}\n.QuizExactText_wrapper__M2nVv .QuizExactText_description__ExSUW {\n  margin-top: 40px;\n  font-size: 15px;\n  font-weight: 400;\n  color: #858f97;\n}\n@media screen and (max-width: 900px) {\n  .QuizExactText_wrapper__M2nVv .QuizExactText_description__ExSUW {\n    order: -1;\n    margin-top: 12px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/QuizExactText/QuizExactText.module.scss"],"names":[],"mappings":"AAEA;EACG,aAAA;EACA,0BAAA;EACA,wBAAA;AADH;AAGG;EACG,cAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,oCAAA;EACA,yBAAA;UAAA,iBAAA;AADN;AAGM;EATH;IAUM,SAAA;EAAP;AACF;AAGG;EACG,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AADN;AAGM;EANH;IAOM,SAAA;IACA,gBAAA;EAAP;AACF","sourcesContent":["@use '../../../../../theme/config' as v;\n\n.wrapper {\n   display: grid;\n   grid-template-columns: 1fr;\n   grid-template-rows: auto;\n\n   .markdown {\n      color: #000000;\n      font-weight: 600;\n      padding: 15px;\n      margin-bottom: 20px;\n      border-radius: 6px;\n      background-color: rgba(246, 248, 250);\n      user-select: none;\n\n      @media screen and (max-width: v.$md) {\n         order: -2;\n      }\n   }\n   \n   .description {\n      margin-top: 40px;\n      font-size: 15px;\n      font-weight: 400;\n      color: #858f97;\n   \n      @media screen and (max-width: v.$md) {\n         order: -1;\n         margin-top: 12px;\n      }\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "QuizExactText_wrapper__M2nVv",
	"markdown": "QuizExactText_markdown__JmCTc",
	"description": "QuizExactText_description__ExSUW"
};
export default ___CSS_LOADER_EXPORT___;

// assets
import { 
    FormatSizeOutlined, 
    FormatColorFillOutlined,
    DashboardCustomizeOutlined,
    QrCode2Outlined,
    CropDinOutlined
 } from '@mui/icons-material';

// icons
const icons = {
    FormatSizeOutlined,
    FormatColorFillOutlined,
    QrCode2Outlined,
    CropDinOutlined,
    DashboardCustomizeOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

export const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-typography',
            title: 'Typography',
            type: 'item',
            url: '/typography',
            icon: icons.FormatSizeOutlined
        },
        {
            id: 'util-color',
            title: 'Color',
            type: 'item',
            url: '/color',
            icon: icons.FormatColorFillOutlined
        },
        {
            id: 'util-shadow',
            title: 'Shadow',
            type: 'item',
            url: '/shadow',
            icon: icons.QrCode2Outlined
        },
        {
            id: 'ant-icons',
            title: 'Ant Icons',
            type: 'item',
            url: '/icons/ant',
            icon: icons.CropDinOutlined,
            breadcrumbs: false
        }
    ]
};
import {maxCommentLength, maxCourseTitleLength} from "./consts";
import {ICommentFeedbackAll} from "../http/guestAPI";
import {ICommentFeedbackCourse} from "../http/studentsAPI";

export const convertToSlug = (text: string) => {
   return text
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
};

export const commentsLengthChecker = (comments: (ICommentFeedbackAll | ICommentFeedbackCourse)[]) =>
   comments.filter(({comment}) => comment.length <= maxCommentLength)

export const queryCutterCourseMax = (course: string, showMax?: boolean) => {
  const sortedQueries =
    Object.keys(maxCourseTitleLength).sort((a, b) => +a - +b)
  const windowWidth = window.innerWidth
  let maxLength: number = 0

  for (const query of sortedQueries) {
    if (+query > windowWidth) {
      maxLength = maxCourseTitleLength[query]
      break
    }
  }

  return (showMax && maxLength) ? maxLength : maxLength ? course.substring(0, maxLength) + '...' : course
}


import { motion } from 'framer-motion';
import Box from '@mui/material/Box';

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

const TabPanel = (props: TabPanelProps) => {
   const { children, value, index, ...other } = props;

   return (
      <motion.div
         role='tabpanel'
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         exit={{ opacity: 0 }}
         transition={{ duration: 1 }}
      >
         {value === index && (
            <Box sx={{ p: 3, border: 1, borderColor: 'divider' }}>
               {children}
            </Box>
         )}
      </motion.div>
   );
};

export default TabPanel;

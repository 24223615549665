import { FC } from 'react';
//mui
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
//styles
import styles from './ListTitle.module.scss';

export const ListTitle: FC = () => {
    return (
        <>
            <div className={styles.list_heading}>
                <div className={styles.list_name}>Name</div>
                <div className={styles.list_contacts}>
                    <div className={styles.list_email}>Email Address</div>
                    <div className={styles.list_phone}>Phone</div>
                </div>
                <div className={styles.list_cont}>Contacts</div>
                <div className={styles.list_courses}>Courses</div>
                <div className={styles.list_notes}>
                    <NoteAltOutlinedIcon />
                    <div>Notes</div>
                </div>
            </div>
        </>

    )
}
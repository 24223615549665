// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SingleChoiceQuestion_wrapper__lLm6F {\n  margin-bottom: 30px;\n}\n\n.SingleChoiceQuestion_question__TWDef {\n  margin-bottom: 16px;\n  font-size: 1.1rem;\n  font-weight: 500;\n}\n\n.SingleChoiceQuestion_field__5yBlj {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  margin-bottom: 10px;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n.SingleChoiceQuestion_field__5yBlj input {\n  cursor: pointer;\n  margin-right: 12px;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/feedback/SingleChoiceQuestion/SingleChoiceQuestion.module.scss"],"names":[],"mappings":"AAAA;EACG,mBAAA;AACH;;AAEA;EACG,mBAAA;EACA,iBAAA;EACA,gBAAA;AACH;;AAEA;EACG,aAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;AACH;AACG;EACG,eAAA;EACA,kBAAA;AACN","sourcesContent":[".wrapper {\n   margin-bottom: 30px;\n}\n\n.question {\n   margin-bottom: 16px;\n   font-size: 1.1rem;\n   font-weight: 500;\n}\n\n.field {\n   display: flex;\n   align-items: center;\n   cursor: pointer;\n   margin-bottom: 10px;\n   width: fit-content;\n\n   & input {\n      cursor: pointer;\n      margin-right: 12px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SingleChoiceQuestion_wrapper__lLm6F",
	"question": "SingleChoiceQuestion_question__TWDef",
	"field": "SingleChoiceQuestion_field__5yBlj"
};
export default ___CSS_LOADER_EXPORT___;

import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import styles from './CellQuizAnswer.module.scss';

interface Props {
   isAnswerOpen: boolean;
   openAnswer: () => void;
   answer: string;
}

export const CellQuizAnswer: FC<Props> = ({
   isAnswerOpen,
   openAnswer,
   answer,
}) => {
   return (
      <td className={styles.container}>
         {!isAnswerOpen ? (
            <button
               type='button'
               className={[styles.answerButton, styles.answer].join(' ')}
               onClick={openAnswer}
            >
               Open answer
               <FontAwesomeIcon
                  icon={faUnlock}
                  color='#474c53'
                  size='sm'
                  style={{ marginLeft: '6px' }}
               />
            </button>
         ) : (
            <div className={answer.length > 240 ? styles.scroll : ''}>
               {answer}
            </div>
         )}
      </td>
   );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Students_block__0I695 {\n  padding: 0px 16px;\n}\n\n.Students_row__BFH53 {\n  padding: 20px 0;\n  line-height: 200%;\n  display: flex;\n  border-bottom: 1px solid #d9d9d9;\n}\n.Students_row__BFH53:last-child {\n  border-bottom: 0px;\n}\n\n.Students_picture__SvGsZ {\n  width: 70px;\n  height: 70px;\n  background-color: rgba(195, 230, 205, 0.7019607843);\n  margin-right: 20px;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/Students/Students.module.scss"],"names":[],"mappings":"AAAA;EACG,iBAAA;AACH;;AAEA;EACG,eAAA;EACA,iBAAA;EACA,aAAA;EACA,gCAAA;AACH;AACG;EACG,kBAAA;AACN;;AAGA;EACG,WAAA;EACA,YAAA;EACA,mDAAA;EACA,kBAAA;AAAH","sourcesContent":[".block {\n   padding: 0px 16px;\n}\n\n.row {\n   padding: 20px 0;\n   line-height: 200%;\n   display: flex;\n   border-bottom: 1px solid #d9d9d9;\n\n   &:last-child {\n      border-bottom: 0px;\n   }\n}\n\n.picture {\n   width: 70px;\n   height: 70px;\n   background-color: #c3e6cdb3;\n   margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": "Students_block__0I695",
	"row": "Students_row__BFH53",
	"picture": "Students_picture__SvGsZ"
};
export default ___CSS_LOADER_EXPORT___;


import { FC} from 'react';
import { NavLink } from 'react-router-dom';
import styles from './StudentInfo.module.scss';
interface IProps {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    telegram: string;
    skype: string;
    phone: string;
}

const StudentInfo: FC<IProps> =({    
    id, 
    firstName,
    lastName,
    email,
    telegram,
    skype,
    phone,
}) => {

    return (
        <>
            <div className={styles.item__title}>

                <NavLink className={styles.link} to={`/student-profile/${id}`}>
                    <strong className={styles.item__name}>{firstName} {lastName}</strong>
                </NavLink> 

                <p><strong>e-mail: </strong><a href="#">{email}</a> </p>
                <p><strong>Telegram: </strong><a href="#">{telegram}</a></p>
                <p><strong>Skype: </strong><a href="#">{skype}</a></p>
                <p><strong>Phone: </strong><a href="#">{phone}</a></p>
            </div>
        </>
    )

}

export default StudentInfo;
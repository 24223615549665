

export const dataCommentsOfTeachers = [
    {
        img: 'https://s.udemycdn.com/teaching/instructors/en/frank-1x-v2.jpg',
        name: 'Frank Kane',
        title: 'Data Science & IT Certifications',
        description:
           'I’m proud to wake up knowing my work is helping people around the world improve their careers and build great things. While being a full-time instructor is hard work, it lets you work when, where, and how you want.',
     },
     {
        img: 'https://s.udemycdn.com/teaching/instructors/en/paulo-1x.jpg',
        name: 'Paulo Dichone',
        title: 'Developer (Android Speciality)',
        description:
           'Udemy has changed my life. It’s allowed me to follow my passion and become a teacher I love to see my students succeed and hear them say they’ve learned more, quicker, from my courses than they did in college. It’s so humbling.',
     },
     {
        img: 'https://s.udemycdn.com/teaching/instructors/en/deborah-1x.jpg',
        name: 'Deborah Grayson Riege',
        title: 'Leadership, Communication',
        description:
           'Teaching on Udemy has provided me with two important elements: the opportunity to reach more learners than I ever would be able to on my own and a steady stream of extra income',
     }, 
]
import { useNavigate } from 'react-router-dom';
import { studentNavigator } from '../../../../router/StudentRoutes';
//project imports
import { useWindowDimensions } from '../../../../../hooks';
//data
import { studentsViewingList } from '../../../../guest/pages/GuestHome/components/mockData';
//components
import {
   SlickSlider,
   CourseCardWidget,
   CourseCardWidgetMobile,
} from '../../../../UI';
import { studentsAreViewing } from '../../../../UI/SlickSlider/Settings';
//styles
import styles from './CourseSlider.module.scss';

export const CourseSlider = () => {
   const { windowWidth } = useWindowDimensions();
   const navigate = useNavigate();

   const handleClickNavigate = () => {
      navigate(studentNavigator.all_courses());
   };

   return (
      <div className={styles.container}>
         <div className={styles.content}>
            <div className={styles.title_block}>
               <h2>Top Development Courses</h2>
               <span onClick={handleClickNavigate}>
                  See Development Courses
               </span>
            </div>

            <SlickSlider settings={studentsAreViewing}>
               {windowWidth > 600
                  ? studentsViewingList.map((course) => (
                       <CourseCardWidget
                          courseData={course}
                          key={Math.random() * 10000}
                       />
                    ))
                  : studentsViewingList.map((course) => (
                       <CourseCardWidgetMobile
                          courseData={course}
                          key={Math.random() * 10000}
                       />
                    ))}
            </SlickSlider>
         </div>
      </div>
   );
};

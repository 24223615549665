import styles from './TabButtons.module.scss';
import { IReviewTask, IReviewQuiz } from '../../../../../http/mentorAPI';

interface Props {
   activeTab: string;
   changeTab: (tab: string) => void;
   tabData: Record<
      string,
      {
         id: number;
         getDataFunc: () => Promise<IReviewTask[] | IReviewQuiz[]>;
         number?: number;
      }
   >;
}

export const TabButtons: React.FC<Props> = ({
   activeTab,
   changeTab,
   tabData,
}) => {
   return (
      <div>
         {Object.keys(tabData).map((tab) => {
            const key = tabData[tab as keyof typeof tabData].id;
            const number = tabData[tab as keyof typeof tabData].number;
            const quantity =
               number === 0 ? '(0)' : !number ? '' : `(${number})`;

            return (
               <button
                  key={key}
                  type='button'
                  className={
                     activeTab === tab
                        ? [styles.tabButton, styles.active].join(' ')
                        : styles.tabButton
                  }
                  onClick={() => changeTab(tab)}
               >
                  {tab}
                  {quantity}
               </button>
            );
         })}
      </div>
   );
};

import { makeAutoObservable } from 'mobx';
import {
   Categories,
   Courses,
   Subcategories,
} from '../modules/UI/Layout/hooks/useCategoriesCascader';

export default class LayoutStore {
   private drawerOpen: boolean;
   private activeItem: string[];
   private submenuIsOpen: boolean;
   private cascaderIsActive: boolean;
   private categoriesRefresh: boolean;
   private allCourses: [Courses[]] | [];
   private allCategories: Categories[];
   private coursesRefresh: boolean;
   private idCourse: number;
   private isLoadingCategories: boolean;

   constructor() {
      this.drawerOpen = false;
      this.activeItem = ['dashboard'];
      this.submenuIsOpen = false;
      this.cascaderIsActive = true;
      this.categoriesRefresh = false;
      this.coursesRefresh = false;
      this.allCourses = [];
      this.allCategories = [];
      this.idCourse = -1;
      this.isLoadingCategories = false;

      makeAutoObservable(this);
   }

   setDrawerOpen(payload: boolean) {
      this.drawerOpen = payload;
   }

   getDrawerOpen() {
      return this.drawerOpen;
   }

   setActiveItem(payload: string[]) {
      this.activeItem = payload;
   }

   getActiveItem() {
      return this.activeItem;
   }

   setSubmenuIsOpen(payload: boolean) {
      this.submenuIsOpen = payload;
   }

   getSubmenuIsOpen() {
      return this.submenuIsOpen;
   }

   setCascaderIsActive(payload: boolean) {
      this.cascaderIsActive = payload;
   }

   getCascaderIsActive() {
      return this.cascaderIsActive;
   }

   setCategoriesRefresh(payload: boolean) {
      this.categoriesRefresh = payload;
   }

   getCategoriesRefresh() {
      return this.categoriesRefresh;
   }

   setCategories(payload: Categories[]) {
      this.allCategories = payload;
   }

   getCategories() {
      return this.allCategories;
   }

   getSubcategories() {
      return this.allCategories.reduce((acc, category) => {
         acc.push(...category.subcategories);
         return acc;
      }, [] as Subcategories[]);
   }

   setIsLoadingCategories(isLoading: boolean) {
      this.isLoadingCategories = isLoading;
   }

   getIsLoadingCategories() {
      return this.isLoadingCategories;
   }

   setCoursesRefresh(payload: boolean) {
      this.coursesRefresh = payload;
   }

   getCoursesRefresh() {
      return this.coursesRefresh;
   }

   setCourses(payload: [Courses[]] | []) {
      this.allCourses = payload;
   }

   getCourses() {
      return this.allCourses;
   }

   setIDCourses(payload: number) {
      this.idCourse = payload;
   }

   getIDCourses() {
      return this.idCourse;
   }
}

import React from 'react';
import { observer } from 'mobx-react-lite';

// project imports
import useCourseImage from '../../../hooks/useCourseImage';

// project components
import { Divider } from '../../../../UI/Divider';

// styles
import styles from './QuizCourseInfo.module.scss';

interface IQuizHeaderType {
   imageUrl: string;
   name: string;
   description: string;
}
export const QuizCourseInfo: React.FC<IQuizHeaderType> = observer(
   ({ imageUrl, name, description }) => {
      const logo = useCourseImage(imageUrl);

      return (
         <div>
            <Divider>
               Course
            </Divider>
            <div className={styles.courseInfo}>
               <div className={styles.image}>
                  {logo && <img src={logo} alt='Technology logo' />}
               </div>
               <div className={styles.info}>
                  <h3>{name}</h3>
                  <p>{description}</p>
               </div>
            </div>
         </div>
      );
   },
);

import React, { FC } from 'react';
import styles from './Row.module.scss';

interface Props {
   children: React.ReactNode;
}

export const Row: FC<Props> = ({ children }) => {
   return <tr className={styles.row}>{children}</tr>;
};

import { useState, useEffect } from 'react';
import { courseContent } from './index';

export const useCourseContent = () => {
   const { sections } = courseContent;

   const [isShownMore, setIsShownMore] = useState(false);
   const [sectionsRendered, setSectionsRendered] = useState(
      sections.slice(0, 5),
   );
   const [allExpanded, setAllExpanded] = useState(false);

   const handleClick = () => {
      setIsShownMore(!isShownMore);
   };

   const toggleExpandAll = () => {
      setAllExpanded(!allExpanded);
   };

   useEffect(() => {
      const sectionsNum = isShownMore ? sections.length : 5;
      const sectionsRendered = sections.slice(0, sectionsNum);
      setSectionsRendered(sectionsRendered);
   }, [isShownMore]);

   return {
      isShownMore,
      sectionsRendered,
      handleClick,
      allExpanded,
      toggleExpandAll,
   };
};

import React, { StyleHTMLAttributes } from 'react';
import { Dropdown, Space, Divider, theme } from 'antd';
import type { MenuProps } from 'antd';
import { AppBtn, AppBtnTypes } from '../../../index';

// styles
import styles from './HoverMenu.module.scss';

const { useToken } = theme;

const items: MenuProps['items'] = [
   {
      key: '1',
      label: (
         <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.antgroup.com'
         >
            1st menu item
         </a>
      ),
   },
   {
      key: '2',
      label: (
         <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.aliyun.com'
         >
            2nd menu item (disabled)
         </a>
      ),
      disabled: true,
   },
   {
      key: '3',
      label: (
         <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.luohanacademy.com'
         >
            3rd menu item (disabled)
         </a>
      ),
      disabled: true,
   },
];

interface Props {
   title: string;
   desc: string;
   functionNavigate?: () => void;
}

export const HoverMenu: React.FC<Props> = ({ title, desc, functionNavigate }) => {
   const { token } = useToken();

   const contentStyle = {
      backgroundColor: token.colorBgElevated,
      borderRadius: token.borderRadiusLG,
      boxShadow: token.boxShadowSecondary,
   };

   return (
      <div onClick={functionNavigate}>
         <Dropdown
            className={styles.dropdown}
            placement='bottomRight'
            overlayClassName={styles.overlay}
            menu={{ items }}
            dropdownRender={(menu) => (
               <div style={contentStyle}>
                  <p className={styles.desc} style={{ fontWeight: 500 }}>
                     {desc}
                  </p>
                  <Divider
                     className={styles.divider}
                     style={{ margin: 0, backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
                  />
                  <Space
                     style={{
                        padding: 16,
                        width: '100%',
                        display: 'block',
                     }}
                  >
                     <AppBtn
                        className={styles.button}
                        buttonType={AppBtnTypes.inverted}
                        onClick={functionNavigate}
                     >
                        Learn More
                     </AppBtn>
                  </Space>
               </div>
            )}
         >
            <Space className={styles.space}>{title}</Space>
         </Dropdown>
      </div>

   );
};

import React from 'react';
import useCourseImage from '../../../student/hooks/useCourseImage';
import styles from './MentorCourse.module.scss';

interface IMentorCourse {
   logoUrl: string;
   title: string;
}

const MentorCourse: React.FC<IMentorCourse> = ({ logoUrl, title }) => {
   const img = useCourseImage(logoUrl);

   return (
      <>
         {img && <img src={img} alt={title} className={styles.img} />}
         <p>{title}</p>
      </>
   );
};

export default MentorCourse;

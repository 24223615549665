import { useNavigate } from 'react-router-dom';
// Material
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useMediaQuery, useTheme } from '@mui/material';
// Project
import { useLocalData } from '../../library';
// Styles
import styles from './HeroBreadcrumbs.module.scss';

// function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
//    event.preventDefault();
//    console.info('You clicked a breadcrumb.');
// }

export const HeroBreadcrumbs: React.FC = () => {
   const navigate = useNavigate();
   const { breadcrumbsData } = useLocalData();
   const { name, cat, subcat } = breadcrumbsData;
   const theme = useTheme();
   const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));

   return (
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumbs}>
         <Link
            underline='hover'
            onClick={() => navigate('/')}
            className={styles.cat}
         >
            {cat}
         </Link>
         <Link
            underline='hover'
            onClick={() => navigate('/')}
            className={styles.cat}
         >
            {subcat}
         </Link>

         {matchesSm && (
            <Typography color='white' fontSize={14}>
               {name}
            </Typography>
         )}
      </Breadcrumbs>
   );
};

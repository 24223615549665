// components
import { AuthorArticle } from '../AuthorArticle';
// project
import { authorsData } from '../../library';
// styles
import styles from './Authors.module.scss';

export const Authors: React.FC = () => {
   const authorsMap = authorsData.map((author) => <AuthorArticle key={author.id} author={author} />);

   return (
      <div className={styles.wrapper}>
         <h3 className={styles.title}>Authors</h3>
         {authorsMap}
      </div>
   );
};

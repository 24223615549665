import { useNavigate, useLocation } from 'react-router-dom';

// material-ui
import Link from '@mui/material/Link';

// project components
import { AppBtn, AppBtnTypes } from '../../../UI';

//styles
import styles from './HelpCenterHeader.module.scss';

export const HelpCenterHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname
    .slice(1)
    .replace(/-/g, ' ');

  return (
    <div className={styles.wrapper}>
      <div className={styles.container_header}>
        <div className={styles.header}>
          <h2 className={styles.heading}>Help Center</h2>
          <div className={styles.subheading}>
            <Link
              underline='hover'
              onClick={() => navigate('/')}
              className={styles.home}
            >
                Home
            </Link>
             <span className={styles.symbol}>/</span>
             <span className={styles.pathname}>{currentLocation}</span>
          </div>
        </div>
        <AppBtn
          className={
            location.pathname !== '/ask-question' 
              ? styles.ask_btn 
              : styles.noAsk_btn
          }
          buttonType={AppBtnTypes.error}
          onClick={() => navigate('/ask-question')}
        >
          Ask question
        </AppBtn>
      </div>
    </div>
  )
}
import { makeAutoObservable } from 'mobx';

export default class PagesStore {
   private coursePreviewHeroHeight: number | null;
   private coursePreviewSidebarLeft: number | null;

   constructor() {
      this.coursePreviewHeroHeight = null;
      this.coursePreviewSidebarLeft = null;

      makeAutoObservable(this);
   }

   getCoursePreviewHeroHeight() {
      return this.coursePreviewHeroHeight;
   }

   setCoursePreviewHeroHeight(payload: number) {
      this.coursePreviewHeroHeight = payload;
   }

   getCoursePreivewSidebarLeft() {
      return this.coursePreviewSidebarLeft;
   }

   setCoursePreviewSidebarLeft(payload: number) {
      this.coursePreviewSidebarLeft = payload;
   }
}

// material-ui
import { styled, Theme } from '@mui/material/styles';
import Drawer, { DrawerProps } from '@mui/material/Drawer';

// assets
import { drawerWidth } from '../../../../../theme/config';

const openedMixin = (theme: Theme) => ({
   width: drawerWidth,
   borderRight: `1px solid ${theme.palette.divider}`,
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
   }),
   overflowX: 'hidden' as const,
   boxShadow: 'none',
});

const closedMixin = (theme: Theme) => ({
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   overflowX: 'hidden' as const,
   width: 0,
   borderRight: 'none',
});

// ==============================|| DRAWER - MINI STYLED ||============================== //

interface MiniDrawerStyledProps extends DrawerProps {
   open?: boolean;
}

export const MiniDrawerStyled = styled(Drawer, {
   shouldForwardProp: (prop) => prop !== 'open',
})<MiniDrawerStyledProps>(({ theme, open }) => ({
   width: drawerWidth,
   flexShrink: 0,
   whiteSpace: 'nowrap',
   boxSizing: 'border-box',
   ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
   }),
   ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
   }),
}));
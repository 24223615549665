import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
// material-ui
import { ClickAwayListener } from '@mui/material';

// project imports
import { useRootStore } from '../../../../../hooks';
import { useCategoriesCascader } from '../../hooks/useCategoriesCascader';
// components
import { CategoriesRow, CoursesRow, CategoriesButton } from './index';
// styles
import styles from './Categories.module.scss';

export const CategoriesNavMenu: React.FC = observer(() => {
   const [subCategoriesIsOpen, setSubCategoriesIsOpen] = useState({
      id: 0,
      isOpen: false,
   });
   const [coursesIsOpen, setCoursesIsOpen] = useState({
      id: 0,
      isOpen: false,
   });

   const { layoutStore } = useRootStore();
   const categoriesRefresh = layoutStore.getCategoriesRefresh();

   const {
      handleClickAway,
      categories,
      courses,
      isOpen,
      handleClick,
      setOpen,
   } = useCategoriesCascader();

   useEffect(() => {
      layoutStore.setCategoriesRefresh(!categoriesRefresh);
   }, []);

   return (
      <ClickAwayListener onClickAway={handleClickAway}>
         <div>
            <CategoriesButton
               isOpen={isOpen}
               handleClick={handleClick}
               setOpen={setOpen}
            />

            {isOpen && (
               <div className={styles.menus}>
                  <CategoriesRow
                     options={categories}
                     setOpen={setSubCategoriesIsOpen}
                     closeCourses={setCoursesIsOpen}
                     setID={1}
                  />

                  {subCategoriesIsOpen.isOpen && (
                     <CategoriesRow
                        options={
                           categories[subCategoriesIsOpen.id].subcategories
                        }
                        closeCourses={setCoursesIsOpen}
                        setOpen={setCoursesIsOpen}
                        setID={0}
                     />
                  )}

                  {coursesIsOpen.isOpen &&
                     <CoursesRow options={courses} />
                  }
               </div>
            )}
         </div>
      </ClickAwayListener>
   );
});

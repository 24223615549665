import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//material ui
import Rating from '@mui/material/Rating/Rating';
import { StarBorder } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
//types
import { IProp, CourseData } from './CourseCardWidgetTypes';
//components
import { PopperCourseCard } from './PopperCourseCard/PopperCourseCard';
//project imports
import { useWindowDimensions } from '../../../hooks';
//styles
import styles from './CourseCardWidget.module.scss';
import useCourseImage from '../../student/hooks/useCourseImage';

export const CourseCardWidget: FC<IProp> = ({ courseData }: IProp) => {
   const { id, logo, title, price, rating, createdBy, countVoters, slug } =
      courseData as CourseData;

   const { windowWidth } = useWindowDimensions();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   const navigate = useNavigate();
   const courseImage = useCourseImage(`api/courses/logo/${id}`, false);

   const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
      setIsModalOpen(true);
      const target = event.currentTarget;
      setAnchorEl(target);
   };

   const handleMouseLeave = () => {
      setIsModalOpen(false);
      setAnchorEl(null);
   };

   const handleClickNavigator = () => {
      if (slug) {
         navigate(`${slug}`);
      }
   };

   return (
      <div
         className={styles.wrapper}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
      >
         {windowWidth > 600 && (
            <PopperCourseCard
               isOpen={isModalOpen}
               anchorEl={anchorEl}
               courseData={courseData}
            />
         )}
         <div onClick={handleClickNavigator}>
            <div className={styles.img_container}>
               {courseImage ? (
                  <img src={courseImage} alt='Course_logo' />
               ) : (
                  <div></div>
               )}
            </div>
            <div className={styles.content_container}>
               <h4 className={styles.title}>{title}</h4>
               <h4 className={styles.mentor_name}>{createdBy}</h4>

               <span className={styles.rating}>
                  {rating}
                  <Rating
                     emptyIcon={<StarBorder className={styles.star} />}
                     name='read-only'
                     value={rating}
                     readOnly
                     size='small'
                     max={5}
                     precision={0.5}
                  />
                  <span className={styles.countVoters}>({countVoters})</span>
               </span>
               <div className={styles.price}>
                  <AttachMoneyIcon />
                  <span>{price}</span>
               </div>
            </div>
         </div>
      </div>
   );
};

import { Redirect } from '../../components/Redirect';
import styles from './NotFound.module.scss'

export const NotFound: React.FC = () => {

   return (
      <div className={styles.container}>
         <h2>
            This Page Does Not Exist
         </h2>
         <Redirect />
      </div>
   );
};

import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { BorderedBlockContainer } from '../UI';
import {
   ProfileMain,
   ProfileCourses,
} from './studentProfileItems';
import useStudentsProfiles from './hook/useStudentsProfiles';
import { useRootStore } from '../../hooks';
import {BasicNote} from '../UI/Note/BasicNote'
import styles from './studentProfile.module.scss';
import stylesNote from '../UI/Note/Note.module.scss'


export const StudentProfile: FC = () => {
   const { id } = useParams();
   const { userStore } = useRootStore();
   const { studentById } = useStudentsProfiles(id!);
   const userRole = userStore.getUserRole();

   return (
      <div className={styles.task}>
         <BorderedBlockContainer className={styles.container}>
            <h3 className={styles.heading}>Student Profile</h3>
         </BorderedBlockContainer>
         {studentById &&
            (userRole === 'MENTOR' || userRole === 'MANAGER' ? (
               <>
                  <ProfileMain data={studentById} userRole={userRole} />{' '}
                  <ProfileCourses courses={studentById.courses} />
                  <BorderedBlockContainer className={styles.container}>
                     <h4 className={styles.subheading}>Note</h4>
                     <BasicNote note={studentById?.note} id={id!} placeholder='Write your note for this student' className={`${stylesNote.textarea} ${stylesNote.textarea_student_profile}`} />
                  </BorderedBlockContainer>
               </>
            ) : (
               <>
                  <ProfileMain data={studentById} userRole={userRole!} />{' '}
                  <ProfileCourses courses={studentById.courses} />
               </>
            ))}
      </div>
   );
};

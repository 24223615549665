import { useEffect, useRef, useState } from 'react';
// mui
import { Box, SxProps, Theme } from '@mui/material';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
// project
import { AppBtn, AppBtnTypes } from '../index';
// styles
import styles from './ShowMoreBox.module.scss';

interface Props {
   children: React.ReactNode;
   height: number;
   className?: string;
   sxProps?: SxProps<Theme>;
}

export const ShowMoreBox: React.FC<Props> = ({
   children,
   height,
   className = '',
   sxProps,
}) => {
   const [fullHeight, setFullHeight] = useState(false);
   const [childrenHeight, setChildrenHeight] = useState(0);
   const [isHigh, setIsHigh] = useState(false);
   const ref = useRef<HTMLDivElement>(null);

   const handleClick = () => {
      setFullHeight(!fullHeight);
   };

   const wrapperStyles = {
      height: !isHigh ? childrenHeight : fullHeight ? 'fit-content' : `${height}px`,
      paddingBottom: isHigh ? '64px' : 0,
      ...sxProps,
   };

   useEffect(() => {
      setChildrenHeight(ref.current?.offsetHeight!);
   }, [children]);

   useEffect(() => {
      setIsHigh(childrenHeight > height);
   }, [childrenHeight]);

   return (
      <Box sx={wrapperStyles} className={[styles.wrapper, className].join(' ')}>
         <div className={styles.children_container} ref={ref}>
            {children}
         </div>

         {isHigh && (
            <div className={styles.show}>
               {!fullHeight && <div className={styles.fade}></div>}
               <AppBtn
                  onClick={handleClick}
                  style={{ fontSize: '14px', fontWeight: 700 }}
                  buttonType={AppBtnTypes.link}
               >
                  {fullHeight ? (
                     <>
                        <span>Show less</span>{' '}
                        <KeyboardArrowUpOutlined className={styles.chevron} />
                     </>
                  ) : (
                     <>
                        <span>Show more</span>{' '}
                        <KeyboardArrowDownOutlined className={styles.chevron} />
                     </>
                  )}
               </AppBtn>
            </div>
         )}
      </Box>
   );
};

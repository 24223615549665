import { useNavigate } from 'react-router-dom';

// material-ui
import {
   Box,
   IconButton,
   Link,
   Theme,
   useMediaQuery,
   Button,
} from '@mui/material';
import { GitHub } from '@mui/icons-material';

// project components
import { Search, CategoriesNavMenu, HoverMenu } from '../../../common';
import { Profile } from './Profile';
import { Notification } from './Notification';
import { MobileSection } from './MobileSection';

// project imports
import { publicURL } from '../../../../../../utils/consts';

// styles
import styles from './index.module.scss';

const teachPoperDescription =
   'Turn what you know into an opportunity and reach millions around the world.';

export const HeaderContent: React.FC = () => {
   const navigate = useNavigate();

   const matchesMd = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('md'),
   );
   const matchesSm = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('sm'),
   );

   const handleClick = () => {
      navigate('/');
   };


   const handleClickTeach = () => {
      navigate('/teach_on_raining');
   };

   return (
      <>
         {matchesSm && <CategoriesNavMenu />}
         {matchesMd && <Search />}
         {!matchesMd && <Box sx={{ width: '100%', ml: 1 }} />}

         {matchesMd && (
            <HoverMenu
               title='Teach on Training'
               desc={teachPoperDescription}
               functionNavigate={handleClickTeach}
            />
         )}
         {matchesSm && !matchesMd && (
            <Button className={styles.teach_button}>Teach</Button>
         )}

         {!matchesSm && (
            <img
               className={styles.logo}
               src={`${publicURL}/images/logo.jpg`}
               alt='Foxminded Logo'
               onClick={handleClick}
            />
         )}

         {matchesSm && (
            <IconButton
               component={Link}
               href='#'
               target='_blank'
               disableRipple
               color='secondary'
               sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
            >
               <GitHub />
            </IconButton>
         )}

         <Notification />
         {matchesMd && <Profile />}
         {!matchesMd && <MobileSection />}
      </>
   );
};

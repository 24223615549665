import { makeAutoObservable } from 'mobx';
import { RoleType } from '../utils/consts';
import { UserRoles } from '../utils/consts';

export interface IUser {
   id: number;
   email: string;
   role: UserRoles | RoleType;
   country: string;
   firstName: string;
   lastName: string;
}
export default class UserStore {
   private isAuth: boolean;
   private user: null | IUser;

   constructor() {
      this.isAuth = sessionStorage.getItem('isAuth')
         ? JSON.parse(sessionStorage.getItem('isAuth') || '')
         : false;
      this.user = sessionStorage.getItem('user')
         ? JSON.parse(sessionStorage.getItem('user') || '')
         : null;
      makeAutoObservable(this);
   }

   setIsAuth(bool: boolean) {
      this.isAuth = bool;
   }

   setUser(user: IUser | null) {
      this.user = user;
   }

   getUser() {
      return this.user;
   }

   getIsAuth() {
      return this.isAuth;
   }

   getUserEmail() {
      return this.user?.email;
   }

   getUserRole() {
      return this.user?.role;
   }

   getUserCountry() {
      return this.user?.country;
   }

   getUserFirstName() {
      return this.user?.firstName;
   }

   getUserLastName() {
      return this.user?.lastName;
   }

   getUserId() {
      return this.user?.id;
   }
}

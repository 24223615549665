// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuizMatching_item__ebz0R {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.QuizMatching_note__acPfo {\n  margin-top: 40px;\n  font-size: 16px;\n  color: #858f97;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/QuizMatching/QuizMatching.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AACF","sourcesContent":[".item {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.note {\n  margin-top: 40px;\n  font-size: 16px;\n  color: #858f97;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "QuizMatching_item__ebz0R",
	"note": "QuizMatching_note__acPfo"
};
export default ___CSS_LOADER_EXPORT___;

// assets
import { 
   KeyboardArrowRightOutlined,
   ListAltOutlined,
   LoginOutlined
} from '@mui/icons-material';

// icons
const icons = {
   LoginOutlined,
   ListAltOutlined,
   KeyboardArrowRightOutlined,
};

export const categories = {
   id: 'categories',
   title: 'Categories',
   type: 'group',
   children: [
      {
         id: 'development',
         title: 'Development',
         type: 'nested',
         icon: icons.KeyboardArrowRightOutlined,
         target: true,
         // url: '/auth',
         children: [
            {
               id: 'front-end',
               title: 'Front End',
               type: 'sub',
               url: '/auth',
               target: true,
               // icon: icons.KeyboardArrowRightOutlined,
            },
            {
               id: 'back-end',
               title: 'Back End',
               type: 'sub',
               url: '/auth',
               target: true,
               // icon: icons.KeyboardArrowRightOutlined,
            },
         ],
      },
      {
         id: 'business',
         title: 'Business',
         type: 'nested',
         icon: icons.KeyboardArrowRightOutlined,
         target: true,
         // url: '/auth/sign-up',
         children: [
            {
               id: 'entrepreneurship',
               title: 'Entrepreneurship',
               type: 'sub',
               url: '/auth',
               target: true,
               // icon: icons.KeyboardArrowRightOutlined,
            },
         ],
      },
   ],
};
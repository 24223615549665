//material ui
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import StarsIcon from '@mui/icons-material/Stars';
import {Tooltip} from '@mui/material'
//styles
import styles from './CommentItemCard.module.scss';
import {AppAvatar} from "../../../../../../UI";
import {queryCutterCourseMax} from "../../../../../../../utils/utils";

interface IProps {
   userName: string;
   comment: string;
   course: string;
}
export const CommentsItemCard = ({ userName, comment, course }: IProps) => {
   return (
      <div className={styles.container}>
         <div className={styles.main_content}>
            <div className={styles.wrapper}>
               <div className={styles.comment}>
                  <FormatQuoteIcon viewBox='5 6 20 20' />
                  <p>{comment}</p>
               </div>
               <div className={styles.user}>
                 <AppAvatar name={userName} className={styles.avatar}/>
                  <p>{userName}</p>
               </div>
            </div>
            <div className={styles.course}>
               <StarsIcon />
              {
                course.length > queryCutterCourseMax(course, true)
                  ? <Tooltip title={course}><a href='#'>{queryCutterCourseMax(course)}</a></Tooltip>
                  : <a href='#'>{course}</a>
              }
            </div>
         </div>
      </div>
   );
};

import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { BorderedBlockContainer } from '../../../UI';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useMentorTask } from '../../hooks';
import styles from './MentorTask.module.scss';

export const MentorTask: FC = () => {
   const { id } = useParams();
   const { taskData, taskDescription } = useMentorTask(id!);

   return (
      <div className={styles.task}>
         <BorderedBlockContainer className={styles.container}>
            <h3 className={styles.heading}>{taskData?.courseTitle}</h3>
         </BorderedBlockContainer>
         <BorderedBlockContainer className={styles.container}>
            <MarkdownPreview
               source={taskDescription}
               className={styles.markdown}
               disableCopy
               warpperElement={{
                  'data-color-mode': 'light',
               }}
            />
         </BorderedBlockContainer>
      </div>
   );
};

import { FC, ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faChevronDown,
   faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

// styles
import styles from './CollapsibleSectionContainer.module.scss';

interface IProps {
   open?: boolean;
   title: string;
   children: ReactNode;
   endAdornment?: ReactNode;
   bottomAdornment?: ReactNode;
   className?: string;
}

export const CollapsibleSectionContainer: FC<IProps> = ({
   open,
   children,
   title,
   endAdornment,
   bottomAdornment,
   className = '',
}) => {
   const [isOpen, setIsOpen] = useState<boolean>(!!open);

   const toggleOpen = () => {
      setIsOpen((prev) => !prev);
   };

   return (
      <section className={[styles.wrapper, className].join(' ')}>
         <div className={styles.headingWrapper}>
            <div className={styles.heading}>
               <button
                  type='button'
                  className={styles.button}
                  onClick={toggleOpen}
               >
                  {!isOpen ? (
                     <FontAwesomeIcon icon={faChevronRight} size='xl' />
                  ) : (
                     <FontAwesomeIcon icon={faChevronDown} size='xl' />
                  )}
               </button>

               {!endAdornment ? (
                  <h2 className={styles.title}>{title}</h2>
               ) : (
                  <div className={styles.titleWithAdornment}>
                     <h2 className={styles.title}>{title}</h2>
                     {endAdornment}
                  </div>
               )}
            </div>

            {bottomAdornment && (
               <div className={styles.bottom}>{bottomAdornment}</div>
            )}
         </div>

         {isOpen && <div className={styles.inner}>{children}</div>}
      </section>
   );
};

import { FC } from 'react';
//mui
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//styles
import styles from './TaskAndResultAccordion.module.scss';

interface IProp {
   defaultExpanded: boolean;
   topic: string;
   children: React.ReactNode;
}

export const TaskAndResultAccordion: FC<IProp> = ({
   defaultExpanded,
   topic,
   children,
}) => {
   return (
      <Accordion
         defaultExpanded={defaultExpanded}
         elevation={0}
         className={styles.accordion}
      >
         <AccordionSummary
            className={styles.summary}
            expandIcon={
               <ExpandMoreIcon fontSize='small' className={styles.icon} />
            }
            aria-controls='panel1a-content'
            id='panel1a-header'
         >
            <p className={styles.accordion_title}>{topic}</p>
         </AccordionSummary>
         <AccordionDetails className={styles.items}>
            {children}
         </AccordionDetails>
      </Accordion>
   );
};

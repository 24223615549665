import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer } from '../../../UI';
import { studentFeedbackType } from '../../consts/consts';
import { FeedbackTopic } from '../FeedbackTopic';
import { FeedbackCourse } from '../FeedbackCourse';
import { FeedbackTask } from '../FeedbackTask';

const FeedbackWrapper = () => {
   const { feedbackType } = useParams();
   const navigate = useNavigate();

   useEffect(() => {
      if (
         feedbackType &&
         !studentFeedbackType.includes(feedbackType.toLowerCase())
      ) {
         navigate('/login'); // replace for 404
      }
   }, []);

   return (
      <PageContainer maxWidth='1000px'>
         {feedbackType === 'task' && <FeedbackTask />}
         {feedbackType === 'topic' && <FeedbackTopic />}
         {feedbackType === 'course' && <FeedbackCourse />}
      </PageContainer>
   );
};

export default FeedbackWrapper;

import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { Button } from '../../../../UI';
import styles from './FeedbackReminder.module.scss';

interface Props {
   navigate: () => void;
}

const FeedbackReminder: FC<Props> = ({ navigate }) => {
   return (
      <div className={styles.wrapper}>
         <p className={styles.text}>To do:</p>

         <Button
            buttonClass='secondary'
            buttonText='Submit Feedback'
            width='min-content'
            additionalClass={styles.button}
            buttonClick={navigate}
            children={
               <FontAwesomeIcon
                  icon={faCommentDots}
                  style={{ marginLeft: '3px', paddingBottom: '1px' }}
               />
            }
         />
      </div>
   );
};

export default FeedbackReminder;

import { useState } from 'react';

// material-ui
import { 
  FormControl, 
  FormControlLabel,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';

// project components
import { QuestionDetailsTextArea } from './QuestionDetailsTextArea';
import { SelectTopics } from './SelectTopics';
import { AppBtn, AppBtnTypes } from '../../../UI';

// styles
import styles from './AskQuestionDetails.module.scss';

export const AskQuestionDetails: React.FC = () => {
  const [topic, setTopic] = useState('');

  const handleSelectChange = (event: SelectChangeEvent) => {
    setTopic(event.target.value);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <form className={styles.ask_form}>
          <div className={styles.title_header}>
            <h4 className={styles.question_title}>Question Details</h4>        
            <FormControl 
              sx={{ width: { xs: '100%', md: '100%' } }}
            >
              <QuestionDetailsTextArea />
            </FormControl>
          </div>
          <div className={styles.topic}>
            <h3 className={styles.subtitle}>Topic</h3>
            <SelectTopics 
              handleChange={handleSelectChange} 
              topic={topic}
            />
          </div>
          <div className={styles.notification}>
            <FormControlLabel control={<Checkbox />} label="Notify me on email when someone replies to my question" />            
            <p className={styles.notification_info}>
              If unchecked, you'll still recieve notifications on our website.
            </p>
          </div>
          <div className={styles.post_question}>
            <AppBtn
              className={styles.post_btn}
              buttonType={AppBtnTypes.error}
            >
              Post question
            </AppBtn>
          </div>
        </form>
      </div>
    </>
  )
}

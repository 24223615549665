// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, IconButton } from '@mui/material';
import { FormatIndentDecreaseOutlined, FormatIndentIncreaseOutlined } from '@mui/icons-material';

// project components
import { HeaderContent } from './HeaderContent';

// project imports
import { AppBarStyled } from './AppBarStyled';
import { useDrawerToggler } from '../../hooks/useDrawerToggler';

import { headerStyles } from '../../../../../theme/config';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

export const Header: React.FC = () => {
   const theme = useTheme();
   const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
   const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
   const { open, handleDrawerToggle } = useDrawerToggler();

   const iconBackColor = 'grey.100';
   const iconBackColorOpen = 'grey.200';

   // common header
   const mainHeader = (
      <Toolbar
         sx={{
            '& svg': {
               maxWidth: headerStyles.svgsSize,
               maxHeight: headerStyles.svgsSize,
            },
            '& .MuiLink-root': {
               padding: headerStyles.btnPadding,
            },
            '& .MuiIconButton-root': {
               padding: headerStyles.btnPadding,
            },
         }}
      >
         {matchDownSM && (
            <IconButton
               disableRipple
               aria-label='open drawer'
               onClick={handleDrawerToggle}
               edge='start'
               color='secondary'
               sx={{
                  color: 'text.primary',
                  bgcolor: open ? iconBackColorOpen : iconBackColor,
                  ml: { xs: 0, lg: -2 },
               }}
            >
               {!open ? <FormatIndentIncreaseOutlined /> : <FormatIndentDecreaseOutlined />}
            </IconButton>
         )}

         <HeaderContent />
      </Toolbar>
   );

   // app-bar params
   const appBar = {
      position: 'absolute' as const,
      top: 0,
      color: 'inherit' as const,
      elevation: 3,
      sx: {
         zIndex: headerStyles.zIndex,
      },
   };

   return (
      <>
         {!matchDownLG ? (
            <AppBarStyled {...appBar}>{mainHeader}</AppBarStyled>
         ) : (
            <AppBar {...appBar}>{mainHeader}</AppBar>
         )}
      </>
   );
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CellGroup_group__rrhfi {\n  min-width: 140px;\n  max-width: 160px;\n}\n\n.CellGroup_textOverflow__HifTI {\n  padding-bottom: 2px;\n  display: block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/AnswerList/commonCells/CellGroup/CellGroup.module.scss"],"names":[],"mappings":"AAAA;EACG,gBAAA;EACA,gBAAA;AACH;;AAEA;EACG,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,uBAAA;AACH","sourcesContent":[".group {\n   min-width: 140px;\n   max-width: 160px;\n}\n\n.textOverflow {\n   padding-bottom: 2px;\n   display: block;\n   overflow: hidden;\n   text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "CellGroup_group__rrhfi",
	"textOverflow": "CellGroup_textOverflow__HifTI"
};
export default ___CSS_LOADER_EXPORT___;

import { Box } from '@mui/material';
import { publicURL } from '../../../utils/consts';

const MinimalBackground = () => {
   return (
      <Box
         sx={{
            // position: 'absolute',
            position: 'fixed',
            filter: 'blur(5px)',
            zIndex: -1,
            bottom: '50%',
            transform: 'translateY(50%)',
         }}
      >
         <img
            style={{ height: '70vh' }}
            src={`${publicURL}/images/logo-small.png`}
            alt='Foxminded Logo'
         />
      </Box>
   );
};

export default MinimalBackground;

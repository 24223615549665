import React from 'react';

// styles
import styles from './TaskBanner.module.scss';

// mockData
import { taskDifficulty } from '../../mockData';

interface IProps {
   courseItemTitle: string;
   topicTitle: string;
}

export const TaskBanner: React.FC<IProps> = ({
   courseItemTitle,
   topicTitle,
}) => {
   const hours: number = Math.floor(taskDifficulty.time / 60);
   const minutes: number = taskDifficulty.time % 60;
   return (
      <div className={styles.wrapper}>
         <h3 className={styles.heading}>{courseItemTitle}</h3>
         <h4 className={styles.subheading}>{topicTitle}</h4>

         <div className={`${styles.banner_btns}`}>
            <a className={styles.btn}>
               <svg
                  width={'24px'}
                  viewBox='0 0 480 480'
                  fill='rgb(95, 145, 161)'
               >
                  <path d='M443.436,218.278c-6.241-6.204-16.319-6.204-22.56,0l-48,48l22.56,22.56l19.04-19.04 c-13.343,105.196-109.438,179.657-214.634,166.314S20.185,326.674,33.528,221.478C45.7,125.521,127.351,53.601,224.076,53.638 v-32C100.364,21.596,0.042,121.85,0,245.562s100.212,224.034,223.924,224.076c115.375,0.039,211.907-87.563,223.032-202.4 l21.76,21.76l22.56-22.56L443.436,218.278z' />
                  <path d='M224.076,165.638v80c-0.025,4.253,1.645,8.34,4.64,11.36l48,48l22.56-22.56l-43.2-43.36v-73.44H224.076z' />
               </svg>
               <p>
                  {hours}h {minutes}min
               </p>
            </a>

            <a className={styles.btn}>
               <svg
                  width={'24px'}
                  viewBox='0 0 480 480'
                  fill='rgb(95, 145, 161)'
               >
                  <path d='M240,144c-17.673,0-32,14.327-32,32v272c0,17.673,14.327,32,32,32c17.673,0,32-14.327,32-32V176 C272,158.327,257.673,144,240,144z M256,448c0,8.837-7.163,16-16,16s-16-7.163-16-16V176c0-8.837,7.163-16,16-16s16,7.163,16,16 V448z' />
                  <path d='M136,240c-17.673,0-32,14.327-32,32v176c0,17.673,14.327,32,32,32s32-14.327,32-32V272C168,254.327,153.673,240,136,240z M152,448c0,8.837-7.163,16-16,16s-16-7.163-16-16V272c0-8.837,7.163-16,16-16s16,7.163,16,16V448z' />
                  <path d='M32,352c-17.673,0-32,14.327-32,32v64c0,17.673,14.327,32,32,32s32-14.327,32-32v-64C64,366.327,49.673,352,32,352z M48,448c0,8.837-7.163,16-16,16s-16-7.163-16-16v-64c0-8.837,7.163-16,16-16s16,7.163,16,16V448z' />
                  <path d='M344,48c-17.673,0-32,14.327-32,32v368c0,17.673,14.327,32,32,32c17.673,0,32-14.327,32-32V80 C376,62.327,361.673,48,344,48z M360,448c0,8.837-7.163,16-16,16s-16-7.163-16-16V80c0-8.837,7.163-16,16-16s16,7.163,16,16V448z' />
                  <path d='M448,0c-17.673,0-32,14.327-32,32v416c0,17.673,14.327,32,32,32c17.673,0,32-14.327,32-32V32C480,14.327,465.673,0,448,0z M464,448c0,8.837-7.163,16-16,16s-16-7.163-16-16V32c0-8.837,7.163-16,16-16s16,7.163,16,16V448z' />
               </svg>
               <p>{taskDifficulty.level}</p>
            </a>
         </div>
      </div>
   );
};

import { FC } from 'react';
import styles from './PaginationLink.module.scss';

export interface IPaginationLink {
    className?: string,
    active?: boolean,
    disabled?: boolean,
    children?: string | number | string[],
    href?: string | number;
    onClick?: React.MouseEventHandler<HTMLElement>
}

const PaginationLink:  FC<IPaginationLink> = ({
  className,
  active,
  disabled,
  children,
  href,
  ...otherProps
}) => {

  if (disabled) {
    return <span className={`page-link ${disabled ? styles.disabled : ""} `}>{children}</span>;
  }

  return (
    <a
      className={`page-link ${styles.link} ${active ? styles.active : ""} ${
            disabled ? "disabled" : ""
        }`}

      aria-current={active ? 'page' : undefined}
      {...otherProps}
    >
      {children}
    </a>
  );
}

export default PaginationLink;

import { FC } from 'react';
import styles from './TextQuestion.module.scss';

interface Props {
   questionId: number;
   question: string;
}

const TextQuestion: FC<Props> = ({ questionId, question }) => {
   return (
      <div className={styles.wrapper}>
         <p className={styles.question}>{question}</p>

         <textarea
            name={questionId.toString()}
            className={styles.textarea}
            rows={4}
         />
      </div>
   );
};

export default TextQuestion;

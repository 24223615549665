import { FC } from 'react';
//styles
import styles from './TableItem.module.scss';

interface IProps {
   children: string;
   onClick: () => void;
   activeLink?: HTMLDivElement | null | undefined;
}

export const TableItem: FC<IProps> = ({ children, onClick, activeLink }) => {
   const activeStyle =
      activeLink?.id === children ? ['#faaf00', 700] : ['#e9edf2', 400];

   return (
      <div className={styles.container} onClick={onClick}>
         <span
            className={styles.circle}
            style={{ backgroundColor: `${activeStyle[0]}` }}
         ></span>
         <p
            className={styles.title}
            style={{ fontWeight: `${activeStyle[1]}` }}
         >
            {children}
         </p>
      </div>
   );
};

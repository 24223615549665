import { useParams } from 'react-router-dom';

import { localData } from './localData';

export const useLocalData = () => {
   const params = useParams();
   const urlTitle = params.title;

   const currentCourse = localData.find((course) => course.slug === urlTitle)!;

   const {
      name,
      cat,
      subcat,
      slug,
      title,
      desc,
      rating,
      ratesNumber,
      students,
      createdBy,
      lastUpdate,
      language,
      price,
      features,
   } = currentCourse;

   const headerData = {
      title,
      rating,
      ratesNumber,
      students,
   };

   const breadcrumbsData = {
      name,
      cat,
      subcat,
   };

   const sidebarData = {
      price,
      features
   }

   return {
      currentCourse,
      headerData,
      breadcrumbsData,
      sidebarData
   };
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner_overlay__Bn6sc {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.Spinner_container__BRpBL {\n  display: inline-block;\n  width: 50px;\n  height: 50px;\n  border: 3px solid rgba(195, 195, 195, 0.6);\n  border-radius: 50%;\n  border-top-color: #636767;\n  animation: Spinner_spin__F66AK 1s ease-in-out infinite;\n  -webkit-animation: Spinner_spin__F66AK 1s ease-in-out infinite;\n}\n@keyframes Spinner_spin__F66AK {\n  to {\n    -webkit-transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA;EAEG,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAH;;AAGA;EACG,qBAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EACA,kBAAA;EACA,yBAAA;EACA,sDAAA;EACA,8DAAA;AAAH;AACG;EACG;IACG,iCAAA;EACP;AACF","sourcesContent":[".overlay {\n   // height: 60vh;\n   height: 100%;\n   width: 100%;\n   display: flex;\n   justify-content: center;\n   align-items: center;\n}\n\n.container {\n   display: inline-block;\n   width: 50px;\n   height: 50px;\n   border: 3px solid rgba(195, 195, 195, 0.6);\n   border-radius: 50%;\n   border-top-color: #636767;\n   animation: spin 1s ease-in-out infinite;\n   -webkit-animation: spin 1s ease-in-out infinite;\n   @keyframes spin {\n      to {\n         -webkit-transform: rotate(360deg);\n      }\n   }\n   @-webkit-keyframes spin {\n      to {\n         -webkit-transform: rotate(360deg);\n      }\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "Spinner_overlay__Bn6sc",
	"container": "Spinner_container__BRpBL",
	"spin": "Spinner_spin__F66AK"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AllCoursesPage_container__qVdS3 {\n  margin-bottom: 50px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n.AllCoursesPage_container__qVdS3 .AllCoursesPage_title__jEZrB {\n  font-weight: 600;\n  font-size: 2.375rem;\n  line-height: 1.21;\n}\n\n.AllCoursesPage_students_are_viewing__16\\+aj h2 {\n  font-weight: 600;\n  font-size: 1.875rem;\n  line-height: 1.27;\n  margin-bottom: 1rem;\n}\n\n.AllCoursesPage_slider_container__XiYf\\+ .AllCoursesPage_slider_title__j2kxS {\n  font-weight: 600;\n  font-size: 1.875rem;\n  line-height: 1.27;\n  margin-bottom: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/pages/Home/nestedPages/AllCoursesPage/AllCoursesPage.module.scss"],"names":[],"mappings":"AAAA;EACG,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACH;AACG;EACG,gBAAA;EACA,mBAAA;EACA,iBAAA;AACN;;AAIG;EACG,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;AADN;;AAMG;EACG,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;AAHN","sourcesContent":[".container {\n   margin-bottom: 50px;\n   display: flex;\n   flex-direction: column;\n   gap: 24px;\n\n   .title {\n      font-weight: 600;\n      font-size: 2.375rem;\n      line-height: 1.21;\n   }\n}\n\n.students_are_viewing {\n   h2 {\n      font-weight: 600;\n      font-size: 1.875rem;\n      line-height: 1.27;\n      margin-bottom: 1rem;\n   }\n}\n\n.slider_container {\n   .slider_title {\n      font-weight: 600;\n      font-size: 1.875rem;\n      line-height: 1.27;\n      margin-bottom: 1rem;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AllCoursesPage_container__qVdS3",
	"title": "AllCoursesPage_title__jEZrB",
	"students_are_viewing": "AllCoursesPage_students_are_viewing__16+aj",
	"slider_container": "AllCoursesPage_slider_container__XiYf+",
	"slider_title": "AllCoursesPage_slider_title__j2kxS"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StudentHome_container__DC8vb {\n  margin: 100px 15px 50px;\n  display: flex;\n  flex-direction: column;\n  row-gap: 1rem;\n}\n@media screen and (min-width: 800px) {\n  .StudentHome_container__DC8vb {\n    margin: 100px 20vw 50px;\n  }\n}\n\n.StudentHome_heading__yV6hO {\n  font-size: 30px;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/pages/StudentHome/StudentHome.module.scss"],"names":[],"mappings":"AAAA;EACG,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AACH;AACG;EANH;IAOM,uBAAA;EAEJ;AACF;;AACA;EACG,eAAA;AAEH","sourcesContent":[".container {\n   margin: 100px 15px 50px;\n   display: flex;\n   flex-direction: column;\n   row-gap: 1rem;\n\n   @media screen and (min-width: 800px) {\n      margin: 100px 20vw 50px;\n   }\n}\n\n.heading {\n   font-size: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "StudentHome_container__DC8vb",
	"heading": "StudentHome_heading__yV6hO"
};
export default ___CSS_LOADER_EXPORT___;

// material-ui
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';


// ==============================|| HEADER CONTENT - SEARCH ||============================== //

export const Search = () => (
   <Box sx={{ width: { md: 'auto', xs: '100%' }, ml: { xs: 0, md: 1 }, mr: 'auto' }}>
      <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
         <OutlinedInput
            sx={{
               '& ::placeholder': {
                  fontSize: '14px'
               }
            }}
            size='small'
            id='header-search'
            startAdornment={
               <InputAdornment
                  position='start'
               // sx={{ mr: -0.5 }}
               >
                  <SearchOutlined />
               </InputAdornment>
            }
            aria-describedby='header-search-text'
            inputProps={{
               'aria-label': 'weight',
            }}
            placeholder='Ctrl + K'
         />
      </FormControl>
   </Box>
);
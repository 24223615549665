import author1 from '../../../assets/markdown/coursePreview/author1.md';
import author2 from '../../../assets/markdown/coursePreview/author2.md';

export const authorsData = [
   {
      id: 1,
      firstName: 'Andrei',
      lastName: 'Neagoie',
      position: 'Founder of zeromastery.io',
      rating: 4.6,
      students: 1015592,
      coursesNumber: 26,
      story: author1,
   },
   {
      id: 2,
      firstName: 'Luis',
      lastName: 'Ramirez Jr',
      position: 'Frontend Software Engineer Instructor',
      rating: 4.6,
      students: 48899,
      coursesNumber: 4,
      story: author2,
   },
];

import { useNavigate } from 'react-router-dom';
import { AppBtn, AppBtnTypes } from '../../../../../UI'
import styles from './Billboard.module.scss';
import { useMediaQuery } from '@mui/material';

export const Billboard = () => {
  const matchesSm = useMediaQuery('(min-width:900px)'
   );

  const billboardDesktot = 'https://s.udemycdn.com/teaching/billboard-desktop-v4.jpg'
  const billboard = 'https://s.udemycdn.com/teaching/billboard-mobile-v3.jpg'

  const navigate = useNavigate();

  const handleClickAuth = () => {
    navigate('/auth');
 };

  return (
    <div className={styles.content_container}>
        <img src={!matchesSm ? billboard : billboardDesktot} alt="billboard" />
        <div className={styles.watch}>
            <h2>Come teach with us</h2>
            <p>Become an instructor and change lives — including your own</p>
            <AppBtn className={styles.btn} onClick={handleClickAuth} buttonType={AppBtnTypes.inverted}>
                     Get started
            </AppBtn>
        </div>
    </div>
  )
}

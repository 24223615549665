import { forwardRef, useEffect } from 'react';
import { Link, LinkProps } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Typography,
} from '@mui/material';

// project imports
import { useRootStore } from '../../../../../../../hooks';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
   item: INavItem;
   level: number;
}

interface INavItem {
   id: string;
   title: string;
   type: string;
   url?: string;
   icon?: React.ComponentType<{ style?: React.CSSProperties }>;
   target?: boolean;
   breadcrumbs?: boolean;
   external?: boolean;
   children?: INavItem[];
}

export const NavItem: React.FC<Props> = ({ item, level }) => {
   const theme = useTheme();
   const { layoutStore } = useRootStore();
   const activeItem = layoutStore.getActiveItem();
   const drawerOpen = layoutStore.getDrawerOpen();

   let itemTarget = '_self';

   let listItemProps: {
      component: React.ElementType<
         LinkProps & React.RefAttributes<HTMLAnchorElement>
      >;
      href?: string;
      target?: string;
   } = {
      component: forwardRef((props, ref) => (
         <Link ref={ref} {...props} to={item.url!} target={itemTarget} />
      )),
   };

   if (item?.external) {
      listItemProps = { component: 'a', href: item.url, target: itemTarget };
   }

   const itemHandler = (id: string) => {
      if (item.type === 'nested') {
         layoutStore.setSubmenuIsOpen(true);
      }
      if (item.type === 'sub') {
         layoutStore.setSubmenuIsOpen(false);
         layoutStore.setDrawerOpen(false);
      }
      layoutStore.setActiveItem([id]);
   };

   const Icon = item.icon && item.icon;

   const isSelected = activeItem.findIndex((id) => id === item.id) > -1;

   // active menu item on page load
   useEffect(() => {
      const currentIndex = document.location.pathname
         .toString()
         .split('/')
         .findIndex((id) => id === item.id);
      if (currentIndex > -1) {
         layoutStore.setActiveItem([item.id]);
      }
      // eslint-disable-next-line
   }, []);

   const textColor = 'text.primary';
   const iconSelectedColor = 'primary.main';

   return (
      <ListItemButton
         {...listItemProps}
         onClick={() => itemHandler(item.id)}
         selected={isSelected}
         //  disabled={item.disabled}
         sx={{
            zIndex: 1201,
            pl: drawerOpen ? `${level * 28}px` : 1.5,
            py: !drawerOpen && level === 1 ? 1.25 : 1,
            ...(drawerOpen && {
               '&:hover': {
                  bgcolor: 'primary.lighter',
               },
               '&.Mui-selected': {
                  bgcolor: 'primary.lighter',
                  borderRight: `2px solid ${theme.palette.primary.main}`,
                  color: iconSelectedColor,
                  '&:hover': {
                     color: iconSelectedColor,
                     bgcolor: 'primary.lighter',
                  },
               },
            }),
            ...(!drawerOpen && {
               '&:hover': {
                  bgcolor: 'transparent',
               },
               '&.Mui-selected': {
                  '&:hover': {
                     bgcolor: 'transparent',
                  },
                  bgcolor: 'transparent',
               },
            }),
         }}
      >
         {Icon && (
            <ListItemIcon
               sx={{
                  minWidth: 28,
                  color: isSelected ? iconSelectedColor : textColor,
                  ...(!drawerOpen && {
                     borderRadius: 1.5,
                     width: 36,
                     height: 36,
                     alignItems: 'center',
                     justifyContent: 'center',
                     '&:hover': {
                        bgcolor: 'secondary.lighter',
                     },
                  }),
                  ...(!drawerOpen &&
                     isSelected && {
                        bgcolor: 'primary.lighter',
                        '&:hover': {
                           bgcolor: 'primary.lighter',
                        },
                     }),
               }}
            >
               {/* {itemIcon} */}
               <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
            </ListItemIcon>
         )}
         {(drawerOpen || (!drawerOpen && level !== 1)) && (
            <ListItemText
               primary={
                  <Typography
                     variant='h6'
                     sx={{ color: isSelected ? iconSelectedColor : textColor }}
                  >
                     {item.title}
                  </Typography>
               }
            />
         )}
      </ListItemButton>
   );
};
import React from 'react';
import { AppBtn, AppBtnTypes } from '../../../../../UI';
import style from './CollToJoin.module.scss';
import { useNavigate } from 'react-router-dom';

export const CollToJoin = () => {
   const navigate = useNavigate();

   const handleClickAuth = () => {
      navigate('/auth');
   };

   return (
      <div className={style.coll_join}>
         <div className={style.wrapper}>
            <h2>Become an instructor today</h2>
            <p>Join one of the world’s largest online learning marketplaces.</p>
            <AppBtn
               onClick={handleClickAuth}
               className={style.btn}
               buttonType={AppBtnTypes.inverted}
            >
               Get started
            </AppBtn>
         </div>
      </div>
   );
};

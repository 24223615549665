import { useState } from 'react';
// Components
import {
   Row,
   CellGroup,
   CellName,
   CellTask,
   CellSubmission,
   CellStatus,
} from '../index';
import { Button } from '../../../../UI';
// Project
import { IReviewTask } from '../../../../../http/mentorAPI';
import { AppModal, MODAL_TYPES } from '../../../../UI';
import { courseItemStateClassNames } from '../../../consts';
import { useTaskModal } from '../../../hooks';
import { useIsoDateTimeFormat } from '../../../../../hooks';
// Styles
import styles from './TaskTableRow.module.scss';

interface Props {
   task: IReviewTask;
}

export const TaskTableRow: React.FC<Props> = ({ task }) => {
   const {
      answer,
      studentLastName,
      studentFirstName,
      studentId,
      id,
      courseTitle,
      courseItemState,
      courseItemTitle,
      answerSubmittedDateTime,
      answerInReviewDateTime,
   } = task;
   const modalHookParams = {
      studentFirstName,
      studentLastName,
      courseItemTitle,
      id,
   };
   const {
      modalIsOpen,
      openAcceptModal,
      openRejectModal,
      handleModalClose,
      onConfirm,
      message,
   } = useTaskModal(modalHookParams);

   const [itemState, setItemState] = useState(courseItemState);

   const confirmTaskModal = () => {
      onConfirm();
      handleModalClose();
   };

   const { stateClassName } = courseItemStateClassNames[itemState];
   const dateTime =
      itemState === 'Answer in review'
         ? answerInReviewDateTime
         : answerSubmittedDateTime;
   const stateDateTime = useIsoDateTimeFormat(dateTime);
   const submittedDateTime = useIsoDateTimeFormat(answerSubmittedDateTime);
   const shortCourseItemState = itemState.slice(7);

   return (
      <Row>
         <AppModal
            type={MODAL_TYPES.optional}
            open={modalIsOpen}
            handleClose={handleModalClose}
            handleConfirm={confirmTaskModal}
            confirmBtnContent='Confirm'
            dismissBtnContent='Dismiss'
         >
            {message}
         </AppModal>

         <CellGroup group={courseTitle} />

         <CellName
            studentFirstName={studentFirstName}
            studentLastName={studentLastName}
            studentId={studentId}
         />

         <CellTask task={courseItemTitle} id={id} />

         <CellSubmission
            courseItemState={itemState}
            setItemState={setItemState}
            id={id}
            answer={answer}
            submittedDateTime={submittedDateTime}
         />

         <CellStatus
            stateClassName={stateClassName}
            stateDateTime={stateDateTime}
            shortCourseItemState={shortCourseItemState}
         />

         <td className={styles.button}>
            <Button
               type='button'
               buttonClass='primary'
               buttonText='Accept'
               style={{ margin: 0, width: 'auto' }}
               buttonClick={openAcceptModal}
               disabled={!(itemState === 'Answer in review')}
            />
         </td>

         <td className={styles.button}>
            <Button
               type='button'
               buttonClass='secondary'
               buttonText='Reject'
               style={{ margin: 0, width: 'auto' }}
               buttonClick={openRejectModal}
               disabled={!(itemState === 'Answer in review')}
            />
         </td>
      </Row>
   );
};

import { useState } from 'react';
// components
import { StudentsEnrollCard } from '../index';
import { AppBtn } from '../../../UI';
// project
import { courses } from '../../library';
// styles
import styles from './StudentsEnroll.module.scss';

export const StudentsEnroll: React.FC = () => {
   const [isShownMore, setIsShownMore] = useState(false);

   const toggleShowMore = () => {
      setIsShownMore(!isShownMore);
   };

   const coursesRendered = isShownMore ? courses : courses.slice(0, 3);

   const coursesMap = coursesRendered.map((course) => (
      <StudentsEnrollCard key={course.id} course={course} />
   ));

   return (
      <div className={styles.wrapper}>
         <h3 className={styles.title}>Students also enroll in</h3>
         {coursesMap}
         <AppBtn onClick={toggleShowMore} className={styles.show_btn}>
            {isShownMore ? 'Show Less' : 'Show More'}
         </AppBtn>
      </div>
   );
};

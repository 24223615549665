// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".studentProfile_task__hJuZf {\n  padding-bottom: 2rem;\n  margin-top: 76px;\n}\n\n.studentProfile_heading__4tE-K {\n  font-size: 30px;\n}\n\n.studentProfile_subheading__pBNlv {\n  font-size: 20px;\n  font-weight: 600;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: center;\n}\n@media screen and (min-width: 992px) {\n  .studentProfile_subheading__pBNlv {\n    display: flex;\n    justify-content: flex-start;\n  }\n}\n\n.studentProfile_container__-y088 {\n  margin: 1rem 20px;\n  box-sizing: border-box;\n  overflow-x: auto;\n}\n@media screen and (min-width: 992px) {\n  .studentProfile_container__-y088 {\n    margin: 1rem 20vw;\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/studentProfile/studentProfile.module.scss"],"names":[],"mappings":"AAAA;EACG,oBAAA;EACA,gBAAA;AACH;;AAEA;EACG,eAAA;AACH;;AAEA;EACG,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;AACH;AAAG;EANH;IAOM,aAAA;IACA,2BAAA;EAGJ;AACF;;AACA;EACG,iBAAA;EACA,sBAAA;EACA,gBAAA;AAEH;AAAG;EALH;IAMM,iBAAA;EAGJ;AACF","sourcesContent":[".task {\n   padding-bottom: 2rem;\n   margin-top: 76px;\n}\n\n.heading {\n   font-size: 30px;\n}\n\n.subheading {\n   font-size: 20px;\n   font-weight: 600;\n   margin-bottom: 1rem;\n   display: flex;\n   justify-content: center;\n   @media screen and (min-width: 992px) {\n      display: flex;\n      justify-content: flex-start;\n\n   }\n}\n\n.container {\n   margin: 1rem 20px;\n   box-sizing: border-box;\n   overflow-x: auto;\n\n   @media screen and (min-width: 992px) {\n      margin: 1rem 20vw;\n   }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"task": "studentProfile_task__hJuZf",
	"heading": "studentProfile_heading__4tE-K",
	"subheading": "studentProfile_subheading__pBNlv",
	"container": "studentProfile_container__-y088"
};
export default ___CSS_LOADER_EXPORT___;

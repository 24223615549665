import { ReactNode } from 'react';

// material-ui
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// styles
import './BlackArrows.scss';

type SlickArrowProps = {
   currentSlide?: number;
   slideCount?: number;
   onClick?: () => void;
   children?: ReactNode;
   className?: string;
   slidesToShow?: number;
};

export const SlickArrowLeft = ({
   currentSlide,
   className,
   onClick,
}: SlickArrowProps) => (
   <>
      {currentSlide !== 0 && (
         <div className={className} onClick={onClick}>
            <ArrowBackIosNewIcon
               style={{ color: 'white', fontSize: '30px', zIndex: 5 }}
            />
         </div>
      )}
   </>
);

export const SlickArrowRight = ({
   currentSlide,
   className,
   onClick,
   slideCount,
   slidesToShow,
}: SlickArrowProps) => (
   <>
      {currentSlide !== slideCount! - slidesToShow! && (
         <div className={className} onClick={onClick}>
            <ArrowForwardIosIcon
               style={{ color: 'white', fontSize: '30px', zIndex: 5 }}
            />
         </div>
      )}
   </>
);

// mui
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// components
import { CourseAssignment } from '../index';
// styles
import styles from './CourseAccordion.module.scss';

interface Props {
   index: number;
   allExpanded: boolean;
   section: {
      title: string;
      theory: number;
      practice: number;
      assignments: {
         title: string;
         type: string;
         link: string;
      }[];
   };
}

export const CourseAccordion: React.FC<Props> = ({
   section,
   index,
   allExpanded,
}) => {
   const { title, theory, practice, assignments } = section;

   return (
      <Accordion
         defaultExpanded={index === 0 || allExpanded}
         elevation={0}
         className={styles.accordion}
      >
         <AccordionSummary
            className={styles.summary}
            expandIcon={
               <ExpandMoreIcon fontSize='small' className={styles.icon} />
            }
            aria-controls='panel1a-content'
            id='panel1a-header'
         >
            <p className={styles.title}>{title}</p>
            <div className={styles.meta}>
               <span>Theory: {theory}</span>
               <span>Practice: {practice}</span>
            </div>
         </AccordionSummary>
         <AccordionDetails>
            {assignments.map((item) => (
               <CourseAssignment props={item} key={Math.random() * 100000} />
            ))}
         </AccordionDetails>
      </Accordion>
   );
};

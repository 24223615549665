// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentsSection_container__RMrbW {\n  display: flex;\n  justify-content: center;\n  position: relative;\n  width: 100vw;\n  background-color: #f7f9fa;\n  padding: 50px 8px;\n  margin-bottom: 50px;\n}\n.CommentsSection_container__RMrbW h2 {\n  font-weight: 700;\n  line-height: 1.2;\n  font-size: 1.875rem;\n  margin-bottom: 30px;\n}", "",{"version":3,"sources":["webpack://./src/modules/guest/pages/GuestHome/components/CommentsSection/CommentsSection.module.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,iBAAA;EACA,mBAAA;AACH;AACG;EACG,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AACN","sourcesContent":[".container {\n   display: flex;\n   justify-content: center;\n   position: relative;\n   width: 100vw;\n   background-color: #f7f9fa;\n   padding: 50px 8px;\n   margin-bottom: 50px;\n\n   h2 {\n      font-weight: 700;\n      line-height: 1.2;\n      font-size: 1.875rem;\n      margin-bottom: 30px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CommentsSection_container__RMrbW"
};
export default ___CSS_LOADER_EXPORT___;

import React, { CSSProperties } from 'react';
import styles from './Divider.module.scss';

interface Props {
   children: React.ReactNode;
   style?: CSSProperties;
   className?: string;
}
export const Divider: React.FC<Props> = ({ 
   children,
   style,
   className = '', 
}) => {
   
   return (
      <>
         <div 
            className={[styles.title, className].join(' ')}
            style={{ ...style }}
         >
            <h3>{children}</h3>
            <p className={styles.divider}></p>
         </div>
      </>
   );
}

import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { BorderedBlockContainer, ErrorMessage } from '../../../UI';
import { TabButtons, QuizReview, AllAnswers, TaskReview } from '../index';

import { useReviewList, useTabData } from '../../hooks';

import styles from './AnswerList.module.scss';

export const AnswerList: FC = observer(() => {
   const [activeTab, setActiveTab] = useState('Task Review');
   const { tasks, quizzes, answers, error, tabHookParams } = useReviewList();
   const { tabData } = useTabData(tabHookParams);

   const changeTab = (tab: string) => {
      setActiveTab(tab);
   };

   return (
      <BorderedBlockContainer className={styles.container}>
         <TabButtons
            activeTab={activeTab}
            changeTab={changeTab}
            tabData={tabData}
         />

         <div className={styles.tableWrapper}>
            {tasks && activeTab === 'Task Review' && <TaskReview />}
            {quizzes && activeTab === 'Quiz Review' && <QuizReview />}
            {answers && activeTab === 'All Task Answers' && <AllAnswers />}

            {error && <ErrorMessage className={styles.error} />}
         </div>
      </BorderedBlockContainer>
   );
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuizTableRow_link__OkKoo {\n  color: #2f3237;\n  font-weight: 500;\n}\n.QuizTableRow_link__OkKoo:hover {\n  text-decoration: underline;\n}\n\n.QuizTableRow_score__jxFEQ {\n  width: 50px;\n  flex-direction: column;\n}\n.QuizTableRow_score__jxFEQ input {\n  display: block;\n  width: 100%;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  font-size: larger;\n  padding: 5px 3px;\n  margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/AnswerList/QuizTableRow/QuizTableRow.module.scss"],"names":[],"mappings":"AAAA;EACG,cAAA;EACA,gBAAA;AACH;AACG;EACG,0BAAA;AACN;;AAGA;EACG,WAAA;EACA,sBAAA;AAAH;AAEG;EACG,cAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AAAN","sourcesContent":[".link {\n   color: #2f3237;\n   font-weight: 500;\n\n   &:hover {\n      text-decoration: underline;\n   }\n}\n\n.score {\n   width: 50px;\n   flex-direction: column;\n\n   input {\n      display: block;\n      width: 100%;\n      border: 1px solid #ccc;\n      border-radius: 5px;\n      font-size: larger;\n      padding: 5px 3px;\n      margin-bottom: 10px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "QuizTableRow_link__OkKoo",
	"score": "QuizTableRow_score__jxFEQ"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (min-width: 700px) {\n  .FeedbackForm_buttonWrapper__cwZDX {\n    display: flex;\n    align-items: center;\n  }\n  .FeedbackForm_submitBtn__9NTSL {\n    flex: 2 1;\n  }\n  .FeedbackForm_dismissBtn__phnOC {\n    flex: 1 1;\n    margin-left: 30px;\n  }\n}\n.FeedbackForm_submitBtn__9NTSL,\n.FeedbackForm_dismissBtn__phnOC {\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/feedback/FeedbackForm/FeedbackForm.module.scss"],"names":[],"mappings":"AAAA;EACG;IACG,aAAA;IACA,mBAAA;EACJ;EAEC;IACG,SAAA;EAAJ;EAGC;IACG,SAAA;IACA,iBAAA;EADJ;AACF;AAIA;;EAEG,gBAAA;AAFH","sourcesContent":["@media (min-width: 700px) {\n   .buttonWrapper {\n      display: flex;\n      align-items: center;\n   }\n\n   .submitBtn {\n      flex: 2;\n   }\n\n   .dismissBtn {\n      flex: 1;\n      margin-left: 30px;\n   }\n}\n\n.submitBtn,\n.dismissBtn {\n   font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": "FeedbackForm_buttonWrapper__cwZDX",
	"submitBtn": "FeedbackForm_submitBtn__9NTSL",
	"dismissBtn": "FeedbackForm_dismissBtn__phnOC"
};
export default ___CSS_LOADER_EXPORT___;

// mui
import Rating from '@mui/material/Rating/Rating';
import { StarBorder } from '@mui/icons-material';
// styles
import styles from './GoProCard.module.scss';

interface Props {
   course: {
      id: number;
      title: string;
      img: string;
      price: number;
      authors: string[];
      rating: number;
      rates: number;
      isLast: boolean;
   };
}

export const GoProCard: React.FC<Props> = ({ course }) => {
   const { id, title, img, price, authors, rating, rates, isLast } = course;

   const authorsMap = authors.map((author, i, arr) => (
      <span
         className={[styles.author, i !== 0 && styles.coauthor].join(' ')}
         key={author}
      >
         {author}
         {i < arr.length - 1 && <span className={styles.coauthor}>,</span>}
      </span>
   ));

   return (
      <div className={styles.wrapper}>
         <div
            className={[styles.img_container, !isLast && styles.plus].join(' ')}
         >
            <img className={styles.img} src={img} alt='Additional Course' />
         </div>
         <div className={styles.content}>
            <h5 className={styles.title}>{title}</h5>
            <div className={styles.authors_container}>{authorsMap}</div>
            <div className={styles.rating_container}>
               <span className={styles.rating}>{rating} </span>
               <Rating
                  className={styles.rating_stars}
                  emptyIcon={<StarBorder className={styles.star_icon} />}
                  name='read-only'
                  value={rating}
                  readOnly
                  max={5}
                  precision={0.5}
               />
               <span className={styles.rates}>
                  ({rates.toLocaleString('en-US')})
               </span>
               <span className={styles.price_narrow}>${price}</span>
            </div>
         </div>
         <div className={styles.price_wide}>${price}</div>
      </div>
   );
};

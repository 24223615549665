import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

// project components
import { AppBtn, AppBtnTypes, AppModal, MODAL_TYPES } from '../../../../UI';
import { QuizHeader } from '../QuizHeader';
import { QuizResults } from '../QuizResults';
import { QuizBody } from '../QuizBody';
import { QuizCourseInfo } from '../QuizCourseInfo';
import { Loader } from '../../Loader';

// project imports
import useProtectedContent from '../../../hooks/useProtectedContent';
import { useRootStore } from '../../../../../hooks';
import { IQuizQuestion } from '../../../interfaces/interfaces';
import { useQuizAnswer } from '../../../hooks/useQuizAnswer';
import { ENDPOINTS } from '../../../../../http/studentAPI';

// styles
import styles from './QuizActive.module.scss';

interface IProps {
   numberOfQuestions: number;
   quizTitle: string;
   topicTitle: string;
   logo: string;
}

export const QuizActive: React.FC<IProps> = observer(
   ({ numberOfQuestions, quizTitle, topicTitle, logo }) => {
      const { id } = useParams();
      const quizData = useProtectedContent(
         ENDPOINTS.quizActive(id!),
      ) as IQuizQuestion[];
      const [questionNumber, setQuestionNumber] = useState<number>(1);
      const { studentStore } = useRootStore();
      const quizAnswer = studentStore.getQuizAnswer();
      const actualValue = studentStore.getActualValue();
      const isTimeOver = studentStore.getIsQuizTimeOver();

      const { statusServer, error } = useQuizAnswer(id!);

      const handleClickShowResult = async () => {
         actualValue &&
            studentStore.setQuizAnswer([...quizAnswer, actualValue!]);
         studentStore.setActualValue(undefined);

         setQuestionNumber((prev) => ++prev);

         if (questionNumber >= numberOfQuestions) {
            studentStore.setIsQuizTimeOver(true);
         }
      };

      const [isModalOpen, setIsModalOpen] = useState(false);

      const openModal = () => {
         setIsModalOpen(true);
      };

      const closeModal = () => {
         setIsModalOpen(false);
      };

      const skipQuiz = () => {
         studentStore.setIsQuizTimeOver(true);
         closeModal();
      };

      if (!quizData) return <Loader />;

      return (
         <div className={styles.test}>
            <AppModal
               type={MODAL_TYPES.optional}
               open={isModalOpen}
               handleClose={closeModal}
               handleConfirm={skipQuiz}
               confirmBtnContent='Yes'
               dismissBtnContent='No'
            >
               <p className={styles.title}>
                  Note: The current quiz result will be counted.
               </p>

               <p className={styles.description}>
                  Are you sure you want to skip quiz ?
               </p>
            </AppModal>
            {quizData ? (
               <div className={styles.container}>
                  {isTimeOver ? (
                     <div className={styles.container_header}>
                        <div className={styles.header}>
                           <h2 className={styles.heading}>{quizTitle}</h2>
                           <h3 className={styles.subheading}>{topicTitle}</h3>
                        </div>
                     </div>
                  ) : (
                     <QuizHeader
                        questionNumber={questionNumber}
                        numberOfQuestions={numberOfQuestions}
                     />
                  )}

                  <div className={styles.wrapper}>
                     {!isTimeOver && (
                        <>
                           <QuizBody
                              topicTitle={topicTitle}
                              questions={quizData}
                              questionNumber={questionNumber}
                           />
                           <div className={styles.container__side}>
                              <div className={styles.container__btn}>
                                 <AppBtn
                                    className={styles.skip_btn}
                                    buttonType={AppBtnTypes.error_inverted}
                                    onClick={openModal}
                                 >
                                    Skip quiz
                                 </AppBtn>
                                 <AppBtn
                                    className={styles.reviewVideo_btn}
                                    buttonType={AppBtnTypes.success_inverted}
                                 >
                                    Review video
                                 </AppBtn>
                                 <AppBtn
                                    className={styles.confirm_btn}
                                    buttonType={AppBtnTypes.success}
                                    onClick={handleClickShowResult}
                                 >
                                    Confirm the answer
                                 </AppBtn>
                              </div>
                              <QuizCourseInfo
                                 name={quizTitle}
                                 description={topicTitle}
                                 imageUrl={logo}
                              />
                           </div>
                        </>
                     )}
                  </div>

                  {isTimeOver && (
                     <QuizResults
                        statusServer={statusServer}
                        name={quizTitle}
                        description={topicTitle}
                        imageUrl={logo}
                     />
                  )}
               </div>
            ) : null}
         </div>
      );
   },
);

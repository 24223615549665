import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
//project imports
import { studentNavigator } from '../../../router/StudentRoutes';
import useCourseImage from '../../hooks/useCourseImage';
//styles
import styles from './StudentCourseCard.module.scss';

interface IProp {
   completionPercentage: number;
   imageURL: string;
   courseName: string;
   courseDescription: string;
   courseId: number;
   mentorName?: string;
   slug: string;
}

export const StudentCourseCard: FC<IProp> = ({
   completionPercentage,
   imageURL,
   courseName,
   courseDescription,
   mentorName,
   slug,
}) => {
   const navigate = useNavigate();
   const image = useCourseImage(imageURL);

   const handleClick = () => {
      navigate(studentNavigator.course(slug));
   };

   return (
      <div className={styles.container} onClick={handleClick}>
         <div className={styles.img_container}>
            {image && (
               <img src={image} alt={courseName} className={styles.image} />
            )}
         </div>
         <div className={styles.content_container}>
            <h4 className={styles.title}>{courseName}</h4>
            <h4 className={styles.mentor_name}>{mentorName}</h4>
            <h4 className={styles.description}>{courseDescription}</h4>
         </div>
         <div className={styles.linear_progress}>
            <LinearProgress
               variant='determinate'
               value={completionPercentage}
            />
            <p>{completionPercentage}% complete</p>
         </div>
      </div>
   );
};

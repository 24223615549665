function useShortDateTimeFormat(dateTime: string | number | Date | undefined) {
   if (dateTime) {
      const shortDateTime = new Date(dateTime).toLocaleString('fr-CH', {
         dateStyle: 'short',
         timeStyle: 'short',
      });

      if (shortDateTime === 'Invalid Date') return;

      return shortDateTime;
   }
}

export default useShortDateTimeFormat;

import { FC, createRef, RefObject } from 'react';
import { ICourseTask } from '../../../interfaces/interfaces';
//components
import { TasksSection, TableOfContents, ResultsSection } from './components';
//styles
import styles from './TasksAndResultSection.module.scss';

interface IProps {
   courseItems: {
      [topic: string]: {
         hasIncompleteCourseItem: true;
         workCourseItems: ICourseTask[];
      };
   };
   id: number;
}

export const TasksAndResultSection: FC<IProps> = ({ courseItems, id }) => {
   const taskTitleArray = Object.keys(courseItems).map((item) => item);

   const refArray: RefObject<HTMLDivElement>[] = [];
   const refResult: RefObject<HTMLDivElement> = createRef();

   Object.keys(courseItems).forEach(() => {
      refArray.push(createRef());
   });
   refArray.push(refResult);

   return (
      <section className={styles.container}>
         <div className={styles.content}>
            <div className={styles.left_column}>
               <TasksSection courseItems={courseItems} refForTasks={refArray} />
               <ResultsSection id={id} refForResults={refResult} />
            </div>
            <div className={styles.right_column}>
               <TableOfContents
                  taskTitleArray={taskTitleArray}
                  refArray={refArray}
               />
            </div>
         </div>
      </section>
   );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuizSingleChoice_description__6lXwk {\n  margin-top: 40px;\n  font-size: 16px;\n  color: #858f97;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/QuizSingleChoice/QuizSingleChoice.module.scss"],"names":[],"mappings":"AAAA;EACG,gBAAA;EACA,eAAA;EACA,cAAA;AACH","sourcesContent":[".description {\n   margin-top: 40px;\n   font-size: 16px;\n   color: #858f97;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "QuizSingleChoice_description__6lXwk"
};
export default ___CSS_LOADER_EXPORT___;

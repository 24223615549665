import { useState, useEffect } from 'react';
import useAuthHost from '../../../hooks/useAuthHost';
import { $host } from '../../../http';

const useCourseImage = (imageURL: string, shouldUseAuthHost = true) => {
   const authHost = useAuthHost();
   const [image, setImage] = useState<string>('');

   useEffect(() => {
      const host = shouldUseAuthHost ? authHost : $host;

      const getCourseImage = async (imgURL: string) => {
         const res = await host.get(imgURL, {
            responseType: 'blob',
         });
         const img = URL.createObjectURL(res.data);
         setImage(img);
      };
      getCourseImage(imageURL);
   }, []);
   return image;
};

export default useCourseImage;

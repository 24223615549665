// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExpandedBlock_btn_container__H-4VT {\n  display: flex;\n}\n.ExpandedBlock_btn_explore__L-rTx {\n  margin-right: 20px;\n}\n.ExpandedBlock_btn_link__Nx6BK {\n  padding: 0 8px;\n}\n\n.ExpandedBlock_chevron__U5OXC {\n  font-size: 11px;\n  margin-left: 5px;\n}", "",{"version":3,"sources":["webpack://./src/modules/allCourses/components/ExpandedBlock/ExpandedBlock.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAEI;EACI,kBAAA;AAAR;AAEI;EACI,cAAA;AAAR;;AAIA;EACI,eAAA;EACA,gBAAA;AADJ","sourcesContent":[".btn {\n    &_container {\n        display: flex;\n    }\n    &_explore {\n        margin-right: 20px;\n    }\n    &_link {\n        padding: 0 8px;\n    }\n}\n\n.chevron {\n    font-size: 11px;\n    margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn_container": "ExpandedBlock_btn_container__H-4VT",
	"btn_explore": "ExpandedBlock_btn_explore__L-rTx",
	"btn_link": "ExpandedBlock_btn_link__Nx6BK",
	"chevron": "ExpandedBlock_chevron__U5OXC"
};
export default ___CSS_LOADER_EXPORT___;

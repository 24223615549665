// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HoverMenu_dropdown__J0SvL {\n  margin-right: 10px;\n  cursor: pointer;\n}\n\n.HoverMenu_overlay__dD3Z7 {\n  z-index: 9999;\n  max-width: 250px;\n}\n.HoverMenu_overlay__dD3Z7 p {\n  padding: 20px;\n  text-align: center;\n}\n\n.HoverMenu_desc__RnCDO {\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 1.2;\n}\n\n.HoverMenu_divider__YjIES {\n  margin: 0;\n  background-color: rgba(0, 0, 0, 0.12);\n}\n\n.HoverMenu_space__S9ixD {\n  min-width: max-content;\n  font-weight: 500;\n}\n\n.HoverMenu_button__zK\\+Zb {\n  display: block;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/Layout/common/HoverMenu/HoverMenu.module.scss"],"names":[],"mappings":"AAAA;EACG,kBAAA;EACA,eAAA;AACH;;AAEA;EACG,aAAA;EACA,gBAAA;AACH;AACG;EACG,aAAA;EACA,kBAAA;AACN;;AAGA;EACG,eAAA;EACA,gBAAA;EACA,gBAAA;AAAH;;AAGA;EACG,SAAA;EACA,qCAAA;AAAH;;AAGA;EACG,sBAAA;EACA,gBAAA;AAAH;;AAGA;EACG,cAAA;EACA,WAAA;AAAH","sourcesContent":[".dropdown {\n   margin-right: 10px;\n   cursor: pointer;\n}\n\n.overlay {\n   z-index: 9999;\n   max-width: 250px;\n\n   p {\n      padding: 20px;\n      text-align: center;\n   }\n}\n\n.desc {\n   font-size: 16px;\n   font-weight: 600;\n   line-height: 1.2;\n}\n\n.divider {\n   margin: 0;\n   background-color: rgba(0, 0, 0, 0.12);\n}\n\n.space {\n   min-width: max-content;\n   font-weight: 500;\n}\n\n.button {\n   display: block;\n   width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "HoverMenu_dropdown__J0SvL",
	"overlay": "HoverMenu_overlay__dD3Z7",
	"desc": "HoverMenu_desc__RnCDO",
	"divider": "HoverMenu_divider__YjIES",
	"space": "HoverMenu_space__S9ixD",
	"button": "HoverMenu_button__zK+Zb"
};
export default ___CSS_LOADER_EXPORT___;

import { FC, useState } from 'react';
import { AppBtn, AppBtnTypes } from '../../../UI';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import styles from './ExpandedBlock.module.scss';

interface IProp {
   description: string;
   title: string;
}

export const ExpandedBlock: FC<IProp> = ({ description, title }) => {
   const [isShownMore, setIsShownMore] = useState(false);

   const handleClick = () => {
      setIsShownMore(!isShownMore);
   };

   return (
      <>
         {isShownMore && <h5>{description}</h5>}
         <div className={styles.btn_container}>
            <div className={styles.btn_explore}>
               <AppBtn>Explore {title}</AppBtn>
            </div>
            <AppBtn
               className={styles.btn_link}
               buttonType={AppBtnTypes.link}
               onClick={handleClick}
            >
               {isShownMore ? (
                  <>
                     Show less <ExpandLessOutlined sx={{ fontSize: 30 }} className={styles.chevron} />
                  </>
               ) : (
                  <>
                     Show more <ExpandMoreOutlined className={styles.chevron} />
                  </>
               )}
            </AppBtn>
         </div>
      </>
   );
};
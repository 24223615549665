import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';

// project components
import { AppModal, MODAL_TYPES } from '../../../UI';
import { Loader } from '../../components/Loader';
import { IQuiz } from '../../interfaces/interfaces';
import { QuizDescription } from '../../components/quiz/QuizDescription/QuizDescription';
import { QuizActive } from '../../components/quiz/QuizActive/QuizActive';
import { QuizResults } from '../../components/quiz/QuizResults/QuizResults';

// project imports
import useProtectedContent from '../../hooks/useProtectedContent';
import { useRootStore } from '../../../../hooks/mobx';
import { ENDPOINTS } from '../../../../http/studentAPI';
import { convertToSlug } from '../../../../utils/utils';

// styles
import styles from './Quiz.module.scss';

export const Quiz: React.FC = () => {
   const navigate = useNavigate();
   const { id, title } = useParams();
   const quizContent = useProtectedContent(ENDPOINTS.quiz(id!)) as IQuiz;
   const { studentStore } = useRootStore();

   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isBeginQuiz, setIsBeginQuiz] = useState(false);

   if (!quizContent) return <Loader />;

   const { courseItem, numberOfQuestions, score, quizResult } = quizContent;
   const {
      title: courseItemTitle,
      topicTitle,
      time,
      state,
      passingScore,
   } = courseItem;
   const userResult =
      (state === 'Answer accepted' || 'Answer rejected') &&
      `${score}/${passingScore}`;
   const logo = quizContent.workCourse.courseLogoUrl;
   const isResultShown =
      state === 'Answer submitted' ||
      state === 'Answer accepted' ||
      state === 'Answer rejected';

   studentStore.setNumberOfQuestions(numberOfQuestions);

   if (convertToSlug(courseItemTitle) !== title) {
      navigate(-1);
   }

   const openModal = () => {
      setIsModalOpen(true);
   };

   const closeModal = () => {
      setIsModalOpen(false);
   };

   const startQuiz = () => {
      studentStore.setIsQuizTimeOver(false);
      studentStore.setQuizAnswer([]);
      setIsBeginQuiz(true);
      closeModal();
   };

   return (
      <div className={styles.quiz}>
         <AppModal
            type={MODAL_TYPES.optional}
            open={isModalOpen}
            handleClose={closeModal}
            handleConfirm={startQuiz}
            confirmBtnContent='Confirm'
            dismissBtnContent='Dismiss'
         >
            Are you sure you want to start quiz ?
         </AppModal>

         {isBeginQuiz ? (
            <QuizActive
               numberOfQuestions={numberOfQuestions}
               quizTitle={courseItemTitle}
               topicTitle={topicTitle}
               logo={logo}
            />
         ) : (
            <>
               <div className={styles.container_header}>
                  <div className={styles.header}>
                     <h2 className={styles.heading}>{courseItemTitle}</h2>
                     <h3 className={styles.subheading}>{topicTitle}</h3>
                  </div>
               </div>

               {isResultShown ? (
                  <div className={styles.container_main}>
                     <QuizResults
                        state={state}
                        startQuiz={startQuiz}
                        userResult={userResult}
                        quizResult={
                           state === 'Answer accepted' ? quizResult : undefined
                        }
                        name={courseItemTitle}
                        description={topicTitle}
                        imageUrl={logo}
                     />
                  </div>
               ) : (
                  <QuizDescription
                     id={id}
                     time={time}
                     numberOfQuestions={numberOfQuestions}
                     name={courseItemTitle}
                     description={topicTitle}
                     imageUrl={logo}
                     openModal={openModal}
                  />
               )}
            </>
         )}
      </div>
   );
};

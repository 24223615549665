// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LearningSection_tabs_section_container__CID5s {\n  width: 900px;\n  margin: 100px auto;\n}\n@media screen and (max-width: 1000px) {\n  .LearningSection_tabs_section_container__CID5s {\n    width: calc(100% - 20px);\n  }\n}\n.LearningSection_tabs_section_container__CID5s h2 {\n  font-weight: 700;\n  font-size: 36px;\n  line-height: 1.2;\n  text-align: center;\n  margin-bottom: 20px;\n}", "",{"version":3,"sources":["webpack://./src/modules/guest/pages/TeachOnTraining/components/LearningSection/LearningSection.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;AACJ;AAAI;EAHJ;IAIM,wBAAA;EAGJ;AACF;AAFI;EACI,gBAAA;EACD,eAAA;EACA,gBAAA;EACC,kBAAA;EACA,mBAAA;AAIR","sourcesContent":[".tabs_section_container {\n    width: 900px;\n    margin: 100px auto;\n    @media screen and (max-width: 1000px) {\n      width: calc(100% - 20px);\n     }\n    h2 {\n        font-weight: 700;\n       font-size: 36px;\n       line-height: 1.2;\n        text-align: center; \n        margin-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs_section_container": "LearningSection_tabs_section_container__CID5s"
};
export default ___CSS_LOADER_EXPORT___;

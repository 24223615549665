import { makeAutoObservable } from 'mobx';
import {
   ITask,
   IQuizAnswer,
   QuizResult,
} from '../modules/student/interfaces/interfaces';

export default class StudentStore {
   private task: null | ITask;
   private quizAnswer: IQuizAnswer[];
   private actualValue: IQuizAnswer | undefined;
   private numberOfQuestions: number;
   private isQuizTimeOver: boolean;
   private quizResults: QuizResult[];

   constructor() {
      this.task = null;
      this.quizAnswer = [];
      this.actualValue = undefined;
      this.numberOfQuestions = 0;
      this.isQuizTimeOver = false;
      this.quizResults = [];
      makeAutoObservable(this);
   }

   setTask(task: ITask | null) {
      this.task = task;
   }

   setQuizAnswer(answer: IQuizAnswer[]) {
      this.quizAnswer = answer;
   }

   setActualValue(value: any) {
      this.actualValue = value;
   }

   setNumberOfQuestions(value: number) {
      this.numberOfQuestions = value;
   }

   setIsQuizTimeOver(isTimeOver: boolean) {
      this.isQuizTimeOver = isTimeOver;
   }

   setQuizResults(payload: QuizResult[]) {
      this.quizResults = payload;
   }

   getTask() {
      return this.task;
   }

   getQuizAnswer() {
      return this.quizAnswer;
   }

   getActualValue() {
      return this.actualValue;
   }

   getNumberOfQuestions() {
      return this.numberOfQuestions;
   }

   getIsQuizTimeOver() {
      return this.isQuizTimeOver;
   }

   getQuizResults() {
      return this.quizResults;
   }
}

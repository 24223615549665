// mui
import { Rating } from '@mui/material';
import { StarBorder } from '@mui/icons-material';

// styles
import styles from './Indicators.module.scss';

const backData = [
   {
      rating: 5,
      number: 655,
   },
   {
      rating: 4,
      number: 247,
   },
   {
      rating: 3,
      number: 55,
   },
   {
      rating: 2,
      number: 18,
   },
   {
      rating: 1,
      number: 7,
   },
];

type Data = {
   rating: number;
   number: number;
}[];

const convertData = (data: Data) => {
   const sum = data.reduce((a, b) => a + b.number, 0);
   const percentAdded = data.map((rate) => ({
      ...rate,
      percent: Math.round((rate.number / sum) * 100),
   }));
   return percentAdded;
};

const data = convertData(backData);

export const Indicators: React.FC = () => {
   const ratingsMap = data.map((rate) => {
      const { rating, percent } = rate;

      return (
         <div key={Math.random() * 100000} className={styles.rate}>
            <div className={styles.line}>
               <span
                  style={{width: `${percent}%`}} 
                  className={styles.line_rating}
               ></span>
            </div>
            <span className={styles.rating_container}>
               <Rating
                  className={styles.rating_stars}
                  emptyIcon={<StarBorder className={styles.star_icon} />}
                  name='read-only'
                  value={rating}
                  readOnly
                  max={5}
                  precision={0.5}
               />
            </span>
            <div>{percent}%</div>
         </div>
      );
   });

   return <div className={styles.wrapper}>{ratingsMap}</div>;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputCheckbox_checkbox__48Tej {\n  display: flex;\n  cursor: pointer;\n  margin: 5px;\n}\n.InputCheckbox_checkbox__48Tej span {\n  font-weight: 600;\n  font-size: 15px;\n  margin: 6px;\n}", "",{"version":3,"sources":["webpack://./src/modules/quiz/components/InputCheckbox/InputCheckbox.module.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,eAAA;EACA,WAAA;AACH;AACG;EACG,gBAAA;EACA,eAAA;EACA,WAAA;AACN","sourcesContent":[".checkbox {\n   display: flex;\n   cursor: pointer;\n   margin: 5px;\n \n   & span {\n      font-weight: 600;\n      font-size: 15px;\n      margin: 6px;\n   }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "InputCheckbox_checkbox__48Tej"
};
export default ___CSS_LOADER_EXPORT___;

import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../../../../../hooks';
// styles
import styles from '../Categories.module.scss';

interface Props {
   isOpen: boolean;
   handleClick: (
      e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
   ) => void;
   setOpen: () => void;
}

export const CategoriesButton: React.FC<Props> = observer(
   ({ isOpen, handleClick, setOpen }) => {
      const { layoutStore } = useRootStore();
      const cascaderIsActive = layoutStore.getCascaderIsActive();

      return (
         <div
            onClick={handleClick}
            onMouseEnter={cascaderIsActive ? setOpen : undefined}
         >
            <span
               className={
                  isOpen
                     ? styles.categories__button_selected
                     : styles.categories__button
               }
            >
               Courses
            </span>
         </div>
      );
   },
);

import { CSSProperties, ReactNode } from 'react';

// styles
import styles from './PageContainer.module.scss';

interface Props {
   children?: ReactNode;
   maxWidth?: string;
   style?: CSSProperties;
}

export function PageContainer({ children, maxWidth, style }: Props) {
   return (
      <div className={styles.container} style={{ maxWidth, ...style }}>
         {children}
      </div>
   );
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentsTitle_wrapper__mvAR3 {\n  font-size: 24px;\n  font-weight: 700;\n  margin-bottom: 18px;\n  display: inline-block;\n}\n@media screen and (min-width: 600px) {\n  .CommentsTitle_wrapper__mvAR3 {\n    width: max-content;\n  }\n}\n\n.CommentsTitle_rating__v4OBa {\n  transform: translateY(3px);\n}", "",{"version":3,"sources":["webpack://./src/modules/coursePreview/components/CommentsTitle/CommentsTitle.module.scss","webpack://./src/theme/_config.scss"],"names":[],"mappings":"AAEA;ECmCG,eAAA;EACA,gBAAA;EACA,mBAAA;EDnCA,qBAAA;AACH;AACG;EAJH;IAKM,kBAAA;EAEJ;AACF;;AACA;EACG,0BAAA;AAEH","sourcesContent":["@use '../../../../theme/config' as v;\n\n.wrapper {\n   @include v.title;\n   display: inline-block;\n\n   @media screen and (min-width: v.$sm) {\n      width: max-content;\n   }\n}\n\n.rating {\n   transform: translateY(3px);\n}\n","// ============================ Palette\n$dark-bcg: #1c1d1f;\n$golden: #faaf00;\n// --------- MUI Palette\n$primary-main: #1976d2;\n$primary-dark: #1565c0;\n$secondary-main: #9c27b0;\n$secondary-dark: #7b1fa2;\n$success-main: #4caf50;\n$success-dark: #2e7d32;\n$error-light: #ef5350;\n$error-main: #d32f2f;\n$warning-light: #ff9800;\n$divider-light: rgba(0, 0, 0, 0.12);\n$divider-dark: rgba(255, 255, 255, 0.12);\n\n// ============================ Media Queries\n$xs: 0;\n$mob: 450px;\n$sm: 600px;\n$sm_md: 760px;\n$md: 900px;\n$lg: 1200px;\n$xl: 1536px;\n\n//  =========================== Layout Element\n$px-small: 16px;\n$px-large: 24px;\n\n$header-height: 60px;\n\n// ============================ Course Preview Page\n$max-width-lg: 1000px;\n$hero-height-desktop: 350px;\n$sidebar-width: 340px;\n\n@mixin title {\n   font-size: 24px;\n   font-weight: 700;\n   margin-bottom: 18px;\n}\n\n@mixin ellipsis($lines) {\n   display: -webkit-box;\n   -webkit-line-clamp: $lines;\n   -webkit-box-orient: vertical;\n   overflow: hidden;\n   text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CommentsTitle_wrapper__mvAR3",
	"rating": "CommentsTitle_rating__v4OBa"
};
export default ___CSS_LOADER_EXPORT___;

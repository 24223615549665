// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project components
import { DrawerHeader } from './DrawerHeader';
import { DrawerContent } from './DrawerContent';
import { Submenu } from './Submenu';

// project imports
import { MiniDrawerStyled } from './MiniDrawerStyled';
import { drawerWidth } from '../../../../../theme/config';
import { useDrawerToggler } from '../../hooks/useDrawerToggler';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //
interface MainDrawerProps {
   window?: () => Window;
}

export const MainDrawer: React.FC<MainDrawerProps> = ({ window }) => {
   const theme = useTheme();
   const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
   const { open, handleDrawerToggle, submenuOpen, activeItem } =
      useDrawerToggler();

   // responsive drawer container
   const container =
      window !== undefined ? () => window().document.body : undefined;

   return (
      <Box
         component='nav'
         sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}
         aria-label='mailbox folders'
      >
         {!matchDownMD ? (
            <MiniDrawerStyled variant='permanent' open={open}>
               <DrawerHeader open={open} />
               <DrawerContent />

               <Submenu
                  submenuOpen={submenuOpen}
                  drawerWidth={drawerWidth}
                  activeItem={activeItem}
               />
            </MiniDrawerStyled>
         ) : (
            <Drawer
               container={container}
               variant='temporary'
               open={open}
               onClose={handleDrawerToggle}
               ModalProps={{ keepMounted: true }}
               sx={{
                  display: { xs: 'block', lg: 'none' },
                  '& .MuiDrawer-paper': {
                     position: 'relative',
                     boxSizing: 'border-box',
                     width: drawerWidth,
                     borderRight: `1px solid ${theme.palette.divider}`,
                     backgroundImage: 'none',
                     boxShadow: 'inherit',
                     overflowX: 'hidden',
                  },
               }}
            >
               {open && <DrawerHeader open={open} />}
               {open && <DrawerContent />}

               <Submenu
                  submenuOpen={submenuOpen}
                  drawerWidth={drawerWidth}
                  activeItem={activeItem}
               />
            </Drawer>
         )}
      </Box>
   );
};
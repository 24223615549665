import { useEffect, useState } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
// mui
import GroupIcon from '@mui/icons-material/Group';
import StarIcon from '@mui/icons-material/Star';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
// components
import { ShowMoreBox, AppAvatar } from '../../../UI';
// styles
import styles from './AuthorArticle.module.scss';

interface Props {
   author: {
      id: number;
      firstName: string;
      lastName: string;
      position: string;
      rating: number;
      students: number;
      coursesNumber: number;
      story: string;
   };
}

export const AuthorArticle: React.FC<Props> = ({ author }) => {
   const {
      firstName,
      lastName,
      position,
      rating,
      students,
      coursesNumber,
      story,
   } = author;
   const authorName = firstName + ' ' + lastName;

   const [description, setDescription] = useState('');

   useEffect(() => {
      fetch(story)
         .then((res) => {
            return res.text();
         })
         .then((text) => {
            setDescription(text);
         });
   }, []);

   return (
      <div className={styles.wrapper}>
         <div className={styles.name}>{authorName}</div>
         <p className={styles.position}>{position}</p>

         <div className={styles.info}>
            <AppAvatar name={authorName} className={styles.avatar} />
            <div className={styles.meta}>
               <div className={styles.meta_item}>
                  <StarIcon fontSize='small' />
                  {rating} Instructor Rating
               </div>
               <div className={styles.meta_item}>
                  <GroupIcon fontSize='small' />
                  {students.toLocaleString('en-US')} Students
               </div>
               <div className={styles.meta_item}>
                  <PlayCircleIcon fontSize='small' />
                  {coursesNumber} Courses
               </div>
            </div>
         </div>

         <ShowMoreBox height={250}>
            <div className={styles.md_container}>
               <MarkdownPreview
                  source={description}
                  className={styles.markdown}
                  disableCopy
                  warpperElement={{
                     'data-color-mode': 'light',
                  }}
               />
            </div>
         </ShowMoreBox>
      </div>
   );
};

//router
import { useLocation, useNavigate } from 'react-router-dom';
import { studentNavigator } from '../../../../../../../router/StudentRoutes';
import { convertToSlug } from '../../../../../../../../utils/utils';
//interfaces
import { ICourseTask } from '../../../../../../interfaces/interfaces';
//components
import {
   QuizOutlined,
   InfoOutlined,
   ArticleOutlined,
   CodeOutlined,
} from '@mui/icons-material';
//styles
import styles from './TaskItem.module.scss';

export const AccordionItem: React.FC<{
   task: ICourseTask;
   idx: number;
}> = ({ task, idx }) => {
   const { id, courseItemTitle, courseItemType, state } = task;
   const location = useLocation();
   const navigate = useNavigate();

   const IconType = () => {
      switch (courseItemType) {
         case 'Coding assignment':
            return <CodeOutlined fontSize='small' />;
         case 'Article':
            return <ArticleOutlined fontSize='small' />;
         case 'Info page':
            return <InfoOutlined fontSize='small' />;
         case 'Quiz':
            return <QuizOutlined fontSize='small' />;
      }
   };

   function handleClick() {
      courseItemType === 'Quiz'
         ? navigate(
              studentNavigator.quiz(
                 location.pathname,
                 id,
                 convertToSlug(courseItemTitle),
              ),
           )
         : navigate(
              studentNavigator.task(
                 location.pathname,
                 id,
                 convertToSlug(courseItemTitle),
              ),
           );
   }

   return (
      <div className={styles.accordion_item}>
         <button
            disabled={state === 'Covered'}
            className={styles.title}
            onClick={handleClick}
         >
            {IconType()}
            Task {idx + 1} {courseItemType === 'Quiz' && '(Quiz)'} -{' '}
            {courseItemTitle}
         </button>
      </div>
   );
};

// components
import { CommentArticle, CommentsTitle, CommentsModal } from '../index';
import { AppBtn, AppModal, useAppModal } from '../../../UI';
// project
import { commentsData } from '../../library';
// styles
import styles from './Comments.module.scss';

export const Comments: React.FC = () => {
   const { rating, rates, comments } = commentsData;
   const commentsMap = comments
      .slice(0, 4)
      .map((comment) => <CommentArticle key={comment.id} comment={comment} />);

   const { isModalOpen, handleOpen, handleClose } = useAppModal();

   return (
      <div className={styles.wrapper}>
         <CommentsTitle rating={rating} rates={rates} />
         <div className={styles.comments_container}>{commentsMap}</div>
         <AppBtn className={styles.button} onClick={handleOpen}>
            Show all reviews
         </AppBtn>
         <AppModal
            open={isModalOpen}
            handleClose={handleClose}
            className={styles.modal}
         >
            <CommentsModal handleClose={handleClose} />
         </AppModal>
      </div>
   );
};

import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { BorderedBlockContainer } from '../../../UI';
import { FeedbackReminder } from '../../components/feedback';
import useProtectedContent from '../../hooks/useProtectedContent';
import { ICourse } from '../../interfaces/interfaces';
import { Tabs, CourseTab, ResultsTab } from '../../components';
import { ENDPOINTS } from '../../../../http/studentAPI';
import styles from './Course.module.scss';
import { studentNavigator } from '../../../router/StudentRoutes';

enum Tab {
   Tasks,
   Results,
}

export const Course: FC = () => {
   const { title } = useParams();
   const navigate = useNavigate();
   const courseContent = useProtectedContent(
      ENDPOINTS.course(title!),
   ) as ICourse;

   const [activeTab, setActiveTab] = useState(Tab.Tasks);

   const handleTabClick = (tab: Tab) => {
      setActiveTab(tab);
   };

   if (!courseContent) return <Loader />;
   const { courseTitle, courseDescription, id, topicToWorkCourse } =
      courseContent;

   function navigateCourseFeedback(id: number) {
      return () => navigate(studentNavigator.feedback('course', id));
   }

   return (
      <div className={styles.course}>
         <BorderedBlockContainer className={styles.container}>
            <h3 className={styles.heading}>{courseTitle}</h3>
            <h4 className={styles.subheading}>{courseDescription}</h4>
            <FeedbackReminder navigate={navigateCourseFeedback(id)} />
         </BorderedBlockContainer>
         <Tabs activeTab={activeTab} handleTabClick={handleTabClick} />
         <div className='content'>
            {activeTab === Tab.Tasks && courseContent && (
               <CourseTab courseItems={topicToWorkCourse} />
            )}
            {activeTab === Tab.Results && <ResultsTab id={id} />}
         </div>
      </div>
   );
};

import { FC } from 'react';
//components
import {
    ListTitle,
    ListItem
} from './index';

interface ICourses {
    id: number;
    title: string;
    status?: string;
    lastAction?: string;
}

interface IStudents {
    id: number;
    avatarLogoUrl: string | null;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    skype: string;
    telegram?: string;
    note: string | null;
    courses?: ICourses[];
}

interface IProps {
    students: IStudents[];
    studentSelected: (studentValue: any) => void;
}

export const StudentsList: FC<IProps> = ({ students, studentSelected}) => {
    return (
        <>
            <ListTitle />
            <ListItem students={students} studentSelected={studentSelected}/>
        </>
    )
}

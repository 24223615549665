// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuizMultiChoice_test__questions-container__97p5G {\n  margin: 15px;\n}\n.QuizMultiChoice_test__question-container__Aevrt {\n  margin: 10px 0;\n}\n.QuizMultiChoice_test__question-info__mvBXq {\n  font-size: 16px;\n  font-weight: 600;\n}\n\n.QuizMultiChoice_description__VQeee {\n  margin-top: 40px;\n  font-size: 16px;\n  color: #858f97;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/QuizMultiChoice/QuizMultiChoice.module.scss"],"names":[],"mappings":"AACG;EACG,YAAA;AAAN;AAGG;EACG,cAAA;AADN;AAIG;EACG,eAAA;EACA,gBAAA;AAFN;;AAMA;EACG,gBAAA;EACA,eAAA;EACA,cAAA;AAHH","sourcesContent":[".test {\n   &__questions-container {\n      margin: 15px;\n   }\n\n   &__question-container {\n      margin: 10px 0;\n   }\n\n   &__question-info {\n      font-size: 16px;\n      font-weight: 600;\n   }\n}\n\n.description {\n   margin-top: 40px;\n   font-size: 16px;\n   color: #858f97;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"test__questions-container": "QuizMultiChoice_test__questions-container__97p5G",
	"test__question-container": "QuizMultiChoice_test__question-container__Aevrt",
	"test__question-info": "QuizMultiChoice_test__question-info__mvBXq",
	"description": "QuizMultiChoice_description__VQeee"
};
export default ___CSS_LOADER_EXPORT___;

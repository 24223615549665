import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { InputCheckboxType } from './InputCheckboxType';
import styles from './InputCheckbox.module.scss';

export const InputCheckbox: React.FC<InputCheckboxType> = ({
   id: answerId,
   value,
   name,
   handleChange
}) => {

   return (
      <FormControlLabel
         className={styles.checkbox}
         control={<Checkbox 
            color="secondary"
            size='small'
            sx={{
               color: "#e0e0e0",
               borderRadius: '2px',
               backgroundColor: '#e0e0e0',
               width: '8px',
               marginRight: '16px',
               height: '8px',
            }}
            id={`${answerId}`}
            value={value}
            name={name}
            onChange={handleChange}
         />}
         label={value}
      />
   );
};

// ==============================|| THEME CONFIG  ||============================== //

const config = {
   defaultPath: '/dashboard/default',
   fontFamily: `'Public Sans', sans-serif`,
   i18n: 'en',
   miniDrawer: false,
   container: true,
   mode: 'light',
   presetColor: 'default',
   themeDirection: 'ltr',
};

export default config;

//  =================================== Global Styles

export const global = {
   light_theme: {
      divider: 'rgba(0, 0, 0, 0.12)',
   },
   dark_theme: {
      divider: 'rgba(255, 255, 255, 0.12)',
   },
};

// =================================== Media Query Breakpoints
export const breakpoints = {
   xs: 0,
   sm: '600px',
   md: '900px',
   lg: '1200px',
   xl: '1536px',
};

// =================================== Layout Element

export const outletStyles = {
   mt: '30px',
};

export const drawerWidth = 260;

export const headerStyles = {
   height: '60px',
   svgsSize: '16px',
   btnPadding: '10px',
   zIndex: 33,
};

// =================================== Sign Up Page

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

// ==================================== Course Preview Page

export const heroStyles = {
   heightDesktop: '350px',
};

export const sidebarStyles = {
   sidebarWidth: 340
}

// ===================================== MUI Palette

export const muiPalette = {
   secondaryMain: '#9c27b0',
};

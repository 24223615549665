import { useNavigate } from 'react-router-dom';
import {
   BorderedBlockContainer,
   CollapsibleSectionContainer,
} from '../../../../UI';
import { FeedbackReminder, FeedbackSubmittedCheckmark } from '../../feedback';
import { TasksListItem } from '../../TasksListItem';
import { ICourseTask } from '../../../interfaces/interfaces';
import { v4 } from 'uuid';
import styles from './CourseTab.module.scss';
import { studentNavigator } from '../../../../router/StudentRoutes';

interface Props {
   courseItems: {
      [topic: string]: {
         hasIncompleteCourseItem: true;
         workCourseItems: ICourseTask[];
      };
   };
}

const CourseTab: React.FC<Props> = ({ courseItems }) => {
   const navigate = useNavigate();

   function navigateTopicFeedback(id: number) {
      return () => navigate(studentNavigator.feedback('topic', id));
   }

   return (
      <>
         {courseItems &&
            Object.keys(courseItems).map((item, i) => (
               <CollapsibleSectionContainer
                  key={v4()}
                  className={styles.container}
                  title={item}
                  endAdornment={<FeedbackSubmittedCheckmark />}
                  bottomAdornment={
                     <FeedbackReminder navigate={navigateTopicFeedback(2)} />
                  }
                  open={courseItems[item].hasIncompleteCourseItem}
               >
                  {' '}
                  <BorderedBlockContainer>
                     <h4 className={styles.subheading}>Topic Tasks</h4>
                     {courseItems[item].workCourseItems.map((task, idx) => (
                        <TasksListItem key={v4()} task={task} idx={idx} />
                     ))}
                  </BorderedBlockContainer>
               </CollapsibleSectionContainer>
            ))}
      </>
   );
};

export default CourseTab;

import * as React from 'react';

// material-ui
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// styles
import styles from './SelectLabels.module.scss';

interface Props {
  handleChange: (event: SelectChangeEvent) => void;
  topic: string;
}

export const SelectLabels: React.FC<Props> = ({ handleChange, topic }) => {

  return (
    <div>
      <FormControl 
        sx={{ m: 0, minWidth: 128 }}
      >
        <Select
          value={topic}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          size='small'
          sx={{ color: '#858f97' }}
        >
          <MenuItem value="">
            <p>All Topics</p>
          </MenuItem>
          <MenuItem value={'General'}>General</MenuItem>
          <MenuItem value={'Lessons'}>Lessons</MenuItem>
          <MenuItem value={'Customer Support'}>Customer Support</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
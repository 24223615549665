export type FeedbackQuestionType = 'SINGLE_CHOICE' | 'TEXT';

export interface IFeedbackAnswer {
   id: number;
   description: string;
}

export interface IFeedbackQuestion {
   id: number;
   questionType: FeedbackQuestionType;
   description: string;
   answers?: IFeedbackAnswer[];
}

export interface ITopicFeedback {
   id: number;
   topicId: number;
   topicTitle: string;
   questions: IFeedbackQuestion[];
}

export interface ITaskFeedback {
   id: number;
   taskId: number;
   courseItemTitle: string;
   questions: IFeedbackQuestion[];
}

export interface ICourseFeedback {
   id: number;
   courseId: number;
   courseTitle: string;
   questions: IFeedbackQuestion[];
}

export interface IFeedbackResponseItem {
   questionId: number;
   answer: string;
}

export interface IDiscussionsItem {
   id: number;
   name: string;
   lastName: string;
   timestamp: number;
   topic: string;
   answers: number;
}

export interface ITaskDifficulty {
   id: number;
   time: number;
   level: string;
}

export interface ICourseInfo {
   course: { title: string; lessons: number };
   author: {
      name: string;
      status: string;
      about: string;
   };
}

export const taskFeedback: ITaskFeedback = {
   id: 1,
   taskId: 1,
   courseItemTitle: 'Anagrams',
   questions: [
      {
         id: 1,
         questionType: 'SINGLE_CHOICE',
         description: 'На сколько сложным задание было для тебя?',
         answers: [
            {
               id: 1,
               description: 'Слишком сложно',
            },

            {
               id: 2,
               description: 'Сложно, но я не ищу легких путей',
            },

            {
               id: 3,
               description: 'Не сложно, но и не очень легко',
            },

            {
               id: 4,
               description: 'Легко, а должно быть сложно?',
            },

            {
               id: 5,
               description: 'Слишком легко',
            },
         ],
      },

      {
         id: 2,
         questionType: 'TEXT',
         description: 'Здесь можно оставить комментарий к заданию:',
      },
   ],
};

export const topicFeedback: ITopicFeedback = {
   id: 1,
   topicId: 1,
   topicTitle: 'Java Clean Code',
   questions: [
      {
         id: 1,
         questionType: 'SINGLE_CHOICE',
         description: 'Тебе понравилась тема "Java Clean Code"?',
         answers: [
            {
               id: 1,
               description: 'Очень понравилась',
            },

            {
               id: 2,
               description: 'Скорее понравилась',
            },

            {
               id: 3,
               description: 'И понравилась, и нет',
            },

            {
               id: 4,
               description: 'Скорее не понравилась',
            },

            {
               id: 5,
               description: 'Совсем не понравилась',
            },
         ],
      },

      {
         id: 2,
         questionType: 'SINGLE_CHOICE',
         description:
            'Какова вероятность того, что ты порекомендуешь нашу компанию и курс своим друзьям, знакомым и коллегам? Оцени результат по шкале от 10 до 0:',
         answers: [
            {
               id: 1,
               description:
                  '10. Да! С радостью порекомендую всем друзьям вашу замечательную компанию и курс.',
            },

            {
               id: 2,
               description: '9. Да, порекомендую, если спросят',
            },

            {
               id: 3,
               description:
                  '8. Расскажу о своем опыте, пусть человек сам решает, где учиться.',
            },

            {
               id: 4,
               description: '7. Расскажу, но только близким',
            },

            {
               id: 5,
               description: '6. Скорее да, чем нет',
            },

            {
               id: 6,
               description: '5. Затрудняюсь ответить',
            },

            {
               id: 7,
               description: '4. Скорее нет, чем да',
            },

            {
               id: 8,
               description: '3. Нет, рекомендовать не буду',
            },

            {
               id: 9,
               description: '2. Скорее буду отговаривать, чем рекомендовать',
            },

            {
               id: 10,
               description: '1. Всем расскажу, что не стоит тут учиться!',
            },

            {
               id: 11,
               description: '0. Ни в коем случае не буду рекомендовать!',
            },
         ],
      },

      {
         id: 3,
         questionType: 'TEXT',
         description:
            'Если ты ответил 6-5-4-3-2-1-0, напиши, что именно повлияло на твой ответ?',
      },

      {
         id: 4,
         questionType: 'TEXT',
         description:
            'Если осталось что-то, чем ты хочешь с нами поделиться, а мы не спросили, то напишите в поле ниже:',
      },
   ],
};

export const courseFeedback: ICourseFeedback = {
   id: 1,
   courseId: 1,
   courseTitle: 'Java Spring Development',
   questions: [
      {
         id: 1,
         questionType: 'SINGLE_CHOICE',
         description: 'Тебе понравился курс "Java Spring Development"?',
         answers: [
            {
               id: 1,
               description: 'Очень понравился',
            },

            {
               id: 2,
               description: 'Скорее понравился',
            },

            {
               id: 3,
               description: 'И понравился, и нет',
            },

            {
               id: 4,
               description: 'Скорее не понравился',
            },

            {
               id: 5,
               description: 'Совсем не понравился',
            },
         ],
      },

      {
         id: 2,
         questionType: 'SINGLE_CHOICE',
         description:
            'Какова вероятность того, что ты порекомендуешь нашу компанию и курс своим друзьям, знакомым и коллегам? Оцени результат по шкале от 10 до 0:',
         answers: [
            {
               id: 1,
               description:
                  '10. Да! С радостью порекомендую всем друзьям вашу замечательную компанию и курс.',
            },

            {
               id: 2,
               description: '9. Да, порекомендую, если спросят',
            },

            {
               id: 3,
               description:
                  '8. Расскажу о своем опыте, пусть человек сам решает, где учиться.',
            },

            {
               id: 4,
               description: '7. Расскажу, но только близким',
            },

            {
               id: 5,
               description: '6. Скорее да, чем нет',
            },

            {
               id: 6,
               description: '5. Затрудняюсь ответить',
            },

            {
               id: 7,
               description: '4. Скорее нет, чем да',
            },

            {
               id: 8,
               description: '3. Нет, рекомендовать не буду',
            },

            {
               id: 9,
               description: '2. Скорее буду отговаривать, чем рекомендовать',
            },

            {
               id: 10,
               description: '1. Всем расскажу, что не стоит тут учиться!',
            },

            {
               id: 11,
               description: '0. Ни в коем случае не буду рекомендовать!',
            },
         ],
      },

      {
         id: 3,
         questionType: 'TEXT',
         description:
            'Если ты ответил 6-5-4-3-2-1-0, напиши, что именно повлияло на твой ответ?',
      },

      {
         id: 4,
         questionType: 'TEXT',
         description:
            'Если осталось что-то, чем ты хочешь с нами поделиться, а мы не спросили, то напишите в поле ниже:',
      },
   ],
};

export const DiscussionsItem: IDiscussionsItem[] = [
   {
      id: 1,
      name: 'Laza',
      lastName: 'Bogdan',
      timestamp: 2,
      topic: 'Using Angular HttpClientModule instead of HttpModule',
      answers: 1,
   },
   {
      id: 2,
      name: 'Adam',
      lastName: 'Curtis',
      timestamp: 3,
      topic: 'Why am I getting an error when trying to install angular/http@2.4.2',
      answers: 1,
   },
];

export const taskDifficulty: ITaskDifficulty = {
   id: 1,
   time: 166,
   level: 'Beginner',
};

export const courseInfo: ICourseInfo = {
   course: { title: 'Angular Fundamentals', lessons: 16 },
   author: {
      name: 'Eddie Bryan',
      status: 'instructor',
      about: 'Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.',
   },
};
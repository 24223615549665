// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CellTask_link__RhWvX {\n  color: #2f3237;\n  font-weight: 500;\n}\n.CellTask_link__RhWvX:hover {\n  text-decoration: underline;\n}\n\n.CellTask_task__kPIsm {\n  min-width: 180px;\n  max-width: 250px;\n}\n\n.CellTask_textOverflow__Ke5Mu {\n  padding-bottom: 2px;\n  display: block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/AnswerList/commonCells/CellTask/CellTask.module.scss"],"names":[],"mappings":"AAAA;EACG,cAAA;EACA,gBAAA;AACH;AACG;EACG,0BAAA;AACN;;AAGA;EACG,gBAAA;EACA,gBAAA;AAAH;;AAGA;EACG,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,uBAAA;AAAH","sourcesContent":[".link {\n   color: #2f3237;\n   font-weight: 500;\n\n   &:hover {\n      text-decoration: underline;\n   }\n}\n\n.task {\n   min-width: 180px;\n   max-width: 250px;\n}\n\n.textOverflow {\n   padding-bottom: 2px;\n   display: block;\n   overflow: hidden;\n   text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "CellTask_link__RhWvX",
	"task": "CellTask_task__kPIsm",
	"textOverflow": "CellTask_textOverflow__Ke5Mu"
};
export default ___CSS_LOADER_EXPORT___;

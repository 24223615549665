// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TasksAndResultSection_container__50WP\\+ {\n  width: 100%;\n  background-color: #f5f7fa;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-bottom: 2px solid #e9edf2;\n}\n\n.TasksAndResultSection_content__VDK6H {\n  position: relative;\n  max-width: 1400px;\n  padding: 0 20px;\n  display: flex;\n  flex-direction: column;\n}\n@media screen and (min-width: 900px) {\n  .TasksAndResultSection_content__VDK6H {\n    flex-direction: row;\n    padding: 0 24px;\n    gap: 20px;\n  }\n}\n\n.TasksAndResultSection_left_column__SDsTx,\n.TasksAndResultSection_right_column__QyXPV {\n  border-left: 2px solid #e9edf2;\n}\n\n.TasksAndResultSection_left_column__SDsTx {\n  flex: 75% 1;\n}\n\n.TasksAndResultSection_right_column__QyXPV {\n  flex: 25% 1;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/studentCourse/TasksAndResultSection/TasksAndResultSection.module.scss"],"names":[],"mappings":"AAEA;EACG,WAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;AADH;;AAIA;EACG,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;AADH;AAGG;EAPH;IAQM,mBAAA;IACA,eAAA;IACA,SAAA;EAAJ;AACF;;AAGA;;EAEG,8BAAA;AAAH;;AAGA;EACG,WAAA;AAAH;;AAEA;EACG,WAAA;AACH","sourcesContent":["@use '../../../../../theme/config' as v;\n\n.container {\n   width: 100%;\n   background-color: #f5f7fa;\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   border-bottom: 2px solid #e9edf2;\n}\n\n.content {\n   position: relative;\n   max-width: 1400px;\n   padding: 0 20px;\n   display: flex;\n   flex-direction: column;\n\n   @media screen and (min-width: v.$md) {\n      flex-direction: row;\n      padding: 0 v.$px-large;\n      gap: 20px;\n   }\n}\n\n.left_column,\n.right_column {\n   border-left: 2px solid #e9edf2;\n}\n\n.left_column {\n   flex: 75%;\n}\n.right_column {\n   flex: 25%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TasksAndResultSection_container__50WP+",
	"content": "TasksAndResultSection_content__VDK6H",
	"left_column": "TasksAndResultSection_left_column__SDsTx",
	"right_column": "TasksAndResultSection_right_column__QyXPV"
};
export default ___CSS_LOADER_EXPORT___;

export const commentsData ={
   rating: 4.6,
   rates: 2133,
   comments: [
      {
         id: 1,
         name: 'ShihPingChao',
         rating: 4,
         date: 'a month ago',
         text: 'Content is good and the pace is niceBut the sound recoding quality is not that nicelectures about v-slot is a little messy and spread around the sectionshope there will be a neat sequence to introduce the feature'
      },
      {
         id: 2,
         name: 'Joao Pedro J.',
         rating: 5,
         date: 'a month ago',
         text: 'Super curso! Aprendi muito com este curso, e penso em repetir para fixar melhor o aprendizado. Valeu'
      },
      {
         id: 3,
         name: 'Sredhar T.',
         rating: 5,
         date: 'a month ago',
         text: 'Yes, It was perfect. I learned so much from this course'
      },
      {
         id: 4,
         name: 'Alan Y.',
         rating: 4,
         date: '3 months ago',
         text: 'The course was well structured and organized. But sometimes it is better to review what has been done in previous sessions and use PowerPoint to briefly introduce what is going to be done.'
      },
      {
         id: 5,
         name: 'Randy H.',
         rating: 4,
         date: '2 weeks ago',
         text: 'Looks like the composition API could be an entire course on its own instead of a short introdution.'
      }
   ]
} 
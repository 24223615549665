//project imports
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
//components
import { PageContainer } from '../../../../../UI';
import { SlickSlider } from '../../../../../UI';
import { studentsAreViewing } from '../../../../../UI/SlickSlider/Settings';
import { CourseCardWidget, CourseCardWidgetMobile } from '../../../../../UI';
//styles
import styles from './StudentsAreViewing.module.scss';
import { useRootStore } from '../../../../../../hooks';
import { Spinner } from '../../../../../UI/Spinner';

export const StudentsAreViewing = () => {
   const { windowWidth } = useWindowDimensions();
   const { guestStore } = useRootStore();
   const filteredCourses = guestStore.getCoursesByCategory('Front End');

   return (
      <div className={styles.container}>
         <PageContainer>
            <h2 className={styles.title}>Students are viewing</h2>
            {filteredCourses?.length === 0 ? (
               <Spinner />
            ) : (
               <SlickSlider settings={studentsAreViewing}>
                  {windowWidth > 600
                     ? filteredCourses.map((course) => (
                          <CourseCardWidget
                             courseData={course}
                             key={course.id}
                          />
                       ))
                     : filteredCourses.map((course) => (
                          <CourseCardWidgetMobile
                             courseData={course}
                             key={course.id}
                          />
                       ))}
               </SlickSlider>
            )}
         </PageContainer>
      </div>
   );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AllMentorsBox_wrapper__My-xw {\n  padding: 20px 0;\n  border-bottom: 1px solid rgb(217, 217, 217);\n}\n\n.AllMentorsBox_courses__-MpIQ {\n  display: flex;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.AllMentorsBox_course__EulAM {\n  display: flex;\n  align-items: center;\n  margin-right: 10px;\n  padding-right: 10px;\n  border-right: 1px solid rgb(217, 217, 217);\n}", "",{"version":3,"sources":["webpack://./src/modules/spectator/components/AllMentorsBox/AllMentorsBox.module.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;EACA,2CAAA;AACH;;AACA;EACG,aAAA;EACA,mBAAA;EACA,gBAAA;AAEH;;AAAA;EACG,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,0CAAA;AAGH","sourcesContent":[".wrapper {\n   padding: 20px 0;\n   border-bottom: 1px solid rgb(217, 217, 217);\n}\n.courses {\n   display: flex;\n   align-items: center;\n   margin-top: 20px;\n}\n.course {\n   display: flex;\n   align-items: center;\n   margin-right: 10px;\n   padding-right: 10px;\n   border-right: 1px solid rgb(217, 217, 217);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AllMentorsBox_wrapper__My-xw",
	"courses": "AllMentorsBox_courses__-MpIQ",
	"course": "AllMentorsBox_course__EulAM"
};
export default ___CSS_LOADER_EXPORT___;

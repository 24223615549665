import { 
   PlayCircleOutlined,
   InsertDriveFileOutlined,
   SaveAltOutlined,
   ArticleOutlined,
   AccessTimeOutlined,
   PhoneIphoneOutlined,
   EmojiEventsOutlined 
 } from '@mui/icons-material';


export const castFeature = (
   type: string,
   value: string[] | string | number | boolean | null | undefined,
) => {
   switch (type) {
      case 'video':
         return `${value} hours on-demand video`;
      case 'articles':
         return `${value} articles`;
      case 'downloadable':
         return `${value} downloadable resources`;
      case 'exercise':
         return `${value} exercises`;
      case 'duration':
         return `${value} access`;
      case 'devices':
         return `Access on ${Array.isArray(value) && value.join(' & ')}`;
      case 'certificate':
         return `Certificate of completion`;
   }
};

export const icons = {
   video: PlayCircleOutlined,
   articles: InsertDriveFileOutlined,
   downloadable: SaveAltOutlined,
   exercise: ArticleOutlined,
   duration: AccessTimeOutlined,
   devices: PhoneIphoneOutlined,
   certificate: EmojiEventsOutlined,
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MentorCourse_img__HacFy {\n  width: 70px;\n  height: 70px;\n  margin-right: 20px;\n}", "",{"version":3,"sources":["webpack://./src/modules/manager/components/MentorCourse/MentorCourse.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AACJ","sourcesContent":[".img {\n    width: 70px;\n    height: 70px;\n    margin-right: 20px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "MentorCourse_img__HacFy"
};
export default ___CSS_LOADER_EXPORT___;

// Material
import Rating from '@mui/material/Rating/Rating';
import { StarBorder } from '@mui/icons-material';
// Project
import { useLocalData } from '../../library';
// Styles
import styles from './Header.module.scss';

export const Header: React.FC = () => {
   const { headerData } = useLocalData();
   const { title, rating, ratesNumber, students } = headerData;

   return (
      <div className={styles.wrapper}>
         <h3 className={styles.title}>{title}</h3>
         <div className={styles.info}>
            <span className={styles.rating}>
               {rating}
               <Rating
                  emptyIcon={<StarBorder className={styles.star} />}
                  name='read-only'
                  value={rating / 5}
                  readOnly
                  size='small'
                  max={1}
                  precision={0.1}
               />
            </span>
            <span className={styles.ratesNumber}>
               ({ratesNumber.toLocaleString('en-US')} ratings)
            </span>
            <span className={styles.students}>
               {students.toLocaleString('en-US')} students
            </span>
         </div>
      </div>
   );
};

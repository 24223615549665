// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestHome_container__HTVHb {\n  margin-top: 45px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  overflow: hidden;\n}\n@media screen and (max-width: 600px) {\n  .GuestHome_container__HTVHb {\n    margin-top: 60px;\n  }\n}\n@media screen and (max-width: 440px) {\n  .GuestHome_container__HTVHb {\n    margin-top: 40px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/guest/pages/GuestHome/GuestHome.module.scss"],"names":[],"mappings":"AAAA;EACG,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AACH;AACG;EAPH;IAQM,gBAAA;EAEJ;AACF;AAAG;EAXH;IAYM,gBAAA;EAGJ;AACF","sourcesContent":[".container {\n   margin-top: 45px;\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   overflow: hidden;\n\n   @media screen and (max-width: 600px) {\n      margin-top: 60px;\n   }\n\n   @media screen and (max-width: 440px) {\n      margin-top: 40px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GuestHome_container__HTVHb"
};
export default ___CSS_LOADER_EXPORT___;

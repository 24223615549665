import React from 'react';
import styles from './MentorCard.module.scss';
import { MentorData } from '../CourseMentors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Rating } from '@mui/material';
import { StarBorder } from '@mui/icons-material';

interface Props {
   data: MentorData;
}

export const MentorCard: React.FC<Props> = ({ data }) => {
   return (
      <div className={styles.card}>
         <div className={styles.title}>
            <h3>{data.name}</h3>
            <button className={styles.follow}> Following </button>
         </div>

         <div className={styles.horizontalLine}></div>

         <div className={styles.block}>
            <div className={styles.imageContainer}>
               {data.img ? (
                  <img className={styles.image_avatar} src={data.img} />
               ) : (
                  <AccountCircleIcon
                     sx={{ fontSize: 100 }}
                     style={{ color: 'blue' }}
                  />
               )}
            </div>

            <div className={styles.gradeBlock}>
               <Rating
                  emptyIcon={
                     <StarBorder
                        className={styles.star}
                        style={{ fontSize: '16px' }}
                     />
                  }
                  name='read-only'
                  value={data.grade}
                  readOnly
                  sx={{ fontSize: '16px' }}
                  max={5}
                  precision={0.1}
               />
               <p>{data.grade}/5</p>
            </div>

            <div className={styles.aboutYourselfBlock}>
               <p className={styles.aboutYourself}>{data.aboutYourself}</p>
            </div>
            <div className={styles.jobTitle}>{data.jobTitle}</div>
         </div>

         <div className={styles.horizontalLine}></div>
         <div className={styles.courseContainer}>
            {data.courses.map((course, index) => (
               <div key={index}>
                  <img src={course.img} className={styles.course_img} />
               </div>
            ))}
         </div>
      </div>
   );
};

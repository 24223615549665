
// material-ui
import { ButtonBase, Stack, Typography } from '@mui/material';
import { PersonOutlined } from '@mui/icons-material';
import UserService from '../../../../../utils/userService';

export const SignInButton = () => {
   const handleClick = async () => {
      UserService.doLogin()
   };

   return (
      <ButtonBase
         sx={{
            minWidth: 'fit-content',
            p: 0.25,
            bgcolor: 'transparent',
            borderRadius: 1,
            '&:hover': { bgcolor: 'grey.100' },
            transition: 'all 0.2s ease-out',
         }}
         onClick={handleClick}
      >
         <Stack direction='row' spacing={1} alignItems='center' sx={{ p: 0.5 }}>
            <PersonOutlined />
            <Typography variant='subtitle1'>Sign In</Typography>
         </Stack>
      </ButtonBase>
   );
};
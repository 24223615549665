import React, { useState, useEffect } from 'react';

// project imports
import { $host } from '../../../../http';
import { useRootStore } from '../../../../hooks';

export interface Subcategories {
   description: string;
   id: number;
   name: string;
}

export interface Categories {
   description: string;
   id: number;
   name: string;
   subcategories: Subcategories[];
}

export interface Courses {
   countVoters: number;
   createdBy: string;
   logo: string;
   price: number;
   rating: number;
   slug: string;
   title: string;
   subcategory: string;
}

export interface AllCategories {
   categories: Categories[] | [];
}

export interface SubcategoriesData {
   courses: Courses[] | [];
   currentPage: number;
   totalElements: number;
   totalPages: number;
}

export const getAllCategories = async () => {
   const { data } = await $host.get('/api/categories/with-subcategories');
   return data as AllCategories;
};

export const getSubcategories = async (id: number) => {
   const { data } = await $host.get(
      `/api/categories/subcategories/${id}/courses`,
   );
   return data as SubcategoriesData;
};

export function useCategoriesCascader() {
   const [categories, setCategories] = useState<Categories[]>([]);
   const [courses, setCourses] = useState<Courses[]>([]);

   const [isOpen, setIsOpen] = useState<boolean>(false);

   const { layoutStore } = useRootStore();
   const categoriesRefresh = layoutStore.getCategoriesRefresh();

   const coursesRefresh = layoutStore.getCoursesRefresh();
   const coursesArr = layoutStore.getCourses();
   const idCourse = layoutStore.getIDCourses();

   const handleClick = (
      e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
   ) => {
      setIsOpen(false);
   };

   const handleClickAway = (e: MouseEvent | TouchEvent) => {
      setIsOpen(false);
   };

   const setOpen = () => {
      setIsOpen(true);
   };

   useEffect(() => {
      layoutStore.setIsLoadingCategories(true);
      getAllCategories().then(({ categories }) => {
         setCategories(categories);
         layoutStore.setCategories(categories);
         layoutStore.setIsLoadingCategories(false);
      });
   }, [categoriesRefresh]);

   useEffect(() => {
      if (coursesArr[idCourse]) {
         setCourses(coursesArr[idCourse]);
      } else {
         getSubcategories(idCourse).then(({ courses }) => {
            setCourses(courses);

            coursesArr.splice(idCourse, 1, courses);
            layoutStore.setCourses(coursesArr);
         });
      }
   }, [coursesRefresh]);

   return {
      categories,
      courses,
      setCourses,
      isOpen,
      handleClickAway,
      handleClick,
      setOpen,
   };
}

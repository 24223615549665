import 'simplebar/dist/simplebar.min.css';
import PropTypes from 'prop-types';

// material-ui
import { alpha, styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// third-party
import SimpleBar from 'simplebar-react';
import { BrowserView, MobileView } from 'react-device-detect';

// root style
const RootStyle = styled(BrowserView)({
   flexGrow: 1,
   height: '100%',
   overflow: 'hidden',
});

// scroll bar wrapper
const SimpleBarStyle = styled(SimpleBar)(({ theme }) => ({
   maxHeight: '100%',
   '& .simplebar-scrollbar': {
      '&:before': {
         backgroundColor: alpha(theme.palette.grey[500], 0.48)
      },
      '&.simplebar-visible:before': {
         opacity: `1 !important`,
      },
   },
   '& .simplebar-track.simplebar-vertical': {
      width: `10 !important`,
   },
   '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
      height: `6 !important`,
   },
   '& .simplebar-mask': {
      zIndex: 'inherit !important',
   },
}));

// ==============================|| SIMPLE SCROLL BAR  ||============================== //

interface SimpleBarScrollProps extends BoxProps {
   sx?: BoxProps['sx'];
   timeout?: number;
   clickOnTrack?: boolean;
}

export const SimpleBarScroll = ({
   children,
   sx,
   timeout = 500,
   clickOnTrack = false,
}: // ...other
SimpleBarScrollProps) => {
   return (
      <>
         <RootStyle>
            <SimpleBarStyle
               timeout={timeout}
               clickOnTrack={clickOnTrack}
               sx={sx}
               // {...other}
            >
               {children}
            </SimpleBarStyle>
         </RootStyle>
         <MobileView>
            <Box
               sx={{ overflowX: 'auto', ...sx }}
               // {...other}
            >
               {children}
            </Box>
         </MobileView>
      </>
   );
}

// SimpleBarScroll.propTypes = {
//    children: PropTypes.node,
//    sx: PropTypes.object,
// };

// material-ui
import { Button, CardMedia, Link, Stack, Typography } from '@mui/material';

// project components
import { MainCard, AnimateButton } from '../../../common';

// assets
// import avatar from 'assets/images/users/avatar-group.png';
import avatar from '../../../../../../assets/images/users/avatar-group.png';

// ==============================|| DRAWER CONTENT - NAVIGATION CARD ||============================== //

export const NavCard = () => (
   <MainCard sx={{ bgcolor: 'grey.50', m: 3 }}>
      <Stack alignItems='center' spacing={2.5}>
         <CardMedia component='img' image={avatar} sx={{ width: 112 }} />
         <Stack alignItems='center'>
            <Typography variant='h5'>Training Service Pro</Typography>
            <Typography variant='h6' color='secondary'>
               Checkout pro features
            </Typography>
         </Stack>
         <AnimateButton>
            <Button
               component={Link}
               target='_blank'
               href='#'
               variant='contained'
               color='success'
               size='small'
            >
               Pro
            </Button>
         </AnimateButton>
      </Stack>
   </MainCard>
);
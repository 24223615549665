// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_logo_xs__pGQ17 {\n  cursor: pointer;\n  max-height: 40px;\n  margin-right: 10px;\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/Layout/ManagerLayout/Header/index.module.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;EACA,gBAAA;EACA,kBAAA;AACH","sourcesContent":[".logo_xs {\n   cursor: pointer;\n   max-height: 40px;\n   margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo_xs": "Header_logo_xs__pGQ17"
};
export default ___CSS_LOADER_EXPORT___;

// material-ui
import Rating from '@mui/material/Rating/Rating';
import { StarBorder } from '@mui/icons-material';
// project components
import { BorderedBlockContainer } from '../../../UI';
// mock data
import { courses } from '../../mockData';
// styles
import styles from './Courses.module.scss';

interface ICourse {
   id: number;
   courseTitle: string;
   mentors: string[];
   rating: number;
   ratingQuantity: number;
   hours: number;
   lectures: number;
   price: number;
}

export const Courses: React.FC = () => {
   return (
      <BorderedBlockContainer marginBottom='20px'>
         <div className={styles.container}>
            {courses.map(
               ({
                  id,
                  courseTitle,
                  mentors,
                  rating,
                  ratingQuantity,
                  hours,
                  lectures,
                  price,
               }: ICourse) => (
                  <div key={id} className={styles.item}>
                     <div className={styles.imageContainer}>
                        <img
                           className={styles.image}
                           src={require(`../../../../assets/images/myCoursesMentor/${id}.jpg`)}
                           alt={courseTitle}
                        />
                     </div>

                     <h3 className={styles.title}>{courseTitle}</h3>
                     <p className={styles.mentors}>{mentors.join(', ')}</p>

                     <div className={styles.ratingContainer}>
                        <span className={styles.rating}>{rating}</span>
                        <Rating
                           emptyIcon={<StarBorder className={styles.star} />}
                           name='read-only'
                           value={rating}
                           readOnly
                           size='small'
                           max={5}
                           precision={0.5}
                        />

                        <span className={styles.countVoters}>
                           ({ratingQuantity})
                        </span>
                     </div>
                     <ul className={styles.course_info}>
                        <li>{hours} total hours</li>
                        <li>{lectures} lectures</li>
                        <li>All levels</li>
                     </ul>
                     <h3 className={styles.price}>${price}</h3>
                  </div>
               ),
            )}
         </div>
      </BorderedBlockContainer>
   );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Main_wrapper__za2FS {\n  margin-top: 24px;\n  padding: 0 16px;\n}\n@media screen and (min-width: 600px) {\n  .Main_wrapper__za2FS {\n    padding: 0 24px;\n  }\n}\n\n.Main_container__vY90Z {\n  min-height: 150vh;\n  position: relative;\n  max-width: 1000px;\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n}\n\n.Main_content__Uzo-W {\n  width: 100%;\n}\n.Main_content__Uzo-W > * {\n  margin-bottom: 36px;\n}", "",{"version":3,"sources":["webpack://./src/modules/coursePreview/components/Main/Main.module.scss"],"names":[],"mappings":"AAEA;EACG,gBAAA;EACA,eAAA;AADH;AAGG;EAJH;IAKM,eAAA;EAAJ;AACF;;AAGA;EACG,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,cAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;AAAH;;AAGA;EACG,WAAA;AAAH;AAEG;EACG,mBAAA;AAAN","sourcesContent":["@use '../../../../theme/config' as v;\n\n.wrapper {\n   margin-top: 24px;\n   padding: 0 16px;\n\n   @media screen and (min-width: v.$sm) {\n      padding: 0 24px;\n   }\n}\n\n.container {\n   min-height: 150vh;\n   position: relative;\n   max-width: 1000px;\n   width: 100%;\n   margin: 0 auto;\n   display: flex;\n   justify-content: space-between;\n   gap: 20px;\n}\n\n.content {\n   width: 100%;\n\n   & > * {\n      margin-bottom: 36px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Main_wrapper__za2FS",
	"container": "Main_container__vY90Z",
	"content": "Main_content__Uzo-W"
};
export default ___CSS_LOADER_EXPORT___;

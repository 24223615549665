import { forwardRef, Ref } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Fade, Box, Grow, GrowProps, FadeProps } from '@mui/material';

type Position =
   | 'top-right'
   | 'top'
   | 'bottom-left'
   | 'bottom-right'
   | 'bottom'
   | 'top-left';
type Type = 'grow' | 'fade';

interface TransitionsProps {
   children: React.ReactNode;
   position?: Position;
   type: Type;
   ref?: Ref<HTMLDivElement>;
   timeout?: GrowProps['timeout'] | FadeProps['timeout'];
}

// ==============================|| TRANSITIONS ||============================== //

export const Transitions = forwardRef<HTMLDivElement, TransitionsProps>(
   ({ children, position = 'top-left', type = 'grow', ...others }, ref) => {
      let positionSX = {
         transformOrigin: '0 0 0',
      };

      switch (position) {
         case 'top-right':
         case 'top':
         case 'bottom-left':
         case 'bottom-right':
         case 'bottom':
         case 'top-left':
         default:
            positionSX = {
               transformOrigin: '0 0 0',
            };
            break;
      }

      return (
         <Box ref={ref}>
            {type === 'grow' && (
               <Grow {...others}>
                  <Box sx={positionSX}>{children}</Box>
               </Grow>
            )}
            {type === 'fade' && (
               <Fade
                  {...others}
                  timeout={{
                     appear: 0,
                     enter: 300,
                     exit: 150,
                  }}
               >
                  <Box sx={positionSX}>{children}</Box>
               </Fade>
            )}
         </Box>
      );
   },
);

// Transitions.propTypes = {
//     children: PropTypes.node,
//     type: PropTypes.oneOf(['grow', 'fade', 'collapse', 'slide', 'zoom']),
//     position: PropTypes.oneOf(['top-left', 'top-right', 'top', 'bottom-left', 'bottom-right', 'bottom'])
// };

// Transitions.defaultProps = {
//     type: 'grow',
//     position: 'top-left'
// };
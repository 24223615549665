// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AnswerAccepted_result__3gs6R {\n  display: flex;\n  width: 100%;\n  white-space: nowrap;\n}\n\n.AnswerAccepted_result_info__AKVpP {\n  color: #198754;\n  font-weight: 600;\n  padding-left: 5px;\n  display: inline-block;\n  width: 100%;\n  text-align: end;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/QuizResults/QuizResultsItems/AnswerAccepted/AnswerAccepted.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,WAAA;EACA,eAAA;AACF","sourcesContent":[".result {\n  display: flex;\n  width: 100%;\n  white-space: nowrap;\n}\n\n.result_info {\n  color: #198754;\n  font-weight: 600;\n  padding-left: 5px;\n  display: inline-block;\n  width: 100%;\n  text-align: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"result": "AnswerAccepted_result__3gs6R",
	"result_info": "AnswerAccepted_result_info__AKVpP"
};
export default ___CSS_LOADER_EXPORT___;

import { FormEvent, useState } from 'react';
import { BorderedBlockContainer } from '../../../UI';
import {
   FeedbackForm,
   FeedbackSubmittedCheckmark,
   SubmissionSummary,
} from '../../components/feedback';
import { IFeedbackResponseItem, taskFeedback } from '../../mockData';
import styles from '../FeedbackTopic/FeedbackTopic.module.scss';

const FeedbackTask = () => {
   const { courseItemTitle, questions } = taskFeedback;

   const [studentAnswers, setStudentAnswers] = useState<
      IFeedbackResponseItem[]
   >([]);

   const submitFeedback = (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formData = new FormData(event.target as HTMLFormElement);
      const data = Object.fromEntries(formData.entries());
      const answers: IFeedbackResponseItem[] = [];

      Object.keys(data).forEach((key) => {
         if (data[key]) {
            answers.push({
               questionId: Number(key),
               answer: data[key] as string,
            });
         }
      });

      setStudentAnswers(answers);
   };

   return (
      <div>
         <BorderedBlockContainer>
            <div className={styles.heading}>
               <h1 className={styles.title}>
                  Feedback on the topic {courseItemTitle}
               </h1>
               {studentAnswers.length ? <FeedbackSubmittedCheckmark /> : null}
            </div>
         </BorderedBlockContainer>

         <BorderedBlockContainer>
            {studentAnswers.length ? (
               <SubmissionSummary answers={studentAnswers} />
            ) : (
               <FeedbackForm questions={questions} onSubmit={submitFeedback} />
            )}
         </BorderedBlockContainer>
      </div>
   );
};

export default FeedbackTask;

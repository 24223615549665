// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Authors_title__FhcAg {\n  font-size: 24px;\n  font-weight: 700;\n  margin-bottom: 18px;\n}", "",{"version":3,"sources":["webpack://./src/modules/coursePreview/components/Authors/Authors.module.scss","webpack://./src/theme/_config.scss"],"names":[],"mappings":"AAEA;ECmCG,eAAA;EACA,gBAAA;EACA,mBAAA;ADnCH","sourcesContent":["@use '../../../../theme/config' as v;\n\n.title {\n   @include v.title;\n}","// ============================ Palette\n$dark-bcg: #1c1d1f;\n$golden: #faaf00;\n// --------- MUI Palette\n$primary-main: #1976d2;\n$primary-dark: #1565c0;\n$secondary-main: #9c27b0;\n$secondary-dark: #7b1fa2;\n$success-main: #4caf50;\n$success-dark: #2e7d32;\n$error-light: #ef5350;\n$error-main: #d32f2f;\n$warning-light: #ff9800;\n$divider-light: rgba(0, 0, 0, 0.12);\n$divider-dark: rgba(255, 255, 255, 0.12);\n\n// ============================ Media Queries\n$xs: 0;\n$mob: 450px;\n$sm: 600px;\n$sm_md: 760px;\n$md: 900px;\n$lg: 1200px;\n$xl: 1536px;\n\n//  =========================== Layout Element\n$px-small: 16px;\n$px-large: 24px;\n\n$header-height: 60px;\n\n// ============================ Course Preview Page\n$max-width-lg: 1000px;\n$hero-height-desktop: 350px;\n$sidebar-width: 340px;\n\n@mixin title {\n   font-size: 24px;\n   font-weight: 700;\n   margin-bottom: 18px;\n}\n\n@mixin ellipsis($lines) {\n   display: -webkit-box;\n   -webkit-line-clamp: $lines;\n   -webkit-box-orient: vertical;\n   overflow: hidden;\n   text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Authors_title__FhcAg"
};
export default ___CSS_LOADER_EXPORT___;

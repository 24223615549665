// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tabs_container__pgoyk {\n  max-width: -moz-fit-content;\n  max-width: fit-content;\n  display: flex;\n  flex-direction: column;\n  margin: 0 auto;\n}\n\n.Tabs_tabs__buiK2 {\n  display: flex;\n}\n\n.Tabs_tab__eQqJX {\n  font-size: 24px;\n  font-weight: bold;\n  padding: 10px;\n  padding-bottom: 0;\n  cursor: pointer;\n  transition: all 0.2s ease-in-out;\n}\n\n.Tabs_tab__eQqJX.Tabs_active__SpqzI {\n  color: blue;\n}\n\n.Tabs_underline_container__buwBP {\n  width: 100%;\n  padding: 0 10px;\n}\n\n.Tabs_underline__i16Ok {\n  height: 3px;\n  background-color: blue;\n  margin-top: 5px;\n  transition: all 0.2s ease-in-out;\n}\n\n.Tabs_tab__eQqJX.Tabs_active__SpqzI ~ .Tabs_underline__i16Ok {\n  transform: translateX(100px);\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/course/Tabs/Tabs.module.scss"],"names":[],"mappings":"AAAA;EACG,2BAAA;EAAA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AACH;;AAEA;EACG,aAAA;AACH;;AAEA;EACG,eAAA;EACA,iBAAA;EACA,aAAA;EACA,iBAAA;EACA,eAAA;EACA,gCAAA;AACH;;AAEA;EACG,WAAA;AACH;;AAEA;EACG,WAAA;EACA,eAAA;AACH;;AAEA;EACG,WAAA;EACA,sBAAA;EACA,eAAA;EACA,gCAAA;AACH;;AAEA;EACG,4BAAA;AACH","sourcesContent":[".container {\n   max-width: fit-content;\n   display: flex;\n   flex-direction: column;\n   margin: 0 auto;\n}\n\n.tabs {\n   display: flex;\n}\n\n.tab {\n   font-size: 24px;\n   font-weight: bold;\n   padding: 10px;\n   padding-bottom: 0;\n   cursor: pointer;\n   transition: all 0.2s ease-in-out;\n}\n\n.tab.active {\n   color: blue;\n}\n\n.underline_container {\n   width: 100%;\n   padding: 0 10px;\n}\n\n.underline {\n   height: 3px;\n   background-color: blue;\n   margin-top: 5px;\n   transition: all 0.2s ease-in-out;\n}\n\n.tab.active ~ .underline {\n   transform: translateX(100px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Tabs_container__pgoyk",
	"tabs": "Tabs_tabs__buiK2",
	"tab": "Tabs_tab__eQqJX",
	"active": "Tabs_active__SpqzI",
	"underline_container": "Tabs_underline_container__buwBP",
	"underline": "Tabs_underline__i16Ok"
};
export default ___CSS_LOADER_EXPORT___;

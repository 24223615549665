// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuizTextForMentor_textarea__1W3mR {\n  resize: none;\n  min-width: 100%;\n  border: 1.5px solid #ced4da;\n  padding: 10px 14px;\n  border-radius: 5px;\n  font-size: 18px;\n}\n\n.QuizTextForMentor_description__5pY8o {\n  margin-top: 40px;\n  font-size: 16px;\n  color: #858f97;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/QuizTextForMentor/QuizTextForMentor.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,2BAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AACF","sourcesContent":[".textarea {\n  resize: none;\n  min-width: 100%;\n  border: 1.5px solid #ced4da;\n  padding: 10px 14px;\n  border-radius: 5px;\n  font-size: 18px;\n}\n\n.description {\n  margin-top: 40px;\n  font-size: 16px;\n  color: #858f97;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "QuizTextForMentor_textarea__1W3mR",
	"description": "QuizTextForMentor_description__5pY8o"
};
export default ___CSS_LOADER_EXPORT___;

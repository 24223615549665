import React from 'react';

// types
import { BasicInputType } from './BasicInputType';

// styles
import './BasicInput.scss';

export const BasicInput: React.FC<BasicInputType> = ({
   value,
   setValue,
   onChange,
   placeholder,
   name,
   margin,
   additionalClass,
   type = 'text',
   required,
   style,
}) => {
   return (
      <input
         className={`basic-input ${additionalClass}`}
         style={{ margin, ...style }}
         type={type}
         name={name}
         required={required}
         placeholder={placeholder}
         value={value}
         onChange={
            onChange
               ? onChange
               : (e) => (setValue ? setValue(e.target.value) : null)
         }
      />
   );
};

//components
import { MoreItem } from './MoreItem/MoreItem';
import { AppBtn, AppBtnTypes } from '../../../../UI';
import { ENDPOINTS } from '../../../../../http/studentAPI';
import useProtectedContent from '../../../hooks/useProtectedContent';
import { ICourse } from '../../../interfaces/interfaces';
import { Loader } from '../../Loader';

import { useParams } from 'react-router-dom';
//styles
import styles from './AboutTheAuthor.module.scss';

export const AboutTheAuthor = () => {
   const { title } = useParams();
   const courseContent = useProtectedContent(
      ENDPOINTS.course(title!),
   ) as ICourse;

   if (!courseContent) {
      return <Loader />;
   }
   return (
      <div className={styles.container}>
         <div className={styles.content}>
            <div className={styles.left_column}>
               <div className={styles.about_author}>
                  <h3>About the author</h3>
                  <p>{courseContent.mentorInfoDto.about}</p>
               </div>
               <div className={styles.more}>
                  <div className={styles.title}>
                     <h3>More from the author</h3>
                     <p className={styles.divider}></p>
                  </div>
                  <div className={styles.item_container}>
                     {courseContent.mentorInfoDto.courses.map((item, index) => (
                        <MoreItem
                           key={index}
                           title={item.courseName}
                           rating={item.rank}
                           voters={item.voters}
                        />
                     ))}
                  </div>
               </div>
            </div>
            <div className={styles.right_column}>
               <div className={styles.author_widget}>
                  <img
                     src={courseContent.mentorInfoDto.logo}
                     alt='avatar'
                     width='64px'
                  />

                  <h4>{courseContent.mentorInfoDto.name}</h4>

                  <h5>{courseContent.mentorInfoDto.specialization}</h5>
                  <div className={styles.btn_container}>
                     <AppBtn>Follow</AppBtn>
                     <AppBtn buttonType={AppBtnTypes.inverted}>
                        View Profile
                     </AppBtn>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

// project components
import { QuizQuestion } from '../QuizQuestion';

// types
import { IQuizQuestion } from '../../../interfaces/interfaces';

// styles
import styles from './QuizBody.module.scss';

interface IProps {
   questions: IQuizQuestion[];
   questionNumber: number;
   topicTitle: string;
}

export const QuizBody: React.FC<IProps> = ({
   questions,
   questionNumber,
   topicTitle,
}) => {
   let questionsList: JSX.Element[] = [];

   questions.forEach((item) => {
      const question: JSX.Element = (
         <QuizQuestion
            topicTitle={topicTitle}
            question={item}
         />
      );

      questionsList.push(question);
   });

   return (
      <div className={styles.wrapper}>{questionsList[questionNumber - 1]}</div>
   );
};

import { comments } from '../../../../guest/pages/GuestHome/components/mockData';
//components
import { LinearProgressBlock } from './LinearProgressBlock/LinearProgressBlock';
import { CommentsItem } from './CommentsItem/CommentsItem';
//mui
import Rating from '@mui/material/Rating/Rating';
import { StarBorder } from '@mui/icons-material';
//styles
import styles from './StudentFeedback.module.scss';

export const StudentFeedback = () => {
   return (
      <div className={styles.container}>
         <div className={styles.content}>
            <div className={styles.title}>
               <h3>Student Feedback</h3>
               <p className={styles.divider}></p>
            </div>
            <div className={styles.global_rating}>
               <div className={styles.left_block}>
                  <span className={styles.average_count}>4.5</span>
                  <span className={styles.rating}>
                     <Rating
                        emptyIcon={<StarBorder className={styles.star} />}
                        name='read-only'
                        value={4.5}
                        readOnly
                        size='medium'
                        max={5}
                        precision={0.5}
                     />
                  </span>
                  <span className={styles.countVoters}>1357 ratings</span>
               </div>
               <LinearProgressBlock />
            </div>
            <div className={styles.comments}>
               {comments.map((comment) => (
                  <CommentsItem
                     key={Math.random() * 10000}
                     userName={comment.userName}
                     comment={comment.comment}
                  />
               ))}
            </div>
         </div>
      </div>
   );
};

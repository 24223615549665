import { CSSProperties, ReactNode } from 'react';

// styles
import styles from './BorderedBlockContainer.module.scss';

interface Props {
   children?: ReactNode;
   marginBottom?: string;
   style?: CSSProperties;
   className?: string;
}

export function BorderedBlockContainer({
   children,
   marginBottom,
   style,
   className = '',
}: Props) {
   const mb = marginBottom ? marginBottom : '15px';

   return (
      <div
         className={[styles.container, className].join(' ')}
         style={{ marginBottom: mb, ...style }}
      >
         {children}
      </div>
   );
}
import { AllMentorsPage } from '../spectator';
import { SpectatorLayout } from '../UI/Layout/SpectatorLayout';
import { StudentProfile } from '../studentProfile/studentProfile';
import { SpectatorHome } from '../spectator';
import { AllCourses } from '../allCourses';
import ViewAllStudents from '../viewAllStudents/pages/ViewAllStudents'

enum SpectatorPaths {
   home = '/',
   all_mentors = '/spectator/all-mentors',
   student_profile = '/student-profile/:id',
   all_courses = '/all-courses',
   all_students = '/all-students',
}

export const SpectatorRoutes = {
   path: SpectatorPaths.home,
   element: <SpectatorLayout />,
   children: [
      {
         path: SpectatorPaths.home,
         element: <SpectatorHome />,
      },
      {
         path: SpectatorPaths.all_mentors,
         element: <AllMentorsPage />,
      },
      {
         path: SpectatorPaths.student_profile,
         element: <StudentProfile />,
      },
      {
         path: SpectatorPaths.all_students,
         element: <ViewAllStudents />,
      },
      {
         path: SpectatorPaths.all_courses,
         element: <AllCourses />,
      },
   ],
};

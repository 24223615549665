import MarkdownPreview from '@uiw/react-markdown-preview';
import { convertToSlug } from '../../../../utils/utils';
import { ENDPOINTS } from '../../../../http/studentAPI';

// interfaces
import { ITask } from '../../interfaces/interfaces';
import { ICoursesList } from '../../interfaces/interfaces';

// hooks
import useProtectedContent from '../../hooks/useProtectedContent';
import { useParams, useNavigate } from 'react-router-dom';

// styles
import styles from './Task.module.scss';

// components
import {
   Loader,
   TaskHistory,
   TaskForm,
   TaskDiscussions,
   TaskCourseInfo,
   TaskBanner,
} from '../../components';
import { FeedbackTask } from '../FeedbackTask';

export const Task: React.FC = () => {
   const navigate = useNavigate();
   const { id, title } = useParams();
   const taskContent = useProtectedContent(ENDPOINTS.task(id!)) as ITask;
   const taskMarkdown = useProtectedContent(
      ENDPOINTS.taskMarkdown(id!),
   ) as string;

   const isHomepage = true;
   const courses = useProtectedContent(
      ENDPOINTS.homepage,
      isHomepage,
   ) as ICoursesList[];

   if (!taskContent || !courses) return <Loader />;

   const { answer, courseItem } = taskContent;
   const { title: courseItemTitle, topicTitle, state } = courseItem;

   if (convertToSlug(courseItemTitle) !== title) {
      navigate(-1);
   }

   return (
      <>
         <div className={styles.banner}>
            <TaskBanner
               courseItemTitle={courseItemTitle}
               topicTitle={topicTitle}
            />
         </div>

         <div className={styles.task}>
            <div className={styles.content}>
               <div className={`${styles.markdown} ${styles.container}`}>
                  <MarkdownPreview
                     source={taskMarkdown}
                     disableCopy
                     warpperElement={{
                        'data-color-mode': 'light',
                     }}
                  />
               </div>

               {courses && courses.map((course) => (
                  <div key={course.id}
                     className={`${styles.container} ${styles.container_info} `}
                  >
                     <TaskCourseInfo
                        key={course.id}
                        imageURL={course.courseLogoUrl}
                        courseName={course.courseTitle}
                        mentorName={`${course.mentorFirstName} ${course.mentorLastName}`}
                     />
                  </div>
               ))}

               <div
                  className={`${styles.container} ${styles.container_tracker} `}
               >
                  <TaskHistory taskContent={taskContent} />
               </div>

               <div className={`${styles.container} ${styles.answer} `}>
                  <TaskForm id={id!} answer={answer} state={state} />
               </div>

               <div className={`${styles.container} ${styles.discussions} `}>
                  <TaskDiscussions />
               </div>

               <div className={`${styles.container} ${styles.feedback} `}>
                  <FeedbackTask />
               </div>
            </div>
         </div>
      </>
   );
};

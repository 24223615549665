// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotFound_container__km44E {\n  width: 100%;\n  min-height: 50vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 15px;\n  text-align: center;\n  line-height: 1.5;\n}\n.NotFound_container__km44E h2 {\n  font-size: xx-large;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/modules/error/pages/NotFound/NotFound.module.scss"],"names":[],"mappings":"AAAA;EACG,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;AACH;AACG;EACG,mBAAA;EACA,gBAAA;AACN","sourcesContent":[".container {\n   width: 100%;\n   min-height: 50vh;\n   display: flex;\n   flex-direction: column;\n   justify-content: center;\n   align-items: center;\n   padding: 15px;\n   text-align: center;\n   line-height: 1.5;\n\n   h2 {\n      font-size: xx-large;\n      font-weight: 500;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NotFound_container__km44E"
};
export default ___CSS_LOADER_EXPORT___;

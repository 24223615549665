//project imports
import { IActiveCompletedCourseList } from '../../../../interfaces/interfaces';
import { ENDPOINTS } from '../../../../../../http/studentAPI';
import useProtectedContent from '../../../../hooks/useProtectedContent';
//components
import { EmptyTabContent } from '../../../../components/EmptyTabContent/EmptyTabContent';
import { Loader, Training5OrMore } from '../../../../components';
import { StudentCourseCard } from '../../../../components/StudentCourseCard/StudentCourseCard';
//styles
import styles from './CompeletedCoursesPage.module.scss';
//hooks
import useGetSessionStorageItem from '../../../../../../hooks/useSessionStorageData';
import { IUser } from '../../../../../../store/UserStore';

export const CompletedCoursesPage = () => {
   const isHomepage = true;
   
   const {id:userId} : IUser = useGetSessionStorageItem('user') 

   const activeCourses  = useProtectedContent(
      ENDPOINTS.courseСompletionStatus(userId,true),
      isHomepage
   ) as IActiveCompletedCourseList ;
   
   const courses  =  activeCourses?.workCourses
   if (!courses) return <Loader />;
   
  
   
   
   return (
      <>
         {!courses.length && <EmptyTabContent/>}
         <Training5OrMore />
         
         <div className={styles.container}>
         
         {courses.map((course) => (
            <StudentCourseCard
               key={course.id}
               completionPercentage={course.completionPercentage}
               imageURL={course.courseLogoUrl}
               courseName={course.courseTitle}
               courseDescription={course.courseDescription}
               mentorName={`${course.mentorFirstName} ${course.mentorLastName}`}
               slug={course.slug}
               courseId={course.id}
            />
         ))}
      </div>
      </>
   );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroBreadcrumbs_breadcrumbs__uG3Bu {\n  font-size: 14px;\n  font-weight: 600;\n  color: #ffffff;\n  margin-bottom: 16px;\n  width: 100%;\n}\n\n.HeroBreadcrumbs_cat__zt3Ad {\n  color: #daade1;\n  text-transform: capitalize;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/modules/coursePreview/components/HeroBreadcrumbs/HeroBreadcrumbs.module.scss"],"names":[],"mappings":"AAEA;EACG,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,WAAA;AADH;;AAIA;EACG,cAAA;EACA,0BAAA;EACA,eAAA;AADH","sourcesContent":["@use '../../../../theme/config' as v;\n\n.breadcrumbs {\n   font-size: 14px;\n   font-weight: 600;\n   color: #ffffff;\n   margin-bottom: 16px;\n   width: 100%;\n}\n\n.cat {\n   color: lighten($color: #ce93d8, $amount: 7);\n   text-transform: capitalize;\n   cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": "HeroBreadcrumbs_breadcrumbs__uG3Bu",
	"cat": "HeroBreadcrumbs_cat__zt3Ad"
};
export default ___CSS_LOADER_EXPORT___;

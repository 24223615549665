import { FC } from 'react';
import { useRootStore } from '../../../../../hooks';
import {
    IViewAllStudents,
} from '../../../../../http/studentsAPI';
import { publicURL } from '../../../../../utils/consts';
import StudentCourses from '../StudentCourses/StudentCourses';
import StudentInfo from '../StudentInfo/StudentInfo';
import { BasicNote } from '../../../../UI/Note';
import styles from './StudentItem.module.scss';
import stylesNote from '../../../../UI/Note/Note.module.scss';


const StudentItem: FC<IViewAllStudents> =({
    firstName,
    lastName,
    courses,
    id,
    avatarLogoUrl,
    email,
    phone,
    telegram,
    skype,
    note,
    onChange,
    onSubmit,
    onBlur,
    onFocus,
}) => {

    const { userStore } = useRootStore();
    const userRole = userStore.getUserRole();

    return (
        <>
            <div
                key={id}
                className={styles.item__block}
            >
                <div className={styles.avatar}>
                    {avatarLogoUrl === null ? (
                        <div>
                            <img src={`${publicURL}/images/avatar.png`} alt='Students avatar'/>
                        </div>
                    ) : avatarLogoUrl}
                </div>

                <div className={styles.item__info}>
                    <StudentInfo
                        id={id}
                        firstName={firstName}
                        lastName={lastName}
                        email={email}
                        telegram={telegram}
                        skype={skype}
                        phone={phone}
                    />
                    <StudentCourses courses={courses}/>
                </div>

                <div className={styles.item__note}>
                    {((userRole === 'MENTOR' || userRole === 'MANAGER')) ? (
                        <BasicNote id={id} note={note} placeholder="Add a note" className={`${stylesNote.textarea} ${stylesNote.textarea_allStudents}`}/>
                    ) : null}
                </div>
            </div>
        </>
    )

}

export default StudentItem;

import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { mentorNavigator } from '../../../../../router/MentorRoutes';
import styles from './CellTask.module.scss';

interface Props {
   task: string;
   id?: number;
}

export const CellTask: FC<Props> = ({ task, id }) => {
   const route = mentorNavigator.task(id!);

   return (
      <td className={styles.task}>
         <span className={styles.textOverflow}>
            <NavLink className={styles.link} to={'../' + route}>
               {task}
            </NavLink>
         </span>
      </td>
   );
};

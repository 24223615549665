import styles from './Statistics.module.scss';

export const Statistics = () => {
   return (
      <div className={styles.container_statistics}>
         <div className={styles.wrapper}>
            <div className={styles.item_container_statistics}>
               <h2>62M</h2>
               <p>Students</p>
            </div>
            <div className={styles.item_container_statistics}>
               <h2>75+</h2>
               <p>Languages</p>
            </div>
            <div className={styles.item_container_statistics}>
               <h2>800M</h2>
               <p>Enrollments</p>
            </div>
            <div className={styles.item_container_statistics}>
               <h2>180+</h2>
               <p>Countries</p>
            </div>
            <div className={styles.item_container_statistics}>
               <h2>13,400+</h2>
               <p>Enterprise customers</p>
            </div>
         </div>
      </div>
   );
};

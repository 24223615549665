import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './modules/router/ScrollToTop';
import Routes from './modules/router';
import ThemeCustomization from './theme';

import './App.scss';

export const App: React.FC = () => {
   return (
      <ThemeCustomization>
         <BrowserRouter>
            <ScrollToTop />
            <Routes />
         </BrowserRouter>
      </ThemeCustomization>
   );
};

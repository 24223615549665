import img1 from '../../../assets/images/coursePreview/gopro-vue-1.jpg';
import img2 from '../../../assets/images/coursePreview/gopro-vue-2.jpg';
import img3 from '../../../assets/images/coursePreview/gopro-vue-3.jpg';

export const goProData = [
   {
      id: 1,
      title: 'Complete Vue Developer 2023: Zero to Mastery (Pinia, Vitest)',
      img: img1,
      price: 19.99,
      authors: ['Andrei Neagoie', 'Luis Ramirez Jr'],
      rating: 4.6,
      rates: 2116,
   },
   {
      id: 2,
      title: 'JavaScript Web Projects: 20 Projects to Build Your Portfolio',
      img: img2,
      price: 17.99,
      authors: ['Andrei Neagoie', 'Jacinto Wong'],
      rating: 4.7,
      rates: 3470,
   },
   {
      id: 3,
      // eslint-disable-next-line no-script-url
      title: 'JavaScript: The Advanced Concepts (2023 Update)',
      img: img3,
      price: 16.99,
      authors: ['Andrei Neagoie'],
      rating: 4.8,
      rates: 12679,
   },
];

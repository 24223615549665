import { useState } from "react"

export const useWishlistBtn = () => {
   const [isEnlisted, setIsEnlisted] = useState(false)

   const toggleWishlist = () => setIsEnlisted(!isEnlisted);

   return {
      isEnlisted,
      toggleWishlist
   }
}
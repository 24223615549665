import { FC } from 'react';
//mui
import Rating from '@mui/material/Rating/Rating';
import { StarBorder } from '@mui/icons-material';
//styles
import styles from './MoreItem.module.scss';

interface IProps {
   title: string;
   rating: number;
   voters: number;
}

export const MoreItem: FC<IProps> = ({ title, rating, voters }) => {
   return (
      <div className={styles.container}>
         <h5>{title}</h5>
         <span className={styles.rating}>
            {rating}
            <Rating
               emptyIcon={<StarBorder className={styles.star} />}
               name='read-only'
               value={rating}
               readOnly
               size='small'
               max={5}
               precision={0.5}
            />
            <span className={styles.countVoters}>({voters})</span>
         </span>
      </div>
   );
};

import { useState } from 'react';
import { useRootStore } from '../../../hooks';
import {
   acceptAnswerFromStudent,
   rejectAnswerFromStudent,
} from '../../../http/mentorAPI';

interface Params {
   studentFirstName: string;
   studentLastName: string;
   courseItemTitle: string;
   id: number;
}

export const useTaskModal = ({
   studentFirstName,
   studentLastName,
   courseItemTitle,
   id,
}: Params) => {
   const { mentorStore } = useRootStore();
   const refresh = mentorStore.getTaskRefresh();

   const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
   const [modalType, setModalType] = useState<'accept' | 'reject'>('accept');

   const openAcceptModal = () => {
      setModalType('accept');
      setModalIsOpen(true);
   };

   const openRejectModal = () => {
      setModalType('reject');
      setModalIsOpen(true);
   };

   const handleModalClose = () => {
      setModalIsOpen(false);
   };

   const acceptAnswer = (taskId: number) => () => {
      acceptAnswerFromStudent(taskId).then(() =>
         mentorStore.setTaskRefresh(!refresh),
      );
   };

   const rejectAnswer = (taskId: number) => () => {
      rejectAnswerFromStudent(taskId).then(() =>
         mentorStore.setTaskRefresh(!refresh),
      );
   };

   const modalSettings = {
      accept: {
         confirm: acceptAnswer,
         message: `Accept answer from ${studentFirstName} ${studentLastName} for the task ${courseItemTitle}?`,
      },
      reject: {
         confirm: rejectAnswer,
         message: `Reject answer from ${studentFirstName} ${studentLastName} for the task ${courseItemTitle}?`,
      },
   };

   return {
      modalIsOpen,
      openAcceptModal,
      openRejectModal,
      handleModalClose,
      onConfirm: modalSettings[modalType].confirm(id),
      message: modalSettings[modalType].message,
   };
};

import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { FormatIndentDecreaseOutlined, FormatIndentIncreaseOutlined } from '@mui/icons-material';

// project components
import { HeaderContent } from './HeaderContent';

// project imports
import { AppBarStyled } from './AppBarStyled';
import { useDrawerToggler } from '../../hooks/useDrawerToggler';
import { publicURL } from '../../../../../utils/consts';

// assets
import { headerStyles } from '../../../../../theme/config';

// styles
import styles from './index.module.scss';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

export const Header: React.FC = () => {
   const navigate = useNavigate();
   const theme = useTheme();
   const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
   const matchUpSM = useMediaQuery(theme.breakpoints.up('sm'));
   const { open, handleDrawerToggle } = useDrawerToggler();

   const iconBackColor = 'grey.100';
   const iconBackColorOpen = 'grey.200';

   const handleClick = () => {
      navigate('/');
   };

   // common header
   const mainHeader = (
      <Toolbar
         sx={{
            '& svg': {
               maxWidth: headerStyles.svgsSize,
               maxHeight: headerStyles.svgsSize,
            },
            '& .MuiLink-root': {
               padding: headerStyles.btnPadding,
            },
            '& .MuiIconButton-root': {
               padding: headerStyles.btnPadding,
            },
         }}
      >
         {!open && matchUpSM && (
            <img
               className={styles.logo_xs}
               src={`${publicURL}/images/logo-small.png`}
               alt='Foxminded Logo'
               onClick={handleClick}
            />
         )}
         <IconButton
            disableRipple
            aria-label='open drawer'
            onClick={handleDrawerToggle}
            color='secondary'
            sx={{
               color: 'text.primary',
               bgcolor: open ? iconBackColorOpen : iconBackColor,
            }}
         >
            {!open ? <FormatIndentIncreaseOutlined /> : <FormatIndentDecreaseOutlined />}
         </IconButton>
         <HeaderContent />
      </Toolbar>
   );

   // app-bar params
   const appBar = {
      position: 'absolute' as const,
      top: 0,
      color: 'inherit' as const,
      elevation: 3,
      sx: {
         zIndex: headerStyles.zIndex,
      },
   };

   return (
      <>
          {!matchDownLG ? (
            <AppBarStyled open={open} {...appBar}>
               {mainHeader}
            </AppBarStyled>
         ) : (
            <AppBar {...appBar}>{mainHeader}</AppBar>
         )}
      </>
   );
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CourseTab_container__PlrN5 {\n  margin: 1rem 20px;\n}\n@media screen and (min-width: 700px) {\n  .CourseTab_container__PlrN5 {\n    margin: 1rem 20vw;\n  }\n}\n\n.CourseTab_heading__qVmNv {\n  font-size: 30px;\n  margin-bottom: 1rem;\n  font-weight: 500;\n}\n\n.CourseTab_subheading__eH6dl {\n  font-size: 20px;\n  margin-bottom: 20px;\n  font-weight: 500;\n}\n\n.CourseTab_button__RMIm7 {\n  display: block;\n  margin: 0;\n  padding: 6px 10px;\n}\n@media screen and (max-width: 1200px) {\n  .CourseTab_button__RMIm7 {\n    font-size: 1rem;\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/course/CourseTab/CourseTab.module.scss"],"names":[],"mappings":"AAAA;EACG,iBAAA;AACH;AACG;EAHH;IAIM,iBAAA;EAEJ;AACF;;AACA;EACG,eAAA;EACA,mBAAA;EACA,gBAAA;AAEH;;AACA;EACG,eAAA;EACA,mBAAA;EACA,gBAAA;AAEH;;AACA;EACG,cAAA;EACA,SAAA;EACA,iBAAA;AAEH;AAAG;EALH;IAMM,eAAA;EAGJ;AACF","sourcesContent":[".container {\n   margin: 1rem 20px;\n\n   @media screen and (min-width: 700px) {\n      margin: 1rem 20vw;\n   }\n}\n\n.heading {\n   font-size: 30px;\n   margin-bottom: 1rem;\n   font-weight: 500;\n}\n\n.subheading {\n   font-size: 20px;\n   margin-bottom: 20px;\n   font-weight: 500;\n}\n\n.button {\n   display: block;\n   margin: 0;\n   padding: 6px 10px;\n\n   @media screen and (max-width: 1200px) {\n      font-size: 1rem;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CourseTab_container__PlrN5",
	"heading": "CourseTab_heading__qVmNv",
	"subheading": "CourseTab_subheading__eH6dl",
	"button": "CourseTab_button__RMIm7"
};
export default ___CSS_LOADER_EXPORT___;

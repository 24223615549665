import { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';

// project imports
import { useRootStore } from '../../../../hooks';
// import { useMediaQuery } from '../../../../hooks';
// import { useTheme } from '@mui/material/styles';

export const useDrawerToggler = () => {
   // const theme = useTheme();
   // const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
   const { layoutStore } = useRootStore();
   const drawerOpen = useObserver(() => layoutStore.getDrawerOpen());
   const submenuIsOpen = useObserver(() => layoutStore.getSubmenuIsOpen());
   const activeItem = useObserver(() => layoutStore.getActiveItem());

   const [open, setOpen] = useState(drawerOpen);
   const [submenuOpen, setSubmenuOpen] = useState(submenuIsOpen);

   const handleDrawerToggle = () => {
      setOpen(!open);
      layoutStore.setDrawerOpen(!drawerOpen);
      layoutStore.setSubmenuIsOpen(false);
      layoutStore.setCascaderIsActive(false);

      setTimeout(() => {
         layoutStore.setCascaderIsActive(true);
      }, 1000);
   };

   useEffect(() => {
      if (open !== drawerOpen) setOpen(drawerOpen);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [drawerOpen]);

   useEffect(() => {
      if (submenuOpen !== submenuIsOpen) setSubmenuOpen(submenuIsOpen);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [submenuIsOpen]);

   // useEffect(() => {
   //    setOpen(!matchDownLG);
   //    layoutStore.setDrawerOpen(!matchDownLG);

   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [matchDownLG]);

   return { open, handleDrawerToggle, submenuOpen, activeItem };
};

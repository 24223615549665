// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowMoreBox_wrapper__xoYqK {\n  width: 100%;\n  overflow: hidden;\n  position: relative;\n  padding-bottom: 64px;\n}\n\n.ShowMoreBox_fade__coDJK {\n  position: absolute;\n  height: 120px;\n  top: -120px;\n  left: 0;\n  width: 100%;\n  transform: translateY(5px);\n  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);\n}\n\n.ShowMoreBox_show__C7jh2 {\n  padding: 8px 0 24px;\n  position: absolute;\n  width: 100%;\n  bottom: 0;\n  z-index: 1;\n  background-color: #ffffff;\n}\n\n.ShowMoreBox_chevron__QlQWM {\n  font-size: 11px;\n  margin-left: 5px;\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/ShowMoreBox/ShowMoreBox.module.scss"],"names":[],"mappings":"AAAA;EACG,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,oBAAA;AACH;;AAEA;EACG,kBAAA;EACA,aAAA;EACA,WAAA;EACA,OAAA;EACA,WAAA;EACA,0BAAA;EACA,2EAAA;AACH;;AAMA;EACG,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,yBAAA;AAHH;;AAMA;EACG,eAAA;EACA,gBAAA;AAHH","sourcesContent":[".wrapper {\n   width: 100%;\n   overflow: hidden;\n   position: relative;\n   padding-bottom: 64px;\n}\n\n.fade {\n   position: absolute;\n   height: 120px;\n   top: -120px;\n   left: 0;\n   width: 100%;\n   transform: translateY(5px);\n   background-image: linear-gradient(\n      to bottom,\n      rgba(255, 255, 255, 0),\n      white\n   );\n}\n\n.show {\n   padding: 8px 0 24px;\n   position: absolute;\n   width: 100%;\n   bottom: 0;\n   z-index: 1;\n   background-color: #ffffff;\n}\n\n.chevron {\n   font-size: 11px;\n   margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ShowMoreBox_wrapper__xoYqK",
	"fade": "ShowMoreBox_fade__coDJK",
	"show": "ShowMoreBox_show__C7jh2",
	"chevron": "ShowMoreBox_chevron__QlQWM"
};
export default ___CSS_LOADER_EXPORT___;

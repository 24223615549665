// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CellStatus_status__19XXB {\n  width: 160px;\n}\n\n.CellStatus_courseItemState__Gt1qD {\n  display: block;\n  padding: 4px 10px;\n  white-space: nowrap;\n  width: -moz-fit-content;\n  width: fit-content;\n  border-radius: 5px;\n  background-color: #f3f3f3;\n}\n\n.CellStatus_date__Py82j {\n  display: block;\n  margin-top: 10px;\n  font-size: 0.9rem;\n}\n\n.CellStatus_submitted__QGfnR {\n  background-color: rgba(187, 239, 253, 0.4);\n}\n\n.CellStatus_in-review__drGuK {\n  background-color: rgba(255, 229, 100, 0.3);\n}\n\n.CellStatus_accepted__vjL5k {\n  background-color: rgba(195, 230, 205, 0.7);\n}\n\n.CellStatus_rejected__QoxCW {\n  background-color: rgb(250, 218, 208);\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/AnswerList/commonCells/CellStatus/CellStatus.module.scss"],"names":[],"mappings":"AAAA;EACG,YAAA;AACH;;AAEA;EACG,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;EACA,yBAAA;AACH;;AAEA;EACG,cAAA;EACA,gBAAA;EACA,iBAAA;AACH;;AAEA;EACG,0CAAA;AACH;;AAEA;EACG,0CAAA;AACH;;AAEA;EACG,0CAAA;AACH;;AAEA;EACG,oCAAA;AACH","sourcesContent":[".status {\n   width: 160px;\n}\n\n.courseItemState {\n   display: block;\n   padding: 4px 10px;\n   white-space: nowrap;\n   width: fit-content;\n   border-radius: 5px;\n   background-color: #f3f3f3;\n}\n\n.date {\n   display: block;\n   margin-top: 10px;\n   font-size: 0.9rem;\n}\n\n.submitted {\n   background-color: rgba(187, 239, 253, 0.4);\n}\n\n.in-review {\n   background-color: rgba(255, 229, 100, 0.3);\n}\n\n.accepted {\n   background-color: rgba(195, 230, 205, 0.7);\n}\n\n.rejected {\n   background-color: rgb(250, 218, 208);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "CellStatus_status__19XXB",
	"courseItemState": "CellStatus_courseItemState__Gt1qD",
	"date": "CellStatus_date__Py82j",
	"submitted": "CellStatus_submitted__QGfnR",
	"in-review": "CellStatus_in-review__drGuK",
	"accepted": "CellStatus_accepted__vjL5k",
	"rejected": "CellStatus_rejected__QoxCW"
};
export default ___CSS_LOADER_EXPORT___;

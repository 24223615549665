import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

// project imports
import { useRootStore } from '../../../../../hooks/mobx';

import { AccessTimeOutlined } from '@mui/icons-material';

// styles
import styles from './QuizHeader.module.scss';

interface IQuizHeaderType {
   questionNumber: number;
   numberOfQuestions: number;
}
export const QuizHeader: React.FC<IQuizHeaderType> = observer(
   ({ questionNumber, numberOfQuestions }) => {
      const { studentStore } = useRootStore();
      const [time, setTime] = useState<number>(30);

      useEffect(() => {
         let timer = setTimeout(() => {
            setTime((prevTime) => prevTime - 1);
         }, 1000);

         if (time <= 0 || studentStore.getIsQuizTimeOver()) {
            studentStore.setIsQuizTimeOver(true);
            clearTimeout(timer);
         }

         return () => clearTimeout(timer);
      }, [time]);

      return (
         <div className={styles.header}>
            <div className={styles.wrapper}>
               <div className={styles.info}>
                  <h3>Question {questionNumber} of {numberOfQuestions}</h3>
                  {!studentStore.getIsQuizTimeOver() ? (
                     <div className={styles.timeContainer}>
                        <AccessTimeOutlined />
                        {' '}
                        {time < 3600
                           ? `minutes ${new Date(time * 1000)
                              .toISOString()
                              .substring(14, 19)}`
                           : `hours ${new Date(time * 1000)
                              .toISOString()
                              .substring(11, 16)}`}
                     </div>
                  ) : null}
               </div>
               <button className={styles.badge}>
                  Questions
                  <span className={styles.questionsQuantity}>
                     {numberOfQuestions}
                  </span>
               </button>
            </div>
         </div>
      );
   },
);
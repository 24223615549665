// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TaskItem_accordion_item__P\\+LKk {\n  display: flex;\n  align-items: center;\n  margin-bottom: 0.7rem;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n}\n.TaskItem_accordion_item__P\\+LKk:last-child {\n  margin-bottom: 0;\n  border-bottom: none;\n}\n.TaskItem_accordion_item__P\\+LKk:last-child .TaskItem_title__iL6U3 {\n  margin-bottom: 0;\n}\n\n.TaskItem_title__iL6U3 {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-bottom: 0.7rem;\n  font-size: 14px;\n  background-color: #fff;\n  text-align: left;\n}\n@media screen and (min-width: 600px) {\n  .TaskItem_title__iL6U3 {\n    font-size: 16px;\n  }\n}\n\nbutton:disabled {\n  cursor: auto;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/studentCourse/TasksAndResultSection/components/TasksSection/TaskItem/TaskItem.module.scss"],"names":[],"mappings":"AAEA;EACG,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,4CAAA;AADH;AAGG;EACG,gBAAA;EACA,mBAAA;AADN;AAGM;EACG,gBAAA;AADT;;AAMA;EACG,aAAA;EACA,mBAAA;EACA,QAAA;EACA,qBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;AAHH;AAKG;EATH;IAUM,eAAA;EAFJ;AACF;;AAMG;EACG,YAAA;AAHN","sourcesContent":["@use '../../../../../../../../theme/config' as v;\n\n.accordion_item {\n   display: flex;\n   align-items: center;\n   margin-bottom: 0.7rem;\n   border-bottom: 1px solid v.$divider-light;\n\n   &:last-child {\n      margin-bottom: 0;\n      border-bottom: none;\n\n      .title {\n         margin-bottom: 0;\n      }\n   }\n}\n\n.title {\n   display: flex;\n   align-items: center;\n   gap: 8px;\n   margin-bottom: 0.7rem;\n   font-size: 14px;\n   background-color: #fff;\n   text-align: left;\n\n   @media screen and (min-width: v.$sm) {\n      font-size: 16px;\n   }\n}\n\nbutton {\n   &:disabled {\n      cursor: auto;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion_item": "TaskItem_accordion_item__P+LKk",
	"title": "TaskItem_title__iL6U3"
};
export default ___CSS_LOADER_EXPORT___;

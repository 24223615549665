import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

// project components
import { InputCheckbox } from '../../../../quiz/components/InputCheckbox';

// project imports
import { useRootStore } from '../../../../../hooks';

// types
import { IChoiceAnswer, IQuizQuestion } from '../../../interfaces/interfaces';

// styles
import styles from './QuizMultiChoice.module.scss';

interface IProps {
   answers: IChoiceAnswer[];
   question: IQuizQuestion;
   title: string;
}

export const QuizMultiChoice: React.FC<IProps> = observer(
   ({ answers, question }) => {
      const [inputValue, setInputValue] = useState<number[]>([]);
      const { questionId } = question;
      const { studentStore } = useRootStore();

      useEffect(() => {
         inputValue.length &&
            studentStore.setActualValue({
               questionId: questionId,
               choiceAnswer: inputValue,
            });
      }, [inputValue]);

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
         const { id } = e.target;

         if (e.target.checked) {
            setInputValue([...inputValue, +id]);
         } else {
            setInputValue(inputValue.filter((value) => value !== +id));
         }
      };

      return (
         <>
            <div className={styles.test}>
               {answers.map((answer) => (
                  <InputCheckbox
                     value={answer.choiceAnswer + ''}
                     id={answer.id}
                     key={answer.choiceAnswer}
                     name={question.description}
                     inputValue={inputValue}
                     setValue={setInputValue}
                     handleChange={handleChange}
                  />
               ))}
            </div>

            <p className={styles.description}>
               Note: There can be multiple correct answers to this question.
            </p>

         </>
      );
   },
);

import { useEffect, useState } from 'react';
// mui
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
// components
import { Indicators } from './Indicators';
import { CommentsTitle, CommentArticle } from '../index';
// project
import { commentsData } from '../../library';
// styles
import styles from './CommentsModal.module.scss';

type Props = {
   handleClose: () => void;
};

export const CommentsModal: React.FC<Props> = ({ handleClose }) => {
   const { rating, rates, comments } = commentsData;

   const [search, setSearch] = useState('');
   const [actualComments, setActualComments] = useState(comments || []);

   const handleType = (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target;
      setSearch(target.value);
   };

   useEffect(() => {
      if (search.length > 1) {
         const filteredComments = comments
            .filter((item) =>
               item.text
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase()),
            )
            .map((item) => ({
               ...item,
               text: item.text.replaceAll(search, `<strong>${search}</strong>`),
            }));

         setActualComments(filteredComments);
      } else {
         setActualComments(comments);
      }
   }, [search]);

   const commentsMap = actualComments.map((comment) => (
      <CommentArticle key={comment.id} comment={comment} />
   ));

   return (
      <div className={styles.wrapper}>
         <div className={styles.title_row}>
            <CommentsTitle rating={rating} rates={rates} />
            <div className={styles.close_btn} onClick={handleClose}>
               <CloseIcon />
            </div>
         </div>

         <div className={styles.sections}>
            <div className={styles.interactive}>
               <Indicators />
               <div className={styles.search}>
                  <input
                     className={styles.search_input}
                     type='text'
                     name='search'
                     placeholder='Search Reviews'
                     onChange={handleType}
                  />
                  <SearchIcon className={styles.search_icon} />
               </div>
            </div>
            <div className={styles.comments}>{commentsMap}</div>
         </div>
      </div>
   );
};

import { SetStateAction, useState, useEffect, SyntheticEvent } from 'react';
//material ui
import { Tabs, Tab } from '@mui/material';
//router
import { Link, useLocation } from 'react-router-dom';

export const HomePageTabs = () => {
   const location = useLocation();
   const [value, setValue] = useState('/student-all-courses');

   useEffect(() => {
      if (location.pathname === '/') {
         setValue('/student-all-courses');
      } else {
         setValue(location.pathname);
      }
   }, [location]);

   const handleChange = (
      event: SyntheticEvent,
      newValue: SetStateAction<string>,
   ) => {
      setValue(newValue);
   };

   const SxProp = {
      maxWidth: '100%',
      '& a.MuiTab-root': {
         minWidth: 'max-content',
         fontSize: '16px',
         color: '#d1d7dc',
         fontWeight: 700,
         padding: '2px',
         marginRight: '1rem',
         letterSpacing: '0.5px',
      },
      '& .MuiTab-root:hover': { color: '#fff' },
      '& button.Mui-selected': { color: '#fff' },
      '& a.Mui-selected': { color: '#fff' },
      '& button:selected': { color: '#fff' },
      '& MuiTab-root.Mui-selected': { color: '#fff' },
   };

   return (
      <Tabs
         value={value}
         onChange={handleChange}
         TabIndicatorProps={{
            sx: { backgroundColor: '#fff', height: '5px' },
         }}
         variant='scrollable'
         sx={SxProp}
      >
         <Tab
            label='All courses'
            value='/student-all-courses'
            component={Link}
            to='/student-all-courses'
         />
         <Tab
            label='Active courses'
            value='/active-courses'
            component={Link}
            to='/active-courses'
         />
         <Tab
            label='Completed courses'
            value='/completed-courses'
            component={Link}
            to='/completed-courses'
         />
         <Tab
            label='Wishlist'
            value='Wishlist'
            component={Link}
            to='/wishlist'
            disabled
         />
      </Tabs>
   );
};

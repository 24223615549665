import { v4 } from 'uuid';
//components
import { PageContainer, SlickSlider } from '../../../../../UI';
import { CommentsItemCard } from './CommentItemCard/CommentItemCard';
import { commentsSection } from '../../../../../UI/SlickSlider/Settings';
//query
import {IAllFeedback} from "../../../../../../http/guestAPI";
//styles
import styles from './CommentsSection.module.scss';

interface ICommentSection {
  feedback: IAllFeedback
}

export const CommentsSection = ({ feedback }: ICommentSection) => {
  const { comments } = feedback
   return (
      <div className={styles.container}>
         <PageContainer>
            <h2>How learners like you are achieving their goals</h2>

            <SlickSlider settings={commentsSection}>
               {comments.map((comment) => (
                  <CommentsItemCard
                     userName={comment.nameStudent}
                     comment={comment.comment}
                     course={comment.courseName}
                     key={v4()}
                  />
               ))}
            </SlickSlider>
         </PageContainer>
      </div>
   );
};

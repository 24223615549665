import React from 'react';

// ui
import { AppBtn, AppBtnTypes } from '../../../UI';

// styles
import styles from './TaskDiscussions.module.scss';

// mockData
import { DiscussionsItem } from '../../mockData';

export const TaskDiscussions: React.FC = () => {
   return (
      <>
         <div className={styles.title_section}>
            <h2>Discussions</h2>
            <a>
               <p>Ask a Question</p>
            </a>
         </div>

         <div className={styles.comments_container}>
            {DiscussionsItem.map((item) => {
               return (
                  <div key={item.id} className={styles.comment_container}>
                     <div className={styles.comment}>
                        <div className={styles.user}>
                           <div className={styles.user_avatar}>
                              <p className={styles.user_name_init}>
                                 {item.name.charAt(0)}
                                 {item.lastName.charAt(0)}
                              </p>
                           </div>
                           <div className={styles.user_name}>
                              <h5>
                                 {item.name} {item.lastName}
                              </h5>
                              <p>{item.timestamp} days ago</p>
                           </div>
                        </div>

                        <div className={styles.message}>
                           <p>{item.topic}</p>
                        </div>
                     </div>
                     <div className={styles.answers_discussions}>
                        <p>{item.answers}</p>
                        <p>answers</p>
                     </div>
                  </div>
               );
            })}
         </div>

         <AppBtn buttonType={AppBtnTypes.base} className={styles.seeAll_btn}>
            see all discussions for this lesson
         </AppBtn>
      </>
   );
};

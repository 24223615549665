export const answerList = [
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring Development 1.0',
      id: 1,
      studentFirstName: 'S-Name-1',
      studentLastName: 'S-LastName-1',
      taskState: 'Answer in review',
      taskTitle: 'Anagrams',
   },
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring',
      id: 2,
      studentFirstName: 'Name',
      studentLastName: 'LastName',
      taskState: 'Answer in review',
      taskTitle: 'Anagrams in review in review',
   },
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring Development 1.0',
      id: 3,
      studentFirstName: 'Sarah',
      studentLastName: 'Jessicagdhfdgggggggcgrdgrsgrs',
      taskState: 'Answer in review',
      taskTitle: 'Anagrams',
   },
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring Development 1.0',
      id: 4,
      studentFirstName: 'S-Name-1',
      studentLastName: 'S-LastName-1',
      taskState: 'Answer in review',
      taskTitle: 'Anagrams 22',
   },
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring Development 1.0',
      id: 5,
      studentFirstName: 'S-Name-1',
      studentLastName: 'S-LastName-1',
      taskState: 'Answer rejected',
      taskTitle: 'Anagrams',
   },
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring Development 1.0',
      id: 6,
      studentFirstName: 'Jack-Russel',
      studentLastName: 'Junior',
      taskState: 'Answer rejected',
      taskTitle:
         'Anagrams Answer in review Anagrams Answer Anagrams Answer 2222',
   },
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring Development 1.0',
      id: 7,
      studentFirstName: 'S-Name-1',
      studentLastName: 'S-LastName-1',
      taskState: 'Answer accepted',
      taskTitle: 'Anagrams',
   },
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring Development 1.0',
      id: 8,
      studentFirstName: 'S-Name-1',
      studentLastName: 'S-LastName-1',
      taskState: 'Answer rejected',
      taskTitle: 'Anagrams',
   },
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring',
      id: 9,
      studentFirstName: 'Name',
      studentLastName: 'LastName',
      taskState: 'Answer accepted',
      taskTitle: 'Anagrams in review in review',
   },
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring Development 1.0',
      id: 10,
      studentFirstName: 'Sarah',
      studentLastName: 'Jessica',
      taskState: 'Answer accepted',
      taskTitle: 'Anagrams',
   },
   {
      answer: 'https://gitlab.com/answer1',
      courseTitle: 'Java Spring Development 1.0',
      id: 11,
      studentFirstName: 'Sarah',
      studentLastName: 'Jessica',
      taskState: 'Answer accepted',
      taskTitle: 'Anagrams',
   },
];

export const students = [
   {
      id: 1,
      courseTitle: 'Java Spring Development 1.0',
      studentFirstName: 'Sarah',
      studentLastName: 'Jessica',
   },
   {
      id: 2,
      courseTitle: 'Java Spring Development 1.0',
      studentFirstName: 'Jack',
      studentLastName: 'Russel',
   },
   {
      id: 3,
      courseTitle: 'Java Spring Development 1.0',
      studentFirstName: 'S-Name-1',
      studentLastName: 'S-LastName-1',
   },
   {
      id: 4,
      courseTitle: 'Java Spring Development 1.0',
      studentFirstName: 'S-Name-1',
      studentLastName: 'S-LastName-1',
   },
];

export const courses = [
   {
      id: 1,
      courseTitle: 'Complete Web & Mobile Designer in 2023: UI/UX',
      mentors: ['Andrei Neagoie', 'Daniel Schifano'],
      rating: 4.5,
      ratingQuantity: 20963,
      hours: 28,
      lectures: 349,
      price: 129.99,
   },
   {
      id: 2,
      courseTitle: 'Complete Python Developer in 2023: Zero to Mastery',
      mentors: ['Andrei Neagoie'],
      rating: 4.6,
      ratingQuantity: 48089,
      hours: 31,
      lectures: 340,
      price: 99.99,
   },
   {
      id: 3,
      courseTitle: 'Complete Machine Learning & Data Science Bootcamp 2023',
      mentors: ['Andrei Neagoie', 'Daniel Bourke'],
      rating: 4.6,
      ratingQuantity: 15807,
      hours: 44,
      lectures: 381,
      price: 79.99,
   },
   {
      id: 4,
      courseTitle: 'Complete Angular Developer in 2023: Zero to Mastery',
      mentors: ['Andrei Neagoie', 'Luis Ramirez'],
      rating: 4.5,
      ratingQuantity: 1502,
      hours: 27,
      lectures: 324,
      price: 74.99,
   },
   {
      id: 5,
      courseTitle: 'Complete WordPress Developer Course',
      mentors: ['Luis Ramirez'],
      rating: 4.5,
      ratingQuantity: 2380,
      hours: 29,
      lectures: 325,
      price: 74.99,
   },
   {
      id: 6,
      courseTitle: 'Learn and Understand D3.js for Data Visualization',
      mentors: ['Luis Ramirez'],
      rating: 4.6,
      ratingQuantity: 1390,
      hours: 9,
      lectures: 92,
      price: 64.99,
   },
];

export const mockQuizAnswers = [
   {
      questionId: 5,
      quizAnswer:
         ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque hendrerit risus, sit amet facilisis mi luctus quis. Nullam sed dui id dui viverra porttitor. Maecenas efficitur dui at faucibus accumsan. Mauris sit amet elit at nisi congue interdum. Proin venenatis sagittis libero et dignissim. Nullam suscipit tellus ut sapien tincidunt lacinia. Aliquam eget tellus quis erat placerat dapibus nec ac tortor. Nulla cursus augue lectus, id laoreet massa ultricies ut. Nunc fringilla nisl quis nulla laoreet viverra ut ac turpis. Nullam id turpis mauris. Sed placerat, diam fermentum rutrum faucibus, diam nisl laoreet sapien, ac rutrum lacus diam sed sapien. Donec in justo ullamcorper, rhoncus libero mattis, porttitor ante. Suspendisse ut est a sapien feugiat finibus. Sed fringilla, ipsum eget imperdiet ultricies, nibh metus pulvinar lectus, eget dictum ex leo ut ipsum. Sed tincidunt, justo maximus sollicitudin cursus, tortor mi fermentum turpis, in luctus ipsum purus et erat. Ut vestibulum tortor eu enim sollicitudin, a dapibus lectus gravida. Maecenas purus diam, fermentum sit amet pharetra at, pretium ullamcorper ipsum. Phasellus euismod congue magna eu sagittis. Vivamus vitae sapien non nunc ultrices accumsan. In varius orci at est fermentum laoreet. ',
      description: 'What is abstraction?',
      courseTitle: 'Java Spring Development',
      taskTitle: 'Тест по теории Java Core',
      studentLastName: 'S-LastName-1',
      studentFirstName: 'S-Name-1',
      answerSubmittedDateTime: '01-04-2023 00:00:00',
   },
   {
      questionId: 5,
      quizAnswer:
         ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque hendrerit risus, sit amet facilisis mi luctus quis. Nullam sed dui id dui viverra porttitor. Maecenas efficitur dui at faucibus accumsan. Mauris sit amet elit at nisi congue interdum. Proin venenatis sagittis libero et dignissim. Nullam suscipit tellus ut sapien tincidunt lacinia. Aliquam eget tellus quis erat placerat dapibus nec ac tortor. Nulla cursus augue lectus, id laoreet massa ultricies ut. Nunc fringilla nisl quis nulla laoreet viverra ut ac turpis. Nullam id turpis mauris. Sed placerat, diam fermentum rutrum faucibus, diam nisl laoreet sapien, ac rutrum lacus diam sed sapien. Donec in justo ullamcorper, rhoncus libero mattis, porttitor ante. Suspendisse ut est a sapien feugiat finibus. Sed fringilla, ipsum eget imperdiet ultricies, nibh metus pulvinar lectus, eget dictum ex leo ut ipsum. Sed tincidunt, justo maximus sollicitudin cursus, tortor mi fermentum turpis, in luctus ipsum purus et erat. Ut vestibulum tortor eu enim sollicitudin, a dapibus lectus gravida. Maecenas purus diam, fermentum sit amet pharetra at, pretium ullamcorper ipsum. Phasellus euismod congue magna eu sagittis. Vivamus vitae sapien non nunc ultrices accumsan. In varius orci at est fermentum laoreet. ',
      description: 'What is memoization?',
      courseTitle: 'Java Spring Development',
      taskTitle: 'Тест по теории Java Core',
      studentLastName: 'S-LastName-1',
      studentFirstName: 'S-Name-1',
      answerSubmittedDateTime: '01-04-2023 00:00:00'
   }
];

export const myStudents = [
   {
      avatarLogoUrl: null,
      id: 5,
      firstName: 'S-Name-1',
      lastName: 'S-LastName-1',
      email: 's-1@email.com', 
      phone: 'phone_5',
      skype: 'skype_5', 
      telegram: 'telegram_5', 
      note: '5',
      courses: [ 
         {
            id: 1,
            title: 'Java Spring Development',
            status: 'ACTIVE',
            lastAction: `01-04-2023 00:00:00`,
         }
      ] 
   },
   {
      avatarLogoUrl: null,
      id: 6,
      firstName: 'V-Name-2',
      lastName: 'S-LastName-2',
      email: 's-2@email.com', 
      phone: 'phone_6',
      skype: 'skype_6', 
      telegram: 'telegram_6', 
      note: '6',
      courses: [ 
         {
            id: 2,
            title: 'React Js',
            status: 'ACTIVE',
            lastAction: `01-04-2023 00:00:00`,
         }
      ] 
   },
   {
      avatarLogoUrl: null,
      id: 7,
      firstName: 'M-Name-3',
      lastName: 'S-LastName-3',
      email: 's-3@email.com', 
      phone: 'phone_7',
      skype: 'skype_7', 
      telegram: 'telegram_7', 
      note: '7',
      courses: [ 
         {
            id: 2,
            title: 'React Js',
            status: 'COMPLETED',
            lastAction: `01-06-2023 00:00:00`,
         },
         {
            id: 1,
            title: 'Java Spring Development',
            status: 'STOPPED',
            lastAction: `01-06-2023 00:00:00`,
         }
      ] 
   },
   {
      avatarLogoUrl: null,
      id: 11,
      firstName: 'S-Name-4',
      lastName: 'S-LastName-4',
      email: 's-4@email.com', 
      phone: 'phone_11',
      skype: 'skype_11', 
      telegram: 'telegram_11', 
      note: '8',
      courses: [] 
   },
   {
      avatarLogoUrl: null,
      id: 15,
      firstName: 'S-Name-1',
      lastName: 'S-LastName-1',
      email: 's-1@email.com', 
      phone: 'phone_5',
      skype: 'skype_5', 
      telegram: 'telegram_5', 
      note: '15',
      courses: [ 
         {
            id: 1,
            title: 'Java Spring Development',
            status: 'ACTIVE',
            lastAction: `01-04-2023 00:00:00`,
         }
      ] 
   },
   {
      avatarLogoUrl: null,
      id: 16,
      firstName: 'S-Name-2',
      lastName: 'S-LastName-2',
      email: 's-2@email.com', 
      phone: 'phone_6',
      skype: 'skype_6', 
      telegram: 'telegram_6', 
      note: '16',
      courses: [ 
         {
            id: 2,
            title: 'React Js',
            status: 'ACTIVE',
            lastAction: `01-04-2023 00:00:00`,
         }
      ] 
   },
   {
      avatarLogoUrl: null,
      id: 17,
      firstName: 'S-Name-3',
      lastName: 'S-LastName-3',
      email: 's-3@email.com', 
      phone: 'phone_7',
      skype: 'skype_7', 
      telegram: 'telegram_7', 
      note: '17',
      courses: [ 
         {
            id: 2,
            title: 'React Js',
            status: 'COMPLETED',
            lastAction: `01-06-2023 00:00:00`,
         },
         {
            id: 1,
            title: 'Java Spring Development',
            status: 'STOPPED',
            lastAction: `01-06-2023 00:00:00`,
         }
      ] 
   },
   {
      avatarLogoUrl: null,
      id: 110,
      firstName: 'S-Name-4',
      lastName: 'S-LastName-4',
      email: 's-4@email.com', 
      phone: 'phone_11',
      skype: 'skype_11', 
      telegram: 'telegram_11', 
      note: '',
      courses: [] 
   },
]

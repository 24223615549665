// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CellQuizAnswer_container__cqL9f {\n  min-width: 180px;\n}\n.CellQuizAnswer_container__cqL9f div {\n  max-height: 175px;\n}\n.CellQuizAnswer_container__cqL9f .CellQuizAnswer_scroll__BVPAh {\n  overflow-y: auto;\n}\n\n.CellQuizAnswer_answer__NJ6Qd {\n  padding: 5px 10px;\n  white-space: nowrap;\n  width: -moz-fit-content;\n  width: fit-content;\n  border-radius: 5px;\n  background-color: #f3f3f3;\n}\n\n.CellQuizAnswer_answerButton__Z27dG {\n  height: 32px;\n  font-size: inherit;\n  color: #2f3237;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/AnswerList/commonCells/CellQuizAnswer/CellQuizAnswer.module.scss"],"names":[],"mappings":"AAAA;EACG,gBAAA;AACH;AACG;EACG,iBAAA;AACN;AAEG;EACG,gBAAA;AAAN;;AAIA;EACG,iBAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;EACA,yBAAA;AADH;;AAIA;EACG,YAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;AADH","sourcesContent":[".container {\n   min-width: 180px;\n\n   div {\n      max-height: 175px;\n   }\n\n   .scroll {\n      overflow-y: auto;\n   }\n}\n\n.answer {\n   padding: 5px 10px;\n   white-space: nowrap;\n   width: fit-content;\n   border-radius: 5px;\n   background-color: #f3f3f3;\n}\n\n.answerButton {\n   height: 32px;\n   font-size: inherit;\n   color: #2f3237;\n   font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CellQuizAnswer_container__cqL9f",
	"scroll": "CellQuizAnswer_scroll__BVPAh",
	"answer": "CellQuizAnswer_answer__NJ6Qd",
	"answerButton": "CellQuizAnswer_answerButton__Z27dG"
};
export default ___CSS_LOADER_EXPORT___;

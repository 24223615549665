//material ui
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//components
import { allCoursesMobile } from '../../../../../../UI/SlickSlider/Settings';
import { CourseCardWidget, AppBtn, SlickSlider } from '../../../../../../UI';
//project imports
//styles
import styles from './Accordions.module.scss';
import { useRootStore } from '../../../../../../../hooks';
import { Spinner } from '../../../../../../UI/Spinner';
import {
   Subcategories,
   getAllCategories,
} from '../../../../../../UI/Layout/hooks/useCategoriesCascader';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

export const Accordions: React.FC = observer(() => {
   const { guestStore, layoutStore } = useRootStore();
   const subcategories = layoutStore.getSubcategories();
   const isLoadingCategories = layoutStore.getIsLoadingCategories();
   const isLoadingCoursesPreview = guestStore.getIsLoadingCoursesPreview();
   const allSubcategories = [
      { id: -1, name: 'All Courses', description: '' },
      ...subcategories,
   ] as Subcategories[];

   useEffect(() => {
      if (subcategories.length === 0 && !isLoadingCategories) {
         layoutStore.setIsLoadingCategories(true);
         getAllCategories().then(({ categories }) => {
            layoutStore.setCategories(categories);
            layoutStore.setIsLoadingCategories(false);
         });
      }
   }, []);

   return (
      <div className={styles.container}>
         {isLoadingCoursesPreview || isLoadingCategories ? (
            <Spinner />
         ) : (
            allSubcategories.map((category, index) => {
               const defaultExpanded = index === 0;
               const categoryFilter = index === 0 ? '' : category.name;
               const showDescriptionAndExploreBtn = index !== 0;
               const filteredCourses =
                  guestStore.getCoursesByCategory(categoryFilter);

               return (
                  <Accordion
                     key={category.name}
                     defaultExpanded={defaultExpanded}
                  >
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                     >
                        <h3>{category.name}</h3>
                     </AccordionSummary>
                     <AccordionDetails>
                        {showDescriptionAndExploreBtn ? (
                           <>
                              <h3 className={styles.description}>
                                 {category.description}
                              </h3>
                              <div className={styles.btn_container}>
                                 <AppBtn>Explore {category.name}</AppBtn>
                              </div>
                           </>
                        ) : (
                           <></>
                        )}

                        <SlickSlider settings={allCoursesMobile}>
                           {filteredCourses.map((course) => (
                              <CourseCardWidget
                                 courseData={course}
                                 key={course.id}
                              />
                           ))}
                        </SlickSlider>
                     </AccordionDetails>
                  </Accordion>
               );
            })
         )}
      </div>
   );
});

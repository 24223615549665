// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StudentsAreViewing_container__g\\+SgT {\n  display: flex;\n  justify-content: center;\n  position: relative;\n  width: 100%;\n}\n\n.StudentsAreViewing_title__XP-AJ {\n  font-weight: 700;\n  line-height: 1.2;\n  font-size: 1.875rem;\n  margin-bottom: 30px;\n}", "",{"version":3,"sources":["webpack://./src/modules/guest/pages/GuestHome/components/StudentsAreViewing/StudentsAreViewing.module.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;AACH;;AAGA;EACG,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AAAH","sourcesContent":[".container {\n   display: flex;\n   justify-content: center;\n   position: relative;\n   width: 100%;\n   \n}\n\n.title {\n   font-weight: 700;\n   line-height: 1.2;\n   font-size: 1.875rem;\n   margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "StudentsAreViewing_container__g+SgT",
	"title": "StudentsAreViewing_title__XP-AJ"
};
export default ___CSS_LOADER_EXPORT___;

import { useNavigate } from 'react-router-dom';

// project components
import { AppBtn, AppBtnTypes } from '../../../../UI';
import { Divider } from '../../../../UI/Divider';
import { QuizCourseInfo } from '../QuizCourseInfo';

// project import
import useProtectedContent from '../../../hooks/useProtectedContent';
import { ENDPOINTS } from '../../../../../http/studentAPI';

// assets
import MarkdownPreview from '@uiw/react-markdown-preview';

// styles
import styles from './QuizDescription.module.scss';

interface IProps {
   id: string | undefined;
   time: number;
   numberOfQuestions: number;
   name: string;
   description: string;
   imageUrl: string;
   openModal: () => void;
}

export const QuizDescription: React.FC<IProps> = ({
   id,
   time,
   numberOfQuestions,
   name,
   description,
   imageUrl,
   openModal,
}) => {
   const quizMarkdown = useProtectedContent(
      ENDPOINTS.taskMarkdown(id!),
   ) as string;

   const navigate = useNavigate();
   const navigateBack = () => {
      navigate(-1);
   }
   
   return (
      <div className={styles.container}>
         <div className={styles.container__main}>
            <MarkdownPreview
               source={quizMarkdown}
               className={styles.markdown}
               disableCopy
               warpperElement={{
                  'data-color-mode': 'light',
               }}
            />
            
            <Divider>
               Quiz description
            </Divider>

            <ul className={styles.quiz_params}>
               <li>Time limit for quiz: <span className={styles.params_data}>{time} seconds</span></li>
               <li>Number of questions: <span className={styles.params_data}>{numberOfQuestions}</span></li>
            </ul>
         </div>
         <div className={styles.container__side}>
            <div className={styles.container__btn}>
               <AppBtn
                  className={styles.startQuiz_btn}
                  buttonType={AppBtnTypes.success_inverted}
                  onClick={openModal}
               >
                  Start Quiz
               </AppBtn>
               <AppBtn
                  className={styles.startQuiz_btn}
                  buttonType={AppBtnTypes.error_inverted}
                  onClick={navigateBack}
               >
                  Back
               </AppBtn>
            </div>
            <QuizCourseInfo
               name={name}
               description={description}
               imageUrl={imageUrl}
            />
         </div>
      </div>
   );
};

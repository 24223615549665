import { v4 } from 'uuid';
import { observer } from 'mobx-react-lite';

import { TaskTableRow } from '../index';
import { useReviewList } from '../../../hooks';

import { ErrorMessage } from '../../../../UI';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import styles from '../AnswerList.module.scss';

export const TaskReview = observer(() => {
   const { tasks, error } = useReviewList();

   return (
      <div className={styles.tableWrapper}>
         {tasks.length ? (
            <table>
               <tbody>
                  <tr>
                     <th>Group</th>
                     <th>Name</th>
                     <th>Task</th>
                     <th className={styles.submissionCellHeader}>Submission
                        <ExpandMoreIcon fontSize='small'
                        className={styles.submissionCellHeader__icon} />
                     </th>
                     <th>Status</th>
                     <th colSpan={2} />
                  </tr>

                  {tasks.map((task) => (
                     <TaskTableRow key={v4()} task={task} />
                  ))}
               </tbody>
            </table>
         ) : (
            <p className={styles.info}>
               You currently have no review tasks assigned
            </p>
         )}

         {error && <ErrorMessage className={styles.error} />}
      </div>
   );
});

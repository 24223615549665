// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CellName_name__V-rBI {\n  min-width: 140px;\n  max-width: 180px;\n}\n\n.CellName_link__ybO3I {\n  color: #2f3237;\n  font-weight: 500;\n}\n.CellName_link__ybO3I:hover {\n  text-decoration: underline;\n}\n\n.CellName_textOverflow__jgkYT {\n  padding-bottom: 2px;\n  display: block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/AnswerList/commonCells/CellName/CellName.module.scss"],"names":[],"mappings":"AAAA;EACG,gBAAA;EACA,gBAAA;AACH;;AAEA;EACG,cAAA;EACA,gBAAA;AACH;AACG;EACG,0BAAA;AACN;;AAGA;EACG,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,uBAAA;AAAH","sourcesContent":[".name {\n   min-width: 140px;\n   max-width: 180px;\n}\n\n.link {\n   color: #2f3237;\n   font-weight: 500;\n\n   &:hover {\n      text-decoration: underline;\n   }\n}\n\n.textOverflow {\n   padding-bottom: 2px;\n   display: block;\n   overflow: hidden;\n   text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "CellName_name__V-rBI",
	"link": "CellName_link__ybO3I",
	"textOverflow": "CellName_textOverflow__jgkYT"
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from 'react';
import { useRootStore } from '../../../hooks/mobx';
import useAuthHost from '../../../hooks/useAuthHost';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
   IQuizQuestion,
   ICourse,
   ICoursesList,
   IQuiz,
   ITask,
   IScoreData,
   IActiveCompletedCourseList,
} from '../interfaces/interfaces';

type StudentData =
   | ICoursesList[]
   | IActiveCompletedCourseList
   | IScoreData
   | ICourse
   | ITask
   | IQuiz
   | IQuizQuestion[]
   | string
   | undefined;

const useProtectedContent = (url: string, isHomepage = false): StudentData => {
   const { userStore } = useRootStore();
   const role = userStore.getUserRole();
   const authHost = useAuthHost();
   const navigate = useNavigate();
   const location = useLocation();
   const [content, setContent] = useState();

   useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();

      if (role === 'STUDENT') {
         const getContent = async () => {
            try {
               const response = await authHost.get(url);
               
               isMounted && setContent(response.data);
            } catch (error) {
               const err = error as AxiosError;
               
               if (err.response?.status === 401) return;

               if (err.response?.status === 404) {
                  return navigate('/not-found', {
                     state: { from: location, isHomepage },
                     replace: true,
                  });
               }
               navigate('/connection-failed', {
                  state: { from: location, isHomepage },
                  replace: true,
               });
            }
         };

         getContent();
      }

      return () => {
         isMounted = false;
         controller.abort();
      };
   }, []);

   return content;
};

export default useProtectedContent;

import { useState, useEffect } from 'react';
//styles
import styles from './MentorSection.module.scss';

const mockName = 'Eddie Bryan';

export const MentorSection = () => {
   const [letters, setLetters] = useState<string>();

   useEffect(() => {
      const nameArr = mockName.split(' ');
      setLetters(nameArr[0][0] + nameArr[1][0]);
   }, []);

   return (
      <section className={styles.mentor_section}>
         <div className={styles.avatar}>{letters}</div>
         <div className={styles.names}>
            <p>{mockName}</p>
            <p>Mentor</p>
         </div>
      </section>
   );
};

import { forwardRef, HTMLAttributes } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme, Theme } from '@mui/material/styles';
import {
   Card,
   CardContent,
   CardHeader,
   Divider,
   Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';

// project imports
// import Highlighter from './third-party/Highlighter';

// header style
const headerSX = {
   p: 2.5,
   '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
};

type MainCardProps = HTMLAttributes<HTMLDivElement> & {
   border?: boolean;
   boxShadow?: string | boolean;
   children?: React.ReactNode;
   content?: boolean;
   contentSX?: object;
   darkTitle?: boolean;
   divider?: boolean;
   elevation?: number;
   secondary?: React.ReactNode;
   shadow?: boolean;
   title?: string;
   codeHighlight?: boolean;
   sx?: SxProps;
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

export const MainCard: React.FC<MainCardProps> = forwardRef<HTMLDivElement, MainCardProps>(
   (
      {
         border = true,
         boxShadow,
         children,
         content = true,
         contentSX = {},
         darkTitle,
         divider = true,
         elevation,
         secondary,
         shadow,
         title,
         codeHighlight,
         sx = {},
         ...others
      },
      ref,
   ) => {
      const theme = useTheme();
      //   boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

      return (
         <Card
            elevation={elevation || 0}
            ref={ref}
            {...others}
            sx={{
               ...sx,
               border: border ? '1px solid' : 'none',
               borderRadius: 2,
                 borderColor: theme.palette.divider,
                 boxShadow: (theme: Theme) => theme.shadows[5],
                 ':hover': {
                     boxShadow: (theme: Theme) => theme.shadows[10]
                 },
               '& pre': {
                  m: 0,
                  p: '16px !important',
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '0.75rem',
               },
            }}
         >
            {/* card header and action */}
            {!darkTitle && title && (
               <CardHeader
                  sx={headerSX}
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  title={title}
                  action={secondary}
               />
            )}
            {darkTitle && title && (
               <CardHeader
                  sx={headerSX}
                  title={<Typography variant='h3'>{title}</Typography>}
                  action={secondary}
               />
            )}

            {/* content & header divider */}
            {title && divider && <Divider />}

            {/* card content */}
            {content && <CardContent sx={contentSX}>{children}</CardContent>}
            {!content && children}

            {/* card footer - clipboard & highlighter  */}
            {/* {codeHighlight && (
                    <>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                        <Highlighter codeHighlight={codeHighlight} main>
                            {children}
                        </Highlighter>
                    </>
                )} */}
         </Card>
      );
   },
);
import { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
   List,
   ListItemButton,
   ListItemIcon,
   ListItemText,
} from '@mui/material';
import { 
   EditOutlined, 
   ListAltOutlined,
   LogoutOutlined,
   PersonOutlined,
   AccountBalanceWalletOutlined
} from '@mui/icons-material';


// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
   handleLogout: () => void;
}

export const ProfileTab: React.FC<Props> = ({ handleLogout }) => {
   const theme = useTheme();

   const [selectedIndex, setSelectedIndex] = useState(0);
   const handleListItemClick = (
      event: React.MouseEvent<HTMLDivElement>,
      index: number,
   ) => {
      setSelectedIndex(index);
   };

   return (
      <List
         component='nav'
         sx={{
            p: 0,
            '& .MuiListItemIcon-root': {
               minWidth: 32,
               color: theme.palette.grey[500],
            },
         }}
      >
         <ListItemButton
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
         >
            <ListItemIcon>
               <EditOutlined />
            </ListItemIcon>
            <ListItemText primary='Edit Profile' />
         </ListItemButton>
         <ListItemButton
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
         >
            <ListItemIcon>
               <PersonOutlined />
            </ListItemIcon>
            <ListItemText primary='View Profile' />
         </ListItemButton>

         <ListItemButton
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
         >
            <ListItemIcon>
               <ListAltOutlined />
            </ListItemIcon>
            <ListItemText primary='Social Profile' />
         </ListItemButton>
         <ListItemButton
            selected={selectedIndex === 4}
            onClick={(event) => handleListItemClick(event, 4)}
         >
            <ListItemIcon>
               <AccountBalanceWalletOutlined />
            </ListItemIcon>
            <ListItemText primary='Billing' />
         </ListItemButton>
         <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
            <ListItemIcon>
               <LogoutOutlined />
            </ListItemIcon>
            <ListItemText primary='Logout' />
         </ListItemButton>
      </List>
   );
};
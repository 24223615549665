// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Submenu_wrapper__26hgy {\n  height: 100%;\n  transition: width 0.15s ease-out;\n  position: absolute;\n  top: 0;\n  right: 0;\n  z-index: 2222;\n  background-color: white;\n}\n\n.Submenu_button__K1Cp\\+ {\n  color: #000000;\n  font-size: 1rem;\n  background-color: aliceblue;\n  width: 100%;\n  text-align: left;\n  padding: 17px 0;\n  border: 0;\n}\n.Submenu_button__K1Cp\\+:hover {\n  border: 0;\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/Layout/ManagerLayout/Drawer/Submenu/Submenu.module.scss"],"names":[],"mappings":"AAAA;EACG,YAAA;EACA,gCAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,aAAA;EACA,uBAAA;AACH;;AAEA;EACG,cAAA;EACA,eAAA;EACA,2BAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,SAAA;AACH;AAAG;EACG,SAAA;AAEN","sourcesContent":[".wrapper {\n   height: 100%;\n   transition: width 0.15s ease-out;\n   position: absolute;\n   top: 0;\n   right: 0;\n   z-index: 2222;\n   background-color: white;\n}\n\n.button {\n   color: #000000;\n   font-size: 1rem;\n   background-color: aliceblue;\n   width: 100%;\n   text-align: left;\n   padding: 17px 0;\n   border: 0;\n   &:hover {\n      border: 0;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Submenu_wrapper__26hgy",
	"button": "Submenu_button__K1Cp+"
};
export default ___CSS_LOADER_EXPORT___;

// Material
import { useMediaQuery, Theme } from '@mui/material';
// Components
import { Header, Hero, Main } from './components';

export const CoursePreview: React.FC = () => {
   const matchesSm = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('sm'),
   );

   return (
      <>
         <Hero />

         <div>
            {matchesSm && <Header />}

            <Main />
         </div>
      </>
   );
};

import { FC } from 'react';
import { BorderedBlockContainer, Button } from '../../../UI';
import { IStudentData } from '../../../../http/mentorAPI';
import { publicURL } from '../../../../utils/consts';
import styles from './ProfileMain.module.scss';

interface IProps {
   data: IStudentData;
   userRole: string;
}

export const ProfileMain: FC<IProps> = ({ data, userRole }) => {
   const { firstName, lastName, email, phone, skype, telegram, avatarLogoUrl } =
      data;
   return (
      <BorderedBlockContainer
         className={`${styles.container} ${styles.information}`}
      >
         <div className={styles.main}>
            <h4 className={styles.subheading}>Information</h4>
            <ul className={styles.main_list}>
               <li>
                  <span>First Name:</span> {firstName}
               </li>
               <li>
                  <span>Last Name:</span> {lastName}
               </li>
               <li>
                  <span>Email:</span> {email}
               </li>
               <li>
                  <span>Telegram:</span> {telegram}
               </li>
               <li>
                  <span>Skype:</span> {skype}
               </li>
               <li>
                  <span>Phone:</span> {phone}
               </li>
            </ul>
            {userRole === 'MANAGER' && (
               <Button
                  buttonText={'Edit profile'}
                  buttonClass='profile'
                  width='150px'
               />
            )}
         </div>

         {avatarLogoUrl ? (
            <div className={styles.avatar}>
               <img
                  src={`${avatarLogoUrl}`}
                  alt='avatarLogo'
                  className={styles.avatar_logo}
               />
            </div>
         ) : (
            <div className={styles.avatar}>
               <img
                  src={`${publicURL}/images/avatar.png`}
                  alt='Students avatar'
                  className={styles.avatar_logo}
               />
            </div>
         )}
      </BorderedBlockContainer>
   );
};

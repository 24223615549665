import { useState, useEffect } from 'react';

// material-ui
import Chip from '@mui/material/Chip';

// styles
import styles from './ArticleItem.module.scss';

interface Props {
  article: {
    id: number;
    topic: string;
    category: string;
    userName: string;
    userID: number;
    title: string;
    details: string;
    course: string;
    answers: {
      answerID: number;  
      answerText: string;
      author: string;
      }[];
 };
}

export const ArticleItem: React.FC<Props> = ({ article }) => {
  const { userName, title, course, answers } = article;
  
  const [letters, setLetters] = useState<string>();

   useEffect(() => {
      const nameArr = userName.split(' ');
      setLetters(nameArr[0][0] + nameArr[1]);
   }, []);

   return (
      <div className={styles.container}>
         <div className={styles.name_block}>
            <div className={styles.avatar}>{letters}</div>
            <div>
               <h5 className={styles.public_date}>2 days ago</h5>
               <h4 className={styles.name}>{userName}</h4>
            </div>
         </div>
         <div className={styles.questions_block}>
            <div className={styles.question}>{title}</div>
            {course ? <Chip label={course} className={styles.course} /> : <></>}
            
         </div>
         <div className={styles.quantity_block}>
            <p className={styles.qnt}>{answers.length}</p>
            <p className={styles.answers}>answers</p>
         </div>
      </div>
   );
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AllCourses_section_container__2QUnj {\n  margin-top: 60px;\n}", "",{"version":3,"sources":["webpack://./src/modules/allCourses/pages/AllCourses.module.scss"],"names":[],"mappings":"AAAA;EACG,gBAAA;AACH","sourcesContent":[".section_container {\n   margin-top: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section_container": "AllCourses_section_container__2QUnj"
};
export default ___CSS_LOADER_EXPORT___;

// project components
import { NavCard } from './NavCard';
import { Navigation } from './Navigation';
import { SimpleBarScroll } from '../../../common'; 

// ==============================|| DRAWER CONTENT ||============================== //

export const DrawerContent = () => (
    <SimpleBarScroll
        sx={{
            '& .simplebar-content': {
                display: 'flex',
                flexDirection: 'column'
            }
        }}
    >
        <Navigation />
        <NavCard />
    </SimpleBarScroll>
);
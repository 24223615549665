// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TaskAndResultAccordion_accordion__yb4G1 {\n  border: 1px solid rgba(0, 0, 0, 0.12);\n}\n\n.TaskAndResultAccordion_icon__2ERJq {\n  color: #000000;\n}\n\n.TaskAndResultAccordion_summary__jl8LW {\n  flex-direction: row-reverse;\n  background-color: #fff;\n}\n\n.TaskAndResultAccordion_accordion_title__998IB {\n  font-weight: 700;\n  margin-left: 4px;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n}\n@media screen and (min-width: 600px) {\n  .TaskAndResultAccordion_accordion_title__998IB {\n    font-size: 16px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/studentCourse/TasksAndResultSection/components/Accordion/TaskAndResultAccordion.module.scss"],"names":[],"mappings":"AAEA;EACG,qCAAA;AADH;;AAIA;EACG,cAAA;AADH;;AAIA;EACG,2BAAA;EACA,sBAAA;AADH;;AAIA;EACG,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AADH;AAGG;EAPH;IAQM,eAAA;EAAJ;AACF","sourcesContent":["@use '../../../../../../../theme/config' as v;\n\n.accordion {\n   border: 1px solid v.$divider-light;\n}\n\n.icon {\n   color: #000000;\n}\n\n.summary {\n   flex-direction: row-reverse;\n   background-color: #fff;\n}\n\n.accordion_title {\n   font-weight: 700;\n   margin-left: 4px;\n   font-size: 14px;\n   display: flex;\n   align-items: center;\n\n   @media screen and (min-width: v.$sm) {\n      font-size: 16px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": "TaskAndResultAccordion_accordion__yb4G1",
	"icon": "TaskAndResultAccordion_icon__2ERJq",
	"summary": "TaskAndResultAccordion_summary__jl8LW",
	"accordion_title": "TaskAndResultAccordion_accordion_title__998IB"
};
export default ___CSS_LOADER_EXPORT___;

import { v4 } from 'uuid';
import { PageContainer, SlickSlider } from '../../../../../UI'
import CardCommentOfTeacher from './CardCommentOfTeacher/CardCommentOfTeacher'
import { commentsTeacher } from '../../../../../UI/SlickSlider/Settings';
import { dataCommentsOfTeachers } from './dataCommentsOfTeachers';
import style from './CommentsOfTeachers.module.scss'

export const CommentsOfTeachers = () => {
  return (
    <div className={style.container}>
       <PageContainer>
<SlickSlider settings={commentsTeacher}>
    {dataCommentsOfTeachers.map((comment) => (
       <CardCommentOfTeacher
         imgUser={comment.img}
          userName={comment.name}
          comment={comment.description}
          course={comment.title}
          key={v4()}
       />
    ))}
 </SlickSlider>
    </PageContainer>
    </div>
   
    
  )
}


import { useState, useEffect } from 'react';
import {
   getAllMentorsData,
   IAllMentorsData,
} from '../../../../http/spectatorAPI';
import { Loader } from '../../../student/components/Loader';
import {
   BorderedBlockContainer,
   CollapsibleSectionContainer,
   PageContainer,
} from '../../../UI';
import AllMentorsBox from '../../components/AllMentorsBox/AllMentorsBox';

// Styles
import styles from './AllMentorsPage.module.scss';

export const AllMentorsPage = () => {
   const [AllMentorsData, setAllMentorsData] = useState<IAllMentorsData[]>([]);

   useEffect(() => {
      getAllMentorsData().then((data) => setAllMentorsData(data.mentors));
   }, []);

   return (
      <div className={styles.wrapper}>
         <PageContainer>
            <CollapsibleSectionContainer title='All Mentors Content'>
               <BorderedBlockContainer>
                  {AllMentorsData.length ? (
                     AllMentorsData.map(({ id, firstName, courses }) => {
                        return (
                           <AllMentorsBox
                              key={id}
                              firstName={firstName}
                              courses={courses}
                           />
                        );
                     })
                  ) : (
                     <Loader />
                  )}
               </BorderedBlockContainer>
            </CollapsibleSectionContainer>
         </PageContainer>
      </div>
   );
};

import { v4 } from 'uuid';

// project imports
import { uIElements } from '../../../../consts/consts';

// types
import { QuizResult } from '../../../../interfaces/interfaces';

// styles
import styles from './ScoreDetails.module.scss';

interface Props {
   score: QuizResult;
}

const ScoreDetails: React.FC<Props> = ({ score }) => {
   const {
      questionType,
      description,
      answer,
      correctAnswer,
      weight,
      score: scoreGained,
      correctMatchingAnswer,
      matchingAnswer,
   } = score;

   const usualDetails = questionType !== 'MATCHING' && (
      <>
         {correctAnswer && (
            <div>
               <p>Correct Answer: </p>
               <ol className={styles.answers}>
                  {correctAnswer.map((item) => (
                     <li key={v4()}>{item}</li>
                  ))}
               </ol>
            </div>
         )}
         <div>
            <p>Your Answer:</p>
            <ol className={styles.answers}>
               {answer.map((item) => (
                  <li key={v4()}>{item}</li>
               ))}
            </ol>
         </div>
      </>
   );

   const matchingDetails = questionType === 'MATCHING' && (
      <>
         <div>
            <p>Correct Answers: </p>
            <ol className={styles.answers}>
               {Object.keys(correctMatchingAnswer).map((item) => (
                  <li key={v4()}>
                     {correctMatchingAnswer[item]}: {item}
                  </li>
               ))}
            </ol>
         </div>
         <div>
            <p>Your Answers: </p>
            <ol className={styles.answers}>
               {Object.keys(matchingAnswer).map((item) => (
                  <li key={v4()}>
                     {correctMatchingAnswer[item]}: {item}
                  </li>
               ))}
            </ol>
         </div>
      </>
   );

   return (
      <div className={styles.score}>
         <div className={styles.heading}>Quiz Details:</div>
         <div className={styles.grid_info}>
            <div>
               <p>Question: </p>
               {description}
            </div>
            <div>
               <p>Weight: </p>
               {weight}
            </div>
            <div>
               <p>Type: </p>
               {uIElements[questionType as keyof typeof uIElements].subTitle}
            </div>
            <div>
               <p>Your Score: </p>
               {scoreGained}
            </div>
         </div>
         {usualDetails || matchingDetails}
      </div>
   );
};

export default ScoreDetails;

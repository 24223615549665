import Keycloak from 'keycloak-js';
import { RoleType, keycloakURL } from './consts';
import { IUser } from '../store/UserStore';
import { JwtPayload } from 'jwt-decode';

interface JwtTokenPayload extends JwtPayload {
  family_name: string;
  given_name: string;
  email: string;
  realm_access: {
    roles: RoleType[];
  };
  resource_access: {
    [key: string]: {
      roles: RoleType[];
    };
  };
}

const clientId = 'Training-service-client';
const realm = 'training-service-realm';
const _kc = new Keycloak({
  url: keycloakURL,
  realm,
  clientId,
});

_kc.onTokenExpired = () => {
  _kc.updateToken(50).catch((err) => {});
};

_kc.onAuthRefreshError = () => {
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('isAuth');
  doLogin();
};

const initKeycloak = async (onAuthenticatedCallback: Function) => {
  _kc.init({
    onLoad: 'check-sso',
    redirectUri: window.location.href,
  })
    .then((authenticated) => {
      if (authenticated) {
        const { family_name, given_name, email, resource_access } =
          getTokenParsed() as JwtTokenPayload;
        const user: IUser = {
          id: 5,
          email,
          role: resource_access[clientId].roles[0],
          country: 'Ukraine',
          firstName: given_name,
          lastName: family_name,
        };
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('isAuth', JSON.stringify(true));
      } else {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('isAuth');
      }
    })
    .catch((err) => console.log(err))
    .finally(() => onAuthenticatedCallback());
};

const doLogin = () =>
  _kc.login({
    redirectUri: window.location.href,
  });

const doLogout = () =>
  _kc.logout({
    redirectUri: window.location.origin,
  });

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
};

export default UserService;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StudentInfo_container__c6zw0 {\n  min-height: 60vh;\n}\n\n.StudentInfo_item__title__rwnoi {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n.StudentInfo_item__title__rwnoi p {\n  margin-right: 10px;\n}\n.StudentInfo_item__title__rwnoi a {\n  color: black;\n}\n.StudentInfo_item__title__rwnoi a:hover {\n  text-decoration: underline;\n}\n.StudentInfo_item__name__v0lAY {\n  min-width: 250px;\n  padding-right: 15px;\n}\n\n@media all and (max-width: 768px) {\n  .StudentInfo_item__title__rwnoi {\n    flex-direction: column;\n    flex-wrap: wrap;\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/viewAllStudents/components/AllStudents/StudentInfo/StudentInfo.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAGI;EACI,aAAA;EACA,8BAAA;EACA,eAAA;AAAR;AACQ;EACI,kBAAA;AACZ;AACQ;EACI,YAAA;AACZ;AAAY;EACI,0BAAA;AAEhB;AAEI;EACI,gBAAA;EACA,mBAAA;AAAR;;AAIA;EAEM;IACE,sBAAA;IACA,eAAA;EAFN;AACF","sourcesContent":[".container {\n    min-height: 60vh;\n}\n\n.item {\n    &__title {\n        display: flex;\n        justify-content: space-between;\n        flex-wrap: wrap;\n        p {\n            margin-right: 10px;\n        }\n        a {\n            color: black;\n            &:hover {\n                text-decoration: underline;\n            }\n        }\n    }\n    &__name {\n        min-width: 250px;\n        padding-right: 15px;\n    }\n}\n\n@media all and (max-width: 768px) {\n    .item {\n      &__title {\n        flex-direction: column;\n        flex-wrap: wrap;\n      }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "StudentInfo_container__c6zw0",
	"item__title": "StudentInfo_item__title__rwnoi",
	"item__name": "StudentInfo_item__name__v0lAY"
};
export default ___CSS_LOADER_EXPORT___;

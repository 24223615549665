import useProtectedContent from '../../../hooks/useProtectedContent';
import { IScoreData } from '../../../interfaces/interfaces';
import { CollapsibleSectionContainer } from '../../../../UI';
import { v4 } from 'uuid';
import { ENDPOINTS } from '../../../../../http/studentAPI';
import { ResultsTableCard } from './ResultsTableCard/ResultsTableCard';
import styles from './ResultsTab.module.scss';

interface Props {
   id: number;
}

const ResultsTab: React.FC<Props> = ({ id }) => {
   const result = useProtectedContent(ENDPOINTS.resultsTab(id)) as IScoreData;
   const topics = result && result.topicToWorkQuizScore;

   return (
      <div className={styles.page}>
         {result && (
            <div className={styles.container}>
               <div className={styles.header}>
                  <div className={styles.title}>Title</div>
                  <div className={styles.col}>Weight</div>
                  <div className={styles.col}>Range</div>
                  <div className={styles.col}>Score</div>
                  <div className={styles.col}>Contribution to Course</div>
               </div>

               {Object.keys(topics).map((topic, i) => (
                  <CollapsibleSectionContainer
                     key={v4()}
                     className={styles.collapsible}
                     title={topic}
                     open={i === 0}
                  >
                     {topics[topic as keyof typeof topics].map((result) => (
                        <ResultsTableCard key={v4()} result={result} />
                     ))}
                  </CollapsibleSectionContainer>
               ))}
            </div>
         )}
      </div>
   );
};

export default ResultsTab;

import { FC } from 'react';
import { v4 } from 'uuid';
//styles
import styles from './Filter.module.scss';

interface IProps {
    filterValueSelected: (filterValue: any) => void;
}

export const Filter: FC<IProps> = ({filterValueSelected}) => {

    const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
        filterValueSelected(event.currentTarget.value)
    }

    return (
        <div>
            <select id={v4()} className={styles.form_select} defaultValue='all' onChange={handleChange}>
                <option id='all' value='all'>All courses</option>
                <option id='1' value='React Js'>React Js</option> 
                <option id='2' value='Java Spring Development'>Java Spring Development</option> 
            </select>
        </div>
    )
} 
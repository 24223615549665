// Mui
import { useMediaQuery, Theme } from '@mui/material';
// Components
import {
   Sidebar,
   WhatLearn,
   CourseContent,
   Requirements,
   StudentsEnroll,
   GoPro,
   Authors,
   Comments
} from '../index';
// Styles
import styles from './Main.module.scss';

export const Main: React.FC = () => {
   const matchesMd = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('md'),
   );

   return (
      <div className={styles.wrapper}>
         <div className={styles.container}>
            <div className={styles.content}>
               <WhatLearn />
               <CourseContent />
               <Requirements />
               <StudentsEnroll />
               <GoPro />
               <Authors />
               <Comments />
            </div>
            {matchesMd && <Sidebar />}
         </div>
      </div>
   );
};

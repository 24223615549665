// assets
import { 
    SpeedOutlined, 
    BookOutlined,
 } from '@mui/icons-material';
// icons
const icons = {
    SpeedOutlined,
    BookOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.SpeedOutlined,
            breadcrumbs: false
        },
        {
            id: 'my-courses',
            title: 'My Courses',
            type: 'item',
            url: '#',
            icon: icons.BookOutlined,
            breadcrumbs: false
        },
        {
            id: 'all-courses',
            title: 'All Courses',
            type: 'item',
            url: '/all-courses',
            icon: icons.BookOutlined,
            breadcrumbs: false
        },
    ]
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuizQuestion_titleWrapper__vXVUl {\n  display: flex;\n  justify-content: space-between;\n  margin: 0 0 15px;\n}\n\n.QuizQuestion_title__F40ab {\n  font-size: 20px;\n  font-weight: 600;\n}\n\n.QuizQuestion_counter__hOlcb {\n  text-align: center;\n  font-size: 16px;\n  font-weight: 600;\n}\n\n.QuizQuestion_questionTitle__K-iIB {\n  font-size: 18px;\n  font-weight: 400;\n  margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/QuizQuestion/QuizQuestion.module.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,8BAAA;EACA,gBAAA;AACH;;AAEA;EACG,eAAA;EACA,gBAAA;AACH;;AAEA;EACG,kBAAA;EACA,eAAA;EACA,gBAAA;AACH;;AAEA;EACG,eAAA;EACA,gBAAA;EACA,mBAAA;AACH","sourcesContent":[".titleWrapper {\n   display: flex;\n   justify-content: space-between;\n   margin: 0 0 15px;\n}\n\n.title {\n   font-size: 20px;\n   font-weight: 600;\n}\n\n.counter {\n   text-align: center;\n   font-size: 16px;\n   font-weight: 600;\n}\n\n.questionTitle {\n   font-size: 18px;\n   font-weight: 400;\n   margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrapper": "QuizQuestion_titleWrapper__vXVUl",
	"title": "QuizQuestion_title__F40ab",
	"counter": "QuizQuestion_counter__hOlcb",
	"questionTitle": "QuizQuestion_questionTitle__K-iIB"
};
export default ___CSS_LOADER_EXPORT___;

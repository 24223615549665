import img1 from '../../../assets/images/coursePreview/vue-img-2.jpg'
import img2 from '../../../assets/images/coursePreview/vue-img-3.jpg'
import img3 from '../../../assets/images/coursePreview/vue-img-4.jpg'
import img4 from '../../../assets/images/coursePreview/vue-img-5.jpg'


export const courses = [
   {
      id: 1,
      title: 'Vue - The Complete Guide (incl. Router & Composition API)',
      img: img1,
      rating: 4.7,
      studentsNumber: 218741,
      price: 19.99,
      totalTasks: 99,
      lastUpdate: '11/2022',
   },
   {
      id: 2,
      title: 'Vue Masterclass',
      img: img2,
      rating: 4.5,
      studentsNumber: 4098,
      price: 15.99,
      totalTasks: 89,
      lastUpdate: '4/2023',
   },
   {
      id: 3,
      title: 'The Vue 3 Bootcamp - The Complete Developer Guide',
      img: img3,
      rating: 4.4,
      studentsNumber: 1068,
      price: 14.99,
      totalTasks: 79,
      lastUpdate: '10/2022',
   },
   {
      id: 4,
      title: 'Build Web Apps with Vue JS 3 & Firebase',
      img: img4,
      rating: 4.7,
      studentsNumber: 28600,
      price: 17.99,
      totalTasks: 69,
      lastUpdate: '11/2022',
   },
];

export const ENDPOINTS = {
   homepage: `/api/work-courses/student`,
   course: (title: string) => `api/work-courses/title/${title}`,
   resultsTab: (id: number) => `/api/course-items/student/${id}/grades`,
   task: (id: string) => `/api/course-items/student/code-assignments/${id}/`,
   taskMarkdown: (id: string) => `/api/course-items/markdown/${id}`,
   quiz: (id: string) => `/api/course-items/student/quizzes/${id}`,
   quizActive: (id: string) => `/api/course-items/student/quizzes/${id}/start`,
   courseСompletionStatus : (userId: number,isCompleted:boolean) =>`/api/work-courses/student/${userId}/all/${isCompleted}`
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './FeedbackSubmittedCheckmark.module.scss';

const FeedbackSubmittedCheckmark = () => {
   return (
      <div className={styles.wrapper} title='Feedback submitted successfully'>
         <FontAwesomeIcon icon={faCheck} size='xl' className={styles.icon} />
      </div>
   );
};

export default FeedbackSubmittedCheckmark;

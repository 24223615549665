// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CompeletedCoursesPage_container__\\+YAW2 {\n  padding: 2rem 0;\n}\n@media screen and (min-width: 600px) {\n  .CompeletedCoursesPage_container__\\+YAW2 {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 2rem;\n  }\n}\n\n.CompeletedCoursesPage_empty__7iySY {\n  margin: 2rem;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/pages/Home/nestedPages/CompletedCoursesPage/CompeletedCoursesPage.module.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;AACH;AACG;EAHH;IAIM,aAAA;IACA,8BAAA;IACA,cAAA;EAEJ;AACF;;AACA;EACG,YAAA;EACA,kBAAA;AAEH","sourcesContent":[".container {\n   padding: 2rem 0;\n\n   @media screen and (min-width: 600px) {\n      display: grid;\n      grid-template-columns: 1fr 1fr;\n      grid-gap: 2rem;\n   }\n}\n\n.empty{\n   margin: 2rem;\n   text-align: center;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CompeletedCoursesPage_container__+YAW2",
	"empty": "CompeletedCoursesPage_empty__7iySY"
};
export default ___CSS_LOADER_EXPORT___;

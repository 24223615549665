import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project imports
import { DrawerHeaderStyled } from './DrawerHeaderStyled';
import { publicURL } from '../../../../../../utils/consts';

// ==============================|| DRAWER HEADER ||============================== //

export const DrawerHeader = ({ open }: { open: boolean }) => {
   const theme = useTheme();

   return (
      <DrawerHeaderStyled theme={theme} open={open}>
         <Stack direction='row' spacing={1} alignItems='center'>
         <img
            style={{ height: '50px' }}
            src={`${publicURL}/images/logo-small.png`}
            alt='Foxminded Logo'
         />
         </Stack>
      </DrawerHeaderStyled>
   );
};

DrawerHeader.propTypes = {
   open: PropTypes.bool,
};
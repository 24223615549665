import { v4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import { FavoriteBorderOutlined, FavoriteOutlined } from '@mui/icons-material';
// Project Import
import { publicURL } from '../../../../utils/consts';
import {
   useLocalData,
   useWishlistBtn,
   castFeature,
   icons,
} from '../../library';
import { useScrollPosition } from '../../../../hooks';
// Project Components
import { AppBtn, AppBtnTypes } from '../../../UI';
// Styles
import styles from './Sidebar.module.scss';
import {useNavigate} from "react-router-dom";

export const Sidebar: React.FC = observer(() => {
   const { scrollPosition } = useScrollPosition();
   const navigate = useNavigate();
   const scrollAtTop = scrollPosition < 360;

   const { sidebarData } = useLocalData();
   const { price, features } = sidebarData;

   const { isEnlisted, toggleWishlist } = useWishlistBtn();

  const handleClick = () => {
    navigate('/mentors/vue-js');
  };

   return (
      <div
         style={{
            minHeight: '100%',
            transform: scrollAtTop ? 'translateY(-350px)' : undefined,
         }}
      >
         <div className={styles.wrapper}>
            {scrollAtTop && (
               <div className={styles.img_wrapper}>
                  <img
                     src={`${publicURL}/images/temp/preview/vue-js.jpg`}
                     alt='Course Preview'
                  />
               </div>
            )}

            <div className={styles.container}>
               <section className={styles.btns_section}>
                  <h3 className={styles.price}>${price}</h3>
                  <div className={styles.btns}>
                     <AppBtn
                        buttonType={AppBtnTypes.secondary_dark}
                        className={styles.cart_btn}
                     >
                        Add to cart
                     </AppBtn>
                    <AppBtn
                      buttonType={AppBtnTypes.secondary_dark}
                      className={styles.cart_btn}
                      onClick={handleClick}
                    >
                      Mentors
                    </AppBtn>
                     <AppBtn
                        onClick={toggleWishlist}
                        className={styles.wishlist_btn}
                     >
                        {isEnlisted ? <FavoriteOutlined /> : <FavoriteBorderOutlined />}
                     </AppBtn>
                  </div>
               </section>

               <section className={styles.features_section}>
                  <h5>This course includes:</h5>
                  {Object.keys(features).map((feature) => {
                     const Icon = icons[feature as keyof typeof icons] || null;
                     const value = features[feature as keyof typeof features];
                     return (
                        <div key={v4()} className={styles.feature}>
                           {value && Icon && <Icon sx={{ fontSize: 18 }} className={styles.icon} />}
                           {value && castFeature(feature, value)}
                        </div>
                     );
                  })}
               </section>

               <div className={styles.links_container}>
                  <button className={styles.share_btn}>Share</button>
                  <button className={styles.coupon_btn}>Apply coupon</button>
               </div>

               <section className={styles.classes_section}>
                  <h4>Have a team of 5 or more?</h4>
                  <p>
                     Get your team access an exclusive offer anytime, anywhere
                  </p>
                  <AppBtn className={styles.classes_btn}>
                     Try Classes on Training
                  </AppBtn>
               </section>
            </div>
         </div>
      </div>
   );
});

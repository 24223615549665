import { FC } from 'react';
// ui
import { AppBtn, AppBtnTypes } from '../../../UI';

// hooks
import useCourseImage from '../../hooks/useCourseImage';

// styles
import styles from './TaskCourseInfo.module.scss';

// mockData
import { courseInfo } from '../../mockData';

interface IProp {
   imageURL: string;
   courseName: string;
   mentorName?: string;
}

export const TaskCourseInfo: FC<IProp> = ({
   imageURL,
   courseName,
   mentorName,
}) => {
   const image = useCourseImage(imageURL);

   return (
      <>
         <div className={styles.course}>
            <div className={styles.course_head_container}>
               <h5 className={styles.heading_info}>Course</h5>
               <span className={styles.decor} />
            </div>

            <div className={styles.course_info}>
               <div className={styles.title_course_image_container}>
                  <img src={image} className={styles.title_course_image} />
               </div>

               <div>
                  <p>{courseName}</p>
                  <p className={styles.lessons}>
                     {courseInfo.course.lessons} Lessons
                  </p>
               </div>
            </div>
         </div>
         <div className={styles.course}>
            <div className={styles.course_head_container}>
               <h5 className={styles.heading_info}>Author</h5>
               <span className={styles.decor} />
            </div>
            <div className={styles.course_info}>
               <div className={styles.author_image_container}>
                  <img
                     width={'50px'}
                     src={require('../../../../assets/images/Task/author.jpg')}
                     alt=''
                     className={styles.author_image}
                  />
               </div>
               <div>
                  <p>{mentorName}</p>
                  <p className={styles.lessons}>{courseInfo.author.status}</p>
               </div>
            </div>
            <p className={styles.about_author}>{courseInfo.author.about}</p>
            <AppBtn buttonType={AppBtnTypes.base} className={styles.follow_btn}>
               follow
            </AppBtn>
         </div>
      </>
   );
};

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

// assets
import MarkdownPreview from '@uiw/react-markdown-preview';

// project components
import { BasicInput } from '../../../../UI';
import { Divider } from '../../../../UI/Divider';

// project imports
import { useRootStore } from '../../../../../hooks';

// types
import { IChoiceAnswer, IQuizQuestion } from '../../../interfaces/interfaces';

// styles
import styles from './QuizExactText.module.scss';

interface IProps {
   answers: IChoiceAnswer[];
   question: IQuizQuestion;
   title: string;
}

export const QuizExactText: React.FC<IProps> = observer(({ question }) => {
   const [inputValue, setInputValue] = useState<string>('');
   const { studentStore } = useRootStore();
   const { questionId } = question;

   useEffect(() => {
      inputValue &&
         studentStore.setActualValue({
            questionId: questionId,
            exactAnswer: inputValue,
         });
   }, [inputValue]);

   return (
      <div className={styles.wrapper}>
         <MarkdownPreview
            source={question.description}
            className={styles.markdown}
            disableCopy
         />
    
         <Divider>
            Your answer
         </Divider>
            
         <BasicInput
            value={inputValue}
            placeholder='Your answer'
            setValue={setInputValue}
            style={{
               width: '100%',
               maxWidth: '100%',
               borderColor: '#5e718c',
            }}
         />

         <p className={styles.description}>
            Note: Keep your response as short as possible, avoid using
            special characters if not needed. The answer should be a word, a
            number or a short phrase.
         </p>
      </div>
   );
});

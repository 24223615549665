import { v4 } from 'uuid';
import { observer } from 'mobx-react-lite';

import { AllAnswersTableRow } from '../index';
import { useReviewList } from '../../../hooks';

import { ErrorMessage } from '../../../../UI';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import styles from '../AnswerList.module.scss';

export const AllAnswers = observer(() => {
   const { answers, error } = useReviewList();

   return (
      <div className={styles.tableWrapper}>
         {answers.length ? (
            <table>
               <tbody>
                  <tr className={styles.tableHeading}>
                     <th>Group</th>
                     <th>Name</th>
                     <th>Task</th>
                     <th className={styles.submissionCellHeader}>Submission
                        <ExpandMoreIcon fontSize='small'
                        className={styles.submissionCellHeader__icon} />
                     </th>
                     <th>Status</th>
                  </tr>

                  {answers.map((item) => (
                     <AllAnswersTableRow key={v4()} task={item} />
                  ))}
               </tbody>
            </table>
         ) : (
            <p className={styles.info}>
               You don't have any answers from your students yet
            </p>
         )}

         {error && <ErrorMessage className={styles.error} />}
      </div>
   );
});

import { QuestionsType } from '../utils/consts';
import { IThemaQuestions } from '../store/QuizStore';
import { $host } from './index';

export interface IQuizNames {
   [id: string]: [string, string, string];
}

export interface IQuizAnswer {
   id: number;
   description: string;
   correct: boolean;
}

export interface IQuestion {
   id: string;
   questionType: QuestionsType;
   description: string;
   quizAnswers: IQuizAnswer[];
}

export interface IThema {
   id: string;
   title: string;
   questions: IQuestion[];
}

export interface IQuiz {
   id: string;
   description: string;
   name: string;
   imageUrl: string;
   creationTime: number[];
   themas: IThema[];
}

interface IAnswersData {
   id: number;
   name: string;
   guestQuizUserName: string | null;
   themas: IThemaQuestions[];
}

export interface IResult {
   uniqueId: number;
   guestQuizUserName: string | null;
   resultTime: string;
   quizResult: { [name: string]: number };
}

export const getQuizByName = async (quizName: string) => {
   const { data } = await $host.get(`guestQuiz/getQuizByName/${quizName}`);

   return data;
};

export const getQuizById = async (quizId: number) => {
   const { data } = await $host.get(`guestQuiz/${quizId}`);

   return data as IQuiz;
};

export const getQuizNames = async () => {
   const { data } = await $host.get(`guestQuiz/getQuizNames`);

   return data as IQuizNames;
};

export const postQuizAnswersAndGetResult = async (
   answersData: IAnswersData,
) => {
   const { data } = await $host.post(`guestQuiz/countResult`, {
      ...answersData,
   });

   return data as IResult;
};

export const getGuestQuizResultById = async (quizId: number) => {
   const { data } = await $host.get(`/guestQuiz/getResultById/${quizId}`);

   return data as IResult;
};

export const getGuestQuizResultsList = async () => {
   const { data } = await $host.get(`/guestQuiz/getResults`);

   return data as IResult[];
};

export const deleteQuizResultById = async (quizId: number) => {
   const { data } = await $host.delete(`/guestQuiz/deleteResult/${quizId}`);

   return data as string;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExpandedBlock_main_list__NrjZp {\n  margin-top: 15px;\n  padding-bottom: 10px;\n  border-bottom: 1px solid #dce2e5;\n}\n.ExpandedBlock_main_list__NrjZp svg {\n  color: #6f6f6f;\n  margin-right: 5px;\n  width: 17px;\n  height: 17px;\n}\n.ExpandedBlock_main_list__NrjZp .ExpandedBlock_contacts__lDnWi {\n  display: flex;\n  align-items: center;\n  line-height: 30px;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/MyStudents/ExpandedBlock/ExpandedBlock.module.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,oBAAA;EACA,gCAAA;AADR;AAEQ;EACI,cAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AAAZ;AAEQ;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;AAAZ","sourcesContent":["\n.main {\n    &_list {\n        margin-top: 15px;\n        padding-bottom: 10px;\n        border-bottom: 1px solid #dce2e5;\n        svg {\n            color: #6f6f6f;\n            margin-right: 5px;\n            width: 17px;\n            height: 17px;\n        }\n        .contacts {\n            display: flex;\n            align-items: center;\n            line-height: 30px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_list": "ExpandedBlock_main_list__NrjZp",
	"contacts": "ExpandedBlock_contacts__lDnWi"
};
export default ___CSS_LOADER_EXPORT___;

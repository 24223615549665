// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutLearningDesktop_container__9CDzv {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n.AboutLearningDesktop_container__9CDzv .AboutLearningDesktop_wrapper__sak3m {\n  width: 400px;\n}\n.AboutLearningDesktop_container__9CDzv h2 {\n  margin-top: 20px;\n  font-weight: 700;\n  text-align: start;\n}\n.AboutLearningDesktop_container__9CDzv p {\n  margin-top: 10px;\n}\n.AboutLearningDesktop_container__9CDzv p, .AboutLearningDesktop_container__9CDzv h2 {\n  font-size: 20px;\n}", "",{"version":3,"sources":["webpack://./src/modules/guest/pages/TeachOnTraining/components/LearningSection/AboutLearningDesktop/AboutLearningDesktop.module.scss"],"names":[],"mappings":"AACC;EACG,aAAA;EACA,6BAAA;EACA,mBAAA;AAAJ;AACI;EACI,YAAA;AACR;AACI;EACI,gBAAA;EACA,gBAAA;EACA,iBAAA;AACR;AACI;EACI,gBAAA;AACR;AACI;EACI,eAAA;AACR","sourcesContent":["\n .container {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    .wrapper {\n        width: 400px;\n    }\n    h2{\n        margin-top: 20px;\n        font-weight: 700;\n        text-align: start; \n    }\n    p {\n        margin-top: 10px;\n    }\n    p, h2 {\n        font-size: 20px;\n    }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AboutLearningDesktop_container__9CDzv",
	"wrapper": "AboutLearningDesktop_wrapper__sak3m"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderContent_logo__tTxKq {\n  cursor: pointer;\n  max-height: 40px;\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/Layout/MentorLayout/Header/HeaderContent/index.module.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,2BAAA;AACH","sourcesContent":[".logo {\n   cursor: pointer;\n   max-height: 40px;\n   position: absolute;\n   left: 50%;\n   transform: translateX(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "HeaderContent_logo__tTxKq"
};
export default ___CSS_LOADER_EXPORT___;

import { StudentLayout } from '../UI/Layout/StudentLayout';
import { MinimalLayout } from '../UI/Layout/MinimalLayout';
import { StudentCourse } from '../student/pages/StudentCourse/StudentCourse';

import {
   Course,
   Task,
   Quiz,
   FeedbackWrapper,
   Home,
   AllCoursePage,
   ActiveCoursesPage,
   CompletedCoursesPage,
} from '../student';
import { AllCourses } from '../allCourses';

enum StudentPaths {
   home = '/',
   home_all_courses = 'student-all-courses',
   home_active_courses = 'active-courses',
   home_completed_courses = 'completed-courses',
   course = 'course/:title',
   task = 'course/:title/task/:id/:title',
   quiz = 'course/:title/quiz/:id/:title',
   feedback = '/feedback/:feedbackType/:id',
   all_courses = '/all-courses',
}

export const studentNavigator = {
   course: (title: string) => `/course/${title}`,
   task: (pathname: string, id: number, title: string) =>
      `${pathname}/task/${id}/${title}`,
   quiz: (pathname: string, id: number, title: string) =>
      `${pathname}/quiz/${id}/${title}`,
   feedback: (feedbackType: string, id: number) =>
      `/feedback/${feedbackType}/${id}`,
   all_courses: () => `/all-courses`,
};

export const StudentRoutes = {
   path: StudentPaths.home,
   element: <StudentLayout />,
   children: [
      {
         path: StudentPaths.home,
         element: <Home />,
         children: [
            {
               path: '',
               element: <AllCoursePage />,
            },
            {
               path: StudentPaths.home_all_courses,
               element: <AllCoursePage />,
            },
            {
               path: StudentPaths.home_active_courses,
               element: <ActiveCoursesPage />,
            },
            {
               path: StudentPaths.home_completed_courses,
               element: <CompletedCoursesPage />,
            },
         ],
      },
      {
         path: StudentPaths.course,
         element: <StudentCourse />,
      },
      {
         path: StudentPaths.task,
         element: <Task />,
      },
      {
         path: StudentPaths.quiz,
         element: <Quiz />,
      },
      {
         path: StudentPaths.all_courses,
         element: <AllCourses />,
      },
      // {
      //     path: '*',
      //     element: <StudentHome />,
      // },
   ],
};

export const StudentRoutesMinimal = {
   path: StudentPaths.feedback,
   element: <MinimalLayout />,
   children: [
      {
         path: StudentPaths.feedback,
         element: <FeedbackWrapper />,
      },
   ],
};

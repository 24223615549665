import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { mentorNavigator } from '../../../../../router/MentorRoutes';
import styles from './CellName.module.scss';

interface Props {
   studentFirstName: string;
   studentLastName: string;
   studentId: number;
}

export const CellName: FC<Props> = ({
   studentFirstName,
   studentLastName,
   studentId,
}) => {
   const route = mentorNavigator.student_profile(studentId);

   return (
      <td className={styles.name}>
         <span className={styles.textOverflow}>
            <NavLink className={styles.link} to={'../' + route}>
               {studentFirstName}
               <br />
               {studentLastName}
            </NavLink>
         </span>
      </td>
   );
};

import { Link, useNavigate } from 'react-router-dom';
import { BorderedBlockContainer, Button } from '../../../UI';
import style from './SpectatorHome.module.scss';

export const SpectatorHome = () => {
   const navigate = useNavigate();

   const navigateToAllMentorPage = () => navigate('/spectator/all-mentors');

   return (
      <div className={style.container}>
         Spectator Page
         <BorderedBlockContainer>
            <Button
               buttonText='All mentors'
               buttonClass='primary'
               width='200px'
               buttonClick={navigateToAllMentorPage}
            />
         </BorderedBlockContainer>
      </div>
   );
};

import { FC } from 'react';
import { v4 } from 'uuid';
import { AppAvatar } from '../../../../UI';
//mui
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
//components
import { ExpandedBlock } from '../ExpandedBlock/ExpandedBlock';
//styles
import styles from './StudentsListSm.module.scss';

interface ICourses {
    id: number;
    title: string;
    status?: string;
    lastAction?: string;
}
interface IStudents {
    id: number;
    avatarLogoUrl: string | null;
    firstName: string;
    lastName: string; 
    email: string; 
    phone: string;
    skype: string; 
    telegram: string; 
    note: string | undefined;
    courses: ICourses[];
}

interface IProps { 
    students: IStudents[];
}

export const StudentsListSm: FC<IProps> = ({students}) => {

    return (
        <>
            {students.map((student) => (
            <div className={styles.list} key={v4()}>
                <div className={styles.list_main}>
                    <div className={styles.list_student}>
                        <AppAvatar name={`${student?.firstName} ${student?.lastName}`} className={styles.avatar} />
                        <div className={styles.list_names}>
                            <div>{student.firstName}</div>
                            <div>{student.lastName}</div>
                        </div>
                    </div>
                    <div><EmailOutlinedIcon /></div>
                </div>
                <div className={styles.container}>
                    <ExpandedBlock 
                        id={student.id}
                        email={student.email}
                        phone={student.phone}
                        skype={student.skype}
                        telegram={student.telegram}
                        note={student.note}
                        courses={student.courses}             
                    />
                </div>
            </div>
            ))}
        </>

    );
};
import { FC, useState } from 'react';
import { v4 } from 'uuid';
//mui
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
//UI
import { AppBtn, AppBtnTypes } from '../../../../UI';
//components
import {
   ProfileNote,
   ProfileCourses
} from '../StudentProfile';

import styles from './ExpandedBlock.module.scss';

interface ICourses {
   id: number;
   title: string;
   status?: string;
   lastAction?: string;
}

interface IProp {
   id: number;
   email: string;
   phone: string;
   skype: string;
   telegram: string;
   note: string | undefined;
   courses: ICourses[]
}

export const ExpandedBlock: FC<IProp> = ({ id, email, phone, skype, telegram, note, courses }) => {
   const [isShownMore, setIsShownMore] = useState(false);

   const handleClick = () => {
      setIsShownMore(!isShownMore);
   };

   return (
      <>
         {isShownMore && 
            <div>
                <div className={styles.main_list}>
                    <div key={v4()} className={styles.contacts}>
                        <EmailOutlinedIcon />
                        <span>Email: </span> {email}
                    </div>
                    <div key={v4()}  className={styles.contacts}>
                        <SendOutlinedIcon />
                        <span>Telegram: </span> {telegram}
                    </div>
                    <div key={v4()}  className={styles.contacts}>
                        <LocalPhoneOutlinedIcon />
                        <span>Skype: </span> {skype}
                    </div>
                    <div key={v4()}  className={styles.contacts}>
                        <LocalPhoneOutlinedIcon />
                        <span>Phone: </span> {phone}
                    </div>
                </div>
                <ProfileCourses courses={courses}/>
                <ProfileNote id={id} note={note}/>
            </div>

         }
         <div className={styles.btn_container}>
            <AppBtn
               className={styles.btn_link}
               buttonType={AppBtnTypes.link}
               onClick={handleClick}
            >
               {isShownMore ? (
                  <>
                     Show less <UpOutlined className={styles.chevron} />
                  </>
               ) : (
                  <>
                     Show more <DownOutlined className={styles.chevron} />
                  </>
               )}
            </AppBtn>
         </div>
      </>
   );
};
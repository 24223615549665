// assets
import { 
    SpeedOutlined, 
    BookOutlined,
    PeopleOutlined,
    NotificationsOutlined
 } from '@mui/icons-material';
// icons
const icons = {
    SpeedOutlined,
    BookOutlined,
    PeopleOutlined,
    NotificationsOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.SpeedOutlined,
            breadcrumbs: false
        },
        {
            id: 'answers',
            title: 'Answers',
            type: 'item',
            url: '#',
            icon: icons.NotificationsOutlined,
            breadcrumbs: false
        },
        {
            id: 'my-courses',
            title: 'Courses',
            type: 'item',
            url: '#',
            icon: icons.BookOutlined,
            breadcrumbs: false
        },
        {
            id: 'all-courses',
            title: 'All Courses',
            type: 'item',
            url: '/all-courses',
            icon: icons.BookOutlined,
            breadcrumbs: false
        },
        {
            id: 'all-students',
            title: 'All students',
            type: 'item',
            url: '/all-students',
            icon: icons.PeopleOutlined,
            breadcrumbs: false
        },
        {
            id: 'my-students',
            title: 'My students',
            type: 'item',
            url: '/my-students',
            icon: icons.PeopleOutlined,
            breadcrumbs: false
        }
    ]
};

import { v4 } from 'uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { dataText } from '../../dataText';
import styles from './AboutLearning.module.scss';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

export const AboutLearning = () => {
 
  return (
    <div className={styles.container}>

         {dataText.map((data, index) => (
            <Accordion key={v4()}
            defaultExpanded = {index === 0 ? true : false}
            >
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
               >
                  <h3 className={styles.header}>{data.header}</h3>
               </AccordionSummary>

               <AccordionDetails>
                  <div className={styles.container}>
                     <img src={data.img} alt="data_img" />
                     <p>{data.your_task}</p>
                     <h2>How we help you</h2>
                     <p>{data.our_task}</p>
                  </div>
               </AccordionDetails>
            </Accordion>
         ))}
      </div>
  );
};

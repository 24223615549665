// mui
import Chip from '@mui/material/Chip';
import Rating from '@mui/material/Rating/Rating';
import { 
   StarBorder, 
   FavoriteBorderOutlined, 
   FavoriteOutlined, 
   PeopleOutlined 
} from '@mui/icons-material';
// components
import { AppBtn } from '../../../UI';
// project
import { useWishlistBtn } from '../../library';
// styles
import styles from './StudentsEnrollCard.module.scss';

interface Props {
   course: {
      id: number;
      title: string;
      img: string;
      rating: number;
      studentsNumber: number;
      price: number;
      totalTasks: number;
      lastUpdate: string;
   };
}

export const StudentsEnrollCard: React.FC<Props> = ({ course }) => {
   const {
      id,
      title,
      img,
      rating,
      studentsNumber,
      price,
      totalTasks,
      lastUpdate,
   } = course;

   const { isEnlisted, toggleWishlist } = useWishlistBtn();

   return (
      <div className={styles.wrapper}>
         <div className={styles.img_container}>
            <img className={styles.img} src={img} alt='Available Course' />
         </div>

         <div className={styles.content}>
            <div className={styles.upper_row}>
               <h4 className={styles.title}>{title}</h4>
               <div className={styles.meta}>
                  <span className={styles.rating_container}>
                     {rating}{' '}
                     <Rating
                        className={styles.rating}
                        emptyIcon={<StarBorder className={styles.star} />}
                        name='read-only'
                        value={rating / 5}
                        readOnly
                        max={1}
                        precision={0.1}
                     />
                  </span>
                  <span className={styles.students}>
                     <PeopleOutlined className={styles.team_icon} />
                     {studentsNumber.toLocaleString('en-US')}
                  </span>
                  <span className={styles.price}>${price}</span>
               </div>
            </div>

            <div className={styles.lower_row}>
               {studentsNumber > 100000 && (
                  <Chip label='Bestseller' className={styles.bestseller} />
               )}
               <span className={styles.tasks}>{totalTasks} total tasks</span>
               <span className={styles.dot}>●</span>
               <span className={styles.updated}>Updated {lastUpdate}</span>
            </div>
         </div>

         <div className={styles.btn_container}>
            <AppBtn onClick={toggleWishlist} className={styles.wishlist_btn}>
               {isEnlisted ? <FavoriteOutlined /> : <FavoriteBorderOutlined />}
            </AppBtn>{' '}
         </div>
      </div>
   );
};

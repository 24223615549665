import { useState, useEffect } from 'react';
import { useRootStore } from '../../../hooks';
import { useAuthHost } from '../../../hooks';
import { toJS } from 'mobx';

export const useQuizAnswer = (id: string) => {
   const [error, setError] = useState(false);
   const [statusServer, setStatusServer] = useState<number>(0);

   const authHost = useAuthHost();
   const { studentStore } = useRootStore();
   const quizAnswer = studentStore.getQuizAnswer();
   const answerData = toJS(quizAnswer);
   const isTimeOver = studentStore.getIsQuizTimeOver()

   const submitAnswer = async () => {
      try {
         const res = await authHost.post(
            `/api/course-items/student/quizzes/${id}/answer`,
            answerData,
         );

         setStatusServer(res.status);
         studentStore.setQuizAnswer([]);
      } catch (err) {
         setError(true);
      }
   };

   useEffect(() => {
      if (isTimeOver) {
         submitAnswer()
      }
   }, [isTimeOver])

   return {
      statusServer,
      error,
   };
};

import styles from './Tabs.module.scss';

interface Props {
   activeTab: Tab;
   handleTabClick: (tab: Tab) => void;
}

enum Tab {
   Tasks,
   Results,
}

const Tabs: React.FC<Props> = ({ activeTab, handleTabClick }) => {
   return (
      <div className={styles.container}>
         <div className={styles.tabs}>
            <div
               className={[
                  styles.tab,
                  activeTab === Tab.Tasks && styles.active,
               ].join(' ')}
               onClick={() => handleTabClick(Tab.Tasks)}
            >
               Tasks
            </div>
            <div
               className={[
                  styles.tab,
                  activeTab === Tab.Results && styles.active,
               ].join(' ')}
               onClick={() => handleTabClick(Tab.Results)}
            >
               Results
            </div>
         </div>
         <div className={styles.underline_container}>
            <div
               className={styles.underline}
               style={{
                  transform: `translateX(${
                     activeTab === Tab.Tasks ? 0 : 100
                  }%)`,
                  width: `${activeTab === Tab.Tasks ? '59px' : '79px'}`,
               }}
            />
         </div>
      </div>
   );
};

export default Tabs

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResultsTableCard_container__\\+cyMG {\n  width: 100%;\n  display: flex;\n  min-height: 50px;\n  border-bottom: 1px solid rgba(128, 128, 128, 0.3);\n  padding: 15px 0;\n  align-items: center;\n}\n.ResultsTableCard_container__\\+cyMG .ResultsTableCard_col__0i4Bx {\n  width: 15%;\n  text-align: center;\n}\n.ResultsTableCard_container__\\+cyMG .ResultsTableCard_title__8IG-z {\n  width: 40%;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/course/ResultsTab/ResultsTableCard/ResultsTableCard.module.scss"],"names":[],"mappings":"AAAA;EACG,WAAA;EACA,aAAA;EACA,gBAAA;EACA,iDAAA;EACA,eAAA;EACA,mBAAA;AACH;AACG;EACG,UAAA;EACA,kBAAA;AACN;AAEG;EACG,UAAA;AAAN","sourcesContent":[".container {\n   width: 100%;\n   display: flex;\n   min-height: 50px;\n   border-bottom: 1px solid rgba(128, 128, 128, 0.3);\n   padding: 15px 0;\n   align-items: center;\n\n   .col {\n      width: 15%;\n      text-align: center;\n   }\n\n   .title {\n      width: 40%\n   }\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ResultsTableCard_container__+cyMG",
	"col": "ResultsTableCard_col__0i4Bx",
	"title": "ResultsTableCard_title__8IG-z"
};
export default ___CSS_LOADER_EXPORT___;

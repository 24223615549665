//mui
import { AccessTime, Assessment } from '@mui/icons-material';
//components
import { MentorSection, RatingSection } from './components';
//styles
import styles from './CourseInfoBar.module.scss';

export const CourseInfoBar = () => {
   return (
      <section className={styles.container}>
         <div className={styles.content}>
            <MentorSection />
            <section className={styles.time_section}>
               <AccessTime />
               2h 46m
            </section>
            <section className={styles.level_section}>
               <Assessment />
               Beginner
            </section>
            <RatingSection />
         </div>
      </section>
   );
};

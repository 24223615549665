// material-ui
import { Box, Button } from '@mui/material';
import { NavigateBeforeOutlined } from '@mui/icons-material';
// project components
import { NavItem } from '../DrawerContent/Navigation/NavItem';

// project imports
import { categories } from '../../menu-items/categories';
import { useRootStore } from '../../../../../../hooks';

// styles
import styles from './Submenu.module.scss';

interface Props {
   submenuOpen: boolean;
   drawerWidth: number;
   activeItem: string[];
}

export const Submenu: React.FC<Props> = ({ submenuOpen, drawerWidth, activeItem }) => {
   const { layoutStore } = useRootStore();

   const menu = categories.children.find(
      (submenu) => submenu.id === activeItem[0],
   );

   const navCollapse = menu?.children?.map((menuItem) => (
      <NavItem key={menuItem.id} item={menuItem} level={1} />
   ));

   const handleClick = () => {
      layoutStore.setSubmenuIsOpen(false);
   };

   return (
      <Box
         className={styles.wrapper}
         sx={{
            width: submenuOpen ? drawerWidth : 0,
         }}
      >
         <Button
            className={styles.button}
            variant='outlined'
            startIcon={<NavigateBeforeOutlined style={{ fontSize: '0.7rem' }} />}
            onClick={handleClick}
         >
            Menu
         </Button>{' '}
         {navCollapse}
      </Box>
   );
};
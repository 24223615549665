export const courseItemStateClassNames: Record<string, Record<string, string>> = {
   'Answer submitted': {
      stateClassName: 'submitted',
   },

   'Answer in review': {
      stateClassName: 'in-review',
   },

   'Answer accepted': {
      stateClassName: 'accepted',
   },

   'Answer rejected': {
      stateClassName: 'rejected',
   },
};



// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FeedbackTopic_title__prcHu {\n  font-size: 1.9rem;\n  margin-right: 10px;\n}\n\n.FeedbackTopic_heading__eEhvl {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/pages/FeedbackTopic/FeedbackTopic.module.scss"],"names":[],"mappings":"AAAA;EACG,iBAAA;EACA,kBAAA;AACH;;AAEA;EACG,aAAA;EACA,mBAAA;EACA,8BAAA;AACH","sourcesContent":[".title {\n   font-size: 1.9rem;\n   margin-right: 10px;\n}\n\n.heading {\n   display: flex;\n   align-items: center;\n   justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "FeedbackTopic_title__prcHu",
	"heading": "FeedbackTopic_heading__eEhvl"
};
export default ___CSS_LOADER_EXPORT___;

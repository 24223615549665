import { FormEvent, ChangeEvent, useState } from 'react';
import { v4 } from 'uuid';

// material-ui
import { 
  Box, 
  FormControl, 
  OutlinedInput, 
} from '@mui/material';

// project components
import { QuestionItem } from '../QuestionItem';
import { AppBtn } from '../../../UI';

// assets
import { questions } from '../../../guest/pages/GuestHome/components/mockData';

// styles
import styles from './AskQuestionTitle.module.scss';

export const AskQuestionTitle: React.FC = () => {
  const [questionTitle, setQuestionTitle] = useState('');

  const style = {
    px: { xs: 2, sm: 3 },
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuestionTitle(e.target.value);
  };

  const questionsFilter = questions.filter(
    question => question.title
    .toLowerCase()
    .includes(questionTitle.toLowerCase())
  );
    
  let similarQuestions = questionsFilter ? questionsFilter : questions;

  const [isShownMore, setIsShownMore] = useState(false);
    
  const toggleShowMore = () => {
    setIsShownMore(!isShownMore);
  };

  const questionsRendered = isShownMore ? similarQuestions : similarQuestions.slice(0, 2);

  const questionsMap = questionsRendered.map(question => (
    <li className={styles.list_item}  key={v4()}>
      <QuestionItem article={question}/>
    </li>
  ));

  return (
    <>
      <div className={styles.wrapper}>
        <form onSubmit={handleSubmit} className={styles.ask_form}>
          <h3 className={styles.title}>Ask a Question</h3>
          <div className={styles.title_header}>
            <h4 className={styles.question_title}>Question Title</h4>
            
            <Box sx={{ width: '100%', ml: { xs: 0, md: 0 } }}>
              <FormControl 
                sx={{ width: { xs: '100%', md: '100%' } }}
              >
                <OutlinedInput
                  sx={{
                    '& ::placeholder': {
                      fontSize: '16px',
                      fontWeight: 600,
                    }
                  }}
                  size='medium'
                  id='header-search'
                  aria-describedby='header-search-text'
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  placeholder='Your question ...'
                  value={questionTitle}
                  onChange={handleInputChange}                
                />
              </FormControl>
            </Box>

          </div>
          <div>
            <h3 className={styles.subtitle}>Similar Questions</h3>
            <ul className={styles.list}>{questionsMap}</ul>
            <AppBtn onClick={toggleShowMore} className={styles.show_btn}>
              {isShownMore ? 'Show Less' : 'Show More'}
            </AppBtn>
          </div>
        </form>
      </div>
    </>
  )
}
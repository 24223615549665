export const localData = [
   {
      name: 'Vue JS',
      cat: 'development',
      subcat: 'front-end',
      // slug: 'vue-js',
      slug: 'complete-vue-developer-2023-zero-to-mastery-pinia-vitest',
      title: 'Complete Vue Developer 2023: Zero to Mastery (Pinia, Vitest)',
      desc: 'Ultimate guide to Vue 3 Development. Build large scale applications and deploy to production. Go from Zero to Mastery!',
      rating: 4.6,
      ratesNumber: 2041,
      students: 13524,
      createdBy: ['Andrei Neagoie', 'Luis Ramirez Jr'],
      lastUpdate: '4/2022',
      language: 'English',
      price: 14.99,
      features: {
         video: 28,
         articles: 19,
         downloadable: 59,
         exercise: 1,
         duration: 'Full lifetime',
         devices: ['mobile', 'TV'],
         certificate: true
      }
   },
];

export const courseContent = {
   totalSections: 7,
   totalTheory: 99,
   totalPractice: 99,
   sections: [
      {
         title: 'Introduction',
         theory: 3,
         practice: 4,
         assignments: [
            {
               title: 'Course Outline',
               type: 'theory',
               link: '#'
            },
            {
               title: 'Join Our Online Classroom!',
               type: 'theory',
               link: '#'
            },
            {
               title: 'Complete Course Resources',
               type: 'practice',
               link: '#'
            }
         ]
      },
      {
         title: 'Project 1: Perspective Playground',
         theory: 4,
         practice: 7,
         assignments: [
            {
               title: 'Join Our Online Classroom!',
               type: 'theory',
               link: '#'
            },
            {
               title: 'Complete Course Resources',
               type: 'practice',
               link: ''
            },
            {
               title: 'Course Outline',
               type: 'theory',
               link: ''
            },
         ]
      },
      {
         title: 'Vue: Beyond the Fundamentals',
         theory: 3,
         practice: 4,
         assignments: [
            {
               title: 'Complete Course Resources',
               type: 'practice',
               link: ''
            },
            {
               title: 'Course Outline',
               type: 'theory',
               link: ''
            },
            {
               title: 'Join Our Online Classroom!',
               type: 'theory',
               link: ''
            },
         ]
      },
      {
         title: 'Vue Developer Environment',
         theory: 4,
         practice: 7,
         assignments: [
            {
               title: 'Complete Course Resources',
               type: 'practice',
               link: ''
            },
            {
               title: 'Course Outline',
               type: 'theory',
               link: ''
            },
            {
               title: 'Join Our Online Classroom!',
               type: 'theory',
               link: ''
            },
         ]
      },
      {
         title: 'Advanced Vue Components',
         theory: 3,
         practice: 4,
         assignments: [
            {
               title: 'Join Our Online Classroom!',
               type: 'theory',
               link: ''
            },
            {
               title: 'Complete Course Resources',
               type: 'practice',
               link: ''
            },
            {
               title: 'Course Outline',
               type: 'theory',
               link: ''
            },
         ]
      },
      {
         title: 'Transitions and Animations',
         theory: 4,
         practice: 7,
         assignments: [
            {
               title: 'Join Our Online Classroom!',
               type: 'theory',
               link: ''
            },
            {
               title: 'Complete Course Resources',
               type: 'practice',
               link: ''
            },
            {
               title: 'Course Outline',
               type: 'theory',
               link: ''
            },
         ]
      },
      {
         title: 'Project 2: Vue Quiz App',
         theory: 4,
         practice: 7,
         assignments: [
            {
               title: 'Join Our Online Classroom!',
               type: 'theory',
               link: ''
            },
            {
               title: 'Complete Course Resources',
               type: 'practice',
               link: ''
            },
            {
               title: 'Course Outline',
               type: 'theory',
               link: ''
            },
         ]
      }
   ]  
}

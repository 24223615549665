import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import UserStore from './store/UserStore';
import { CookiesProvider } from 'react-cookie';
import QuizStore from './store/QuizStore';
import StudentStore from './store/StudentStore';
import MentorStore from './store/MentorStore';
import LayoutStore from './store/LayoutStore';
import PagesStore from './store/PagesStore';
import { GuestStore } from './store/GuestStore';
import './index.scss';
import UserService from './utils/userService';

window.addEventListener('beforeunload', () => {
   localStorage.setItem('lastPath', window.location.pathname);
});

export const Context: React.Context<any> = createContext(null);
const root = ReactDOM.createRoot(
   document.getElementById('root') as HTMLElement,
);

export interface IRootStore {
   userStore: UserStore;
   quizStore: QuizStore;
   studentStore: StudentStore;
   mentorStore: MentorStore;
   layoutStore: LayoutStore;
   pagesStore: PagesStore;
   guestStore: GuestStore;
}
const renderApp = () => root.render(
   <React.StrictMode>
      <CookiesProvider>
         <Context.Provider
            value={
               {
                  userStore: new UserStore(),
                  quizStore: new QuizStore(),
                  studentStore: new StudentStore(),
                  mentorStore: new MentorStore(),
                  layoutStore: new LayoutStore(),
                  pagesStore: new PagesStore(),
                  guestStore: new GuestStore(),
               } as IRootStore
            }
         >
            <App />
         </Context.Provider>
      </CookiesProvider>
   </React.StrictMode>,
);

UserService.initKeycloak(renderApp)
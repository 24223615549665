import { $authHost } from './index';

export interface IAllMentorsData {
   id: number;
   firstName: string;
   lastName: string;
   email: string;
   courses: IMentorCourseData[];
}

export interface IMentorCourseData {
   id: number;
   logoUrl: string;
   title: string;
}

export const getAllMentorsData = async () => {
   const { data } = await $authHost.get('/api/mentors');

   return data;
};

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormEvent, ChangeEvent } from 'react';

// material-ui
import { 
   Box, 
   FormControl, 
   InputAdornment, 
   OutlinedInput, 
   Button,
   SelectChangeEvent,
} from '@mui/material';

// project components
import { HelpCenterArticles } from '../HelpCenterArticles';
import { SelectLabels } from '../SelectLabels';
import { Divider } from '../../../UI/Divider';

// assets
import { SearchOutlined } from '@ant-design/icons';
//import { mockDataArticles } from '../../library/mockDataArticles';
import { questions as mockDataArticles } from '../../../guest/pages/GuestHome/components/mockData';

//styles
import styles from './HelpCenterSearch.module.scss';

export const HelpCenterSearch = () => {
   const numberOfArticles = mockDataArticles.length;

   const [searchParams, setSearchParams] = useSearchParams();
   const [searchValue, setSearchValue] = useState('');
   
   const [topic, setTopic] = useState('');
   
   const articleQuery = searchParams.get('article') || '';
   
   const articlesFilter = mockDataArticles.filter(
      article => article.title
      .toLowerCase()
      .includes(articleQuery.toLowerCase())
   );
      
   let articles = articlesFilter ? articlesFilter : mockDataArticles;

   articles = topic 
      ? articles.filter(article => article.topic.includes(topic))
      : articles;
      
   const articlesNumber = articles.length;

   const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setSearchParams({ article: searchValue });
   }
      
   const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchValue(e.target.value);
   };
      
   const handleSelectChange = (event: SelectChangeEvent) => {
      setTopic(event.target.value);
   };

   return (
      <div className={styles.search_container}>
         <form onSubmit={handleSubmit}>
            <Box sx={{ width: '100%', ml: { xs: 0, md: 0 } }}>
               <FormControl 
                  sx={{ width: { xs: '100%', md: '100%' } }}
               >
                  <OutlinedInput
                     sx={{
                        '& ::placeholder': {
                           fontSize: '16px',
                           fontWeight: 600,
                        }
                     }}
                     size='medium'
                     id='header-search'
                     startAdornment={
                        <InputAdornment
                           position='start'
                        >  
                           <Button 
                              type="submit" 
                              variant="contained"
                              className={styles.search_btn}
                           >
                              <SearchOutlined />
                           </Button>
                        </InputAdornment>
                     }
                     aria-describedby='header-search-text'
                     inputProps={{
                        'aria-label': 'weight',
                     }}
                     placeholder='Search articles'
                     value={searchValue}
                     onChange={handleInputChange}
                  />
               </FormControl>
            </Box>
            <div className={styles.description}>
               <p className={styles.articles_info}>
                  Displaying {articlesNumber} out of {numberOfArticles} articles
               </p>
               <SelectLabels 
                  handleChange={handleSelectChange} 
                  topic={topic}
               />
            </div>
         </form>

         <Divider>Articles</Divider>

         <HelpCenterArticles articles={articles}/>
      </div>
   );
}
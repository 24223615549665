import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
IStudentData,
getStudentProfileById,
} from '../../../http/mentorAPI';

const useStudentsProfiles = (id: string) => {
    const [studentById, setStudentById] = useState<IStudentData>();
    const [error, setError] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const isHomepage = false;

    useEffect(() => {
        getStudentProfileById(id!)
            .then((data) => {
                setStudentById(data);
        })
        .catch(() => setError(true));
    }, []);

    useEffect(() => {
        if (error) {
            return navigate('/not-found', {
                state: { from: location, isHomepage },
                replace: true,
            });
        }
    }, [error, location, isHomepage, navigate]);

    return { studentById, error };
};

export default useStudentsProfiles;
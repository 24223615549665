// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CourseAssignment_wrapper__oPYTg {\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 0;\n}\n\n.CourseAssignment_title__R5uO4 {\n  font-weight: 700;\n}\n\n.CourseAssignment_preview__N4xtR {\n  text-decoration: underline;\n  margin-left: auto;\n}", "",{"version":3,"sources":["webpack://./src/modules/coursePreview/components/CourseAssignment/CourseAssignment.module.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;AACH;;AAEA;EACG,gBAAA;AACH;;AAEA;EACG,0BAAA;EACA,iBAAA;AACH","sourcesContent":[".wrapper {\n   font-size: 14px;\n   display: flex;\n   align-items: center;\n   gap: 8px;\n   padding: 8px 0;\n}\n\n.title {\n   font-weight: 700;\n}\n\n.preview {\n   text-decoration: underline;\n   margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CourseAssignment_wrapper__oPYTg",
	"title": "CourseAssignment_title__R5uO4",
	"preview": "CourseAssignment_preview__N4xtR"
};
export default ___CSS_LOADER_EXPORT___;

import { useLocation, useNavigate } from 'react-router-dom';
import { convertToSlug } from '../../../../utils/utils';
import { ICourseTask } from '../../interfaces/interfaces';
import { FeedbackReminder } from '../feedback';
import { studentNavigator } from '../../../router/StudentRoutes';
import styles from './TasksListItem.module.scss';

export const TasksListItem: React.FC<{
   task: ICourseTask;
   idx: number;
}> = ({ task, idx }) => {
   const { id, courseItemTitle, courseItemType, state } = task;
   const location = useLocation();
   const navigate = useNavigate();

   function handleClick() {
      courseItemType === 'Quiz'
         ? navigate(
              studentNavigator.quiz(
                 location.pathname,
                 id,
                 convertToSlug(courseItemTitle),
              ),
           )
         : navigate(
              studentNavigator.task(
                 location.pathname,
                 id,
                 convertToSlug(courseItemTitle),
              ),
           );
   }

   function navigateTaskFeedback(id: number) {
      return () => navigate(studentNavigator.feedback('task', id));
   }

   return (
      <div className={styles.task}>
         <button
            disabled={state === 'Covered'}
            className={styles.title}
            onClick={handleClick}
         >
            Task {idx + 1} {courseItemType === 'Quiz' && '(Quiz)'} -{' '}
            {courseItemTitle}
         </button>

         <div className={styles.bottom}>
            {state === 'Answer accepted' && (
               <FeedbackReminder navigate={navigateTaskFeedback(id)} />
            )}
         </div>
      </div>
   );
};

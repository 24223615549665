// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserLayout_wrapper__K9nMU {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  min-height: 100%;\n}\n\n.UserLayout_main__\\+Z922 {\n  width: 100%;\n  flex: 1 1 auto;\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/Layout/UserLayout/UserLayout.module.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,WAAA;EACA,sBAAA;EACA,gBAAA;AACH;;AAEA;EACG,WAAA;EACA,cAAA;AACH","sourcesContent":[".wrapper {\n   display: flex;\n   width: 100%;\n   flex-direction: column;\n   min-height: 100%;\n}\n\n.main {\n   width: 100%;\n   flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "UserLayout_wrapper__K9nMU",
	"main": "UserLayout_main__+Z922"
};
export default ___CSS_LOADER_EXPORT___;

import styles from './ResultsTableCard.module.scss';

interface Props {
   result: {
      title: string;
      calculatedWeight: number;
      range: string;
      score: number;
      contributionToCourse: number;
   };
}

export const ResultsTableCard: React.FC<Props> = ({
   result: { title, calculatedWeight, range, score, contributionToCourse },
}) => {
   return (
      <div className={styles.container}>
         <span className={styles.title}>{title}</span>
         <span className={styles.col}>{calculatedWeight}</span>
         <span className={styles.col}>{range}</span>
         <span className={styles.col}>{score}</span>
         <span className={styles.col}>{contributionToCourse}</span>
      </div>
   );
};

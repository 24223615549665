import PropTypes from 'prop-types';
import { useMemo, ReactNode } from 'react';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles';

import Typography from './typography';

interface Props {
   children: ReactNode;
}

export default function ThemeCustomization({ children }: Props) {
   const themeTypography = Typography(`'Public Sans', sans-serif`);

   const themeOptions: ThemeOptions = useMemo(
      () => ({
         // breakpoints: {
         //    values: {
         //       xs: 0,
         //       sm: 768,
         //       md: 1024,
         //       lg: 1266,
         //       xl: 1536,
         //    },
         // },
         direction: 'ltr',
         mixins: {
            toolbar: {
               minHeight: 60,
               paddingTop: 8,
               paddingBottom: 8,
               maxWidth: 1400,
               margin: 'auto',
               width: '100%',
            },
         },
         typography: themeTypography,
         components: {
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  fontSize: '16px',
                },
              },
            },
         },
      }),
      [themeTypography],
   );

   const themes = createTheme(themeOptions);

   return (
      <StyledEngineProvider injectFirst>
         <ThemeProvider theme={themes}>
            <CssBaseline />
            {children}
         </ThemeProvider>
      </StyledEngineProvider>
   );
}

ThemeCustomization.propTypes = {
   children: PropTypes.node,
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FeedbackReminder_wrapper__10F0G {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  border-radius: 5px;\n  background-color: #f6f6f6;\n}\n\n.FeedbackReminder_text__i3Mt5 {\n  margin-right: 6px;\n  font-weight: 500;\n}\n\n.FeedbackReminder_button__lli98 {\n  margin: 0;\n  padding: 2px 10px;\n  background-color: #ffffff;\n  font-size: 1rem;\n  font-weight: 500;\n  letter-spacing: 0.5px;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/feedback/FeedbackReminder/FeedbackReminder.module.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AACH;;AAEA;EACG,iBAAA;EACA,gBAAA;AACH;;AAEA;EACG,SAAA;EACA,iBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AACH","sourcesContent":[".wrapper {\n   display: flex;\n   align-items: center;\n   padding: 10px;\n   border-radius: 5px;\n   background-color: #f6f6f6;\n}\n\n.text {\n   margin-right: 6px;\n   font-weight: 500;\n}\n\n.button {\n   margin: 0;\n   padding: 2px 10px;\n   background-color: #ffffff;\n   font-size: 1rem;\n   font-weight: 500;\n   letter-spacing: 0.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "FeedbackReminder_wrapper__10F0G",
	"text": "FeedbackReminder_text__i3Mt5",
	"button": "FeedbackReminder_button__lli98"
};
export default ___CSS_LOADER_EXPORT___;

import { useState, ChangeEvent } from 'react';
import { useRootStore } from '../../../hooks';
import { useAuthHost } from '../../../hooks';

const useFormHandler = (id: string) => {
   const { studentStore } = useRootStore();
   const [userInput, setUserInput] = useState('');
   const [error, setError] = useState(false);
   const authHost = useAuthHost();

   function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
      setUserInput(e.target.value);
   }

   async function submitAnswer() {
      try {
         const res = await authHost.patch(
            `/api/course-items/student/code-assignments/${id}/submit`,
            {
               answer: userInput,
            },
         );

         studentStore.setTask(res.data);
         setUserInput('');
      } catch (err) {
         setUserInput('ERROR');
         setError(true);
      }
   }

   return {
      userInput,
      handleChange,
      submitAnswer,
      error,
   };
};

export default useFormHandler;

import { ManagerLayout } from "../UI/Layout/ManagerLayout";
import { AllMentorsPage, ManagerHome } from "../manager";
import { StudentProfile } from '../studentProfile/studentProfile';

enum ManagerPaths {
   home = '/',
   all_mentors = '/manager/all-mentors',
   student_profile = '/student-profile/:id',
}

export const ManagerRoutes = {
   path: ManagerPaths.home,
   element: <ManagerLayout />,
   children: [
      {
         path: ManagerPaths.home,
         element: <ManagerHome />,
      },
      {
         path: ManagerPaths.all_mentors,
         element: <AllMentorsPage />,
      },
      {
        path: ManagerPaths.student_profile,
        element: <StudentProfile />,
     },
   ],
};
import { Outlet } from 'react-router-dom';

// material-ui
import { Box } from '@mui/material';

// project components
import { Header } from './Header';
import { MainDrawer } from './Drawer';
import { Footer } from '../common/Footer';

// styles
import styles from './GuestLayout.module.scss';

export const GuestLayout: React.FC = () => {
   return (
      <Box className={styles.wrapper}>
         <Header />
         <MainDrawer />
         <Box component='main' className={styles.main}>
            <Outlet />
         </Box>
         <Footer />
      </Box>
   );
};

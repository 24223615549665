import { useState } from 'react';
import { v4 } from 'uuid';

// project components
import { ArticleItem } from './ArticleItem';
import { AppBtn } from '../../../UI';

// styles
import styles from './HelpCenterArticles.module.scss';

interface Props {
  articles: {
    id: number;
    topic: string;
    category: string;
    userName: string;
    userID: number;
    title: string;
    details: string;
    course: string;
    answers: {
      answerID: number;  
      answerText: string;
      author: string;
      }[];
  }[];
}

export const HelpCenterArticles: React.FC<Props> = ({ articles }) => {
  const [isShownMore, setIsShownMore] = useState(false);
  
  const toggleShowMore = () => {
    setIsShownMore(!isShownMore);
  };

  const articlesRendered = isShownMore ? articles : articles.slice(0, 2);

  const articlesMap = articlesRendered.map(item => (
    <li className={styles.list_item}  key={v4()}>
      <ArticleItem article={item}/>
    </li>
  ));

  return (
    <div className={styles.container}>
      
      <ul>
        {articlesMap}
      </ul>

      <AppBtn onClick={toggleShowMore} className={styles.show_btn}>
        {isShownMore ? 'Show Less' : 'Show More'}
      </AppBtn>

    </div>
  )
}
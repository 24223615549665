import { useState } from 'react';
// mui
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type Props = {
   options?: string[];
   maxHeight?: number;
   className?: string;
};

export const AppDotsMenu: React.FC<Props> = ({
   options = ['Report'],
   maxHeight = 48,
   className,
}) => {
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <div className={className || ''}>
         <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleClick}
         >
            <MoreVertIcon />
         </IconButton>
         <Menu
            id='long-menu'
            MenuListProps={{
               'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
               style: {
                  maxHeight: maxHeight * 4.5,
                  width: '20ch',
               },
            }}
         >
            {options.map((option) => (
               <MenuItem
                  key={option}
                  selected={option === 'Pyxis'}
                  onClick={handleClose}
               >
                  {option}
               </MenuItem>
            ))}
         </Menu>
      </div>
   );
};

import { FC, useState } from 'react';
//UI
import { PageContainer } from '../../../UI';
//components
import {
   Profile,
   Filter,
   StudentsListSm,
   StudentsList,
} from '../../components/MyStudents';
//mockData
import { myStudents } from '../../mockData';
//styles
import styles from './MyStudents.module.scss';

export const MyStudents: FC = () => {
   const [activeItem, setActiveItem] = useState(0);
   const [isFiltered, setIsFiltered] = useState('all')

   const onStudentsValueSelected = (studentValue: any): void => {
      setActiveItem(studentValue);
   }
   
   const onFilterValueSelected = (filterValue: any): void => {
      setIsFiltered(filterValue);
      setActiveItem(0);
   }

   let filteredStudents = myStudents.filter( ({courses: arr}) => arr.some( tag => isFiltered.includes(tag.title)) );

   let newStudentsList = isFiltered === 'all' ? myStudents : filteredStudents

   return (
      <PageContainer>
         <div className={styles.wrapper}>
            <div className={styles.mobile}>
               <div className={styles.filter_sm}>
                  <Filter filterValueSelected={onFilterValueSelected}/> 
               </div>
               <StudentsListSm students={newStudentsList} />
            </div>

            <div className={styles.desktop}>
               <div className={styles.container}>
                  <div className={styles.container_left}>
                     <Filter filterValueSelected={onFilterValueSelected}/> 
                  
                     <StudentsList students={newStudentsList} studentSelected={onStudentsValueSelected}/>
                  </div>

                  <div className={styles.container_right}>
                     <Profile students={newStudentsList} activeItem={activeItem}/>
                  </div>      
               </div>
            </div>
         </div>
      </PageContainer>
   );
};

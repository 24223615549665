import React, { useState, useEffect } from 'react';
import { MentorCard } from './mentorCard';
import { mentorsData } from './mentorsData';
import styles from './CourseMentors.module.scss';
import {Button, Pagination, PaginationItem} from '@mui/material';

export interface MentorData {
  name: string;
  grade: number;
  img: string;
  aboutYourself: string;
  jobTitle: string;
  courses: Course[];
}

export interface Course {
  name: string;
  img: string;
}

export const CourseMentors: React.FC = () => {
  const [sortOrder, setSortOrder] = useState('asc');
  const [originalMentorsData, setOriginalMentorsData] = useState(mentorsData); // Хранение исходного списка
  const [sortBy, setSortBy] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 6;
  const totalPages = Math.ceil(mentorsData.length / cardsPerPage);

  const handleSortByPopularity = () => {
    if (sortBy === 'POPULARITY') {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy('POPULARITY');
      setSortOrder('asc');
      setCurrentPage(1);
    }
  };

  const handleSortByNewest = () => {
    if (sortBy === 'NEWEST') {
      setOriginalMentorsData(mentorsData)
      setSortBy('')
      setSortOrder('asc');
      setCurrentPage(1)
    } else {
      setSortBy('NEWEST')
      setCurrentPage(1)
    }
  }

  useEffect(() => {
    if (sortBy === 'POPULARITY') {
      const sortedData = [...originalMentorsData];
      sortedData.sort((a, b) => (sortOrder === 'asc' ? a.grade - b.grade : b.grade - a.grade));
      setOriginalMentorsData(sortedData);
    } else {
      setOriginalMentorsData(mentorsData);
    }
  }, [sortBy, sortOrder, originalMentorsData]);


  const renderMentorCards = () => {
    const startIndex = (currentPage - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    const currentCards = originalMentorsData.slice(startIndex, endIndex);

    return currentCards.map((item: MentorData, index: number) => (
      <MentorCard key={index} data={item} />
    ));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page:number) => {
    setCurrentPage(page);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title}>
          <div className={styles.sort}>
            DISPLAYING {cardsPerPage} OUT OF {mentorsData.length} TEACHERS{' '}
          </div>

          <div className={styles.sort}>
            <span>SORT BY</span>
            <span
              className={`${styles.sortType} ${sortBy === 'NEWEST' ? styles.activeSortType : ''}`}
              onClick={handleSortByNewest}
            >
              NEWEST
            </span>
            <span className={styles.sortType}
                   onClick={handleSortByPopularity}>
              POPULARITY
            </span>
          </div>
        </div>

        <div className={styles.gridContainer}>{renderMentorCards()}</div>

        <div className={styles.pagination}>
          <Button
            className={styles.paginationButton}
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          />

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                variant="text"
                className={`${item.page === currentPage ? styles.activePage : ''} ${
                  item.page === 1 ? styles.paginationItem : ''
                }`}
              />
            )}
          />
          <Button
            className={styles.paginationButton}
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          />
        </div>

      </div>
    </div>
  );
};

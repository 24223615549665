// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderContent_logo_md__g7GG1 {\n  cursor: pointer;\n  max-height: 40px;\n  margin-right: 10px;\n}\n\n.HeaderContent_logo_xs__DdsXM {\n  cursor: pointer;\n  max-height: 40px;\n  margin: 0 auto;\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.HeaderContent_teach_button__Mcg9Z {\n  letter-spacing: 1px;\n  color: #000000;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/Layout/GuestLayout/Header/HeaderContent/index.module.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;EACA,gBAAA;EACA,kBAAA;AACH;;AAEA;EACG,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,2BAAA;AACH;;AAEA;EACG,mBAAA;EACA,cAAA;EACA,gBAAA;AACH","sourcesContent":[".logo_md {\n   cursor: pointer;\n   max-height: 40px;\n   margin-right: 10px;\n}\n\n.logo_xs {\n   cursor: pointer;\n   max-height: 40px;\n   margin: 0 auto;\n   position: absolute;\n   left: 50%;\n   transform: translateX(-50%);\n}\n\n.teach_button {\n   letter-spacing: 1px;\n   color: #000000;\n   font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo_md": "HeaderContent_logo_md__g7GG1",
	"logo_xs": "HeaderContent_logo_xs__DdsXM",
	"teach_button": "HeaderContent_teach_button__Mcg9Z"
};
export default ___CSS_LOADER_EXPORT___;

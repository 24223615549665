//project imports
import businessImg from '../../../../../../assets/images/guestHome/businessSection.jpg';
import { publicURL } from '../../../../../../utils/consts';
//material ui
import AdjustIcon from '@mui/icons-material/Adjust';
//components
import { AppBtn, AppBtnTypes } from '../../../../../UI/AppBtn/AppBtn';
//styles
import styles from './BusinessSection.module.scss';

export const BusinessSection = () => {
   return (
      <section className={styles.container}>
         <div className={styles.flex_container}>
            <div className={styles.content_container}>
               <img src={`${publicURL}/images/logo.jpg`} alt='Foxminded_logo' />
               <h2>Upskill your team with Foxminded Business</h2>
               <ul>
                  <li>
                     <AdjustIcon />
                     <div className={styles.list_item}>
                        Unlimited access to 19,000+ top Udemy courses, anytime,
                        anywhere
                     </div>
                  </li>
                  <li className={styles.list_item}>
                     <AdjustIcon />
                     <div>International course collection in 14 languages</div>
                  </li>
                  <li className={styles.list_item}>
                     <AdjustIcon />
                     <div>Top certifications in tech and business</div>
                  </li>
               </ul>

               <div className={styles.button_container}>
                  <AppBtn buttonType={AppBtnTypes.inverted}>
                     Get Foxminded Business
                  </AppBtn>
                  <AppBtn buttonType={AppBtnTypes.base}>Learn more</AppBtn>
               </div>
            </div>
            <div className={styles.img_container}>
               <img
                  src={businessImg}
                  alt='business_promo_img'
                  width='400px'
                  height='400px'
               />
            </div>
         </div>
      </section>
   );
};

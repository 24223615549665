// material-ui
import { Box, List, Typography } from '@mui/material';

// project components
import { NavItem } from './NavItem';

// project imports
import { useRootStore } from '../../../../../../../hooks';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

interface Props {
   item: INavItem;
}

interface INavItem {
   id: string;
   title: string;
   type: string;
   url?: string;
   icon: React.ComponentType<{ style?: React.CSSProperties }>;
   target?: boolean;
   breadcrumbs?: boolean;
   external?: boolean;
   children?: INavItem[];
}

export const NavGroup: React.FC<Props> = ({ item }) => {
   const {
      title,
      children,
   } = item;
   const { layoutStore } = useRootStore();
   const drawerOpen = layoutStore.getDrawerOpen();

   const navCollapse = children?.map((menuItem) => {
      switch (menuItem.type) {
         case 'collapse':
            return (
               <Typography
                  key={menuItem.id}
                  variant='caption'
                  color='error'
                  sx={{ p: 2.5 }}
               >
                  collapse - only available in paid version
               </Typography>
            );
         case 'item':
         case 'nested':
            return <NavItem key={menuItem.id} item={menuItem} level={1} />;
         default:
            return (
               <Typography
                  key={menuItem.id}
                  variant='h6'
                  color='error'
                  align='center'
               >
                  Fix - Group Collapse or Items
               </Typography>
            );
      }
   });

   return (
      <List
         subheader={
            title &&
            drawerOpen && (
               <Box sx={{ pl: 3, mb: 1.5 }}>
                  <Typography variant='subtitle2' color='textSecondary'>
                     {title}
                  </Typography>
                  {/* only available in paid version */}
               </Box>
            )
         }
         sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
      >
         {navCollapse}
      </List>
   );
};

// NavGroup.propTypes = {
//    item: PropTypes.object,
// };
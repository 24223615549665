// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuizBody_wrapper__OEZzS {\n  padding: 0;\n  width: 70%;\n  padding-right: 80px;\n}\n@media screen and (max-width: 900px) {\n  .QuizBody_wrapper__OEZzS {\n    padding-right: 0;\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/quiz/QuizBody/QuizBody.module.scss"],"names":[],"mappings":"AAEA;EACG,UAAA;EACA,UAAA;EACA,mBAAA;AADH;AAGG;EALH;IAMM,gBAAA;IACA,WAAA;EAAJ;AACF","sourcesContent":["@use '../../../../../theme/config' as v;\n\n.wrapper {\n   padding: 0;\n   width: 70%;\n   padding-right: 80px;\n\n   @media screen and (max-width: v.$md) {\n      padding-right: 0;\n      width: 100%;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "QuizBody_wrapper__OEZzS"
};
export default ___CSS_LOADER_EXPORT___;

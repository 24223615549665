// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TaskStatusCard_message__45qau {\n  margin-bottom: 10px;\n}\n.TaskStatusCard_message__45qau:last-child {\n  margin-bottom: 15px;\n}\n\n.TaskStatusCard_meta__H7UHj {\n  display: flex;\n  width: -moz-fit-content;\n  width: fit-content;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.TaskStatusCard_name__\\+ILe6 {\n  font-weight: bold;\n  margin-right: 10px;\n}\n\n.TaskStatusCard_time__GkgKJ {\n  font-size: 12px;\n  color: #999;\n}\n\n.TaskStatusCard_mentor__r0Br4,\n.TaskStatusCard_student__1AJLP {\n  padding: 5px 15px;\n  background-color: rgba(239, 239, 239, 0.5);\n}\n\n.TaskStatusCard_mentor__r0Br4 {\n  border-radius: 15px 15px 15px 0;\n}\n\n.TaskStatusCard_student__1AJLP {\n  border-radius: 15px 15px 0 15px;\n  margin-left: auto;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/TaskStatusCard/TaskStatusCard.module.scss"],"names":[],"mappings":"AAAA;EACG,mBAAA;AACH;AACG;EACG,mBAAA;AACN;;AAGA;EACG,aAAA;EACA,uBAAA;EAAA,kBAAA;EACA,mBAAA;EACA,mBAAA;AAAH;;AAGA;EACG,iBAAA;EACA,kBAAA;AAAH;;AAGA;EACG,eAAA;EACA,WAAA;AAAH;;AAGA;;EAEG,iBAAA;EACA,0CAAA;AAAH;;AAGA;EACG,+BAAA;AAAH;;AAIA;EACG,+BAAA;EACA,iBAAA;AADH","sourcesContent":[".message {\n   margin-bottom: 10px;\n\n   &:last-child {\n      margin-bottom: 15px;\n   }\n}\n\n.meta {\n   display: flex;\n   width: fit-content;\n   align-items: center;\n   margin-bottom: 10px;\n}\n\n.name {\n   font-weight: bold;\n   margin-right: 10px;\n}\n\n.time {\n   font-size: 12px;\n   color: #999;\n}\n\n.mentor,\n.student {\n   padding: 5px 15px;\n   background-color: rgba(239, 239, 239, 0.5);\n}\n\n.mentor {\n   border-radius: 15px 15px 15px 0;\n\n}\n\n.student {\n   border-radius: 15px 15px 0 15px;\n   margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "TaskStatusCard_message__45qau",
	"meta": "TaskStatusCard_meta__H7UHj",
	"name": "TaskStatusCard_name__+ILe6",
	"time": "TaskStatusCard_time__GkgKJ",
	"mentor": "TaskStatusCard_mentor__r0Br4",
	"student": "TaskStatusCard_student__1AJLP"
};
export default ___CSS_LOADER_EXPORT___;

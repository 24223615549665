import { FC, RefObject } from 'react';
import { ICourseTask } from '../../../../../interfaces/interfaces';
//components
import { AccordionItem } from './TaskItem/TaskItem';
import { TaskAndResultAccordion } from '../Accordion/TaskAndResultAccordion';
//styles
import styles from './TasksSection.module.scss';

interface IProps {
   courseItems: {
      [topic: string]: {
         hasIncompleteCourseItem: true;
         workCourseItems: ICourseTask[];
      };
   };
   refForTasks: RefObject<HTMLDivElement>[];
}

export const TasksSection: FC<IProps> = ({ courseItems, refForTasks }) => {
   return (
      <>
         {Object.keys(courseItems).map((item, i) => (
            <div
               className={styles.container}
               key={Math.random() * 1000}
               id={`${item}`}
               ref={refForTasks[i]}
            >
               <div className={styles.number}>{i + 1}</div>
               <h2 className={styles.title}>Getting Started With {item}</h2>
               <p className={styles.description}>
                  Good tools make application development quick*er and easier to
                  maintain than* if you did everything by hand. The goal in this
                  guide is to build and run a simple Angular application in
                  TypeScript, using the Angular CLI while adhering to the Style
                  Guide recommendations that benefit every Angular project.
               </p>
               <div className={styles.accordion_container}>
                  <TaskAndResultAccordion
                     defaultExpanded={courseItems[item].hasIncompleteCourseItem}
                     topic={`${item} Assignment`}
                  >
                     {courseItems[item].workCourseItems.map((task, idx) => (
                        <AccordionItem
                           key={Math.random() * 1000}
                           task={task}
                           idx={idx}
                        />
                     ))}
                  </TaskAndResultAccordion>
               </div>
            </div>
         ))}
      </>
   );
};

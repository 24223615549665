// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TaskForm_label__0G3FO {\n  display: block;\n  margin-bottom: 0.5rem;\n}\n\n.TaskForm_h3__1IFlI, .TaskForm_error__a75nr {\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.TaskForm_textarea__k5chs {\n  resize: none;\n  display: block;\n  width: 100%;\n  border: 1px solid rgba(128, 128, 128, 0.3);\n  padding: 1rem;\n  font-size: 1rem;\n}\n\n.TaskForm_answer_btn__-y4CN {\n  font-size: 1.3rem;\n  padding: 5px 15px 5px 15px;\n  width: \"min-content\";\n  border-radius: 6px;\n  margin-top: 10px;\n}\n.TaskForm_answer_btn__-y4CN:disabled {\n  border-color: lightgray;\n}\n\n.TaskForm_error__a75nr {\n  color: #d32f2f;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/TaskForm/TaskForm.module.scss"],"names":[],"mappings":"AAAA;EACG,cAAA;EACA,qBAAA;AACH;;AAEA;EACG,eAAA;EACA,gBAAA;AACH;;AAEA;EACG,YAAA;EACA,cAAA;EACA,WAAA;EACA,0CAAA;EACA,aAAA;EACA,eAAA;AACH;;AAEA;EACG,iBAAA;EACA,0BAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;AACH;AACG;EACG,uBAAA;AACN;;AAGA;EAEG,cAAA;AADH","sourcesContent":[".label {\n   display: block;\n   margin-bottom: 0.5rem;\n}\n\n.h3 {\n   font-size: 20px;\n   font-weight: 500;\n}\n\n.textarea {\n   resize: none;\n   display: block;\n   width: 100%;\n   border: 1px solid rgba(128, 128, 128, 0.3);\n   padding: 1rem;\n   font-size: 1rem;\n}\n\n.answer_btn {\n   font-size: 1.3rem;\n   padding: 5px 15px 5px 15px;\n   width: 'min-content';\n   border-radius: 6px;\n   margin-top: 10px;\n   \n   &:disabled {\n      border-color: lightgray\n   }\n}\n\n.error {\n   @extend .h3;\n   color: #d32f2f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "TaskForm_label__0G3FO",
	"h3": "TaskForm_h3__1IFlI",
	"error": "TaskForm_error__a75nr",
	"textarea": "TaskForm_textarea__k5chs",
	"answer_btn": "TaskForm_answer_btn__-y4CN"
};
export default ___CSS_LOADER_EXPORT___;

import { useNavigate } from 'react-router-dom';

// material-ui
import { Box, IconButton, Link, Theme, useMediaQuery } from '@mui/material';
import { GitHub } from '@mui/icons-material';

// project components
import { Search, CategoriesNavMenu, HoverMenu } from '../../../common';
import { Profile } from './Profile';
import { Notification } from './Notification';
import { MobileSection } from './MobileSection';

// project import
import { publicURL } from '../../../../../../utils/consts';

// styles
import styles from './index.module.scss';

const teachPoperDescription =
   'Turn what you know into an opportunity and reach millions around the world.';
const classesPoperDescription =
   'Have a team of 5 and more? Get an exclusive offer anytime and anywhere.';

// ==============================|| HEADER - CONTENT ||============================== //

export const HeaderContent: React.FC = () => {
   const navigate = useNavigate();

   const matchDownMD = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('md'),
   );

   const matchUpSM = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('sm'),
   );

   const matchDownSM = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('sm'),
   );

   const handleClick = () => {
      navigate('/');
   };

   const handleClickTeach = () => {
      navigate('/teach_on_raining');
   };

   return (
      <>
         {matchUpSM && <CategoriesNavMenu />}
         {!matchDownMD && <Search />}

         {matchDownMD && <Box sx={{ width: '100%', ml: 1 }} />}

         {matchDownSM && (
            <img
               className={styles.logo}
               src={`${publicURL}/images/logo.jpg`}
               alt='Foxminded Logo'
               onClick={handleClick}
            />
         )}

         {!matchDownMD && (
            <>
               <HoverMenu
                  title='Classes on Training'
                  desc={classesPoperDescription}
               />
               <HoverMenu
                  title='Teach on Training'
                  desc={teachPoperDescription}
                  functionNavigate={handleClickTeach}
               />
            </>
         )}

         {!matchDownSM && (
            <IconButton
               component={Link}
               href='#'
               target='_blank'
               disableRipple
               color='secondary'
               sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
            >
               <GitHub />
            </IconButton>
         )}

         <Notification />
         {!matchDownMD && <Profile />}
         {matchDownMD && <MobileSection />}
      </>
   );
};

// assets
import { 
    SpeedOutlined, 
    BookOutlined,
    PeopleOutlined,
    NotificationsOutlined
 } from '@mui/icons-material';

// icons
const icons = {
    SpeedOutlined,
    BookOutlined,
    PeopleOutlined,
    NotificationsOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'mentors',
            title: 'Edit Mentors',
            type: 'item',
            url: '#',
            icon: icons.PeopleOutlined,
            breadcrumbs: false
        },
        {
            id: 'my-courses',
            title: 'Edit Courses',
            type: 'item',
            url: '#',
            icon: icons.BookOutlined,
            breadcrumbs: false
        },
        {
            id: 'my-students',
            title: 'Edit Students',
            type: 'item',
            url: '#',
            icon: icons.PeopleOutlined,
            breadcrumbs: false
        },
    ]
};
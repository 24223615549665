import { useState } from 'react';
//components
import { AppBtn, AppBtnTypes } from '../../../UI';
//styles
import styles from './Training5OrMore.module.scss';

export const Training5OrMore = () => {
   const [isVisible, setIsVisible] = useState(true);

   if (!isVisible) {
      return null;
   }

   return (
      <div className={styles.container}>
         <p className={styles.message}>
            <span>Training 5 or more people?</span> Get your team access to
            Training's top 19,000+ courses
         </p>
         <div className={styles.btn_container}>
            <AppBtn buttonType={AppBtnTypes.base} className={styles.btn_base}>
               Classes on Training
            </AppBtn>
            <AppBtn
               buttonType={AppBtnTypes.inverted}
               onClick={() => setIsVisible(false)}
            >
               Dismiss
            </AppBtn>
         </div>
      </div>
   );
};

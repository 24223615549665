// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MentorSection_mentor_section__VaBLt {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 1rem;\n}\n\n.MentorSection_avatar__O2Ef4 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 40px;\n  height: 40px;\n  background-color: black;\n  border-radius: 50%;\n  font-size: 14px;\n  letter-spacing: 1px;\n  color: #fff;\n  font-weight: 700;\n}\n\n.MentorSection_names__Z59k5 {\n  line-height: 1.3;\n  cursor: pointer;\n}\n.MentorSection_names__Z59k5 :nth-child(1) {\n  font-weight: 600;\n}\n.MentorSection_names__Z59k5 :nth-child(2) {\n  color: rgba(39, 44, 51, 0.5);\n  font-size: 0.82rem;\n  letter-spacing: 0.5px;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/studentCourse/CourseInfoBar/components/MentorSection/MentorSection.module.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AACH;;AAEA;EACG,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;AACH;;AAEA;EACG,gBAAA;EACA,eAAA;AACH;AACG;EACG,gBAAA;AACN;AAEG;EACG,4BAAA;EACA,kBAAA;EACA,qBAAA;AAAN","sourcesContent":[".mentor_section {\n   display: flex;\n   flex-direction: row;\n   align-items: center;\n   gap: 1rem;\n}\n\n.avatar {\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   width: 40px;\n   height: 40px;\n   background-color: black;\n   border-radius: 50%;\n   font-size: 14px;\n   letter-spacing: 1px;\n   color: #fff;\n   font-weight: 700;\n}\n\n.names {\n   line-height: 1.3;\n   cursor: pointer;\n\n   & :nth-child(1) {\n      font-weight: 600;\n   }\n\n   & :nth-child(2) {\n      color: rgba(39, 44, 51, 0.5);\n      font-size: 0.82rem;\n      letter-spacing: 0.5px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mentor_section": "MentorSection_mentor_section__VaBLt",
	"avatar": "MentorSection_avatar__O2Ef4",
	"names": "MentorSection_names__Z59k5"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextQuestion_wrapper__8031o {\n  margin-bottom: 30px;\n}\n\n.TextQuestion_question__-YYUA {\n  margin-bottom: 16px;\n  font-weight: 500;\n}\n\n.TextQuestion_textarea__XU\\+26 {\n  resize: none;\n  display: block;\n  width: 100%;\n  border: 1px solid rgba(128, 128, 128, 0.3);\n  border-radius: 6px;\n  padding: 10px;\n  font-size: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/feedback/TextQuestion/TextQuestion.module.scss"],"names":[],"mappings":"AAAA;EACG,mBAAA;AACH;;AAEA;EACG,mBAAA;EACA,gBAAA;AACH;;AAEA;EACG,YAAA;EACA,cAAA;EACA,WAAA;EACA,0CAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;AACH","sourcesContent":[".wrapper {\n   margin-bottom: 30px;\n}\n\n.question {\n   margin-bottom: 16px;\n   font-weight: 500;\n}\n\n.textarea {\n   resize: none;\n   display: block;\n   width: 100%;\n   border: 1px solid rgba(128, 128, 128, 0.3);\n   border-radius: 6px;\n   padding: 10px;\n   font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TextQuestion_wrapper__8031o",
	"question": "TextQuestion_question__-YYUA",
	"textarea": "TextQuestion_textarea__XU+26"
};
export default ___CSS_LOADER_EXPORT___;

import { FC, useState } from 'react';
import { v4 } from 'uuid';
//mui
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
//styles
import styles from './ProfileNote.module.scss';

interface IProps {
    id: number | undefined;
    note: string | undefined;
}

export const ProfileNote: FC<IProps> = ({ id, note }) => {
    const [text, setText] = useState(note)
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <NoteAltOutlinedIcon />
                <span>Note</span>
            </div>
            <textarea 
                id={v4()} 
                rows={2} 
                maxLength={255} 
                value={note !== undefined ? note : '' }
                onChange={(e) => setText(e.target.value)}
            ></textarea>
        </div>
    )
}
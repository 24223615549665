// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileNote_container__PXb8a {\n  min-width: 100%;\n  padding-bottom: 20px;\n}\n.ProfileNote_container__PXb8a .ProfileNote_title__I-5D\\+ {\n  display: flex;\n  align-items: center;\n  color: #000000;\n  font-family: Verdana;\n  font-size: 20px;\n}\n.ProfileNote_container__PXb8a svg {\n  color: #6f6f6f;\n  width: auto;\n  height: 17px;\n}\n.ProfileNote_container__PXb8a textarea {\n  width: 100%;\n  border: 1px solid #ced4da;\n  min-height: 100%;\n  padding: 5px;\n  margin-top: 5px;\n  resize: none;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/MyStudents/StudentProfile/ProfileNote/ProfileNote.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,oBAAA;AACJ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,oBAAA;EACA,eAAA;AAER;AAAI;EACI,cAAA;EACA,WAAA;EACA,YAAA;AAER;AAAI;EACI,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AAER","sourcesContent":[".container {\n    min-width: 100%;\n    padding-bottom: 20px;\n    .title {\n        display: flex;\n        align-items: center;\n        color: #000000;\n        font-family: Verdana;\n        font-size: 20px; \n    }\n    svg {\n        color: #6f6f6f;\n        width: auto;\n        height: 17px;\n    }\n    textarea {\n        width: 100%;\n        border: 1px solid #ced4da;\n        min-height: 100%;\n        padding: 5px;\n        margin-top: 5px;\n        resize: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ProfileNote_container__PXb8a",
	"title": "ProfileNote_title__I-5D+"
};
export default ___CSS_LOADER_EXPORT___;

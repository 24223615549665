
import styles from './LearningSection.module.scss';
import { useMediaQuery } from "@mui/material";
import { AboutLearning } from "./AboutLearning/AboutLearning";
import { AboutLearningDesktop } from "./AboutLearningDesktop/AboutLearningDesktop";


export const LearningSection = () => {

    const matchesSm = useMediaQuery('(min-width:1000px)'
   );

  return (
    
       <div className={styles.tabs_section_container}>
          <h2 >
          How to begin
          </h2>
          {!matchesSm ? <AboutLearning /> : <AboutLearningDesktop />}
       </div>

  )
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Filter_form_select__mX3xc {\n  color: #262a2e;\n  border: 1px solid #d8d8d8;\n  border-radius: 0.375rem;\n  padding: 5px 8px 5px 5px;\n  margin-top: 20px;\n}\n.Filter_form_select__mX3xc:hover {\n  border-color: #0069F7;\n}\n.Filter_form__aVif\\+ option {\n  font-weight: normal;\n  display: block;\n  word-wrap: nowrap;\n  min-height: 1.2em;\n  padding: 0px 2px 1px;\n}\n.Filter_form__aVif\\+ option:hover {\n  border-color: #0069F7;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/MyStudents/StudentsList/Filter/Filter.module.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,yBAAA;EACA,uBAAA;EACA,wBAAA;EACA,gBAAA;AAAR;AACQ;EACI,qBAAA;AACZ;AAGI;EACI,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,oBAAA;AADR;AAEQ;EACI,qBAAA;AAAZ","sourcesContent":[".form {\n    &_select {\n        color: #262a2e;\n        border: 1px solid #d8d8d8;\n        border-radius: 0.375rem;\n        padding: 5px 8px 5px 5px;\n        margin-top: 20px;\n        &:hover {\n            border-color: #0069F7;\n        }\n    }\n\n    option {\n        font-weight: normal;\n        display: block;\n        word-wrap: nowrap;\n        min-height: 1.2em;\n        padding: 0px 2px 1px;\n        &:hover {\n            border-color: #0069F7;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_select": "Filter_form_select__mX3xc",
	"form": "Filter_form__aVif+"
};
export default ___CSS_LOADER_EXPORT___;

import { v4 } from 'uuid';
//material ui
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
//project imports
import { dataText } from '../../dataText';
import styles from './AboutLearningDesktop.module.scss'
import TabPanel from '../../../../GuestHome/components/TabsSection/DesktopTabs/TabPanel';
import { useState } from 'react';

export const AboutLearningDesktop = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
 };

   return (
      <Box sx={{ width: '100%'}}>
         <Box>
            <Tabs
               value={value}
               onChange={handleChange}
               variant='fullWidth'
               scrollButtons='auto'
               aria-label='secondary tabs example'
               TabIndicatorProps={{ sx: { backgroundColor: 'black' } }}
               sx={{
                  '& button': {
                     color: 'grey',
                     fontSize: '20px',
                     fontWeight: 700,
                     letterSpacing: '0.5px',
                  },
                  '& button:hover': { color: 'black' },
                  '& button.Mui-selected': { color: 'black' },
               }}
            >

               {dataText.map((data) => (
                  <Tab label={data.header} key={v4()} />
               ))}
            </Tabs>
         </Box>

         {dataText.map((data, index) => (
            <TabPanel value={value} index={index} key={v4()}>
               <div className={styles.container}>
                  <div className={styles.wrapper}>
                     <p>{data.your_task}</p>
                     <h2>How we help you</h2>
                     <p>{data.our_task}</p>
                  </div>
                  <img src={data.img} alt="data_img" />
               </div>
            </TabPanel>
         ))}
      </Box>
   );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageContainer_container__jn6cF {\n  max-width: 1400px;\n  margin: 0 auto;\n  padding: 0 24px;\n  height: 100%;\n  width: 100%;\n}\n@media (max-width: 600px) {\n  .PageContainer_container__jn6cF {\n    padding: 0 16px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/PageContainer/PageContainer.module.scss"],"names":[],"mappings":"AAAA;EACG,iBAAA;EACA,cAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AACH;AACG;EAPH;IAQM,eAAA;EAEJ;AACF","sourcesContent":[".container {\n   max-width: 1400px;\n   margin: 0 auto;\n   padding: 0 24px;\n   height: 100%;\n   width: 100%;\n\n   @media (max-width: 600px) {\n      padding: 0 16px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PageContainer_container__jn6cF"
};
export default ___CSS_LOADER_EXPORT___;

const styles = {
   minHeight: '50vh',
   marginTop: '4rem',
   textAlign: 'center' as 'center',
   fontSize: '4rem'
}

export const Loader: React.FC = () => {
   return (
      <>
         <div style={styles} >Loading...</div>
      </>
   );
}
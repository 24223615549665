import { Outlet } from 'react-router-dom';

// material-ui
import { Box } from '@mui/material';

// project components
import { Header } from './Header';
import { MainDrawer } from './Drawer';
import { Footer } from '../common';

// styles
import styles from './ManagerLayout.module.scss';

export const ManagerLayout: React.FC = () => {
   return (
      <Box className={styles.wrapper}>
         <Header />
         <MainDrawer />
         <Box
            component='main'
            className={styles.main}
         >
            <Outlet />
         </Box>
         <Footer />
      </Box>
   );
};

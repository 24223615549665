// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BorderedBlockContainer_container__ivxjT {\n  padding: 42px 28px;\n  border: 1px solid #d9d9d9;\n}", "",{"version":3,"sources":["webpack://./src/modules/UI/BorderedBlockContainer/BorderedBlockContainer.module.scss"],"names":[],"mappings":"AAAA;EACG,kBAAA;EACA,yBAAA;AACH","sourcesContent":[".container {\n   padding: 42px 28px;\n   border: 1px solid #d9d9d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BorderedBlockContainer_container__ivxjT"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubmissionSummary_message__jL4cm {\n  margin-bottom: 60px;\n  text-align: center;\n}\n\n.SubmissionSummary_title__WcGiu {\n  margin-bottom: 10px;\n  font-size: 1.3rem;\n  font-weight: 500;\n}\n\n.SubmissionSummary_button__zCJ59 {\n  display: block;\n  max-width: 400px;\n  margin: 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/feedback/SubmissionSummary/SubmissionSummary.module.scss"],"names":[],"mappings":"AAAA;EACG,mBAAA;EACA,kBAAA;AACH;;AAEA;EACG,mBAAA;EACA,iBAAA;EACA,gBAAA;AACH;;AAEA;EACG,cAAA;EACA,gBAAA;EACA,cAAA;AACH","sourcesContent":[".message {\n   margin-bottom: 60px;\n   text-align: center;\n}\n\n.title {\n   margin-bottom: 10px;\n   font-size: 1.3rem;\n   font-weight: 500;\n}\n\n.button {\n   display: block;\n   max-width: 400px;\n   margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "SubmissionSummary_message__jL4cm",
	"title": "SubmissionSummary_title__WcGiu",
	"button": "SubmissionSummary_button__zCJ59"
};
export default ___CSS_LOADER_EXPORT___;

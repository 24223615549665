import { FC } from 'react';
import { BorderedBlockContainer } from '../../../UI';
import { IStudentCourses } from '../../../../http/mentorAPI';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 } from 'uuid';
import styles from './ProfileCourses.module.scss';

interface IProps {
   courses: IStudentCourses[] | undefined;
}

export const ProfileCourses: FC<IProps> = ({ courses }) => {
   return (
      <BorderedBlockContainer className={styles.container}>
         <h4 className={styles.subheading}>Courses</h4>
         <div>
            {courses?.map((course) => (
               <div key={v4()} className={styles.item}>
                  <div>
                     <FontAwesomeIcon
                        icon={faGraduationCap}
                        className={styles.icons}
                     />
                     {course.title}
                  </div>

                  <div>
                     <strong>last action:</strong> {course.lastAction}
                  </div>

                  <div>
                     {course.status === 'ACTIVE' ? (
                        <span className={styles.active}>{course.status}</span>
                     ) : course.status === 'COMPLETED' ? (
                        <span className={styles.completed}>
                           {course.status}
                        </span>
                     ) : course.status === 'STOPPED' ? (
                        <span className={styles.stopped}>{course.status}</span>
                     ) : null}
                  </div>
               </div>
            ))}
         </div>
      </BorderedBlockContainer>
   );
};

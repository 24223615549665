// styles
import styles from './Spinner.module.scss';

interface Props {
   className?: string;
}

export const Spinner: React.FC<Props> = ({ className }) => {
   return (
      <div className={styles.overlay}>
         <div className={[styles.container, className || ''].join(' ')}></div>
      </div>
   );
};
// components
import { GoProCard } from '../GoProCard';
import { AppBtn, AppBtnTypes } from '../../../UI';
// project
import { goProData } from '../../library';
// styles
import styles from './GoPro.module.scss';

export const GoPro: React.FC = () => {
   const coursesMap = goProData.map((course, i, arr) => (
      <GoProCard
         key={course.id}
         course={{ ...course, isLast: i === arr.length - 1 }}
      />
   ));

   const total = goProData.reduce((acc, course) => {
      return acc + course.price;
   }, 0);

   return (
      <div className={styles.wrapper}>
         <h3 className={styles.title}>Go Pro Path</h3>
         {coursesMap}
         <div className={styles.interactive}>
            <div className={styles.total}>Total: <strong>${total}</strong></div>
            <AppBtn buttonType={AppBtnTypes.secondary_dark} className={styles.cart_btn}>
               Add all to cart
            </AppBtn>
         </div>
      </div>
   );
};

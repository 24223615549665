import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

// project components
import { QuizMatchingItem } from './QuizMatchingItem';

// project imports
import { useRootStore } from '../../../../../hooks';

// types
import { IQuizQuestion, IMatchingAnswer } from '../../../interfaces/interfaces';

// styles
import styles from './QuizMatching.module.scss';

interface IProps {
   question: IQuizQuestion;
   title: string;
}

export const QuizMatching: React.FC<IProps> = observer(({ question }) => {
   const [matchValue, setMatchValue] = useState<IMatchingAnswer[]>([]);
   const [selectChange, setSelectChange] = useState('');
   const { questionId, matchingAnswers, matchingQuestions } = question;
   const { studentStore } = useRootStore();

   useEffect(() => {
      selectChange &&
         studentStore.setActualValue({
            questionId: questionId,
            matchingAnswer: matchValue,
         });
   }, [matchValue, selectChange]);

   const handleChange = (e: React.ChangeEvent<HTMLSelectElement>, answer: string) => {
      const selectValue = e.target.value;
      setSelectChange(e.target.value);
      const findChangebleAnswer = matchValue.find(
         (val) => val.answer === answer,
      );

      if (!findChangebleAnswer) {
         setMatchValue([
            ...matchValue,
            {
               premise: selectValue,
               answer: answer,
            },
         ]);
      } else {
         findChangebleAnswer.premise = selectValue;
      }
   };

   return (
      <>
         <div className={styles.item}>
            <QuizMatchingItem
               matchingAnswers={matchingAnswers!}
               matchingQuestions={matchingQuestions!}
               handleChange={handleChange}
            />
         </div>

         <p className={styles.note}>
            Note: The selected option cannot be correct for more than one action.
         </p>
      </>
   );
});

import { useState, useEffect } from 'react';

const useScrollPosition = () => {
   const [scrollPosition, setScrollPosition] = useState(0);

   const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
   };

   useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);

   return { scrollPosition };
};

export default useScrollPosition;

import { FC } from 'react';
//styles
import styles from './ResultItem.module.scss';

interface IProps {
   result: {
      title: string;
      calculatedWeight: number;
      range: string;
      score: number;
      contributionToCourse: number;
   };
}

export const ResultItem: FC<IProps> = ({ result }) => {
   return (
      <div className={styles.grid_container}>
         <div className={styles.title}>{result.title}</div>
         <div className={styles.weight}>Weight</div>
         <div className={styles.range}>Range</div>
         <div className={styles.score}>Score</div>
         <div className={styles.contribution}>Contribution to Course</div>
         <div className={styles.res_waight}>{result.calculatedWeight}</div>
         <div className={styles.res_range}>{result.range}</div>
         <div className={styles.res_score}>{result.score}</div>
         <div className={styles.res_sontribution}>
            {result.contributionToCourse}
         </div>
      </div>
   );
};

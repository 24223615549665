//mui
import { LinearProgress, Tooltip } from '@mui/material';
import Rating from '@mui/material/Rating/Rating';
import { StarBorder } from '@mui/icons-material';
//styles
import styles from './LinearProgressBlock.module.scss';

export const LinearProgressBlock = () => {
   const popperProps = {
      modifiers: [
         {
            name: 'offset',
            options: {
               offset: [0, -8],
            },
         },
      ],
   };

   return (
      <div className={styles.linear_progress_block}>
         <Tooltip
            title={'75 rated 5/5'}
            placement='top'
            arrow
            PopperProps={popperProps}
         >
            <div className={styles.container_progress}>
               <LinearProgress
                  className={styles.line_progress}
                  color='inherit'
                  variant='determinate'
                  value={75}
               />
               <span className={styles.rating}>
                  <Rating
                     emptyIcon={<StarBorder className={styles.star} />}
                     name='read-only'
                     value={5}
                     readOnly
                     size='small'
                     max={5}
                     precision={0.5}
                  />
               </span>
            </div>
         </Tooltip>
         <Tooltip
            title={'50 rated 4/5'}
            placement='top'
            arrow
            PopperProps={popperProps}
         >
            <div className={styles.container_progress}>
               <LinearProgress
                  className={styles.line_progress}
                  color='inherit'
                  variant='determinate'
                  value={50}
               />
               <span className={styles.rating}>
                  <Rating
                     emptyIcon={<StarBorder className={styles.star} />}
                     name='read-only'
                     value={4}
                     readOnly
                     size='small'
                     max={5}
                     precision={0.5}
                  />
               </span>
            </div>
         </Tooltip>
         <Tooltip
            title={'25 rated 3/5'}
            placement='top'
            arrow
            PopperProps={popperProps}
         >
            <div className={styles.container_progress}>
               <LinearProgress
                  className={styles.line_progress}
                  color='inherit'
                  variant='determinate'
                  value={25}
               />
               <span className={styles.rating}>
                  <Rating
                     emptyIcon={<StarBorder className={styles.star} />}
                     name='read-only'
                     value={3}
                     readOnly
                     size='small'
                     max={5}
                     precision={0.5}
                  />
               </span>
            </div>
         </Tooltip>
         <Tooltip
            title={'12 rated 2/5'}
            placement='top'
            arrow
            PopperProps={popperProps}
         >
            <div className={styles.container_progress}>
               <LinearProgress
                  className={styles.line_progress}
                  color='inherit'
                  variant='determinate'
                  value={12}
               />
               <span className={styles.rating}>
                  <Rating
                     emptyIcon={<StarBorder className={styles.star} />}
                     name='read-only'
                     value={2}
                     readOnly
                     size='small'
                     max={5}
                     precision={0.5}
                  />
               </span>
            </div>
         </Tooltip>
         <Tooltip
            title={'7 rated 1/5'}
            placement='top'
            arrow
            PopperProps={popperProps}
         >
            <div className={styles.container_progress}>
               <LinearProgress
                  className={styles.line_progress}
                  color='inherit'
                  variant='determinate'
                  value={7}
               />
               <span className={styles.rating}>
                  <Rating
                     emptyIcon={<StarBorder className={styles.star} />}
                     name='read-only'
                     value={1}
                     readOnly
                     size='small'
                     max={5}
                     precision={0.5}
                  />
               </span>
            </div>
         </Tooltip>
      </div>
   );
};

import { useEffect } from 'react';
import axios from 'axios';
import { useRootStore } from './mobx';
import { baseURL } from '../utils/consts';
import UserService from '../utils/userService';
import { useSignOut } from './useSignOut';

const authHost = axios.create({
   baseURL,
});

const useAuthHost = () => {
   const { userStore } = useRootStore();
   const user = userStore.getUser();

   useEffect(() => {
      const requestIntercept = authHost.interceptors.request.use((config) => {
         if (UserService.isLoggedIn())
            config.headers.Authorization = `Bearer ${UserService.getToken()}`;
         
         return config;
      });

      const responseIntercept = authHost.interceptors.response.use(
         (res) => {
            return res;
         },
         (err) => {
            if (err.response.data.status === 401) {
               UserService.doLogin()
            }
            return Promise.reject(err);
         },
      );

      return () => {
         authHost.interceptors.request.eject(requestIntercept);
         authHost.interceptors.response.eject(responseIntercept);
      };
   }, [user]);

   return authHost;
};

export default useAuthHost;

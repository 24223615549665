
export const mentorsData = [
  {
    name: "Jonatan S.",
    grade: 3,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqpk-xBcSwJCSwzzXELaXSVu3sMJndcgP9bA&usqp=CAU",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "UI Designer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Fabrice M.",
    grade: 5,
    img: "",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "Web Developer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Diane D.",
    grade: 4,
    img: "",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "IOS Developer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Nicole G.",
    grade: 3,
    img: "https://drasler.ru/wp-content/uploads/2019/05/%D0%98%D0%B4%D0%B5%D0%B8-%D0%B4%D0%BB%D1%8F-%D1%84%D0%BE%D1%82%D0%BE-%D0%BD%D0%B0-%D0%B0%D0%B2%D1%83-%D0%BF%D0%BE%D0%B4%D0%B1%D0%BE%D1%80%D0%BA%D0%B0-002.jpg",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "UX Designer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Abraham K.",
    grade: 5,
    img: "",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "Entrepreneur",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Jane D",
    grade: 4,
    img: "",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "Android Developer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Jonatan O",
    grade: 5,
    img: "",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "UX Designer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Diane D.",
    grade: 4,
    img: "",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "IOS Developer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Nicole G.",
    grade: 3,
    img: "https://drasler.ru/wp-content/uploads/2019/05/%D0%98%D0%B4%D0%B5%D0%B8-%D0%B4%D0%BB%D1%8F-%D1%84%D0%BE%D1%82%D0%BE-%D0%BD%D0%B0-%D0%B0%D0%B2%D1%83-%D0%BF%D0%BE%D0%B4%D0%B1%D0%BE%D1%80%D0%BA%D0%B0-002.jpg",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "UX Designer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Abraham K.",
    grade: 5,
    img: "",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "Entrepreneur",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Diane D.",
    grade: 4,
    img: "",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "IOS Developer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Nicole G.",
    grade: 3,
    img: "https://drasler.ru/wp-content/uploads/2019/05/%D0%98%D0%B4%D0%B5%D0%B8-%D0%B4%D0%BB%D1%8F-%D1%84%D0%BE%D1%82%D0%BE-%D0%BD%D0%B0-%D0%B0%D0%B2%D1%83-%D0%BF%D0%BE%D0%B4%D0%B1%D0%BE%D1%80%D0%BA%D0%B0-002.jpg",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "UX Designer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Abraham K.",
    grade: 5,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAKM9o594H2pfMhjHXTG8hp-C8wKrUFpHQOQ&usqp=CAU",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "Entrepreneur",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Jane D",
    grade: 4,
    img: "",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "Android Developer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Jonatan O",
    grade: 5,
    img: "",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "UX Designer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Diane D.",
    grade: 4,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAKM9o594H2pfMhjHXTG8hp-C8wKrUFpHQOQ&usqp=CAU",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "IOS Developer",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Nicole G.",
    grade: 3,
    img: "https://drasler.ru/wp-content/uploads/2019/05/%D0%98%D0%B4%D0%B5%D0%B8-%D0%B4%D0%BB%D1%8F-%D1%84%D0%BE%D1%82%D0%BE-%D0%BD%D0%B0-%D0%B0%D0%B2%D1%83-%D0%BF%D0%BE%D0%B4%D0%B1%D0%BE%D1%80%D0%BA%D0%B0-002.jpg",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "UX Designer",
    courses : [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  },
  { name: "Abraham K.",
    grade: 5,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqpk-xBcSwJCSwzzXELaXSVu3sMJndcgP9bA&usqp=CAU",
    aboutYourself: "Fueled by my passion for understanding the nuances of cross-cultural advertising, I consider myself a forever student, eager to both build on my academic foundations in psychology and sociology and stay in tune with the latest digital marketing strategies through continued coursework.",
    jobTitle: "Entrepreneur",
    courses: [
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"},
      {name: "Java Script",
        img: "https://static.javatpoint.com/images/javascript/javascript_logo.png"}
    ]
  }
]

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TaskTableRow_link__FQmab {\n  color: #2f3237;\n  font-weight: 500;\n}\n.TaskTableRow_link__FQmab:hover {\n  text-decoration: underline;\n}\n\n.TaskTableRow_button__9Ahhl {\n  width: 120px;\n}", "",{"version":3,"sources":["webpack://./src/modules/mentor/components/AnswerList/TaskTableRow/TaskTableRow.module.scss"],"names":[],"mappings":"AAAA;EACG,cAAA;EACA,gBAAA;AACH;AACG;EACG,0BAAA;AACN;;AAGA;EACG,YAAA;AAAH","sourcesContent":[".link {\n   color: #2f3237;\n   font-weight: 500;\n\n   &:hover {\n      text-decoration: underline;\n   }\n}\n\n.button {\n   width: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "TaskTableRow_link__FQmab",
	"button": "TaskTableRow_button__9Ahhl"
};
export default ___CSS_LOADER_EXPORT___;

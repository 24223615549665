// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableItem_container__uEwii {\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.TableItem_circle__X3Wz3 {\n  margin-left: -7px;\n  margin-right: 10px;\n  display: block;\n  width: 12px;\n  height: 12px;\n  background-color: #e9edf2;\n  border-radius: 50%;\n  border: 2px solid #fff;\n}\n\n.TableItem_title__CHU5Q {\n  color: rgba(39, 44, 51, 0.7);\n  font-size: 1rem;\n  line-height: 1.5;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/studentCourse/TasksAndResultSection/components/TableOfContents/TableItem/TableItem.module.scss"],"names":[],"mappings":"AAEA;EACG,kBAAA;EACA,aAAA;EACA,mBAAA;AADH;;AAIA;EACG,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;AADH;;AAIA;EACG,4BAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AADH","sourcesContent":["@use '../../../../../../../../theme/config' as v;\n\n.container {\n   position: relative;\n   display: flex;\n   align-items: center;\n}\n\n.circle {\n   margin-left: -7px;\n   margin-right: 10px;\n   display: block;\n   width: 12px;\n   height: 12px;\n   background-color: #e9edf2;\n   border-radius: 50%;\n   border: 2px solid #fff;\n}\n\n.title {\n   color: rgba(39, 44, 51, 0.7);\n   font-size: 1rem;\n   line-height: 1.5;\n   cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TableItem_container__uEwii",
	"circle": "TableItem_circle__X3Wz3",
	"title": "TableItem_title__CHU5Q"
};
export default ___CSS_LOADER_EXPORT___;

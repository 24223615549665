import { ICoursePopupInfo, ICoursesPreview } from '../http/guestAPI';
import { makeAutoObservable } from 'mobx';

export class GuestStore {
   private coursesPreview: ICoursesPreview | null = null;
   private coursesPopupInfos: { [key: number]: ICoursePopupInfo } = {};
   private isLoadingCoursesPreview: boolean = true;

   constructor() {
      makeAutoObservable(this);
   }

   setCoursesPreview(coursesPreview: ICoursesPreview) {
      this.coursesPreview = coursesPreview;
   }

   setCoursePopupInfo(courseId: number, coursePopupInfo: ICoursePopupInfo) {
      this.coursesPopupInfos[courseId] = coursePopupInfo;
   }

   setIsLoadingCoursesPreview(isLoading: boolean) {
      this.isLoadingCoursesPreview = isLoading;
   }

   getCoursesPreview() {
      return this.coursesPreview;
   }

   getCoursePopupInfoById(courseId: number) {
      return this.coursesPopupInfos[courseId];
   }

   getIsLoadingCoursesPreview() {
      return this.isLoadingCoursesPreview;
   }

   getCoursesByCategory(category: string) {
      if (category === '') {
         return this.coursesPreview?.courses || [];
      }

      const filteredCourses =
         this.coursesPreview?.courses.filter(
            (course) => course.subcategory === category,
         ) ?? [];
      return filteredCourses;
   }
}

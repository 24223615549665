// styles
import styles from './AnswerRejected.module.scss';

interface IProps {
   userResult: string;
}

export const AnswerRejected: React.FC<IProps> = ({ userResult }) => {
   return (
      <>
         <p className={styles.result}>
            Your Result:
            <span className={styles.result_info}>
               {userResult}
            </span>
         </p>
         <p className={styles.result}>
            Status:
            <span className={styles.result_info}>
               Answer rejected
            </span>
         </p>
      </>
   );
};
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../UI';
import { IFeedbackResponseItem } from '../../../mockData';
import styles from './SubmissionSummary.module.scss';

interface Props {
   answers: IFeedbackResponseItem[];
}

const SubmissionSummary: FC<Props> = ({ answers }) => {
   const navigate = useNavigate();
   const navigateBack = () => {
      navigate(-1);
   };


   return (
      <>
         <div className={styles.message}>
            <h2 className={styles.title}>Submission successful!</h2>
            <p>
               We really appreciate you taking the time to share your experience
               with us.
            </p>
         </div>

         <Button
            buttonClass='primary'
            buttonText='Back to course'
            buttonClick={navigateBack}
            additionalClass={styles.button}
         />
      </>
   );
};

export default SubmissionSummary;

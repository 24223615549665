//project imports
import instructorImg from '../../../../../../assets/images/guestHome/instructorSection.jpg';
//components
import { AppBtn, AppBtnTypes } from '../../../../../UI/AppBtn/AppBtn';
//styles
import styles from './InstructorSection.module.scss';

export const InstructorSection = () => {
   return (
      <section className={styles.container}>
         <div className={styles.flex_container}>
            <div className={styles.img_container}>
               <img
                  src={instructorImg}
                  alt='instructor_promo_img'
                  width='400px'
                  height='400px'
               />
            </div>
            <div className={styles.content_container}>
               <h2>Become an instructor</h2>
               <p>
                  Instructors from around the world teach millions of students
                  on Udemy. We provide the tools and skills to teach what you
                  love.
               </p>
               <div className={styles.button_container}>
                  <AppBtn buttonType={AppBtnTypes.inverted}>
                     Start teaching today
                  </AppBtn>
               </div>
            </div>
         </div>
      </section>
   );
};

import { v4 } from 'uuid';
// project
import { ShowMoreBox } from '../../../UI';
// styles
import styles from './WhatLearn.module.scss';

const mockFeatures = [
   'Build enterprise level Vue applications and deploy to production',
   'File Uploads, Testing, PWAs, Internationalization, Authentication with Vue 3',
   'Learn to build reactive, performant, large scale applications like a senior developer',
   'Learn the latest features with Vue 3 including Composition API, Pinia, Vue Router + more',
   'Learn to build beautiful applications using TailwindCSS, Sass, CSS Animations and Transitions',
   'Master the latest ecosystem of a Vue Developer from scratch',
   'Become the top 10% VueJS Developer',
   'Use Pinia (the new replacement for Vuex) for state management in your applications',
   'Learn to compare tradeoffs when it comes to performance and scalability',
   'Set up authentication and user accounts',
   'Use Firebase to build full stack applications',
   'Learn to lead Vue projects by making good architecture decisions and helping others on your team',
   'Master Vue Design Patterns',
   'Routing with Vue Router',
   'Converting apps to Progressive Web Apps',
   'Testing your application with Vitest, snapshot testing, and even E2E testing!',
   'Using the latest ES6/ES7/ES8/ES9/ES10/ES11 JavaScript to write clean code',
   'Learn why Vue is outgrowing React and Angular in developer popularity!',
];

export const WhatLearn: React.FC = () => {
   const featuresMap = mockFeatures.map((feature) => (
      <li key={v4()} className={styles.li}>
         <span className={styles.bullet}>✔️</span>
         {feature}
      </li>
   ));

   const style = {
      px: { xs: 2, sm: 3 },
   };

   const content = (
      <>
         <h3 className={styles.title}>What you'll learn</h3>
         <ul className={styles.ul}>{featuresMap}</ul>
      </>
   );

   return (
      <ShowMoreBox height={350} sxProps={style} className={styles.wrapper}>
         {content}
      </ShowMoreBox>
   );
};

import {FC, useState, useEffect, RefObject, useCallback} from 'react';
//components
import { TableItem } from './TableItem/TableItem';
//styles
import styles from './TableOfContents.module.scss';

interface IProps {
   taskTitleArray: string[];
   refArray: RefObject<HTMLDivElement>[];
}

export const TableOfContents: FC<IProps> = ({ taskTitleArray, refArray }) => {
   const [activeLink, setActiveLink] = useState<HTMLDivElement | null>();

   const handleClickOnRef = (i: number) => {
      const targetElement = refArray[i].current;

      if (targetElement) {
         const scrollOffset = -50;
         const targetPosition =
            targetElement.getBoundingClientRect().top +
            window.pageYOffset +
            scrollOffset;

         window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      }
   };

   const handleScrollOnRef = useCallback(() => {
      const scrollPosition = window.scrollY;

      for (let i = refArray.length - 1; i >= 0; i--) {
         const targetElement = refArray[i].current;

         if (targetElement && scrollPosition >= targetElement.offsetTop) {
            setActiveLink(refArray[i].current);
            break;
         }
      }
   }, [refArray])

   useEffect(() => {
      window.addEventListener('scroll', handleScrollOnRef);

      return () => {
         window.removeEventListener('scroll', handleScrollOnRef);
      };
   }, [handleScrollOnRef]);

   return (
      <section className={styles.container}>
         <div className={styles.title}>
            <h3>Table of contents</h3>
            <p className={styles.divider}></p>
         </div>
         <div className={styles.content}>
            {taskTitleArray.map((task, i) => (
               <TableItem
                  key={Math.random() * 1000}
                  onClick={() => handleClickOnRef(i)}
                  activeLink={activeLink}
               >
                  {task}
               </TableItem>
            ))}
            <TableItem
               key={Math.random() * 1000}
               onClick={() => handleClickOnRef(taskTitleArray.length)}
               activeLink={activeLink}
            >
               Results
            </TableItem>
         </div>
      </section>
   );
};

import { makeAutoObservable } from 'mobx';

export default class MentorStore {
   private taskRefresh: boolean;
   private quizRefresh: boolean;

   constructor() {
      this.taskRefresh = false;
      this.quizRefresh = false;

      makeAutoObservable(this);
   }

   setTaskRefresh(payload: boolean) {
      this.taskRefresh = payload;
   }

   setQuizRefresh(payload: boolean) {
      this.quizRefresh = payload;
   }

   getTaskRefresh() {
      return this.taskRefresh;
   }

   getQuizRefresh() {
      return this.quizRefresh;
   }

   // setReviewTasksNumber(arrLength: number) {
   //    this.reviewTasksNumber = arrLength;
   // }

   // getReviewTasksNumber() {
   //    return this.reviewTasksNumber;
   // }
}

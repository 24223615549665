// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabsSection_tabs_section_container__Xcvmq {\n  margin-bottom: 50px;\n}\n\n.TabsSection_title__GKQpE {\n  margin-bottom: 14px;\n}", "",{"version":3,"sources":["webpack://./src/modules/guest/pages/GuestHome/components/TabsSection/TabsSection.module.scss"],"names":[],"mappings":"AAAA;EACG,mBAAA;AACH;;AAEA;EACG,mBAAA;AACH","sourcesContent":[".tabs_section_container {\n   margin-bottom: 50px;\n}\n\n.title {\n   margin-bottom: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs_section_container": "TabsSection_tabs_section_container__Xcvmq",
	"title": "TabsSection_title__GKQpE"
};
export default ___CSS_LOADER_EXPORT___;

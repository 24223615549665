import { MinimalLayout } from '../UI/Layout/MinimalLayout';
import { NotFound, ConnectionFailed } from '../error';

enum SpectatorPaths {
   home = '/',
   not_found = '/not-found',
   connection_failed = '/connection-failed',
}

export const ErrorRoutes = {
   path: SpectatorPaths.home,
   element: <MinimalLayout />,
   children: [
      {
         path: SpectatorPaths.not_found,
         element: <NotFound />,
      },
      {
         path: SpectatorPaths.connection_failed,
         element: <ConnectionFailed />,
      },
      // {
      //    path: '*',
      //    element: <MentorHome />,
      // },
   ],
};

import React from 'react';
import useProtectedContent from '../../hooks/useProtectedContent';
import { Loader } from '../../components/Loader';
import { BorderedBlockContainer } from '../../../UI';
import { CourseCard } from '../../components/CourseCard';
import { ICoursesList } from '../../interfaces/interfaces';
import { ENDPOINTS } from '../../../../http/studentAPI';
import styles from './StudentHome.module.scss';

export const StudentHome: React.FC = () => {
   const isHomepage = true
   const courses = useProtectedContent(ENDPOINTS.homepage, isHomepage) as ICoursesList[]

   if (!courses) return <Loader />

   return (
      <div className={styles.container}>
         <BorderedBlockContainer>
            <h3 className={styles.heading}>Available Courses</h3>
         </BorderedBlockContainer>
         <BorderedBlockContainer>
            {
               courses.map(course => {
                  const {
                     id,
                     courseTitle,
                     courseLogoUrl,
                     courseDescription,
                     mentorFirstName,
                     mentorLastName,
                     slug
                  } = course
                  return <CourseCard
                     key={id}
                     imageURL={courseLogoUrl}
                     courseName={courseTitle}
                     courseDescription={courseDescription}
                     mentorName={`${mentorFirstName} ${mentorLastName}`}
                     courseId={id}
                     slug={slug}
                  />
               })
            }
         </BorderedBlockContainer>
      </div>
   );
};

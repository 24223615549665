import { $authHost } from './index';

export interface IReviewTask {
   answer: string;
   courseTitle: string;
   id: number;
   studentFirstName: string;
   studentLastName: string;
   studentId: number;
   courseItemState: string;
   courseItemTitle: string;
   answerSubmittedDateTime: string;
   answerInReviewDateTime?: string;
   answerAcceptedDateTime?: string;
   answerRejectedDateTime?: string;
}

export interface IReviewQuiz {
   answerId: number;
   answer: string;
   description: string;
   courseTitle: string;
   courseItemTitle: string;
   studentLastName: string;
   studentFirstName: string;
   studentId: number;
   answerSubmittedDateTime: string;
}

export interface ITaskData {
   id: number;
   courseTitle: string;
   markdownUrl: string;
   state: string;
   title: string;
   topicTitle: string;
   type: string;
}

export interface IStudentCourses {
   id: number;
   lastAction: string;
   status: string;
   title: string;
}

export interface IStudentData {
   avatarLogoUrl: string;
   courses: IStudentCourses[];
   email: string;
   firstName: string;
   id: number;
   lastName: string;
   note: string;
   phone: string;
   skype: string;
   telegram: string;
}

export interface IMentorStudents {
   id: number;
   firstName: string;
   lastName: string;
   coursesTitles: string[];
}

export const getAllTasksList = async () => {
   const { data } = await $authHost.get(
      `/api/course-items/mentor/code-assignments/list`,
   );

   return data as IReviewTask[];
};

export const getReviewTaskList = async () => {
   const { data } = await $authHost.get(
      `/api/course-items/mentor/code-assignments/review-list`,
   );

   return data as IReviewTask[];
};

export const getReviewQuiz = async () => {
   const { data } = await $authHost.get(
      `/api/course-items/mentor/quiz-answers`,
   );

   return data as IReviewQuiz[];
};

export const reviewAnswerFromStudent = async (taskId: number) => {
   await $authHost.put(
      `/api/course-items/mentor/code-assignments/${taskId}/review`,
   );
};

export const acceptAnswerFromStudent = async (taskId: number) => {
   await $authHost.put(
      `/api/course-items/mentor/code-assignments/${taskId}/accept`,
   );
};

export const rejectAnswerFromStudent = async (taskId: number) => {
   await $authHost.put(
      `/api/course-items/mentor/code-assignments/${taskId}/reject`,
   );
};

export const rateQuizAnswer = async (answerId: number, score: number) => {
   await $authHost.patch(`/api/course-items/mentor/quiz-answers/${answerId}`, {
      score: score,
   });
};

export const getTaskData = async (taskId: string) => {
   const { data } = await $authHost.get(
      `/api/course-items/mentor/code-assignments/${taskId}`,
   );

   return data as ITaskData;
};

export const getTaskMarkdown = async (taskId: string) => {
   const { data } = await $authHost.get(`/api/course-items/markdown/${taskId}`);

   return data as string;
};

export const getStudentProfileById = async (studentId: string) => {
   const { data } = await $authHost.get(`/api/students/${studentId}`);

   return data as IStudentData;
};

export const setNote = async (studentId: string, noteData: string) => {
   const { data } = await $authHost.patch(
      `/api/students/${studentId}/edit-note`,
      { note: noteData },
   );

   return data as string;
};

export const getMentorStudents = async () => {
   const { data } = await $authHost.get(`/api/mentors/students`);

   return data as IMentorStudents[];
};

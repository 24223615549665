import { FC }  from 'react';
import { v4 } from 'uuid';
//UI
import { AppAvatar } from '../../../../../UI';
//mui
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
//styles
import styles from './ListItem.module.scss';

interface ICourses {
    id: number;
    title: string;
    status?: string;
    lastAction?: string;
}

interface IStudents {
    id: number;
    avatarLogoUrl: string | null;
    firstName: string;
    lastName: string; 
    email: string; 
    phone: string;
    skype: string; 
    telegram?: string; 
    note: string | null;
    courses?: ICourses[];
}

interface IProps { 
    students: IStudents[];
    studentSelected: (studentValue: any) => void;
}

export const ListItem: FC<IProps> = ({students, studentSelected}) => {

    const handleDivClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {       
        studentSelected(+event.currentTarget.id);
    }

    return (
        <div className={styles.container}>
            {students ? (
                students.map(student => (
                    <div id={`${student.id}`} key={v4()} className={styles.list_item} onClick={handleDivClick}>
                        <div className={styles.list} id={`${student.id}`} key={v4()} >
                            <div className={styles.list_student}>
                                <div>
                                    <AppAvatar name={`${student?.firstName} ${student?.lastName}`} className={styles.avatar} />
                                </div>
                        
                                <div className={styles.list_names}>
                                    <div>{student.firstName}</div>
                                    <div>{student.lastName}</div>
                                </div>
                            </div>
                            <div className={styles.contacts}>
                                <div className={styles.contacts_email}>
                                    <span className={styles.contacts_icon}><EmailOutlinedIcon /></span>
                                    <span className={styles.contacts_content}>{student.email}</span>
                                </div>
                                <div className={styles.contacts_phone}>
                                    <span className={styles.contacts_icon}><LocalPhoneOutlinedIcon /></span>
                                    <span className={styles.contacts_content}>{student.phone}</span>
                                </div>
                            </div>
                            <div className={styles.courses}>{student.courses?.map(course => <div className={course.id === 1 ? styles.course_id1 : course.id === 2 ? styles.course_id2 : ''} key={v4()}>{course.title}</div>)}</div>
                            <div className={styles.note}>
                                <textarea id={ `${student.id}`} rows={1} maxLength={255} defaultValue={student.note !== null ? student.note : '' }></textarea>
                            </div>
                        </div> 
                    </div>
                ))
            ) : 'list is empty'}  
        </div>
    )
} 
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import Pagination from '@mui/material/Pagination';

// project components
import { AppBtn, AppBtnTypes } from '../../../../UI';
import { Divider } from '../../../../UI/Divider';
import {
   AnswerAccepted,
   AnswerRejected,
   AnswerSubmitted,
} from './QuizResultsItems';
import { QuizResult } from '../../../interfaces/interfaces';
import { QuizCourseInfo } from '../QuizCourseInfo/QuizCourseInfo';

// project imports
import ScoreDetails from '../score/ScoreDetails/ScoreDetails';

// styles
import styles from './QuizResults.module.scss';

interface IProps {
   state?: string;
   startQuiz?: () => void;
   userResult?: string | undefined;
   statusServer?: number;
   quizResult?: QuizResult[];
   name: string;
   description: string;
   imageUrl: string;
}

export const QuizResults: React.FC<IProps> = ({
   state,
   startQuiz,
   userResult,
   statusServer,
   quizResult,
   name,
   description,
   imageUrl,
}) => {
   const navigate = useNavigate();
   const [paginationPage, setPaginationPage] = useState<number | undefined>(1);
   const activeScore = quizResult && quizResult[paginationPage! - 1];

   const handleClick = () => {
      navigate('/');
   };

   const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
      setPaginationPage(page);
   };

   const NextBtn = () => {
      if (paginationPage && paginationPage < quizResult!.length) {
         setPaginationPage(paginationPage + 1);
      } else {
         setPaginationPage(1);
      }
   };

   const PrevBtn = () => {
      if (paginationPage && paginationPage > 1) {
         setPaginationPage(paginationPage - 1);
      } else {
         setPaginationPage(quizResult!.length);
      }
   };

   return (
      <div className={styles.wrapper}>
         <div className={styles.container_info}>
            <h3 className={styles.result_title}>Quiz Finished!</h3>
            <Divider>Quiz results</Divider>
            <div className={styles.container}>
               {statusServer === 200 && <AnswerSubmitted />}
               {state === 'Answer submitted' && <AnswerSubmitted />}
               {state === 'Answer accepted' && (
                  <AnswerAccepted userResult={userResult!} />
               )}
               {state === 'Answer rejected' && (
                  <AnswerRejected userResult={userResult!} />
               )}
            </div>

            <div className={styles.container}>
               {quizResult && (
                  <>
                     <ScoreDetails score={activeScore!} />
                     <div className={styles.pagination}>
                        <Pagination
                           count={quizResult.length}
                           onChange={onPageChange}
                           page={paginationPage || 1}
                           hideNextButton
                           hidePrevButton
                        />
                     </div>
                  </>
               )}
            </div>
         </div>

         <div className={styles.container_side}>
            <div className={styles.container_btn}>
               {state === 'Answer rejected' && (
                  <AppBtn
                     className={styles.tryAgain_btn}
                     buttonType={AppBtnTypes.success}
                     onClick={startQuiz}
                  >
                     Try again
                  </AppBtn>
               )}
               <AppBtn
                  className={styles.done_btn}
                  buttonType={AppBtnTypes.success}
                  onClick={handleClick}
               >
                  Done
               </AppBtn>
               <AppBtn
                  className={styles.done_btn}
                  buttonType={AppBtnTypes.base}
                  onClick={NextBtn}
               >
                  Next Question
               </AppBtn>
               <AppBtn
                  className={styles.done_btn}
                  buttonType={AppBtnTypes.base}
                  onClick={PrevBtn}
               >
                  Previous Question
               </AppBtn>
            </div>
            <QuizCourseInfo
               name={name}
               description={description}
               imageUrl={imageUrl}
            />
         </div>
      </div>
   );
};

export const checkEnableSlider = (
   arrOfUiElements: any,
   windowWidth: number,
) => {
   if (
      (windowWidth >= 1200 && arrOfUiElements.length >= 5) ||
      (windowWidth < 1200 &&
         windowWidth >= 900 &&
         arrOfUiElements.length >= 4) ||
      (windowWidth < 900 && windowWidth >= 600 && arrOfUiElements.length >= 3)
   ) {
      return true;
   } else {
      return false;
   }
};

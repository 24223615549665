interface IRowData {
   toDoDateTime: string;
   inProgressDateTime: string;
   answerSubmittedDateTime: string;
   answerInReviewDateTime: string;
   answerRejectedDateTime: string;
   answerAcceptedDateTime: string;
}

interface IDataItem {
   date: string;
   name: string;
   time: string;
}

const useChatlikeDataModel = (
   rowData: IRowData,
   id: number,
   taskId: number | undefined,
   submittedDate: string | undefined,
) => {
   const newSubmitDate = id === taskId ? submittedDate : null;

   const statuses = Object.entries(rowData)
      .map((arr) =>
         newSubmitDate && arr[0] === 'answerSubmittedDateTime'
            ? { name: arr[0], date: newSubmitDate }
            : { name: arr[0], date: arr[1] },
      )
      .filter((status) => status.date);

   statuses.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

   const statusesDates = statuses.map((status) => ({
      ...status,
      date: status.date.split(' ')[0],
      time: status.date.split(' ')[1],
   }));

   const groupedStatuses = statusesDates
      .map((item) => item.date)
      .map((dateString) => new Date(dateString))
      .reduce<{ [key: string]: IDataItem[] }>((acc, date) => {
         const key = date.toDateString();
         if (!acc[key]) {
            acc[key] = [];
         }
         const matchingStatuses = statusesDates.filter(
            (item) => new Date(item.date).toDateString() === key,
         );
         acc[key] = [...matchingStatuses];
         return acc;
      }, {});

   const sortedStatuses = Object.entries(groupedStatuses)
      .sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime())
      .map(([key, value]) => ({ date: key, data: value }));

   return { statuses, sortedStatuses };
};

export default useChatlikeDataModel;

import { FC } from 'react';
import { IFeedbackAnswer } from '../../../mockData';
import styles from './SingleChoiceQuestion.module.scss';

interface Props {
   questionId: number;
   question: string;
   answers?: IFeedbackAnswer[];
}

const SingleChoiceQuestion: FC<Props> = ({ questionId, question, answers }) => {
   return (
      <div className={styles.wrapper}>
         <p className={styles.question}>{question}</p>

         {answers?.map(({ id, description }) => (
            <div key={id}>
               <label className={styles.field}>
                  <input
                     type='radio'
                     name={questionId.toString()}
                     value={description}
                     required
                  />
                  {description}
               </label>
            </div>
         ))}
      </div>
   );
};

export default SingleChoiceQuestion;

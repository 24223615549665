import {$authHost, $host} from './index';
import { ChangeEvent } from 'react';

export interface ICourse {
    id: number;
    title: string;
    status: string;
    lastAction: string;
}

export interface IViewAllStudents {
    avatarLogoUrl: string | null;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    telegram: string;
    skype: string;
    note: string;
    courses: ICourse[];
    value?: string;
    defaultValue?: string;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
    onBlur?: (e: React.FocusEventHandler<HTMLTextAreaElement>) => void;
    onFocus?: (e: React.FocusEventHandler<HTMLTextAreaElement>) => void;
}

export interface IPagination {
    pageSize: number;
    pageNumber: number;
    totalPages: number;
    currentPage: number;
    totalElements: number;
    href: string;
    students: IViewAllStudents[]
}

export interface ICommentFeedbackCourse {
  courseId: number
  comment: string
  nameStudent: string
  grade: number
  courseName: string
}

export interface ICourseFeedback {
  totalElements: number
  comments: ICommentFeedbackCourse[]
  currentPage: number
  totalPages: number
}

export const getViewAllStudents = async (pageSize: number, pageNumber: number ) => {
    const { data } = await $authHost.get(`/api/students?pageNumber=${pageNumber}&pageSize=${pageSize}`);

    return data.students as IViewAllStudents[];
}

export const getPagination = async (pageSize: number, pageNumber: number ) => {
    const { data } = await $authHost.get(`/api/students?pageNumber=${pageNumber}&pageSize=${pageSize}`);

    return data as IPagination;
}

export const noteStudents = async (answerId: number, note: string) => {
    await $authHost.patch(`/api/students/${answerId}/edit-note`, {
       note: note,
    });

};

export const studentCourseFeedback = async (courseId: number, page?: number, size?: number) => {
  const { pageNumber, pageSize } = {
    pageNumber: page ? `pageNumber=${page}` : '',
    pageSize: size ? `&pageSize=${size}` : '',
  }
  const { data }  = await $authHost.get(`/api/courses/comments/${courseId}?${pageNumber}${pageSize}`)
  return data as ICourseFeedback
}

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

// project components
import { RadioButton } from '../../../../quiz/components/RadioButton';

// project imports
import { useRootStore } from '../../../../../hooks';

// types
import { IChoiceAnswer, IQuizQuestion } from '../../../interfaces/interfaces';

// styles
import styles from './QuizSingleChoice.module.scss';

interface IProps {
   answers: IChoiceAnswer[];
   question: IQuizQuestion;
   title: string;
}

export const QuizSingleChoice: React.FC<IProps> = observer(
   ({ answers, question }) => {
      const [activeValue, setActiveValue] = useState<number>(0);
      const { questionId } = question;
      const { studentStore } = useRootStore();

      useEffect(() => {
         activeValue &&
            studentStore.setActualValue({
               questionId: questionId,
               choiceAnswer: [activeValue],
            });
      }, [activeValue]);

      const handleChange = (id: number) => {
         setActiveValue(id);
      };

      return (
         <>
            <div>
               {answers.map((answer) => (
                  <RadioButton
                     key={answer.choiceAnswer}
                     value={answer.choiceAnswer + ''}
                     name={question.description}
                     activeValue={activeValue}
                     answerId={answer.id}
                     handleChange={handleChange}
                  />
               ))}
            </div>

            <p className={styles.description}>
               Note: There can be only one correct answer to this question.
            </p>
         </>
      );
   },
);

// material-ui
import { Button, ButtonProps } from '@mui/material';

// project components
import { Spinner } from '../Spinner';

// styles
import styles from './AppBtn.module.scss';

export enum AppBtnTypes {
   base = 'base',
   inverted = 'inverted',
   success = 'success',
   success_inverted = 'success_inverted',
   error = 'error',
   error_inverted = 'error_inverted',
   warning_inverted = 'warning_inverted',

   link = 'link',
   secondary_dark = 'secondary_dark',
}

interface Props extends ButtonProps {
   children: React.ReactNode;
   className?: string;
   isLoading?: boolean;
   buttonType?: AppBtnTypes;
}

export const AppBtn: React.FC<Props> = ({
   children,
   className,
   isLoading,
   buttonType,
   ...otherProps
}) => {
   const getBtnClass = (btnType = AppBtnTypes.base): AppBtnTypes =>
      AppBtnTypes[btnType];

   const btnClass = getBtnClass(buttonType);

   return (
      <Button
         sx={{ minWidth: 'unset' }}
         className={[styles.button, styles[btnClass], className || ''].join(
            ' ',
         )}
         {...otherProps}
      >
         {isLoading ? <Spinner className={styles.spinner} /> : children}
      </Button>
   );
};

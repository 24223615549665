// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FeedbackSubmittedCheckmark_wrapper__qdS7S {\n  min-width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  border: 1px solid #d9d9d9;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.FeedbackSubmittedCheckmark_icon__Ql9Gg {\n  color: #198754;\n}", "",{"version":3,"sources":["webpack://./src/modules/student/components/feedback/FeedbackSubmittedCheckmark/FeedbackSubmittedCheckmark.module.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACH;;AAEA;EACG,cAAA;AACH","sourcesContent":[".wrapper {\n   min-width: 40px;\n   height: 40px;\n   border-radius: 50%;\n   border: 1px solid #d9d9d9;\n   display: flex;\n   align-items: center;\n   justify-content: center;\n}\n\n.icon {\n   color: #198754;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "FeedbackSubmittedCheckmark_wrapper__qdS7S",
	"icon": "FeedbackSubmittedCheckmark_icon__Ql9Gg"
};
export default ___CSS_LOADER_EXPORT___;

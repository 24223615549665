// assets
import { ListAltOutlined, LoginOutlined } from '@mui/icons-material';
// icons
const icons = {
    LoginOutlined,
    ListAltOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

export const pages = {
    id: 'authentication',
    title: 'Authentication',
    type: 'group',
    children: [
        {
            id: 'login1',
            title: 'Sign In',
            type: 'item',
            url: '/auth',
            icon: icons.LoginOutlined,
            target: true
        },
        {
            id: 'register1',
            title: 'Sign Up',
            type: 'item',
            url: '/auth/sign-up',
            icon: icons.ListAltOutlined,
            target: true
        }
    ]
};
import {
  LearningSection,
  Advantages,
  Billboard,
  CollToJoin,
  CommentsOfTeachers,
  ReasonsToStart,
  Statistics,
} from './components';
import styles from './TeachOnTraining.module.scss';

const TeachOnTraining: React.FC = () => {

  return (
    <div className={styles.container}>
      <Billboard/>
      <ReasonsToStart/>
      <Statistics/>
      <LearningSection/>
      <CommentsOfTeachers/>
      <Advantages/>
      <CollToJoin/>
    </div>
  )
}

export default TeachOnTraining
import { FC } from 'react';
import styles from './CellStatus.module.scss';

interface Props {
   stateClassName: string;
   shortCourseItemState: string;
   stateDateTime: string | undefined;
}

export const CellStatus: FC<Props> = ({
   stateClassName,
   shortCourseItemState,
   stateDateTime,
}) => {
   return (
      <td className={styles.status}>
         <span
            className={[styles.courseItemState, styles[stateClassName]].join(
               ' ',
            )}
         >
            {shortCourseItemState}
         </span>
         <span className={styles.date}>{stateDateTime}</span>
      </td>
   );
};

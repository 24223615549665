// material-ui
import { styled } from '@mui/material/styles';

const StyledTextarea = styled("textarea")(({ theme }) => ({
  border: `1px solid rgba(0, 0, 0, 0.12)`,
  width: "100%",
  minHeight: '120px',
  fontSize: '16px',
  padding: '12px 16px',
  borderRadius: 4,
  backgroundColor: "#f8f8f8",
  resize: 'vertical',
  "&:focus": {
    border: `2px solid #1976d2`
  },
  "&:hover": {
    border: `1px solid #000000`
  }
}));

export const QuestionDetailsTextArea: React.FC = () => {
  return (
    <>
      <StyledTextarea
        placeholder='Describe your question in detail ...'
      />
    </>
  )
}

// project components
import { SlickArrowLeft, SlickArrowRight } from '../Arrows';

export const allCoursesDesktop = {
   dots: false,
   infinite: false,
   speed: 500,
   slidesToShow: 5,
   slidesToScroll: 5,
   draggable: false,
   prevArrow: <SlickArrowLeft />,
   nextArrow: <SlickArrowRight slidesToShow={5} />,
   responsive: [
      {
         breakpoint: 1200,
         settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            nextArrow: <SlickArrowRight slidesToShow={4} />,
         },
      },
      {
         breakpoint: 900,
         settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: <SlickArrowRight slidesToShow={3} />,
         },
      },
   ],
};

export const allCoursesMobile = {
   dots: false,
   infinite: false,
   arrows: false,
   speed: 500,
   slidesToShow: 3,
   slidesToScroll: 3,
   responsive: [
      {
         breakpoint: 720,
         settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
         },
      },
      {
         breakpoint: 600,
         settings: {
            slidesToShow: 2.1,
            slidesToScroll: 2,
         },
      },
      {
         breakpoint: 500,
         settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1,
         },
      },
   ],
};

export const commentsSection = {
   dots: false,
   infinite: false,
   speed: 500,
   slidesToShow: 3,
   slidesToScroll: 1,
   prevArrow: <SlickArrowLeft />,
   nextArrow: <SlickArrowRight slidesToShow={3} />,
   responsive: [
      {
         breakpoint: 1100,
         settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow: <SlickArrowRight slidesToShow={2} />,
         },
      },
      {
         breakpoint: 600,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            rows: 2,
            slidesPerRow: 1,
         },
      },
   ],
};

export const commentsTeacher = {
   dots: false,
   infinite: false,
   speed: 500,
   slidesToShow: 1,
   slidesToScroll: 1,
   prevArrow: <SlickArrowLeft />,
   nextArrow: <SlickArrowRight slidesToShow={1} />,
};

export const studentsAreViewing = {
   dots: false,
   infinite: false,
   speed: 500,
   slidesToShow: 5,
   slidesToScroll: 5,
   draggable: false,
   prevArrow: <SlickArrowLeft />,
   nextArrow: <SlickArrowRight slidesToShow={5} />,
   responsive: [
      {
         breakpoint: 1200,
         settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            nextArrow: <SlickArrowRight slidesToShow={4} />,
         },
      },
      {
         breakpoint: 900,
         settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: <SlickArrowRight slidesToShow={3} />,
         },
      },
      {
         breakpoint: 600,
         settings: {
            arrows: false,
            draggable: true,
            rows: 3,
            slidesToShow: 1.8,
            slidesToScroll: 1,
            slidesPerRow: 1,
         },
      },
      {
         breakpoint: 490,
         settings: {
            arrows: false,
            draggable: true,
            rows: 3,
            slidesToShow: 1.4,
            slidesToScroll: 1,
            slidesPerRow: 1,
         },
      },
   ],
};

import { SetStateAction, useState, useEffect, SyntheticEvent, FC } from 'react';
import { observer } from 'mobx-react-lite';
//material ui
import { Tabs, Tab } from '@mui/material';
//router
import { Link, useLocation } from 'react-router-dom';

import { useReviewList } from '../../hooks';

export const MentorHomePageTabs: FC = observer(() => {
   const location = useLocation();
   const [value, setValue] = useState('/all-tasks');

   const { tabHookParams } = useReviewList();

   useEffect(() => {
      if (location.pathname === '/') {
         setValue('/all-tasks');
      } else {
         setValue(location.pathname);
      }
   }, [location]);

   const handleChange = (
      event: SyntheticEvent,
      newValue: SetStateAction<string>,
   ) => {
      setValue(newValue);
   };

   const SxProp = {
      maxWidth: '100%',
      '& a.MuiTab-root': {
         minWidth: 'max-content',
         fontSize: '16px',
         color: '#d1d7dc',
         fontWeight: 700,
         padding: '2px',
         marginRight: '1rem',
         letterSpacing: '0.5px',
      },
      '& .MuiTab-root:hover': { color: '#fff' },
      '& button.Mui-selected': { color: '#fff' },
      '& a.Mui-selected': { color: '#fff' },
      '& button:selected': { color: '#fff' },
      '& MuiTab-root.Mui-selected': { color: '#fff' },
   };

   return (
      <Tabs
         value={value}
         onChange={handleChange}
         TabIndicatorProps={{
            sx: { backgroundColor: '#fff', height: '5px' },
         }}
         variant='scrollable'
         sx={SxProp}
      >
         <Tab
            label={`Pending Task Review (${tabHookParams.tasksNum})`}
            value='/all-tasks'
            component={Link}
            to='/all-tasks'
         />
         <Tab
            label={`Pending Quiz Review (${tabHookParams.quizzesNum})`}
            value='/all-quiz'
            component={Link}
            to='/all-quiz'
         />
         <Tab
            label='All Task Answers'
            value='/all-answers'
            component={Link}
            to='/all-answers'
         />
         <Tab
            label='My Students'
            value='/my-students'
            component={Link}
            to='/my-students'
         />
         <Tab
            label='My Courses'
            value='/all-courses'
            component={Link}
            to='/all-courses'
         />
      </Tabs>
   );
});

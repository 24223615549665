import { Avatar } from '@mui/material';
import { useAvatar } from './index';

interface Props {
   name: string;
   className?: string;
}

export const AppAvatar: React.FC<Props> = ({ name, className }) => {
   const avatarProps = useAvatar(name);

   return <Avatar {...avatarProps} className={className} />;
};

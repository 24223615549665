// styles
import styles from './AnswerSubmitted.module.scss';

export const AnswerSubmitted = () => {
   return (
      <>
         <p className={styles.result}>
            Your Result:
            <span className={styles.result_info}>
               Wait for mentor review
            </span>
         </p>
         <p className={styles.result}>
            Status:
            <span className={`${styles.result_info} ${styles.info_color}`}>
               Answer submitted
            </span>
         </p>
      </>
   );
};

import { FC } from 'react';
import styles from './CellGroup.module.scss';

interface Props {
   group: string;
}

export const CellGroup: FC<Props> = ({ group }) => {
   return (
      <td className={styles.group}>
         <span className={styles.textOverflow}>{group}</span>
      </td>
   );
};

import { v4 } from 'uuid';
import { observer } from 'mobx-react-lite';

import { QuizTableRow } from '../index';
import { ErrorMessage } from '../../../../UI';
import { useReviewList } from '../../../hooks';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import styles from '../AnswerList.module.scss';

export const QuizReview = observer(() => {
   const { quizzes, error } = useReviewList();

   return (
      <div className={styles.tableWrapper}>
         {quizzes.length ? (
            <table>
               <tbody>
                  <tr>
                     <th>Group</th>
                     <th>Name</th>
                     <th className={styles.submissionCellHeader}>Submission
                        <ExpandMoreIcon fontSize='small'
                        className={styles.submissionCellHeader__icon} />
                     </th>
                     <th>Task</th>
                     <th className={styles.answer}>Answer</th>
                     <th>Rate</th>
                  </tr>

                  {quizzes.map((item) => (
                     <QuizTableRow key={v4()} quiz={item} />
                  ))}
               </tbody>
            </table>
         ) : (
            <p className={styles.info}>
               You currently have no review quizzes assigned
            </p>
         )}

         {error && <ErrorMessage className={styles.error} />}
      </div>
   );
});

import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import { useRootStore } from '../../../../../../hooks';
import { reviewAnswerFromStudent } from '../../../../../../http/mentorAPI';

import styles from './CellSubmission.module.scss';

interface Props {
   courseItemState: string;
   id: number;
   answer: string;
   submittedDateTime: string | undefined;
   setItemState: React.Dispatch<React.SetStateAction<string>>;
}

export const CellSubmission: FC<Props> = ({
   courseItemState,
   setItemState,
   id,
   answer,
   submittedDateTime,
}) => {
   const { mentorStore } = useRootStore();
   const refresh = mentorStore.getTaskRefresh();

   const handleClick = () => {
      reviewAnswerFromStudent(id).then(() =>
         mentorStore.setTaskRefresh(!refresh),
      );
      setItemState('Answer in review');
   };

   return (
      <td className={styles.submission}>
         {courseItemState === 'Answer submitted' ? (
            <button
               type='button'
               className={[styles.answerButton, styles.answer].join(' ')}
               onClick={handleClick}
            >
               Open answer
               <FontAwesomeIcon
                  icon={faUnlock}
                  color='#474c53'
                  size='sm'
                  style={{ marginLeft: '6px' }}
               />
            </button>
         ) : (
            <div className={styles.answerLinkWrapper}>
               <span className={styles.textOverflow}>
                  <a
                     className={[styles.answerLink, styles.answer].join(' ')}
                     href={answer}
                     target='_blank'
                  >
                     {answer}
                  </a>
               </span>
            </div>
         )}

         <span className={styles.date}>{submittedDateTime}</span>
      </td>
   );
};
